(function () {
    angular
        .module('app')
        .filter('replaceOrgName', replaceOrgName);

    var slugs = [
        {
            slug:"orgname",
            field:"name"
        },
        {
            slug:"orgshortname",
            field:"shortName"
        },
        {
            slug:"employee",
            phrase:"employee"
        },
        {
            slug:"cso",
            phrase:"cso"
        }
    ];

    function replaceOrgName () {
        return function (text, org) {
            if (!text) return "";
            if (!org) return text;

            var output = text;

            slugs.forEach(function(slug){

                if (slug.phrase) {
                    var replaceWith = org.phrase[slug.phrase] + "";
                } else {
                    var replaceWith = org[slug.field] + "";
                }

                var exp = new RegExp("\\[" + slug.slug + "\\]", "gi");
                output = output.replace(exp, replaceWith);

                var expPos = new RegExp("\\[" + slug.slug + "pos\\]", "gi");
                if (replaceWith.charAt(replaceWith.length - 1) == 's') {
                    replaceWithPos = replaceWith + "'";
                } else {
                    replaceWithPos = replaceWith + "'s";
                }

                output = output.replace(expPos, replaceWithPos);
            })

            return output;
        };
    }
})();
