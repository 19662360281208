angular.module('app').component('setupProfile', {
    templateUrl: '/assets/html/setup/setup-profile.html',
    controller: setupProfile,
    bindings: {
        onNext: '&',
        onBack: '&',
        user: '<'
    }
});

function setupProfile($scope, $mdToast, User) {
    var ctrl = this;

    ctrl.$onInit = function() {
        $scope.errors = [];
        $scope.user = ctrl.user;
    }

    $scope.update = function() {
        $scope.user.$save().then(function(usr) {
            $scope.errors = [];
            showToast("Profile saved.");
            ctrl.onNext();
        }, function(err, test) {
            $scope.errors = err.data.error;
        });
    };

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

}
