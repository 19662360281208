(function () {
    "use strict";

    angular.module("app").component("dashboard", {
        bindings: {
            categories: "="
        },

        transclude: true,

        template: `
            <div class="dashboard" flex="100" layout="column">
                <div class="dashboard-categories" layout="row">
                   <div ng-click="$ctrl.selectCategory($ctrl.categories.dashboard)" ng-class="{active: $ctrl.activeCategory.id == 'dashboard'}" class="dashboard-category margin-3" layout="column" layout-align="center" flex="10">
                        <div layout="row" layout-align="space-around space-around">
                            <div class="icon center" layout="column">
                                <icon type="home"></icon>
                                <icon ng-if="$ctrl.activeCategory.id != 'dashboard'" type="arrow-down"></icon>
                            </div>
                        </div>
                    </div>

                    <div ng-href="#{{category.id}}" ng-repeat="category in $ctrl.categoryValues" ng-class="{active: category.active}" ng-click="$ctrl.selectCategory(category)"
                        class="dashboard-category margin-3 padding-3" layout="column" layout-align="end" flex clickable>
                        <div layout="row" layout-align="stretch space-around" layout-fill>
                            <div layout="column" layout-align="center start">
                                <h4 class="padding-0 margin-0">{{category.title}}</h4>
                                <span flex></span>

                                <div class="icon">
                                    <icon type="{{category.icon}}"></icon>
        
									<badge ng-if="category.items.length != null" options="xl bottom">
										{{$ctrl.getCount(category)}}
									</badge>

									<badge ng-if="category.items.length == null" options="xl bottom">
										{{category.metrics.count}}
									</badge>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <md-divider></md-divider>

                <ng-transclude></ng-transclude>
            </div>
        `,

        controller: function ($location, $element) {
            var ctrl = this;

            window.addEventListener("hashchange", hashChange);

            function hashChange(a) {
                var hash = $location.hash();

                if (hash) {
                    var category = ctrl.categories[hash];

                    if (category) {
                        ctrl.selectCategory(category);
                    }
                }
            }

            ctrl.$onInit = function () {
                ctrl.view = "table";
                ctrl.categoryValues = _.values(_.omit(ctrl.categories, ["dashboard"]));

                hashChange();

                if (!ctrl.activeCategory) {
                    ctrl.activeCategory = ctrl.categories.dashboard;
                }
            }

            ctrl.getCount = function (category) {
                return category.items.filter(category.filter({})).length;
            }

            ctrl.toggleView = function () {
                if (ctrl.view == "table") {
                    ctrl.view = "grid";
                } else {
                    ctrl.view = "table";
                }
            }

            ctrl.selectCategory = function (category) {
                ctrl.categoryValues.forEach((c) => {
                    if (c.active) c.active = false;
                });

                if (category) {
                    category.active = true;

                    if (!category.items && category.populate) {
                        category.items = category.populate();
                    }
                }

                $location.hash(category.id)

                ctrl.activeCategory = category;
            }
        }
    });
})();
