(function () {
    angular.module("app").controller("VendorInviteController", VendorInviteController);

    function VendorInviteController ($scope, $location, $http) {

        $scope.ready = false;
        $scope.success = false;
        $scope.vendor = "";
        $scope.error = "";

        var urlPieces = $location.absUrl().split("/");

        if (urlPieces.length == 8) {
            var userOrgId = urlPieces[urlPieces.length - 1];
            var userId = urlPieces[urlPieces.length - 2];
            var vendorId = urlPieces[urlPieces.length - 3];

            $http.post(`/api/vendor/acceptinvite`, {
                vendorId: vendorId,
                userId: userId,
                userOrgId:userOrgId
            }).then( function(res) {
                $scope.success = true;
                $scope.vendor = res.data.vendorName;
                $scope.organization = res.data.organizationName;
                $scope.ready = true;
            }).catch(function(res){
                $scope.error = res.data.reference;
                $scope.success = false;
                $scope.ready = true;
            });
        } else {
                $scope.error = "Invalid vendor invitation";
                $scope.success = false;
                $scope.ready = true;
        }


    }
})();
