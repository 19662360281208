(function () {
    angular.module("app").controller("PolicyOverviewReportController", function ($scope, $http) {
        let users = [];

        $scope.data = {
            filter: {
                policy: null,
                containerType: null,
                assignedTo: null,
                showAcknowledged: true,
                showUnacknowledged: true,
                search: "",
                user: {
                    status: {
                        active: true,
                        archived: false,
                        disabled: false,
                        pending: false
                    }
                },  
            }
        }

        $scope.showMetrics = true;

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }

        $http.get("/api/reporting/policy-overview").then(res => {
            $scope.data.result = res.data;
            $scope.data.loaded = true;

            $http.get("/api/users?activity=policy").then(res => {
                users = res.data;

                generateReport();
            }).catch(console.error);
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);

        function filterFn (item) {
            const options = $scope.data.filter;

            if (!item.user) {
                return true;
            }
    
            if (!options.user.status.active) {
                if (item.user.status == "active") {
                    return false;
                }
            }

            if (!options.user.status.archived) {
                if (item.user.status == "archived") {
                    return false;
                }
            }
            
            if (!options.user.status.pending) {
                if (item.user.status == "pending") {
                    return false;
                }
            }
            
            if (!options.user.status.disabled) {
                if (item.user.status == "disabled") {
                    return false;
                }
            }

            // Filter specific user
            if (options.assignedTo) {
                if (options.assignedTo._id != item.user._id) {
                    return false;
                }
            }

            if (item.data) {
                //Filter ack'd
                if (!options.showAcknowledged) {
                    if (item.data.acknowledged == item.data.total) {
                        return false;
                    }
                }

                if (!options.showUnacknowledged) {
                    if (item.data.acknowledged != item.data.total) {
                        return false;
                    }
                }
            }


            // Filter text search
            if (options.search.length) {
                const search = options.search.toLowerCase();
                return item.user.profile.name.toLowerCase().includes(search);
            }

            return true;
        }

        $scope.filterFn = filterFn;

        function generateReport() {
            $scope.data.report = $scope.data.result;
            $scope.data.report.metrics = generateMetrics($scope.data.report.data);
        }

        function generateMetrics(report) {
            const metrics = {};
            const filter = filterFn;

            metrics.totalUsers = users.filter(user => {
                return filter({
                    user,
                    data: false,
                });
            }).length;

            metrics.acknowledgedUsers = report.entries.filter(filter).reduce((accumulator, current) => {
                if (current.data.acknowledged == current.data.total) {
                    accumulator++;
                }

                return accumulator;
            }, 0);

            metrics.percentTrained = _.round(100 * (metrics.acknowledgedUsers / metrics.totalUsers), 1);

            metrics.acknowledgedPolicies = report.entries.filter(filter).reduce((accumulator, current) => {
                return accumulator += current.data.acknowledged;
            }, 0);

            metrics.assignedPolicies = report.entries.filter(filter).reduce((accumulator, current) => {
                return accumulator += current.data.total;
            }, 0) - metrics.acknowledgedPolicies;

            return metrics;
        }
    });
})();

function policyOverviewReportFilter(options) {
    return (item) => {
        if (!item.user) {
            return true;
        }

        if (!item.data) {
            if (item.data !== false) {
                return true;
            }
        }

        if (!options.showDisabledUsers) {
            if (item.user.disabled) {
                return false;
            }
        }

        if (options.assignedTo) {
            if (options.assignedTo._id != item.user._id) {
                return false;
            }
        }

        if (!options.showAcknowledged) {
            if (item.data.acknowledged == item.data.total) {
                return false;
            }
        }

        if (!options.showUnacknowledged) {
            if (item.data.acknowledged != item.data.total) {
                return false;
            }
        }

        if (options.search.length) {
            const search = options.search.toLowerCase();

            return item.user.profile.name.toLowerCase().includes(search);
        }

        return true;
    }
}