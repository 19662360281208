/**
 * @ngdoc component
 * @name app.control.component:controlStateGrapth
 * @author Nick Vorie
 *
 * @param {arraay}   controlStates The ControlStates to generate the graph for
 * @param {function} onClick Callback with index of clicked ControlState
 *
 * @description This component will render a graph for a set of given ControlStates
 */
(function () {
	"use strict";

	angular.module("app").component("riskChart", {
		bindings: {
			controlStates: "=",
			onClick: "=?"
		},

		template: `
            <div flex layout="row" layout-align="center center">
                <canvas class="chart" width="400" height="300"></canvas>
            </div>
        `,

		controller: function ($element, Cvss) {
			var ctrl = this;

			var container = $element.find("div")[0];
			ctrl.width = container.offsetWidth - 20;

			ctrl.$onInit = function () {
				var controlStates = _.chain(ctrl.controlStates).cloneDeep().reverse().slice(0, 10).reverse().value();

				var minResidualRisks = _.map(controlStates, (controlState) => {
					return _.get(controlState, "metrics[2].value.min");
				});

				var maxResidualRisks = _.map(controlStates, (controlState) => {
					return _.get(controlState, "metrics[2].value.max");
				});

				var controlsInPlace = _.map(controlStates, (controlState) => {
					return _.get(controlState, "metrics[0].value");
				});

				var weightedRisk = _.map(controlStates, (controlState) => {
					return _.get(controlState, "metrics[3]") ? _.get(controlState, "metrics[3].value") : 0;
				});

				var acceptedScores = _.map(controlStates, (controlState) => {
					return _.get(controlState, "metrics[4]") ? _.get(controlState, "metrics[4].value") : 0;
				});

				setTimeout(function () {
					var ctx = $element.find("canvas")[0].getContext("2d");

					var months = _.map(controlStates, (controlState) => {
						return moment(controlState.date).format("MM/DD/YYYY");
					});

					var config = {
						type: 'bar',
						options: {
							showLines: true,
							scales: {
								yAxes: [{
									id: "controls",
									type: "linear",
									position: "left",
									scaleLabel: {
										display: true,
										labelString: "% Controls in Place",
										fontColor: "#73AE57"
									},
									gridLines: {
										display: true
									},
									ticks: {
										min: 0,
										max: 100
									}
								}, {
									id: "risk",
									type: "linear",
									position: "right",
									scaleLabel: {
										display: true,
										labelString: "Risk (CVSS)",
										fontColor: "#AA2D2C"
									},
									gridLines: {
										display: false
									},
									ticks: {
										min: 0,
										max: 10
									}
								}]
							}
						},
						data: {
							labels: months,
							datasets: [{
									data: weightedRisk,
									label: "Weighted Risk",
									type: "line",
									borderColor: "#AA2D2C",
									borderWidth: 4,
									backgroundColor: "#AA2D2C",
									pointHoverBackgroundColor: "#e08585",
									pointHoverBorderColor: "#e08585",
									pointHoverRadius: 5,
									pointBackgroundColor: "#AA2D2C",
									pointBorderColor: "#AA2D2C",
									pointRadius: 3,
									fill: false,
									yAxisID: "risk"
								},
								{
									data: maxResidualRisks,
									label: "Base Risk",
									type: "line",
									borderColor: "#000000",
									borderWidth: 5,
									backgroundColor: "#000000",
									pointHoverBackgroundColor: "#666666",
									pointHoverBorderColor: "#666666",
									pointHoverRadius: 7,
									pointBackgroundColor: "#000000",
									pointBorderColor: "#000000",
									pointRadius: 5,
									fill: false,
									yAxisID: "risk"
								},
								{
									data: acceptedScores,
									label: "Accepted Risk",
									type: "line",
									borderColor: "#AAAAAA",
									borderWidth: 7,
									backgroundColor: "#AAAAAA",
									pointHoverBackgroundColor: "#cccccc",
									pointHoverBorderColor: "#cccccc",
									pointHoverRadius: 9,
									pointBackgroundColor: "#AAAAAA",
									pointBorderColor: "#AAAAAA",
									pointRadius: 7,
									fill: false,
									yAxisID: "risk"
								},
								{
									data: controlsInPlace,
									label: "Controls in Place",
									backgroundColor: "#73AE57",
									borderColor: "#73AE57",
									hoverBackgroundColor: "#b7d6a9",
									hoverBorderColor: "#b7d6a9",
									yAxisID: "controls"
								}
							]
						},


					}

					ctrl.chart = new Chart(ctx, config);
				}, 500);
			}
		}
	});
})();