(function () {
    "use strict";

    angular.module("app").component("forgotPasswordCard", {
        template: `
            <md-card layout="column">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span class="md-headline">Forgot Password</span>
                    </md-card-title-text>
                </md-card-title>

                <md-divider></md-divider>

                <md-card-body ng-if="$ctrl.state == 'info'" layout="column">
                    <div class="padding-2" layout="column">
                        <p class="padding-2-b">To reset your password, enter the email address you use to sign-in.</p>

                        <md-input-container class="margin-0-b">
                            <label>Email/Username</label>
                            <input ng-model="$ctrl.data.email" name="email" autofocus>
                        </md-input-container>
                    </div>

                    <md-button class="md-raised md-primary" ng-click="$ctrl.reset()">Reset Password</md-button>
                    <md-button class="md-flat md-primary" ng-href="/login">Back to Login</md-button>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'load'" layout="column" layout-align="center center">
                    <md-progress-circular class="margin-y" md-mode="indeterminate"></md-progress-circular>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'success'" layout="column">
                    <div class="padding-2" layout="column">
                        <p class="padding-2-b">We sent the instructions for resetting your password to the email address we have on file. Please check your inbox (and double-check the junk/spam folder too).</p>
                    </div>

                    <md-button class="md-raised md-primary" ng-href="/login">Back to Login</md-button>
                </md-card-body>
            </md-card>
        `,

        controller: function ($http) {
            var ctrl = this;

            ctrl.state = "info";

            ctrl.reset = function () {
                ctrl.state = "load";

                $http.post("/api/auth/reset-password/request/", {
                    email: ctrl.data.email
                }).then(function (response) {
                    ctrl.state = "success";
                }, function (response) {
                    ctrl.state = "success";
                });
            }
        }
    })
})();
