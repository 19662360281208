(function () {
    "use strict";

    angular.module("app").component("navHelp", {
        bindings: {
            node: "@"
        },

        /* html */
        template: `
            <md-menu md-position-mode="target-right target" md-offset="0 50">
                <small class="clickable" ng-click="$mdMenu.open($event)">
                    <span>Help <md-icon md-font-icon="fa-caret-down" class="fas"></md-icon></span>
                </small>

                <md-menu-content class="padding-0-y" width="3">
                    <md-menu-item ng-if="false">
                        <md-button href="/help">
                            <span>Quick-Start Guide</span>
                        </md-button>
                    </md-menu-item>

                    <md-menu-item ng-if="false">
                        <md-button href="/help">
                            <span>FAQs</span>
                        </md-button>
                    </md-menu-item>

                    <md-menu-item>
                        <md-button href="/documents/help">
                            <span>Documentation</span>
                        </md-button>
                    </md-menu-item>

                    <md-menu-item>
                        <md-button ng-click="$ctrl.openSupport()">
                            <span>Contact Support</span>
                        </md-button>
                    </md-menu-item>

                    <md-menu-item>
                        <md-button ng-click="$ctrl.openAbout()">
                            <span>About</span>
                        </md-button>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        `,

        controller: function ($mdDialog, $mdToast, $http, Support) {
            var ctrl = this;

            ctrl.openAbout = function () {
                $mdDialog.show({
                    templateUrl: "/assets/html/ui-kit/about-modal.html",
                    clickOutsideToClose: true,

                    controller: function ($scope) {
                        $scope.$mdDialog = $mdDialog;

                        $scope.year = moment().format("YYYY");

                        $http.get("/api/info").then(res => {
                            $scope.info = res.data;
                            $scope.info.node = ctrl.node
                        });
                    }
                });
            }

            ctrl.openSupport = function () {
                Support.supportModal().then(function () {
                    $mdToast.show($mdToast.simple().textContent("Feedback submitted."));
                }, function () {});
            }
        }
    })
})();