(function () {
	angular.module("app").controller("SwitchOrganizationController", function ($scope, $http, Session) {
		$scope.Session = Session;

		$scope.switch = function (organization) {
			if (organization.disabled || organization.archived) {
				return;
			}

			Session.switchOrganization(organization);
		}
	});
})();
