angular.module('app').component('setupLocations', {
    templateUrl: '/assets/html/setup/setup-locations.html',
    controller: setupLocations,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
    }
});

function setupLocations($scope, $mdToast, $mdDialog, Site, Container) {
    var ctrl = this;

    $scope.sites = [];
    $scope.workAtList = ["workforce members' residences","coffee shops and remote locations","hotels or conferences","vehicles","third party facilities"];

    ctrl.$onInit = function() {

        $scope.Container = Container;
        $scope.organization = ctrl.organization;

        Site.Resource.query().$promise.then(function (sites) {
            $scope.sites = sites;
        });
    }

    $scope.update = function() {

        $scope.organization.$save().then(function(org) {
            showToast("Locations saved.");
            ctrl.onNext();
        }, function(err, test) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });
    };

    $scope.industryChanged = function() {

        $scope.organization.industry.requirements.forEach( function(indReq) {
            var found = false;
            $scope.organization.compliance.requirements.forEach( function(orgReq) {
                if(indReq._id == orgReq || indReq._id == orgReq._id) {
                    found = true;
                }
            });
            if (!found) {
                $scope.organization.compliance.requirements.push(indReq);
            }
        });


    }

    $scope.checkWorkAt = function(workAt) {
        var idx = $scope.organization.policy.conductBusinessAt.indexOf(workAt);
        if (idx > -1) {
            return true;
        } else {
            return false;
        }

    }

    $scope.toggleWorkAt = function(workAt) {
        var idx = $scope.organization.policy.conductBusinessAt.indexOf(workAt);

        if (idx > -1) {
            $scope.organization.policy.conductBusinessAt.splice(idx,1);
        } else {
            $scope.organization.policy.conductBusinessAt.push(workAt);
        }
    }

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
