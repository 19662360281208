(function () {

	angular.module("app").component("technologyTable", {
		template: `
			<md-card>
				<table class="material">
					<thead>
						<tr>
							<th>Name</th>
							<th>Type</th>
							<th>Status</th>
							<th>Owner</th>

							<th>Assessed</th>
							<th>Controls</th>
							<th>C-H-M-L Threats</th>
							<th>Base</th>
							<th>Weighted</th>

							<th></th>
						</tr>
					</thead>

					<tbody>
						<tr ng-click="$ctrl.onRowClick(technology)" ng-repeat="technology in $ctrl.technologies | orderBy:'+name' | filter:$ctrl.customFilter()" ng-init="threatScores = $ctrl.threatScores(technology)" ng-style="$ctrl.getStyle(technology)">
							<td>
								<md-icon md-font-icon="{{technology.type.icon || 'fa-laptop'}}" class="fa fa-2x margin-r"></md-icon>
								<span>{{technology.name}}</span>
							</td>

							<td>{{technology.type.title}}</td>

							<td>{{technology.archived ? 'Archived' : 'Active'}}</td>

							<td>{{technology.owner.profile.name}}</td>

							<td>
								<chip ng-if="!$ctrl.lastAssessment(technology)" type="xs danger">Never</chip>
								<chip ng-if="$ctrl.lastAssessment(technology)" type="xs">{{$ctrl.lastAssessmentDate(technology)}}</chip>
							</td>

							<td>
								<div class="progress">
									<md-tooltip>{{$ctrl.controlsInPlace(technology)}}%</md-tooltip>
									<div class="progress-bar progress-bar-success" style="width: {{$ctrl.controlsInPlace(technology)}}%"></div>
								</div>
							</td>

							<td ng-init="threatCount = $ctrl.threatCount(technology)">
								<chip type="xs {{!!threatCount.critical ? 'cvss-critical' : ''}}">
									<span>{{threatCount.critical || "-"}}</span>
									<md-tooltip>Critical</md-tooltip>
								</chip>

								<chip type="xs {{!!threatCount.high ? 'cvss-high' : ''}}">
									<span>{{threatCount.high || "-"}}</span>
									<md-tooltip>High</md-tooltip>
								</chip>

								<chip type="xs {{!!threatCount.medium ? 'cvss-medium' : ''}}">
									<span>{{threatCount.medium || "-"}}</span>
									<md-tooltip>Medium</md-tooltip>
								</chip>

								<chip type="xs {{!!threatCount.low ? 'cvss-low' : ''}}">
									<span>{{threatCount.low || "-"}}</span>
									<md-tooltip>Low</md-tooltip>
								</chip>
							</td>

							<td ng-init="baseRisk = $ctrl.baseRisk(technology)">
								<chip type="xs cvss-{{baseRisk.severity.name.toLowerCase()}}">
									<span>{{baseRisk.score}}</span>
									<md-tooltip>{{baseRisk.severity.name}}</md-tooltip>
								</chip>
							</td>

							<td ng-init="weightedRisk = $ctrl.weightedRisk(technology)">
								<chip type="xs cvss-{{weightedRisk.severity.name.toLowerCase()}}">
									<span>{{weightedRisk.score}}</span>
									<md-tooltip>{{weightedRisk.severity.name}}</md-tooltip>
								</chip>
							</td>

							<td class="action">
								<md-menu ng-if="!$ctrl.isAuditor" md-position-mode="target-right target" md-offset="0 50">
									<md-button ng-click="$mdMenu.open($event)" class="md-icon-button">
										<i class="fas fa-ellipsis-v"></i>
									</md-button>

									<md-menu-content>
										<md-menu-item>
											<md-button ng-click="$ctrl.Assessment.start($event, technology, 'technology')">
												<md-icon md-font-icon="fa-calendar" class="fa"></md-icon>
												<span>Schedule</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-href="/technology/{{technology._id}}">
												<md-icon md-font-icon="fa-eye" class="fa"></md-icon>
												<span>Details</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-click="$ctrl.Container.edit($event, $ctrl.technologies, technology, 'technology')">
												<md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
												<span>Edit</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-if="!technology.archived" ng-click="$ctrl.Container.archive($event, $ctrl.technologies, technology, 'technology')">
												<md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
												<span>Archive</span>
											</md-button>
													
											<md-button ng-if="technology.archived" ng-click="$ctrl.Container.archive($event, $ctrl.technologies, technology, 'technology')">
												<md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
												<span>Restore</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-click="$ctrl.Container.deleteItem($event, $ctrl.technologies, technology, 'technology')">
												<md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
												<span>Delete</span>
											</md-button>
										</md-menu-item>
									</md-menu-content>
								</md-menu>
							</td>
						</tr>
					</tbody>
				</table>
			</md-card>
		`,

		bindings: {
			technologies: "=",
			filter: "=",
			organization: "=",
		},

		controller: function ($mdDialog, $mdToast, $http, Container, Assessment, ControlState, Organization, Cvss, User) {
			var ctrl = this;

			ctrl.Assessment = Assessment;
			ctrl.Container = Container;

			ctrl.$onInit = () => {
				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});
			}

			if (!ctrl.organization) {
				ctrl.organization = Organization.current;
			}

			ctrl.customFilter = function () {
				return function (container) {
					if (!ctrl.filter) {
						return false;
					}

					if (container.archived == true) {
						if (!ctrl.filter.archived) {
							return false;
						}
					}

					if (_.get(ctrl.filter, "name")) {
						return container.name.toLowerCase().includes(ctrl.filter.name.toLowerCase());
					}

					return true;
				}
			}

			ctrl.getStyle = function (container) {
				if (container.archived) {
					return {
						opacity: .25
					}
				}

				return {};
			}


			ctrl.onRowClick = function (technology) {
				window.location = "/technology/" + technology._id
			}

			//Data
			ctrl.lastAssessment = function (technology) {
				var assessments = _.filter(technology.assessment.history, "date.submitted");
				var assessment = assessments[assessments.length - 1];

				if (!_.get(assessment, "date.submitted")) {
					return null;
				}

				return assessment;
			}

			ctrl.lastAssessmentDate = function (technology) {
				return moment(ctrl.lastAssessment(technology).date.submitted).format("MM/DD/YYYY");
			}

			ctrl.controlsInPlace = function (technology) {
				if (!_.get(technology, "controlState.current.controlStates")) {
					return 0;
				}

				return ControlState.controlsInPlace(technology.controlState.current.controlStates);
			}

			ctrl.threatCount = function (technology) {
				if (!_.get(technology, "controlState.current.controlStates")) {
					return 0;
				}

				return ControlState.threatCount(technology.controlState.current.threatRanges);
			}

			ctrl.baseRisk = function (technology) {
				if (!_.get(technology, "controlState.current.controlStates")) {
					return 0;
				}

				var score = _.round(_.get(technology.controlState.current.metrics, "2.value.max", 0), 2);
				var severity = Cvss.getSeverity(score);

				return {
					score,
					severity
				}
			}

			ctrl.weightedRisk = function (technology) {
				if (!_.get(technology, "controlState.current.controlStates")) {
					return 0;
				}

				var score = _.round(_.get(technology.controlState.current.metrics, "3.value", 0), 2);
				var severity = Cvss.getSeverity(score);

				return {
					score,
					severity
				}
			}
		},

	});
})();