angular.module('app').component('setupOrg', {
    templateUrl: '/assets/html/setup/setup-org.html',
    controller: setupOrg,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
        user: '<'
    }
});

function setupOrg($scope, $mdToast, $mdDialog, Site) {
    var ctrl = this;

    ctrl.$onInit = function() {
        $scope.organization = ctrl.organization;

        // TODO: Don't need to get back all the sites, and don't need to get back control state
        Site.Resource.query({}, {name:1, manager:1, phone:1, address:1, type:1}).$promise.then(function (sites) {
            if (sites.length > 0) {
                $scope.site = sites[0];
            } else {
                $scope.site = new Site.Resource();
            }
        });

    }

    $scope.update = function() {

        if ($scope.site.type == null || $scope.site.type == "") {
            return;
        }

        $scope.organization.$save().then(function(usr) {
            $scope.site.manager = ctrl.user;
            $scope.site.name = $scope.organization.name;

            $scope.site.$save().then(function(usr) {

                showToast("Organization details saved.");
                ctrl.onNext();

            }, function(err, test) {
                 // save failed, should tell the user
                  //console.log("save existing didn't work ", err);
            });
        }, function(err, test) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });

    };

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };
}
