/**
 * @ngdoc component
 * @name app.container.component:container
 * @author Nick Vorie
 *
 * @param {object} container The Container to render the view for
 * @param {string} type      The type of Container
 *
 * @description
 * This component will render a detailed overview for a Control. Meta-data, Control State, Assessments and Remediation
 */
(function () {
    "use strict";

    angular.module("app").component("container", {
        bindings: {
            container: "=",
            loaded: "=",
            type: "@"
        },

        /*html*/
        template: `
            <nav-toolbar>
                <toolbar-title> 
                    <md-breadcrumbs>
                        <a href="/">Home</a>

                        <a ng-if="$ctrl.type == 'user' && $ctrl.isUserAdmin" href="/users">People</a>
                        <a ng-if="$ctrl.type == 'user' && !$ctrl.isUserAdmin" href="/#user">People</a>

                        <a ng-if="$ctrl.type == 'domain'" href="/#domain">Organization</a>
                        <a ng-if="$ctrl.type == 'site'" href="/#site">Places</a>
                        <a ng-if="$ctrl.type == 'technology'" href="/#technology">Technologies</a>
                        <a ng-if="$ctrl.type == 'vendor'" href="/#vendor">Vendors</a>

                        <a ng-if="$ctrl.type != 'user'" ng-href="/{{$ctrl.type}}/{{$ctrl.container._id}}">{{$ctrl.container.name}}</a>

                        <a ng-if="$ctrl.type == 'user'" ng-href="/{{$ctrl.type}}/{{$ctrl.container._id}}">{{$ctrl.container.profile.firstName}} {{$ctrl.container.profile.lastName}}</a>
                    </md-breadcrumbs>
                </toolbar-title>
            </nav-toolbar>

            <div ng-if="$ctrl.loaded && $ctrl.container._id" layout="row">
                <div layout="column" flex="40" layout-align="start stretch">
                    <container-card class="test" container="$ctrl.container" type="{{$ctrl.type}}" show="{metrics:true,chip: true,media:true,assessmentIcon:true,viewIcon:false,editIcon:true,deleteIcon:true}"></container-card>

					<container-task-card container="$ctrl.container" container-type="{{$ctrl.type}}"></container-task-card>

					<procedures-card container="$ctrl.container" container-type="{{$ctrl.type}}"></procedures-card>

					<risk-profile ng-if="$ctrl.type != 'user'" container="$ctrl.container"></risk-profile>

					<div id="metrics"></div>
                </div>

                <div ng-if="$ctrl.type != 'user'" layout="column" flex="60">
                    <control-state ng-if="$ctrl.container.controlState" state="$ctrl.container.controlState" container="$ctrl.container" container-type="{{$ctrl.type}}"></control-state>
                </div>
            </div>

            <div ng-if="$ctrl.loaded != true" class="m-t" layout="row" layout-align="center center">
                <md-progress-circular md-mode="indeterminate"></md-progress-circular>
            </div>

            <div ng-if="$ctrl.loaded && !$ctrl.container._id" layout="row" layout-align="center center">
                <p class="margin-t">Not found</p>
            </div>
        `,

        controller: function (User) {
            var ctrl = this;

            ctrl.$onInit = function () {
				User.checkRoles(["user-admin"]).then(result => {
					ctrl.isUserAdmin = result;
				});            
            }
        }
    });
})();