(function () {
    "use strict";
    
    angular.module("app").factory("Site", SiteFactory);
    
    function SiteFactory ($resource, ModalFactory) {
        
        var SiteResource = $resource("/api/sites/:id", {
            id: "@_id"
        });
        
        function SiteFactory () {
            var self = this;
            
            self.Resource = SiteResource;
            
            self.instance = instance;

            self.pickMeta = pickMeta;
            
            function instance () {
                return new SiteResource({
                });
            }

            function pickMeta (site) {
                return _.pick(site, ["_id", "name", "description", "manager", "phone", "address",  "image", "$save"]);
            }
            
            return self;
        }
        
        return new SiteFactory();
    }
})();
