(function () {
    "use strict";

    angular.module("app").directive("imageError", function () {
        return {
            restrict: "A",

            scope: {
                onError: "&"
            },

            link: function (scope, element, attr) {
                element.bind("error", function () {
                    element.css({
                        display: "none"
                    });

                    if (scope.onError) {
                        scope.onError();
                    }
                });
            }
        };
    });
})();
