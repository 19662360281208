/**
 * @ngdoc component
 * @name app.uiKit.component:loader
 * @author Nick Vorie
  *
 * @param {boolean} loaded Binding used to determine if the loader should render the transcluded view code
 *
 * @transclude The view code to render
 *
 * @description This component will render a md-spinner until loaded is a truthy value, and then render the transcluded view code
 */
(function () {
    "use strict";

    angular.module("app").component("loader", {
        bindings: {
            loaded: "=?"
        },

        transclude: true,

        template: `
            <div ng-if="$ctrl.loaded" ng-transclude></div>

            <div class="margin" ng-if="!$ctrl.loaded" layout="row" layout-align="center center" layout-fill>
                <md-progress-circular md-mode="indeterminate"></md-progress-circular>
            </div>
        `,
    });
})();
