(function () {
    angular.module("app").component("orgCard", {
        bindings: {
            org: "=",
            onDelete: "&",
            onEdit: "&"
        },

        template: `
            <md-card>
                <md-card-content layout="column" style="min-height:110px">
                    <div layout flex>
                        <div flex="20">
                            <img width="40px" ng-src="{{$ctrl.org.settings.template.logo.url}}"/>
                        </div>

                        <div flex>
                            <h5 class="truncate">{{$ctrl.org.name}}</h5>
                            <p>{{$ctrl.org.industry.name}}</p>
                        </div>
                    </div>
                    <div flex>
                        <value-chip title="'Users'" value-color="$ctrl.org.userChipColor" value="$ctrl.org.userChipValue"></value-chip>
                        <value-chip title="'Containers'" value-color="$ctrl.org.containerChipColor" value="$ctrl.org.containerChipValue"></value-chip>

                        <p style="margin-top:6px">
                            <i ng-show="$ctrl.org.status.licensed" style="color:green" class="fas fa-check fa-sm"></i>
                            <i ng-show="!$ctrl.org.status.licensed" style="color:red" class="fas fa-times fa-sm"></i>
                            Licensed <span ng-show="$ctrl.org.status.licensed">{{$ctrl.org.license.term}}</span>

                            <span style="padding-left:12px" ng-show="$ctrl.org.license.type == 'reseller'"><i style="color:green" class="fas fa-check fa-sm"></i> Reseller</span>
                        </p>
                    </div>

                </md-card-content>

                <md-divider></md-divider>

                <md-card-actions flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">

                        <md-button class="md-icon-button" ng-href="/users/{{$ctrl.org._id}}" aria-label="Users">
                            <md-tooltip>Users</md-tooltip>
                            <md-icon md-font-icon="fa-users" class="fa"></md-icon>
                        </md-button>

                        <md-button class="md-icon-button" ng-click="$ctrl.onEdit({org:$ctrl.org})" aria-label="Edit">
                            <md-tooltip>Edit</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>

                        <md-button class="md-icon-button" ng-click="$ctrl.onDelete({org:$ctrl.org})" aria-label="Delete">
                            <md-tooltip>Delete</md-tooltip>
                            <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                        </md-button>
                </md-card-actions>
            </md-card>
        `,

        controller: function () {
            var ctrl = this;
        },

    });
})();
