(function () {
    "use strict";

    angular.module("app").run(function (ModalFactory) {
        //Register modal templare with service

        ModalFactory.templates["userRoleSelect"] = {
            templateUrl: "/assets/html/user-role/user-role-select-modal/wrapper.html",
            position: {
                modX: 0,
                modY: 0
            },
        }
    }).component("userRoleSelectModal", {
        templateUrl: "/assets/html/user-role/user-role-select-modal/modal.html",

        require: {
            parent: "^^modal"
        },

        bindings: {
        },

        controller: function (UserRole) {
            var ctrl = this;

            ctrl.roles = UserRole.resource.query();

            ctrl.onClick = onClick;

            function onClick ($event, role) {
                ctrl.role = role;
            }

            ctrl.submit = submit;

            function submit ($event) {
                ctrl.parent.dismiss($event);
                ctrl.parent.modal.onSubmit(ctrl.role);
            }
        }
    });
})();
