(function () {
    "use strict";
    
    angular.module("app").component("step", {
        templateUrl: "/assets/html/core/wizard/step.html",
        
        bindings: {
            title: "@",
            icon: "@"
        },
        
        require: {
            parent: "^^wizard"   
        },
        
        transclude: {
            body: "stepBody",
            control: "stepControl",
        },
        
        controller: function () {
            this.$onInit = function () {
                this.parent.addStep(this);
            }
        }
    });    
})();
