(function () {
    angular.module("app").controller("SetupLiteController", SetupLiteController);

    function SetupLiteController ($scope, $mdToast, User, Session, Organization) {

        $scope.ready = false;

        this.$onInit = function() {
            $scope.errors = [];

            Organization.current.$promise.then(function (org) {
                $scope.organization = org;
            });

            Session.currentUser.$promise.then(function (user) {
                $scope.user = user;
                $scope.ready = true;
            });

        }

        $scope.update = function() {
            $scope.user.$save().then(function(usr) {

                $scope.organization.shortName = $scope.organization.name;
                $scope.organization.settings.setupComplete = true;

                $scope.organization.$save().then(function(usr) {

                showToast("Setup complete.");
                setTimeout(() => {
                    window.location = "/";
                }, 500);

                }, function(err, test) {
                    $scope.errors = err.data.error;
                });

            }, function(err, test) {
                $scope.errors = err.data.error;
            });

        };

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

    }
})();
