/**
 * @ngdoc service
 * @name app.task.service:Task
 * @author Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Tasks
 */
(function () {
    "use strict";

    angular.module("app").factory("Task", function ($resource, $http, $mdDialog, $mdToast, Technology) {
        var self = this;

        var Resource = $resource("/api/tasks/my-tasks", {
            id: "@_id"
        });

        self.Resource = Resource;

		self.currentTasks = Resource.query();

		self.currentTasks.$promise.then(() => {
			setInterval(() => {
				var tasks =  Resource.query();

				tasks.$promise.then(() => {
					self.currentTasks = tasks;
				})
			}, 30000);
		});

        return self;
    });
})();
