(function () {
	"use strict";

	angular.module("app").component("instruction", {
		bindings: {
			id: "@",
		},

		template: `
            <md-card layout="column" ng-if="$ctrl.instruction && !$ctrl.instruction.dismissed">
				<md-toolbar>
					<div class="md-toolbar-tools">
						<md-icon md-font-icon="fa-lightbulb" class="fas margin-r"></md-icon>

						<h2>{{$ctrl.instruction.title}}</h2>

						<span flex></span>

						<md-button class="md-icon-button" ng-click="$ctrl.dismiss()">
							<md-icon md-font-icon="fa-times" class="fa"></md-icon>
						</md-button>
					</div>
				</md-toolbar>

                <md-card-body class="padding-2">
					<div ng-bind-html="$ctrl.instruction.description"></div>
                </md-card-body>
            </md-card>
        `,

		controller: function (Instruction) {
			var ctrl = this;

			ctrl.$onInit = () => {
				Instruction.get(ctrl.id, (error, instruction) => {
					ctrl.instruction = instruction;
				});
			}

			ctrl.dismiss = () => {
				ctrl.instruction.dismissed = true;

				Instruction.dismiss(ctrl.id);
			}
		}
	});
})();