(function () {
	angular.module("app").component("organizationConfiguration", {
		bindings: {
			organization: "=",
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Organization</h3>

					<span class="md-subhead md-block">
						<content content-id="config.organization.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>
			
			<md-card-content>
				<h5>Name</h5>

				<form name="OrganizationConfigurationForm">
					<md-input-container class="md-block">
						<label for="organizationName">What is the name of your organization?</label>
						<input type="text" name="name" ng-model="$ctrl.organization.name" required>

						<div ng-messages="OrganizationConfigurationForm.name.$error">
							<div ng-message="required">This is required!</div>
						</div>
					</md-input-container>

					<md-input-container class="md-block">
						<label for="organizationShortName">What do you call your organization (abbreviation)?</label>
						<input type="text" name="shortName" ng-model="$ctrl.organization.shortName" required>

						<div ng-messages="OrganizationConfigurationForm.shortName.$error">
							<div ng-message="required">This is required!</div>
						</div>
					</md-input-container>

					<md-input-container class="md-block">
						<label for="industry">What is your industry?</label>

						<md-select name="industry" ng-model="$ctrl.organization.industry" ng-model-options="{trackBy: '$value._id'}">
							<md-option ng-repeat="industry in $ctrl.industries | orderBy: 'name'" ng-value="industry">
								{{industry.name}}
							</md-option>
						</md-select>
					</md-input-container>
				</form>
			</md-card-content>

			<md-divider></md-divider>
			
			<md-card-content>
				<h5>Logo</h5>

				<form>
					<div layout="column">
						<div layout="row" ngf-select="$ctrl.setResetLogo(false)" ng-model="$ctrl.logo" name="logo" ngf-pattern="'image/*'" ngf-accept="'image/*'" ngf-max-size="10MB">
							<logo ng-if="$ctrl.organization.settings.template.logo.url && !$ctrl.logo" fallback="false" class="clickable file-upload"></logo>
							<img ng-if="!$ctrl.logo.reset" class="clickable file-upload logo" flex="100" ngf-thumbnail="$ctrl.logo">
						</div>

						<div layout="row" layout-align="end end">
							<md-button ng-if="$ctrl.organization.settings.template.logo.url" type="button" class="md-raised md-button" ng-click="$ctrl.resetLogo()">Remove</md-button>
							<md-button type="button" class="md-raised md-button md-primary" ngf-select="$ctrl.uploadLogo($file)">Upload</md-button>
						</div>
					</div>
				</form>
			</md-card-content>
			
			<md-divider></md-divider>

			<md-card-content>
				<h5>Domains</h5>

				<form class="margin-t" ng-click="alert('a')" name="DomainForm" ng-submit="$ctrl.addDomain()">
					<md-input-container class="md-block margin-0-b">
						<label>What is your domain name?</label>
						<input type="text" name="domainName" ng-model="$ctrl.newDomain.name" ng-change="$ctrl.newDomain.name = $ctrl.newDomain.name.toLowerCase()" required>
					</md-input-container>

					<div layout="row" layout-align="end end">
						<md-button class="md-raised margin-a-0" type="submit">Add</md-button>
					</div>
				</form>
			</md-card-content>

			<md-list>
				<md-list-item class="md-2-line" ng-repeat="domain in $ctrl.organization.domains" ng-click="$ctrl.verifyDomain(domain)">
					<div class="md-list-item-text">
						<h3>{{domain.name}}</h3>

						<p ng-if="domain.verified" class="text-success">Verified</p>
						<p ng-if="!domain.verified" class="text-danger">Unverified</p>
					</div>

					<md-button class="md-secondary md-icon-button" ng-click="$ctrl.removeDomain(domain)">
						<i class="fas fa-times"></i>
					</md-button>
				</md-list-item>
			</md-list>
        `,

		controller: function (Upload, Compliance, Session, $http, $mdToast, $mdDialog) {
			var ctrl = this;

			ctrl.newDomain = {
				name: ""
			}

			Compliance.industryResource.query().$promise.then((industries) => {
				ctrl.industries = industries;
			});

			//Logo
			ctrl.uploadLogo = (file) => {
				$mdToast.show($mdToast.simple().textContent("Uploading logo"));

				Upload.upload({
					url: "/api/organization/preferences/theme/logo",

					data: {
						file
					}
				}).then((res) => {
					$mdToast.show($mdToast.simple().textContent("Logo uploaded"));

					$mdDialog.show($mdDialog.confirm()
							.title("Reload page to see new logo?")
							.textContent("Any unsaved changes will be lost")
							.ok("Reload")
							.cancel("Keep Editing"))
						.then(() => {
							document.location = document.location + "";
						});
				}, (res) => {
					$mdToast.show($mdToast.simple().textContent("Error uploading logo"));
				});
			}

			ctrl.resetLogo = () => {
				ctrl.logo = null;
				ctrl.organization.settings.template.logo = {};

				$http.delete("/api/organization/preferences/theme/logo").then((res) => {
					$mdToast.show($mdToast.simple().textContent("Logo reset"));
					Session.current.organization.settings.template.logo.deleted = true;
				}, (res) => {
					$mdToast.show($mdToast.simple().textContent("Error resetting logo"));
				});
			}

			//Domains
			ctrl.addDomain = () => {
				var domainName = ctrl.newDomain.name;

				if (!domainName) {
					return;
				}

				$http.post("/api/organization/domain", {
					domain: domainName
				}).then(res => {
					if (!ctrl.organization.domains) {
						ctrl.organization.domains = [];
					}

					ctrl.organization.domains.push({
						name: domainName,
						verified: false
					});

					ctrl.newDomain.name = "";

					$mdToast.show($mdToast.simple().textContent("Domain added"));
				}).catch(() => {
					$mdToast.show($mdToast.simple().textContent("Error adding domain"));
				});
			}
			ctrl.removeDomain = (domain) => {
				$mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Are you sure you want to remove this domain?").ok("Confirm").cancel("Cancel")).then(() => {
					$http.delete(`/api/organization/domain/${domain.name}`).then(result => {
						//Remove from organization.domains
						ctrl.organization.domains = ctrl.organization.domains.filter(d => {
							return domain.name != d.name;
						});

						$mdToast.show($mdToast.simple().textContent("Domain removed"));
					}).catch((error) => {
						$mdToast.show($mdToast.simple().textContent("Error removing domain"));
					});
				});
			}

			ctrl.verifyDomain = function (domain) {
				if (domain.verified) {
					return;
				}

				$mdDialog.show({
					templateUrl: "/assets/html/organization/verify-domain-modal.html",
					parent: angular.element(document.body),
					bindToController: true,
					clickOutsideToClose: true,
					multiple: true,

					locals: {
						domain,
					},

					controller: ($scope, domain) => {
						console.log("test")
						$scope.domain = domain;
						$scope.method = "email";
						$scope.email = "security";
						$scope.status = {};

						$http.get(`/api/organization/domain/${domain.name}/token`).then(result => {
							$scope.token = result.data.token;
						});

						$scope.cancel = function () {
							$mdDialog.cancel();
						}

						$scope.verify = function () {
							var body = {
								method: $scope.method,
								https: $scope.https,
								step: 1,
							}

							if ($scope.method == "email") {
								body.email = $scope.email;
								$mdToast.show($mdToast.simple().textContent("Sending verification email"));
							} else {
								$mdToast.show($mdToast.simple().textContent("Verifying domain"));
							}

							$http.post(`/api/organization/domain/${domain.name}/verify`, body).then(result => {
								$mdDialog.hide();

								if ($scope.method != "email") {
									domain.verified = true;
									$mdToast.show($mdToast.simple().textContent("Domain verified"));
								} else {
									$mdToast.show($mdToast.simple().textContent("Email sent"));
								}
							}).catch(result => {
								$mdToast.show($mdToast.simple().textContent("Could not verify domain"));

								$scope.status = {
									message: "Could not verify domain",
									class: "text-danger",
								}
							});
						}
					},
				});
			}
		},
	});
})();