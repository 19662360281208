/**
 * @ngdoc component
 * @name app.control.component:controlStateGrapth
 * @author Nick Vorie
 *
 * @param {arraay}   controlStates The ControlStates to generate the graph for
 * @param {function} onClick Callback with index of clicked ControlState
 *
 * @description This component will render a graph for a set of given ControlStates
 */
(function () {
    "use strict";

    angular.module("app").component("threatTrendChart", {
        bindings: {
            controlState: "=?",
            onClick: "=?"
        },

        template: `
            <div>
                <canvas class="chart" width="200" height="150"></canvas>
            </div>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            var container = $element.find("div")[0];
            ctrl.width = 100 //container.offsetWidth - 20;

            ctrl.$onInit = function () {
                // var threats = _.sortBy(ctrl.controlState.threatRanges, pair => pair.threat._id);

                var labels = ["Base", "Weighted"] //threats.map(pair => pair.threat.title);

                var base = [9, 7, 4, 2] //threats.map(pair => pair.threat.inherentRisk.baseScore);
                var weighted = [8, 6, 4, 1] //threats.map(pair => pair.score.max);

                setTimeout(function () {
                    var ctx = $element.find("canvas")[0].getContext("2d");

                    console.log(ctx);

                    var config = {
                        "type": "line",
                        "data": {
                            "labels": ["January", "February", "March", "April", "May", "June", "July"],
                            "datasets": [{
                                "label": "Base",
                                "data": base,
                                "fill": false,
                                "borderColor": "rgb(75, 192, 192)",
                                "lineTension": 0.1
                            }, {
                                "label": "Weighted",
                                "data": weighted,
                                "fill": false,
                                "borderColor": "rgb(75, 192, 192)",
                                "lineTension": 0.1
                            }],
                        },

                        "options": {
                            lengend: {
                                display: false
                            }
                        }
                    }

                    ctrl.chart = new Chart(ctx, config);
                }, 500);
            }
        }
    });
})();