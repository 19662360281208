(function () {
    angular.module("app").controller("DocumentHistoryReportController", function ($scope, $http) {
        let users = [];
        let filteredUsers = {};

        $scope.data = {
            filter: {
                document: null,
                containerType: null,
                assignedTo: null,
                showAcknowledged: true,
                showPending: false,
                showUnassigned: false,
                user: {
                    status: {
                        active: true,
                        archived: false,
                        disabled: false,
                        pending: false
                    }
                },
                search: ""
            }
        }

        $scope.showMetrics = true;

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }

        $http.get("/api/reporting/document-history").then(res => {
            $scope.data.result = res.data;
            $scope.data.loaded = true;

            console.log($scope)

            $http.get("/api/users?activity=document").then(res => {
                users = res.data.map(user => {
                    return {
                        _id: user._id,
                        profile: user.profile,
                        status: user.status.state,
                        disabled: user.disabled,
                        activity: user.activity
                    }
                });

                generateReport();
            }).catch(console.error);

            generateReport();
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }
            generateReport();
        }, true);


        function filterFn(item) {
            const options = $scope.data.filter;

            if (!options.user.status.active) {
                if (item.user.status == "active") {
                    return false;
                }
            }

            if (!options.user.status.archived) {
                if (item.user.status == "archived") {
                    return false;
                }
            }

            if (!options.user.status.pending) {
                if (item.user.status == "pending") {
                    return false;
                }
            }

            if (!options.user.status.disabled) {
                if (item.user.status == "disabled") {
                    return false;
                }
            }

            // Filter specific user
            if (options.assignedTo) {
                if (options.assignedTo._id != item.user._id) {
                    return false;
                }
            }

            // Filter specific document
            if (options.document) {
                if (options.document._id != item.document._id) {
                    return false;
                }
            }

            // Filter unassigned docs
            if (!item.added && !options.showUnassigned) {
                return false;
            }

            // Filter assigned but un-ack'd docs
            if (item.added && !item.acknowledged && !options.showPending) {
                return false;
            }

            //Filter ack'd docs
            if (item.acknowledged && !options.showAcknowledged) {
                return false;
            }

            // Filter text search
            if (options.search.length) {
                const search = options.search.toLowerCase();

                return item.user.profile.name.toLowerCase().includes(search) ||
                    item.document.title.toLowerCase().includes(search);
            }

            return true;
        }

        $scope.filterFn = filterFn;

        function generateReport() {
            $scope.data.report = $scope.data.result;
            $scope.data.report.metrics = generateMetrics($scope.data.report.data);
        }

        function generateMetrics(report) {
            const metrics = {};
            const options = $scope.data.filter;
            const filter = filterFn;

            metrics.totalUsers = users.filter(user => {
                return true;
            }).length;

            metrics.acknowledgedUsers = users.filter(user => {
                if (!options.user.status.active) {
                    if (user.status == "active") {
                        return false;
                    }
                }

                if (!options.user.status.archived) {
                    if (user.status == "archived") {
                        return false;
                    }
                }

                if (!options.user.status.pending) {
                    if (user.status == "pending") {
                        return false;
                    }
                }

                if (!options.user.status.disabled) {
                    if (user.status == "disabled") {
                        return false;
                    }
                }
                return true;
            }).filter(user => {
                return user.activity.documents.every(entry => {
                    return entry.acknowledged;
                });
            }).length;

            metrics.percentTrained = _.round(100 * (metrics.acknowledgedUsers / metrics.totalUsers), 1);

            if (isNaN(metrics.percentTrained)) {
                metrics.percentTrained = 0;
            }

            metrics.assignedDocuments = report.entries.filter(entry => {
                if (!filter(entry)) {
                    return false;
                }

                return entry.notified && !entry.acknowledged;
            }).length;

            metrics.acknowledgedDocuments = report.entries.filter(entry => {
                if (!filter(entry)) {
                    return false;
                }

                return entry.acknowledged;
            }).length;

            return metrics;
        }
    })
})();