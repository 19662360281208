(function () {
    "use strict";

    angular.module("app").factory("TabProvider", function () {
        function TabProvider () {
            var self = this;
            
            var registry = {};
            
            self.groups = registry;
            self.get = get;
            self.set = set;
            self.create = create;
            
            function get (name) {
                return registry[name];
            }
            
            function getSelectedId (name) {
                return registry[name].selected.id;
            }
            
            function set (name, tabs) {
                return registry[name] = create(tabs);
            }
            
            function create (tabs) {
                tabs[0].active = true;
                
                return {
                    tabs: tabs,
                    selected: tabs[0]
                };
            }
        }
        
        return new TabProvider();
    }).component("tabNavigation", {
        templateUrl: "/assets/html/core/material-strap/tabs/navigation.html",
        replace: true,
        
        bindings: {
            group: "@",
            items: "=",
            toFilter: "=",
            filter: "=",
        },
        
        require: {
            parent: "?^^tabContainer"  
        },
        
        controller: function ($filter, TabProvider) {
            var ctrl = this;
            
            ctrl.$onInit = function () {
                if (ctrl.parent) {
                    ctrl.tabGroup = ctrl.group = ctrl.parent.group;
                } else {
                    ctrl.tabGroup = TabProvider.set(ctrl.group, ctrl.items)
                }
            }
            
            ctrl.selectTab = selectTab;
            ctrl.getFilterCount = getFilterCount;
            
            function getFilterCount (tab) {
                var filtered = $filter("filter")(ctrl.toFilter, tab.filter());
                
                tab.disabled = filtered.length <= 0;
                
                return filtered.length;
            }
            
            function selectTab (tab) {
                if (tab.disabled) return;
                
                ctrl.tabGroup.tabs.forEach (function (item) {
                    item.active = item == tab;
                });
                
                ctrl.tabGroup.selected = tab;
            }
        }
    }).component("tabContainer", {
        bindings: {
            group: "=",
            
        },
        
        controller: function () {
            var ctrl = this;

            ctrl.$onInit = function () {

            }
        },
    }).component("tabContent", {
        templateUrl: "/assets/html/core/material-strap/tabs/content.html",
        transclude: true,
        
        bindings: {
            selector: "@"
        },
        
        require: {
            parent: "^^tabContainer"    
        },
        
        controller: function ($scope) {
            var ctrl = this;
            
            ctrl.$onInit = function () {
                //ctrl.parent.group
            }
        }
    });
    
})();
