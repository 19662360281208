(function () {
    "use strict";

    angular.module("app").component("contentText", {
        transclude: {
            primary: "?primary",
            secondary: "?secondary",
        },

        bindings: {
            primary: "@",
            secondary: "@?",
            fill: "=?"
        },

        template: `
            <div class="text">
                <span ng-if="$ctrl.primary" class="primary" ng-class="{'fill': $ctrl.fill}">{{$ctrl.primary}}</span>
                <span ng-if="$ctrl.isPrimaryTranscluded()" class="primary" ng-class="{'fill': $ctrl.fill}" ng-transclude="primary"></span>

                <span ng-if="$ctrl.secondary" class="secondary">{{$ctrl.secondary}}</span>
                <span ng-if="$ctrl.isSecondaryTranscluded()" class="secondary" ng-transclude="secondary"></span>
            </div>
        `,

        controller: function ($transclude) {
            var ctrl = this;

            ctrl.isPrimaryTranscluded = function () {
                return $transclude.isSlotFilled("primary");
            }

            ctrl.isSecondaryTranscluded = function () {
                return $transclude.isSlotFilled("secondary");
            }
        }
    })
})();
