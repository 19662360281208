(function () {
    angular.module("app").controller("PoliciesReportController", PoliciesReportController);

    function PoliciesReportController($scope, $http, Policy, Organization) {
        $scope.organization = Organization.current;

        $scope.options = {
            showRoles: false
        };

        $http.get("/api/policy/meta").then(result => {
            $scope.meta = result.data;
        });

        $scope.data = [

        ]

        Policy.orgPolicies().query({ returnAll: false, replaceSlugs: true }).$promise.then(function (policies) {
            policies.forEach(function (policy) {

                let category = $scope.data[policy.category];

                if (!category) {
                    category = {
                        category: policy.category,
                        sections: [{
                            section: policy.section,
                            policies: [policy]
                        }]
                    }
                } else {
                    let section = category.sections.find(s => s.section == policy.section);

                    if (!section) {
                        category.sections.push({
                            section: policy.section,
                            policies: [policy]
                        });
                    } else {
                        section.policies.push(policy);
                    }
                }

                $scope.data[policy.category] = category
            });

            $scope.data = Object.values($scope.data);

            console.log($scope.data)

            //     return;
            //     category.sections.forEach(function(section) {
            //         section.policies.forEach(function(policy) {

            //             // build unique list of roles from policies
            //             policy.roles.forEach(function(pRole) {
            //                 if (!$scope.policyRoles.find(function(p){
            //                     return pRole._id == p._id;
            //                 })) {
            //                     pRole.show = true;
            //                     pRole.display = true;

            //                     if (pRole._id == "workforcemember") {
            //                         pRole.name = $scope.organization.phrase.employee;
            //                     }

            //                     pRole.name = pRole.name.charAt(0).toUpperCase() + pRole.name.slice(1);

            //                     $scope.policyRoles.push(pRole);
            //                 };
            //             });

            //         });
            //     })

            // });

            $scope.policies = policies;
            $scope.isReady = true;
        });
    }
})();
