(function () {
    angular.module("app").component("comment", {
         templateUrl: '/assets/html/ui-kit/comments/comment-component.html',

        bindings: {
            comment: "=?",
            comments: "=",
            user: "=?",
            onUpdate: "&"
        },

        controller: function (Session) {
            var ctrl = this;

            ctrl.isEdit = false;
            ctrl.isNew = true;
            ctrl.canEdit = false;
//            ctrl.fabOpen = false;

            ctrl.$onInit = function() {
                if (!ctrl.user) {
                    Session.currentUser.$promise.then(function (user) {
                        ctrl.user = user;
                        initComment();
                    });
                } else {
                    initComment();
                }
            };

            initComment = function() {
                if (ctrl.comment) {
                    ctrl.isNew = false;
                    if (ctrl.comment.user._id == ctrl.user._id) {
                        ctrl.canEdit = true;
                    }
                } else {
                    ctrl.isNew = true;
                    ctrl.isEdit = true;

                    ctrl.comment = {
                        comment: '',
                        user: ctrl.user
                    };
                 }
            }

            ctrl.updateComment = function(e) {
                if (ctrl.isNew) {
                    ctrl.comments.push({
                        comment: ctrl.comment.comment,
                        user: ctrl.comment.user
                    });
                    ctrl.comment = {
                        comment: '',
                        user: ctrl.user
                    };
                } else {
                    ctrl.isEdit = false;
                    ctrl.comment.modifiedOn = Date.now();
                }
                ctrl.onUpdate();
            }

            ctrl.removeComment = function(e) {

//                ctrl.comments = ctrl.comments.filter(function(comment) {
//                   return comment._id != ctrl.comment._id;
//                });

                var idx = ctrl.comments.findIndex(function(comment) {
                   return comment._id == ctrl.comment._id;
                });

                if (idx > -1) {
                    ctrl.comments.splice(idx, 1);
                }

                ctrl.onUpdate();
            }


        }
    });
})();
