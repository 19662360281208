(function () {
    angular.module("app").controller("IncidentController", IncidentController);

    function IncidentController ($scope, $mdDialog, $location, Incident) {

        this.$onInit = function () {
            var url = $location.absUrl();
            var idx = url.lastIndexOf("#");
            if (idx > -1) {
                var id = url.slice(idx+1);
            }

            Incident.resource.query({}).$promise.then(function (incidents) {
                $scope.incidents = incidents;
                $scope.isReady = true;

                if (id) {
                    var incident = incidents.find(function(incident) {
                        return incident._id == id;
                    });
                    console.log("IND", incident);
                    if (incident) {
                        $scope.viewIncident(incident);
                    }
                }
            });

        };

        $scope.newIncident = function(ev) {

            var newIncident = {
                date: {
                    start:'',
                    end:'',
                    discoverdStart:'',
                    discoveredEnd:''
                },
                phi: {
                    isPhi: false,
                    phiTypes:[]
                },
                response: {
                    notice: {
                    startDate:'',
                    endDate:''
                    },
                    actionsTaken:[]
                },
                scope: {
                    dataTypes:[],
                    locations:[],
                    safeguards:[]
                }
            };

            $mdDialog.show({
                templateUrl: '/assets/html/incident/edit-incident-modal.html',
                controller: 'EditIncidentController',
                parent: angular.element(document.body),
                locals: {
                    incident: newIncident,
                    isNew: true
                },
                bindToController: true,
                clickOutsideToClose: false,
                targetEvent: ev
            }).then(function(resp) {
                $scope.incidents.push(resp);
            }, function() {
                // cancel
            });

        };

        $scope.viewIncident = function(incident, ev) {

            $mdDialog.show({
                templateUrl: '/assets/html/incident/edit-incident-modal.html',
                controller: 'EditIncidentController',
                parent: angular.element(document.body),
                locals: {
                    incident: incident,
                    isNew: false
                },
                bindToController: true,
                clickOutsideToClose: false,
                targetEvent: ev
            });

        };

    }
})();
