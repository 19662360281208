angular.module('app').component('displayPolicies', {
    templateUrl: '/assets/html/policy/displayPolicies.html',
    controller: DisplayPoliciesController,
    bindings: {
        policies: '<',
        policyRoles: '<',
        showAcknowledged: '<',
        showAll: '<'
    }
});

function DisplayPoliciesController($mdDialog, $http) {
    var ctrl = this;

    ctrl.$onInit = function() {

    }

    $http.get("/api/policy/meta").then(result => {
        ctrl.meta = result.data;
    });

    // this is just here to reset the categories array each time we loop through
    var indexedCategories = [];

    ctrl.categoryKey = function (category) {
        return Number(category['c-ind']);
    }

    ctrl.sectionKey = function (section) {
        return Number(section['s-ind']);
    }

    ctrl.statementKey = function (statement) {
        return Number(statement['t-ind']);
    }

    ctrl.policiesToFilter = function() {
        indexedCategories = [];
        return ctrl.policies;
    }

    // filter down to a list of unique category/section pairs
    ctrl.filterCategories = function(policy) {
        var key = policy.category + policy.section;
        var categoryIsNew = indexedCategories.indexOf(key) == -1;
        if (categoryIsNew) {
            indexedCategories.push(key);
        }
        return categoryIsNew;
    }

    ctrl.filterRoles = function(policy) {
        var found = false;
        policy.roles.forEach(function(policyRole) {
            ctrl.policyRoles.forEach(function(role){
                if (role.show) {
                   if (policyRole._id == role._id) {
                       found = true;
                   }
                }
            })
        })

        if (found) {
            return true;
        } else {
            return false;
        }
    }

    ctrl.filterAcknoweleged = function(policy) {
        if(ctrl.showAll) {
            return true;
        } else if (ctrl.showAcknowledged) {
            return true;
        } else if (policy.acknowledged) {
            return false;
        } else {
            return true;
        }
    }

    ctrl.showDetailDialog = function(ev, policy) {

        $mdDialog.show({
            templateUrl: '/assets/html/policy/policyDetailDialog.html',
            targetEvent: ev,
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            locals: {
                policy: policy
            },
            controller: function DialogController($scope, $mdDialog, policy) {
                $scope.policy = policy;
                $scope.closeDialog = function() {
                    $mdDialog.hide();
                }
            }
        }).then( function() {
        }, function() {
        });

    };


}
