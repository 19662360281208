(function () {
    "use strict";

    angular.module("app").component("chip", {
        template: `<span class="chip" ng-class="$ctrl.class" ng-style="$ctrl.style"><span class="inner" ng-transclude></span></span>`,
        transclude: true,

        bindings: {
            type: "@",

            style: "=?",

            color: "=?",
            fontColor: "=?",
        },

        controller: function () {
            var ctrl = this;

            ctrl.$onInit = function () {
                updateUi();
            }

            ctrl.$onChanges = updateUi;

            function updateUi () {
                ctrl.class = "";

                if (!ctrl.style) ctrl.style = {};

                if (ctrl.color) {
                    ctrl.style['background-color'] = ctrl.color;
                }

                if (ctrl.fontColor) {
                    ctrl.style['color'] = ctrl.fontColor || "white";
                }

                if (ctrl.type) {
                    ctrl.class += ` ${ctrl.type}`;
                }
            }
        }
    })
})();
