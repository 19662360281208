(function () {
    "use strict";

    angular.module("app").factory("ConfirmModal", function (ModalFactory) {
        function ConfirmModal () {
            var defaults = {
                templateUrl: "/assets/html/core/material-strap/modal/confirm-wrapper.html",
                
                title: "Please confirm",
                body: "Are you sure?",
                
                onConfirm: function () {},
                onCancel: function () {},
            }
            
            this.on = on;
            
            function on (data) {
                data = angular.extend({}, defaults, data);
                ModalFactory.add(data);                 
            }
        }
        
        return new ConfirmModal();
    }).component("confirmModal", {
        templateUrl: "/assets/html/core/material-strap/modal/confirm.html",
        
        require: {
            parent: "^^modal"
        },
        
        controller: function () {
            var ctrl = this;
            
            ctrl.confirm = confirm;
            ctrl.cancel = cancel;
            
            function confirm ($event) {
                ctrl.parent.dismiss($event);
                ctrl.parent.modal.onConfirm();
            }
            
            function cancel ($event) {
                ctrl.parent.dismiss($event);
                ctrl.parent.modal.onCancel();
            }
        }
    }).component("dialogModal", {
        templateUrl: "/assets/html/core/material-strap/modal/dialog/dialog-modal.html",
        
        require: {
            parent: "^^modal"
        },
        
        controller: function () {
            var ctrl = this;
        }
    }).component("inputModal", {
        templateUrl: "/assets/html/core/material-strap/modal/input/input-modal.html",
        
        bindings: {
            data: "&"
        },
        
        require: {
            parent: "^^modal"
        },
        
        controller: function () {
            var ctrl = this;
            
            ctrl.data = ctrl.data || "";
            
            ctrl.submit = submit;
            ctrl.cancel = cancel;
            
            function submit ($event) {
                ctrl.parent.dismiss($event);
                ctrl.parent.modal.onSubmit(ctrl.parent.modal.data);
            }
            
            function cancel ($event) {
                ctrl.parent.dismiss($event);
                ctrl.parent.modal.onCancel();
            }
        }
    });
 
    angular.module("app").component("modal", {
        template: `
            <div class="modal" ng-click="$ctrl.onClick($event)" ng-style="$ctrl.style">
                <ng-include src="$ctrl.templateUrl"></ng-include>
            </div>
        `,
        
        bindings: {
            modal: "=",
            index: "=",
            data: "=",
        },
        
        controller: function ($sce, $compile, $timeout, ModalFactory) {
            var ctrl = this;
            
            ctrl.$onInit = function () {
                ctrl.templateUrl = ctrl.modal.templateUrl;

                ctrl.style = {
                    "margin-left": ctrl.modal.position.modX * (ctrl.index || 0),
                    "margin-top":  ctrl.modal.position.modY * (ctrl.index || 0),
                }

                ctrl.submit = submit;
                ctrl.dismiss = dismiss;
                ctrl.onClick = onClick;

                ctrl.content = $sce.trustAsHtml(ctrl.modal.content);

                function onClick ($event) {
                    $event.stopPropagation();
                }

                function submit ($event, data) {
                    if (ctrl.modal.onSubmit) {
                        var result = ctrl.modal.onSubmit(data);

                        dismiss($event);
                    }
                }

                function dismiss ($event) {
                    onClick($event);
                    ModalFactory.remove(ctrl.modal);
                }
            }
        }
    }).component("modalWrapper", {
        replace: true,
        template: `
            <div id="modal-wrapper" class="modal-wrapper" ng-class="$ctrl.ModalFactory.overlayClasses" ng-click="$ctrl.onClick()">
                <modal ng-repeat="modal in $ctrl.ModalFactory.modalsToRender() track by $index" modal="modal" index="$index"></modal>
            </div>
        `,
        
        controller: function (ModalFactory) {
            var ctrl = this;
            
            ctrl.ModalFactory = ModalFactory;
            ctrl.onClick = onClick;
            
            function onClick () {
                ModalFactory.dismissOnClick();
            }
        }
    });

    angular.module("app").factory("ModalFactory", function () {
        function ModalFactory () {
            var self = this;
            
            var modalDefaults = {
                title: "Heads Up!",

                position: {
                    modX: 10,
                    modY: 10  
                },                
                
                active: true,
                dismissOnClick: true,
                dismissOnEsc: true,
                
                onSubmit: function (data) {},
            }
            
            var templates = {
                dialog: {
                    templateUrl: "/assets/html/core/material-strap/modal/dialog/dialog-modal-wrapper.html",

                    title: "Attention",
                    body: "",                   
                }, confirm: {
                    templateUrl: "/assets/html/core/material-strap/modal/confirm-wrapper.html",

                    title: "Confirm",
                    body: "Are you sure?",

                    onConfirm: function () {},
                    onCancel: function () {},
                }, input: {
                    templateUrl: "/assets/html/core/material-strap/modal/input/input-modal-wrapper.html",

                    title: "Input Required",
                    body: "",

                    onSubmit: function (data) {},
                    onCancel: function () {},
                }
            }
            
            var modals = [];
            
            self.currentModal = null;
            self.modals = [];
            self.overlayClasses = {};
            
            self.modalsToRender = modalsToRender;
            self.dismissOnClick = dismissOnClick;
            
            self.template = template;
            self.templates = templates;
            self.add = add;
            self.remove = remove;
            
            //Init
            update();
            
            function modalsToRender () {
                return modals.filter(function (m) {
                    return m.active;
                })
            }
            
            function dismissOnClick () {
                if (self.currentModal) {
                    if (self.currentModal.dismissOnClick) {
                        remove(self.currentModal);
                    }
                }
            }
            
            function template (name, data) {
                var modalData = angular.extend({}, templates[name], data);
                self.add(modalData);
            }
            
            function add (modal) {
                modals.push(angular.merge({}, modalDefaults, modal));
                console.log(modal)
                update();
            }
            
            function remove (modal) {
                modals.splice(modals.indexOf(function (modal_) {
                    return modal == modal_;
                }), 1);
                
                update();
            }
            
            function update () {
                var modals = modalsToRender();
                self.modals = modals;
                
                self.currentModal = modals.length > 0 ? modals[modals.length - 1] : null;
                
                /*
                 * Prevents scrolling when modal is focused and applies overlay
                 * */
                self.overlayClasses = {
                    active: !!self.currentModal
                }
                
                //$("body").css("overflow", !self.currentModal ? "inherit" : "hidden");
            }
        }
        
        return new ModalFactory();
    });
    
})();
