(function () {
    angular.module("app").controller("ReportingController", ReportingController);

    function ReportingController($scope, Reporting) {

        $scope.reports = [];

        Reporting.reports.query({}, function (reports) {
            $scope.reports = reports;
        });
    }
})();