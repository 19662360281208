(function () {
    "use strict";

    angular.module("app").factory("Assessment", AssessmentFactory);

    function AssessmentFactory($resource, $http, $mdDialog, $mdToast, ModalFactory, Users) {

        const icon = "clipboard";

        var Resource = $resource("/api/:containerType/:id/assessment/:action", {
            containerType: "@containerType",
            id: "@container._id",
        }, {
            start: {
                method: "POST",

                params: {
                    action: "start",
                }
            }
        });

        function AssessmentProvider() {
            var self = this;

            self.icon = icon;

            var defaults = {
                endpoint: "/api/container",
                containerId: "",
            }

            function getAssignees(container, cb) {
                var users = [];

                // if (container.vendor) {
                //     //                    if (container.vendor.contact && container.vendor.organization.settings.setupComplete && !container.vendor.contact.emailonly) {
                //     if (container.vendor.contact && !container.vendor.contact.emailonly) {
                //         container.vendor.contact.profile.name = container.vendor.contact.profile.name + ", " + container.vendor.organization.name;
                //         container.vendor.contact.vendorContact = true;

                //         users.push(container.vendor.contact);
                //     }
                // }

                $http.get("/api/users/simple").then(function (result) {
                    result.data.forEach(user => {
                        if (!user.emailonly) {
                            users.push(user);
                        }
                    })
                });

                return users;
            }

            self.getAsignees = getAssignees;

            /*
             * @ngdoc method
             * @methodOf app.assessment.service:Assessment
             * @name start
             *
             * @param {event} $event     Event passed from ng-click
             * @param {object} container The container to edit
             * @param {string} type      The containerType
             *
             * @description Display a modal to start an Assessment using $mdDialog
             * @todo Abstract ceremony (repetitive/generic) code to a ContainerModal factory?
             * */
            function start($event, container, type) {
                //Call dialog
                $mdDialog.show({
                    templateUrl: `/assets/html/assessment/assessment-start.modal.html`,

                    locals: {
                        container: container,
                        containerType: type,
                    },

                    controller: ($scope, Assessment, Session, Container, container, containerType) => {
                        $scope.Assessment = Assessment;

                        $scope.assignees = self.getAsignees(container);

                        $scope.containerType = containerType;
                        container.containerType = containerType;

                        $scope.assessment = {
                            container: container,
                            containerType: containerType,

                            assignedTo: container.manager,

                            date: {
                                start: moment().toDate(),
                                dueAfter: 7
                            }
                        }

                        //Submit callback
                        $scope.submit = function () {
                            if ($scope.assessment.assignToVendor) {
                                $scope.assessment.assignedTo = $scope.assessment.container.vendor.contact;
                            }

                            if (!$scope.assessment.assignedTo) {
                                return;
                            }

                            $mdDialog.hide(container);

                            new Resource($scope.assessment).$start((data) => {
                                if (!container.assessment) {
                                    container.assessment = {}
                                }

                                container.assessment = data;

                                Session.currentUser.$promise.then((user) => {
                                    //TODO: Should be re-done to use backend populate
                                    if ((data.assignedTo._id || data.assignedTo) == user._id) {
                                        data.container = container;

                                        user.assessments = user.assessments || [];
                                        user.assessments.push(data);
                                    }
                                });

                                $mdToast.showSimple("Assessment started");
                            });
                        }

                        //Cancel callback
                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        }
                    },

                    targetEvent: $event,
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: true,
                }).then((updatedContainer) => {
                    //Copy changed fields as to not overwrite controlState, assessment remediation, ect
                    _.assign(container, updatedContainer);
                }, () => { });
            }

            self.start = start;

            self.close = close;

            function close($event, container, containerType, target) {
                $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Closing an assessment will discard any selected control states").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function () {
                    $mdToast.showSimple("Closing assessment");

                    $http.post(`/api/${containerType}/${container._id}/assessment/close?target=${target}`).then((res) => {
                        container.assessment[target] = null; //TODO: Return from erver with updated history
                        $mdToast.showSimple("Assessment closed");
                    });
                });
            }

            self.instance = function (options) {
                var self = this;

                self.options = options = _.assign(defaults, options);

                self.reset = function ($event, assessment) {
                    $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Are you sure you wish to reset all assessment answers?").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function () {
                        $mdToast.showSimple("Resetting assessment");

                        assessment.controlStates.forEach(controlState => {
                            controlState.state.answer = null;

                            console.log(controlState)

                            controlState.state.answers.forEach(answer => {
                                answer.selected = false
                            });
                        });

                        $http.post(`${self.options.endpoint}/${self.options.containerId}/assessment/reset`).then((res) => {
                            $mdToast.showSimple("Answers reset");
                        });
                    });
                }

                self.save = function ($event, data) {
                    $mdToast.showSimple("Saving answers");

                    $http.post(`${self.options.endpoint}/${self.options.containerId}/assessment/save`, data).then((res) => {
                        $mdToast.showSimple("Answers saved");
                    });
                }

                self.submit = function ($event, data) {
                    $mdDialog.show($mdDialog.confirm().title("All done?").textContent("After you submit the assessment, you will not be able to make edits.").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function () {
                        $mdToast.showSimple("Submitting assessment");

                        $http.post(`${self.options.endpoint}/${self.options.containerId}/assessment/submit`, data).then((res) => {
                            window.location = "/#" + self.options.containerType;
                        });
                    });
                }

                self.close = function ($event) {
                    $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Closing an assessment will discard any selected control states").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function () {
                        $mdToast.showSimple("Closing assessment");

                        $http.post(`${self.options.endpoint}/${self.options.containerId}/assessment/close`).then((res) => {
                            $mdToast.showSimple("Assessment closed");
                        });
                    });
                }

                return self;
            }

            /*
             * Returns an array of the current User's assigned Remediation Plans
             * */
            self.getAssignedAssessments = (cb) => {
                $http.get("/api/assessment/assigned").then((res) => {
                    cb(res.data);
                });
            }

            self.controlsAnswered = function (controlStates) {
                var total = controlStates.length;

                var answered = controlStates.filter((controlState) => {
                    return !!controlState.state.answer;
                }).length;

                var percent = Math.round((answered / total) * 100);

                return {
                    total: total,
                    answered: answered,
                    percent: percent,
                    text: `${answered}/${total} (${percent}%) answered`
                }
            }

            return self;
        }

        return new AssessmentProvider();
    }
})();