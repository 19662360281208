/**
 * @ngdoc component
 * @name app.uiKit.component:address
 * @author Nick Vorie
 *
 * @description Address autoformatter
 */
(function () {
    "use strict";

    angular.module("app").component("address", {
        transclude: true,

        bindings: {
            name: "=",
            email: "=",
            phone: "=",
            phoneExtention: "=",
            address: "=",
            extra: "=",
        },

        template: `
            <div layout="column">
                <div ng-if="$ctrl.name != null && $ctrl.name != ''" flex layout="row">
                    <icon flex="10" type="user"></icon>
                    <div>{{$ctrl.name}}</div>
                </div>

                <div ng-if="$ctrl.email.length" flex layout="row">
                    <icon flex="10" type="envelope"></icon>
                    <a ng-href="mailto:{{$ctrl.email}}">{{$ctrl.email}}</a>
                </div>

                <div ng-if="$ctrl.phone.length" flex layout="row">
                    <icon flex="10" type="phone"></icon>
                    <a ng-href="tel:{{$ctrl.phone}}">{{($ctrl.phone | phone)}} {{$ctrl.phoneExtention}}</a>
                </div>

                <div ng-if="$ctrl.address.street1.length" flex layout="row">
                    <icon flex="10" type="map-marker"></icon>
                    <a layout="column" target="_blank" ng-href="https://www.google.com/maps/search/{{$ctrl.address.street1 + ' ' + $ctrl.address.city + ', ' + $ctrl.address.state}}">
                        <span>{{$ctrl.address.street1 + ($ctrl.address.street2 ? ", " + $ctrl.address.street2 : "")}}</span>
                        <span ng-if="$ctrl.address.city.length">{{$ctrl.address.city}}, {{$ctrl.address.state}} {{$ctrl.address.zip | postalCode}}</span>
                    </a>
                </div>

                <div ng-transclude></div>
            </div>
        `,
    })
})();
