(function () {
    angular.module("app").controller("UsersController", UsersController);

    function UsersController($scope, $mdDialog, $location, Organization, Users, Training, Awareness, Import) {

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        Organization.resource.get({ id: id }, function (org) {
            $scope.org = org;

            Users.byOrgResource.query({ orgId: id }).$promise.then(function (users) {
                $scope.users = users;
                $scope.ready = true;
            });

        });

        Organization.current.$promise.then(function (org) {
            $scope.organization = org;
        });

        //        Users.resource.query().$promise.then(function (users) {
        //            $scope.users = users;
        //        });

        Awareness.resource.query().$promise.then(function (awarenesses) {
            $scope.awarenesses = awarenesses;
        });

        Training.resource.query().$promise.then(function (trainings) {
            $scope.trainings = trainings;
        });

        $scope.newUserDialog = function (ev) {

            Users.userModal(null, $scope.org).then(() => {
                Users.byOrgResource.query({ orgId: $scope.org._id }).$promise.then(function (users) {
                    $scope.users = users;
                });
            });
        }

        $scope.importUsers = function ($event) {
            Import.import($event, {
                title: "Import Users From CSV",
                endpoint: "/api/organization/" + id + "/users/import"
            }, $scope.users, function () {
            });
        }

        $scope.closeDialog = function () {
            $mdDialog.hide();
        };

        $scope.cancelDialog = function () {
            $mdDialog.cancel();
        };

    }
})();
