(function () {
    "use strict";

    angular.module("app").component("content", {
        bindings: {
            contentId: "@",
            html: "@?",
        },

        template: `
			<span ng-if="$ctrl.content && !$ctrl.html">{{$ctrl.content}}</span>
			<div ng-if="$ctrl.content && $ctrl.html" ng-bind-html="$ctrl.content"></div>
        `,

        controller: function (Content) {
            var ctrl = this;

            ctrl.$onInit = () => {
                Content.get(ctrl.contentId, (error, content) => {
                    if (error) {
                        return console.error(`error loading content: ${ctrl.contentId}`, error);
                    }

                    ctrl.content = content.value;
                });
            }
        }
    });
})();