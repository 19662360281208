(function () {
    "use strict";

    angular.module("app").component("remediation", {
        bindings: {
            container: "=",
            containerType: "@",
        },

        template: `
            <nav-toolbar>
                <toolbar-title>
                    <md-breadcrumbs>
                        <a href="/">Home</a>

                        <a ng-if="$ctrl.isCSO && $ctrl.containerType == 'user'" href="/#user">People</a>
					   	<a ng-if="$ctrl.isCSO && $ctrl.containerType == 'domain'" href="/#domain">Organization</a>
						<a ng-if="$ctrl.isCSO && $ctrl.containerType == 'site'" href="/#site">Places</a>
                        <a ng-if="$ctrl.isCSO && $ctrl.containerType == 'technology'" href="/#technology">Technologies</a>
                        <a ng-if="$ctrl.isCSO && $ctrl.containerType == 'vendor'" href="/#vendor">Vendors</a>

                        <a ng-if="$ctrl.containerType == 'user'" ng-href="/{{$ctrl.containerType}}/{{$ctrl.container._id}}">{{$ctrl.container.profile.name}}</a>
                        <a ng-if="$ctrl.containerType != 'user'" ng-href="/{{$ctrl.containerType}}/{{$ctrl.container._id}}">{{$ctrl.container.name}}</a>

                        <a ng-href="#">Remediation</a>
                    </md-breadcrumbs>
                </toolbar-title>
            </nav-toolbar>

            <div ng-if-start="$ctrl.container._id && $ctrl.container.remediation.current" class="margin-b" layout="row" layout-align="start start" >
                <float flex="40" layout="column" layout-align="start stretch">
                    <container-card container="$ctrl.container" type="{{$ctrl.containerType}}" show="{actions:false,chip:false,media:true}"></container-card>

                    <div class="padding-3" layout="column" layout-align="start end">
                        <md-progress-linear ng-if="false"  md-mode="determinate" value="{{$ctrl.Remediation.stepsComplete($ctrl.container.remediation.current.steps).percent}}"></md-progress-linear>
                        <progress-bar style="width: 100%;" value="$ctrl.Remediation.stepsComplete($ctrl.container.remediation.current.steps).percent"></progress-bar>

                    </div>
                </float>

                <div flex="60" layout="column" layout-align="start stretch">
                    <remediation-step-card ng-repeat="step in $ctrl.steps | orderBy:'step.dueDate'" step="step"></remediation-step-card>
                </div>
            </div>

            <div ng-if-end position-fixed-bottom-right layout="row" layout-align="end center">
				<div ng-if="!$ctrl.isAuditor">
					<md-button ng-click="$ctrl.RemediationInstance.reset($event, $ctrl.container.remediation.current)" class="md-raised md-default">Reset</md-button>

					<md-button ng-click="$ctrl.RemediationInstance.submit($event, $ctrl.container.remediation.current)" class="md-raised md-primary">Save</md-button>
				</div>
            </div>

            <div ng-if="!$ctrl.container._id" layout="row" flex="100" layout-align="center">
                <md-progress-circular md-mode="indeterminate"></md-progress-circular>
            </div>

            <div layout="row" layout-align="center" ng-if="$ctrl.container._id && !$ctrl.container.remediation.current">
                <p>No remediation found</p>
            </div>
        `,

        controller: function (Control, Remediation, Session) {
            var ctrl = this;

            ctrl.Control = Control;
            ctrl.Remediation = Remediation;

			ctrl.isCSO = false;

			Session.currentUser.$promise.then(function (user) {
				ctrl.isCSO = user.roles.some(function (role) {
					return role._id == "cso" || role._id == "riskmanager"
				});
			});

            ctrl.$onInit = () => {
                ctrl.RemediationInstance = Remediation.instance({
                    endpoint: "/api/" + ctrl.containerType,
                    containerType: ctrl.containerType,
                    containerId: `${ctrl.container._id}`,
                });

                ctrl.steps = ctrl.container.remediation.current.steps;

                if (!ctrl.steps) {
                    ctrl.steps = [];
                }

                ctrl.steps = ctrl.steps.map((step) => {
                    if (_.get(step, "action.attributes")) {
                        step.action.attributes = step.action.attributes.map((a) => {
                            a.selected = false;//TODO factory to reset
                            return a;
                        })
                    }

                    return step;
                });

				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});
			}
        }
    });
})();
