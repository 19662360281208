(function () {
    "use strict";
    
    angular.module("app").component("wizard", {
        templateUrl: "/assets/html/core/wizard/wizard.html",
        transclude: true,
        controller: Controller,
        
        bindings: {
            onFinish: "&onFinish"
        }
    });    
    
    function Controller () {
        var self = this;

        self.steps = [];
        self.currentStep = 0;
        self.progress = 0;

        self.updateProgress = updateProgress;
        self.next = next;
        self.previous = previous;
        self.select = select;
        self.addStep = addStep;
        self.finish = finish;

        function updateProgress () {
            self.progress = (self.currentStep / self.steps.length) * 100;
        }
        
        function next () {
            var returnNext = false;
            
            var nextStep = self.steps.find(function (step) {
                if (returnNext) return true;
                if (step.active) returnNext = true;
            });

            self.currentStep++;
            self.select(nextStep);
        }
        
        function previous () {
            var last = null;

            self.steps.find(function (step) {
                if (step.active) return true;
                last = step;
            });

            self.currentStep--;
            self.select(last);
        }
        
        function select (step) {
            self.steps.forEach(function (step) {
                step.active = false;
            });

            step.active = true;
            self.updateProgress();
        }
        
        function addStep (step) {
            self.steps.push(step);

            if (self.steps.length == 1) {
                self.select(step);
            }
        }
        
        function finish () {
            self.progress = 100;
            if (self.onFinish) self.onFinish();
        }
    }    
})();
