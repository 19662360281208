(function () {
    "use strict";

    angular.module("app").factory("Awareness", AwarenessFactory);

    function AwarenessFactory ($resource, $http) {

        function AwarenessProvider() {
            var self = this;

            self.notviewedResource = $resource("/api/awareness/notviewed");

            self.resource = $resource(
                "/api/awareness/:id",
                {
                    id: "@_id"
                },
                {
                    update: {
                        method:'POST',
                        url: '/api/awareness/:id',
                        isArray: false
                    },
                    sendNow: {
                        method:'POST',
                        url: '/api/awareness/:id/sendNow',
                        isArray: false
                    }                }
            );

            self.themesResource = $resource("/api/awareness/themes");

            return self;
        }

        return new AwarenessProvider();
    }

})();
