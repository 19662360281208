(function () {
    "use strict";

    angular.module("app").component("icon", {
        transclude: false,
        template: '<i class="fa" ng-class="\'fa-\' + $ctrl.type"></i>',
        bindings: {
            type: "@",
        },
    })
})();
