(function () {
    angular.module("app").controller("ProcedureModalController", ProcedureModalController);

    function ProcedureModalController ($scope, $mdDialog, $mdToast, $http, Incident, Session, procedure, isNew) {

        var ctrl = this;

        $scope.isReady = true;
        $scope.isNew = isNew;
        $scope.isEdit = isNew;
        $scope.procedure = procedure;
        $scope.templates = [{

        }];

        ctrl.$onInit = function () {

            // get current user to populate Reported By info
//            Session.currentUser.$promise.then(function (user) {
//                if (isNew) {
//                    $scope.incident.reportedBy = user;
//                    $scope.incident.organization = user.organization;
//                }
//            });

        };

        $scope.update = function() {
            if (!$scope.isEdit) return;

            Incident.resource.update($scope.incident).$promise.then( function (result) {

                $mdDialog.hide(result);
                showToast("Incident saved.");
           });

        };

        $scope.updateComment = function() {

            Incident.resource.update($scope.incident).$promise.then( function (result) {

                showToast("Comments updated.");
           });

        };

        $scope.edit = function() {
            $scope.isEdit = true;
        }

        $scope.toggleAction = function(action) {
            var actions = $scope.actionsTaken.filter(function(action) {
                return action.enabled;
            });
            $scope.incident.response.actionsTaken = actions.map(function(action) {
                return action.name;
            })
        };

        $scope.cancelDialog = function () {
            if ($scope.isNew) {
                $mdDialog.cancel();
            } else {
                if ($scope.isEdit) {
                    $scope.isEdit = false;
                } else {
                    $mdDialog.cancel();
                }
            };
        };

        showToast = function (msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        }
    }
})();
