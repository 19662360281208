(function () {
    "use strict";

    angular.module("app").component("slider", {
        bindings: {
            value: "=?",
            onChange: "=?",
            label: "@?",
            max: "=?",
            min: "=?",
        },

        template: `
            <div class="form-group-material">
                <input class="nofill" type="range" min="{{$ctrl.min}}" max="{{$ctrl.max}}" ng-model="$ctrl.value" ng-change="$ctrl.updateValue()">
                <label ng-if="$ctrl.label" ng-init="$ctrl.updateLabel(true)" ng-style="$ctrl.labelStyle">{{$ctrl.label}}</label>
            </div>
        `,

        controller: function ($element) {
            var ctrl = this;

            ctrl.labelStyle = {
                position: "relative",

            }

            ctrl.updateValue = function () {
                if (ctrl.onChange) {
                    ctrl.onChange(ctrl.value)
                }

                ctrl.updateLabel();
            }

            ctrl.updateLabel = function (delay) {
                if (delay) {
                    _.delay(update, 50);
                } else {
                    update();
                }

                function update () {
                    var groupElement = $element.find(".form-group-material")[0];
                    var labelElement = $element.find("label")[0];

                    var totalPoints = (ctrl.max - ctrl.min) + 1;
                    var relativeIndex = ctrl.value - ctrl.min;

                    var offsetPerPoint =  1 / (totalPoints - 1);
                    var offsetPercentage = relativeIndex * offsetPerPoint;

                    var groupWidth = groupElement.clientWidth;
                    var labelWidth = labelElement.clientWidth;

                    var offset = (groupWidth * offsetPercentage);

                    if (relativeIndex + 1 == totalPoints) {
                        offset -= labelWidth;
                    } else if (relativeIndex == 0) {
                        offset = 0;
                    } else {
                        offset -= labelWidth / 2;
                    }

                    ctrl.labelStyle.left = offset;
                }
            }
        }
    })

})();
