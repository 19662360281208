angular.module('app').component('setupSummary', {
    templateUrl: '/assets/html/setup/setup-summary.html',
    controller: setupSummary,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<'
    }
});

function setupSummary($scope, $mdToast, $mdDialog, Container, Setup) {
    var ctrl = this;
    $scope.vendors = [];

    ctrl.$onInit = function() {

        $scope.organization = ctrl.organization;
        $scope.organization.settings = {'setupComplete': true};
        $scope.organization.settings.dashboards = {
            cso: {
                alerts: {'show':true},
                myPolicies: {'show':true},
                training: {'show':true},
                awareness: {'show':true},
                setupWizard: {'show':true}
            },
            default: {
                alerts: {'show':true},
                myPolicies: {'show':true},
                training: {'show':true},
                awareness: {'show':true}
            }
        }

        $scope.organization.$save().then(function(org) {

        }, function(err, test) {
             // TODO: Should trap this error
        });

        Setup.setupResource.complete({}).$promise.then( function (result) {
        });
    }

    $scope.allDone = function() {

        showToast("Setup complete.");
        setTimeout(() => {
            window.location = "/";
        }, 500);

    };



    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
