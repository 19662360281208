(function () {
    "use strict";
    
    angular.module("app").component("cvssVector", {
        template: `
            <div class="form-group-material" >
                <input ng-if="$ctrl.editable" type="text" ng-model="$ctrl.cvssData.vector">
                <input ng-if="!$ctrl.editable" type="text" ng-model="$ctrl.cvssData.vector" class="active" onfocus="this.blur()" readonly>
                <label>CVSS Vector String</label>
            </div>
        `,

        bindings: {
            cvssData: "=",
            editable: "@?"
        },
        
        controller: function () {
            var ctrl = this;
        }
    });
})();