(function () {
    "use strict";

    angular.module("app").component("warning", {
        transclude: true,
        bindings: {
        },

        template: `
            <md-toolbar class="md-warn" style="height:inherit">
                <div layout="row" layout-align="start start">
                    <i class="fas fa-exclamation-triangle fa-2x" style="padding:6px"></i>
                    <p style="font-size:14px; padding:6px" ng-transclude felx></p>

                </div>
            </md-toolbar>
        `,

        controller: function () {
        }
    })
})();
