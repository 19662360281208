(function () {
    angular.module("app").controller("OrganizationTheme", OrganizationTheme);

    function OrganizationTheme ($scope, $timeout, Error, Upload) {
        $scope.errorHandler = Error.get("organization-theme");

        $scope.uploadLogo = function (file) {
            $scope.errorHandler.setSuccess("Uploading logo...");

            Upload.upload({
                url: "/api/organization/preferences/theme/logo",
                data: {
                    file: file
                }
            }).then(function (response) {
                $scope.errorHandler.setSuccess("Your logo has been uploaded successfully");

                $timeout(function () {
                    window.location = window.location;
                }, 500);
            }, function (response) {
                $scope.errorHandler.process(response);
            });
        }
    }
})();
