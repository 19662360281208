(function () {
    "use strict";

    angular.module("app").component("navDrawer", {
        template: `
            <md-sidenav md-component-id="left" md-disable-scroll-target="body" class="md-sidenav-left">
                <nav-menu></nav-menu>
            </md-sidenav>
        `,
    })
})();
