(function () {
    "use strict";
    
    angular.module("app").component("siteSelect", {
		template: `
			<md-input-container class="md-block">
				<label>{{$ctrl.label || "Select Site"}}</label>

				<md-select ng-model="$ctrl.bindTo" ng-model-options="{trackBy: '$value._id'}" ng-required="$ctrl.required">
                    <md-option ng-if="!$ctrl.required" ng-value="undefined" ng-selected="!$ctrl.bindTo">No site selected</md-option>
                    <md-option ng-repeat="site in $ctrl.sites | orderBy:'name'" ng-value="site">{{site.name}}</md-option>
				</md-select>
			</md-input-container>
		`,

        bindings: {
            bindTo: "=",
            label: "@?",
        },
        
        controller: function (Site) {
            var ctrl = this;
            
            ctrl.sites = Site.Resource.query();
        }
    });
})();
