(function () {
    "use strict";

    angular.module("app").component("toolbarTitle", {
        transclude: true,
        template: `<div>
                        <div id="page-title" ng-transclude></div>
                    </div>`,
    })
})();
