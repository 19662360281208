(function () {
    angular.module("app").controller("TrainingReportController", function ($scope, $http, $filter, $mdDialog) {
        $scope.data = {
            filter: {
                training: null,
                containerType: null,
                assignedTo: null,

                user: {
                    status: {
                        active: true,
                        archived: false,
                        disabled: false,
                        pending: false
                    }
                },

                showCompleted: true,
                showPending: true,
                showDisabledUsers: false,
                search: ""
            }
        }

        $scope.showMetrics = true;

        let filteredUsers = {};

        $http.get("/api/reporting/training").then(res => {
            $scope.data.report = res.data;
            $scope.data.loaded = true;

            generateReport();
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);

        function generateReport() {
            const entries = $scope.data.report.data.entries;
            const options = $scope.data.filter;
            
            const filter = filterEntry(options);

            filteredUsers = {};
            $scope.data.report.data.filtered = entries.filter(filter);

            $scope.data.report.data.filtered.forEach(entry => {
                filteredUsers[entry.user._id] = entry.user;
            });

            $scope.data.report.metrics = generateMetrics();
        }

        function generateMetrics() {
            const metrics = {};

            const filtered = $scope.data.report.data.filtered;
            metrics.totalUsers = Object.values(filteredUsers).length;

            const trainedUsers = Object.values(filteredUsers).filter(user => {
                return user.fullyTrained;
            }).length;
        
            metrics.trainedUsers = trainedUsers;

            if (metrics.totalUsers == 0) {
                metrics.percentTrained = 0;
            } else {
                metrics.percentTrained = _.round(100 * (trainedUsers / metrics.totalUsers), 1)
            }

            const assignedTrainings = filtered.filter(entry => {
                return entry.assignedOn && !entry.acknowledged;
            }).length;
        
            metrics.assignedTrainings = assignedTrainings;
        
            const completedTrainings = filtered.filter(entry => {
                return entry.acknowledged;
            }).length;
        
            metrics.completedTrainings = completedTrainings;
        
            return metrics;
        }

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }
    });
})();

function filterEntry(options) {
    return (item) => {
        if (item.assignedOn && !item.acknowledged && !options.showPending) {
            return false;
        }

        if (!item.assignedOn && !options.showUnassigned) {
            return false;
        }

        if (item.acknowledged && !options.showCompleted) {
            return false;
        }

        if (options.training) {
            if (options.training._id != item.training._id) {
                return false;
            }
        }

        if (options.assignedTo) {
            if (options.assignedTo._id != item.user._id) {
                return false;
            }
        }

        if (!options.user.status.active) {
            if (item.user.status == "active") {
                return false;
            }
        }

        if (!options.user.status.archived) {
            if (item.user.status == "archived") {
                return false;
            }
        }
        
        if (!options.user.status.pending) {
            if (item.user.status == "pending") {
                return false;
            }
        }
        
        if (!options.user.status.disabled) {
            if (item.user.status == "disabled") {
                return false;
            }
        }

        if (options.search) {
            const search = options.search.toLowerCase();

            return item.user.profile.name.toLowerCase().includes(search) || item.training.title.toLowerCase().includes(search);
        }

        return true;
    }
}