(function () {
    angular.module("app").controller("RequirementsController", ($scope, $http, Import) => {
		$http.get("/api/compliance/requirements").then(response => {
			$scope.requirements = response.data;
		}).catch();

		$scope.import = ($event) => {
            Import.import($event, {
                title: "Import Requirements From CSV",
                endpoint: "/api/compliance/requirements/import"
            }, $scope.requirements, function () {

            });
		}
	});
})();
