// NO LONGER USED

angular.module('app').component('setupUsers', {
    templateUrl: '/assets/html/setup/setup-users.html',
    controller: setupUsers,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
    }
});

function setupUsers($scope, $mdDialog, Users) {
    var ctrl = this;

    $scope.users = [];

    ctrl.$onInit = function() {

        $scope.organization = ctrl.organization;

        Users.resource.query().$promise.then(function (users) {
            $scope.users = users;
        });
    }

    $scope.newUser = function(ev) {

        var newUser = new Users.resource();
        newUser.organization = $scope.organization;

        $mdDialog.show({
            templateUrl: '/assets/html/user/edit-user-modal.html',
            controller: 'EditUserController',
            parent: angular.element(document.body),
            locals: {user: newUser},
            bindToController: true,
            clickOutsideToClose: true,
            targetEvent: ev
        }).then(function(resp) {
            $scope.users.push(resp);
        }, function() {
            // cancel
        });

    }

    $scope.editUser = function(ev, user) {

        $mdDialog.show({
            templateUrl: '/assets/html/user/edit-user-modal.html',
            controller: 'EditUserController',
            parent: angular.element(document.body),
            locals: {user: user},
            bindToController: true,
            clickOutsideToClose: false,
            targetEvent: ev
        }).then(function(updatedUser) {

            $scope.users.forEach(function(user, idx) {
                if(user._id == updatedUser._id) {
                    $scope.users[idx] = updatedUser;
                }
            });

        }, function() {
            // cancel
        });
    }

    $scope.closeDialog = function() {
          $mdDialog.hide();
    };

    $scope.cancelDialog = function() {
          $mdDialog.cancel();
    };

    $scope.next = function() {
        ctrl.onNext();
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
