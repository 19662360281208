(function () {
    "use strict";
    
    angular.module("app").factory("Control", ControlFactory);

    function ControlFactory ($resource, $http) {
        
        var ControlResource = $resource("/api/control/:id", {
            id: "@_id"
        });
        
        var ControlCategoryResource = $resource("/api/control/category");        
        var ControlStateValueResource = $resource("/api/control/control-state-value");
        var ControlRoleResource = $resource("/api/control/role");        
        
        function ControlProvider() {
            var self = this;
            
            var controlStateValues = null;
            var controlStateValuesLoading = false;

            self.resource = ControlResource;
            self.categoryResource = ControlCategoryResource;
            self.roleResource = ControlRoleResource;

            self.getSelectedAnswer = function (question) {
                return "no data";

                return question.answers.find(function (answer) {
                    return answer.selected === true;
                }) || "No Data";
            }

            //TODO make a factory for this
            self.populateStateAnswers = function (state, cb, exclude) {
                if (!exclude) exclude = [];

                function populate () {
                    controlStateValuesLoading = false;

                    state.answers = angular.copy(controlStateValues).filter((a) => {
                        return !_.find(exclude, (e) => {
                            return e == a._id;
                        });
                    }).map(function (value) {
                        if (state.answer) {
                            value.selected = (state.answer._id || state.answer) == value._id;
                        }

                        return value;
                    });

                    if (cb) cb();
                }

                if (controlStateValuesLoading) {
                    controlStateValues.$promise.then(populate);
                    return;
                }

                if (!controlStateValues) {
                    controlStateValuesLoading = true;
                    controlStateValues = ControlStateValueResource.query(populate);
                } else {
                    populate();
                }
            }

            self.populateStateAttributes = function (controlState) {
                if (!controlState.state.attributes) {
                    controlState.state.attributes = angular.copy(controlState.control.attributes);
                }
            }

            self.populateThreatRange = function (controlState) {
                if (!controlState.threatScores || controlState.threatScores.length == 0) return null;

                var scores = controlState.threatScores.map(function (score) {
                    return score.score;
                });

                var min = _.round(_.min(scores), 1);
                var max = _.round(_.max(scores), 1);

                var vals = [min];

                if (min > 0 && max > 0 && max > min) vals.push(max);

                controlState.threatRange = vals;
            }

            self.getSelectedState = function (controlState) {
                return controlState.state.answers.find(function (a) {
                    return a.selected;
                });
            }

            return self;
        }

        return new ControlProvider();
    }
})();
