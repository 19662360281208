(function () {
    angular.module("app").controller("dashboard", function ($scope, $mdDialog, $mdToast, Organization, ECommerce, User, Users, Container, Training, Partner, Import) {

        Training.trainingAndUser.get({
            trainingId: "5dcefad9c06462184dabe83e"
        }).$promise.then(function (training) {

            if (!training.acknowledged) {
                Training.openTrainingModal(training, {
                    showAcknowledge: false,
                    title: "Welcome to VSC",
                    clickOutsideToClose: true,
                    showDontShowAgain: true
                }).then(function (resp) {

                });
            }

        });

        $scope.Container = Container;

        $scope.pageGuide = {
            title: "Dashboard",

            steps: [{
                target: "security-tasks-card > md-card",
                direction: "right",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            }, {
                target: "user-table table",
                direction: "right",
                content: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur."
            },]
        }

        User.checkRoles(['cso', 'riskmanager']).then((userIsAdmin) => {
            $scope.userIsAdmin = userIsAdmin;
        });

        User.checkRoles(['accountadmin']).then((userIsAccountAdmin) => {
            $scope.userIsAccountAdmin = userIsAccountAdmin;
        });

        var categories = {
            dashboard: {
                id: "dashboard",
            }
        };

        $scope.exceededLicense = function (ev, message) {
            var confirm = $mdDialog.confirm()
                .title('License Exceeded')
                .textContent(message)
                .targetEvent(ev)
                .ok('Ok, Got It');

            if ($scope.userIsAccountAdmin) {
                confirm.cancel('Update Plan');
            }

            $mdDialog.show(confirm).then(function () { }, function () {
                ECommerce.editBillingPlan($scope.organization);
            });
        }

        Organization.current.$promise.then((org) => {
            $scope.orgIsLicensed = org.license.type != 'vendor';
            $scope.organization = org;

            categories.user = {
                id: "user",
                title: "People",
                icon: "users",

                populate: () => {
                    return Users.resource.query();
                },


                filter: function (filter) {
                    return function (container) {
                        return container.status.state != "archived";
                    }
                },

                metrics: Container.metrics("user"),
                renderMetaMetrics: false,

                onFabClickCreate: ($event, users) => {
                    var users = Users.resource.query();

                    users.$promise.then(function () {
                        if ($scope.organization.status.licensed) {
                            if ($scope.organization.license) {
                                var userLicenses = $scope.organization.license.users;
                            } else {
                                var userLicenses = 3;
                            }
                        } else {
                            var userLicenses = 3;
                        }

                        console.log(users.filter(user => {
                            return user.status.state != "archived";
                        }))

                        if (users.filter(user => {
                            return user.status.state != "archived";
                        }).length < userLicenses) {
                            Users.userModal(null, $scope.organization).then(() => {
                                var users = Users.resource.query();

                                users.$promise.then(function () {
                                    $scope.categories.user.items = users;
                                });
                            });

                        } else {
                            $scope.exceededLicense($event, 'You are currently licensed for ' + userLicenses + ' VSC users.');
                        }
                    });
                },

                import: ($event, users) => {
                    Import.import($event, {
                        title: "Import Users From CSV",
                        endpoint: "/api/user/import"
                    }, users, function () {
                        var users = Users.resource.query();

                        users.$promise.then(function () {
                            $scope.categories.user.items = users;
                        });
                    });
                },
            }

            if ($scope.orgIsLicensed) {

                categories.domain = {
                    id: "domain",
                    title: "Organization",
                    icon: "sitemap",

                    populate: () => {
                        return Container.query("domain");
                    },

                    filter: function (filter) {
                        return function (container) {
                            if (!filter) {
                                return false;
                            }

                            if (container.archived == true) {
                                return false;
                                if (!filter.archived) {
                                    return false;
                                }
                            }

                            if (_.get(filter, "name")) {
                                return container.name.toLowerCase().includes(filter.name.toLowerCase());
                            }

                            return true;
                        }
                    },

                    metrics: Container.metrics("domain"),
                    renderMetaMetrics: false,

                    onFabClickCreate: ($event, domains) => {
                        Container.edit($event, domains, {}, "domain");
                    },

                    import: ($event, domains) => {
                        Import.import($event, {
                            title: "Import Domains From CSV",
                            endpoint: "/api/domain/import"
                        }, domains, function () {
                            var domains = Container.query("domain");

                            domains.$promise.then(function () {
                                $scope.categories.domain.items = domains;
                            });
                        });
                    },
                };

                categories.site = {
                    id: "site",
                    title: "Places",
                    icon: "building-o",

                    populate: () => {
                        return Container.query("site");
                    },

                    filter: function (filter) {
                        console.log("filter", filter)
                        return function (container) {
                            if (!filter) {
                                return false;
                            }

                            console.log(container, filter)

                            if (container.archived == true) {
                                return false;

                                if (!filter.archived) {
                                    return false;
                                }
                            }

                            if (_.get(filter, "name")) {
                                return container.name.toLowerCase().includes(filter.name.toLowerCase());
                            }

                            return true;
                        }
                    },

                    metrics: Container.metrics("site"),

                    onFabClickCreate: ($event, sites) => {

                        var count = $scope.categories.site.metrics.count + $scope.categories.vendor.metrics.count + $scope.categories.technology.metrics.count;

                        if (count < $scope.organization.license.containers) {
                            Container.edit($event, sites, {}, "site");
                        } else {
                            $scope.exceededLicense($event, 'You are currently licensed for ' + $scope.organization.license.containers + ' VSC containers.')
                        }

                    },

                    import: ($event, sites) => {
                        Import.import($event, {
                            title: "Import Sites From CSV",
                            endpoint: "/api/site/import"
                        }, sites);
                    },
                };

                categories.technology = {
                    id: "technology",
                    title: "Technology",
                    icon: "server",

                    populate: () => {
                        return Container.query("technology");
                    },

                    filter: function (filter) {
                        return function (container) {
                            if (!filter) {
                                return false;
                            }

                            if (container.archived == true) {
                                return false;

                                if (!filter.archived) {
                                    return false;
                                }
                            }

                            if (_.get(filter, "name")) {
                                return container.name.toLowerCase().includes(filter.name.toLowerCase());
                            }

                            return true;
                        }
                    },

                    metrics: Container.metrics("technology"),

                    onFabClickCreate: ($event, technologies) => {

                        var count = $scope.categories.site.metrics.count + $scope.categories.vendor.metrics.count + $scope.categories.technology.metrics.count;
                        if (count < $scope.organization.license.containers) {
                            Container.edit($event, technologies, {}, "technology");
                        } else {
                            $scope.exceededLicense($event, 'You are currently licensed for ' + $scope.organization.license.containers + ' VSC containers.')
                        }

                    },

                    import: ($event, technologies) => {
                        Import.import($event, {
                            title: "Import Technologies From CSV",
                            endpoint: "/api/technology/import"
                        }, technologies);
                    },
                };

                categories.vendor = {
                    id: "vendor",
                    title: "Vendors",
                    icon: "truck",

                    populate: () => {
                        return Container.query("vendor");
                    },

                    filter: function (filter) {
                        return function (container) {
                            if (!filter) {
                                return false;
                            }

                            if (container.archived == true) {
                                return false;

                                if (!filter.archived) {
                                    return false;
                                }
                            }

                            if (_.get(filter, "name")) {
                                return container.name.toLowerCase().includes(filter.name.toLowerCase());
                            }

                            return true;
                        }
                    },

                    metrics: Container.metrics("vendor"),

                    onFabClickCreate: ($event, vendors) => {

                        var count = $scope.categories.site.metrics.count + $scope.categories.vendor.metrics.count + $scope.categories.technology.metrics.count;
                        if (count < $scope.organization.license.containers) {
                            Container.edit($event, vendors, {}, "vendor");
                        } else {
                            $scope.exceededLicense($event, 'You are currently licensed for ' + $scope.organization.license.containers + ' VSC containers.')
                        }

                    },

                    import: ($event, vendors) => {
                        Import.import($event, {
                            title: "Import Vendors From CSV",
                            endpoint: "/api/vendor/import"
                        }, vendors);
                    },
                };

            }

            categories.partner = {
                id: "partner",
                title: "Partners",
                icon: "handshake",

                populate: () => {
                    return Partner.resource.query();
                },

                filter: function (filter) {
                    return function (container) {
                        if (!filter) {
                            return false;
                        }

                        if (container.archived == true) {
                            return false;

                            if (!filter.archived) {
                                return false;
                            }
                        }

                        if (_.get(filter, "name")) {
                            return container.name.toLowerCase().includes(filter.name.toLowerCase());
                        }

                        return true;
                    }
                },

                metrics: Container.metrics("partner"),
                renderMetaMetrics: false,
            };

            $scope.categories = categories;
        });


    });


})();