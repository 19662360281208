(function () {
    angular.module("app").controller("UserListController", UserListController);

    function UserListController ($scope, $http, $mdDialog, Organization, Users, Training, Awareness, Import) {
        loadUsers();

        $scope.organization = Organization.current;
        $scope.filter = {}

        $scope.newUser = function () {
            var users = Users.resource.query();

            users.$promise.then(function () {
                if ($scope.organization.status.licensed) {
                    if ($scope.organization.license) {
                        var userLicenses = $scope.organization.license.users;
                    } else {
                        var userLicenses = 3;
                    }
                } else {
                    var userLicenses = 3;
                }

                if (users.filter(user => {
                    return user.status.state != "archived";
                }).length < userLicenses) {
                    Users.userModal(null, $scope.organization).then(() => {
                        loadUsers();
                    });
                } else {
                    $scope.exceededLicense($event, 'You are currently licensed for ' + userLicenses + ' VSC users.');
                }
            });
        }


        $scope.exceededLicense = function (ev, message) {
            var confirm = $mdDialog.confirm()
                .title('License Exceeded')
                .textContent(message)
                .targetEvent(ev)
                .ok('Ok, Got It');

            $mdDialog.show(confirm);
        }

        $scope.importUsers = function ($event) {
            Import.import($event, {
                title: "Import Users From CSV",
                endpoint: "/api/user/import"
            }, $scope.users, function () {
                loadUsers();
            });
        }

        function loadUsers() {
            $http.get("/api/users").then(result => {
                $scope.users = result.data;
            });
        }
    }
})();