(function () {
    //'use strict';

    angular.module("app").component("alertMenu", {
        bindings: {
            user: "<"
        },

        template: `
            <md-menu md-offset="0 50">
                <md-button class="md-icon-button md-button" type="button" ng-click="$mdMenu.open($event)" >
                    <i class="fa fa-bell fa-2x" ></i>
                    <badge ng-if="alerts.length">{{alerts.length}}</badge>
                </md-button>

                <md-menu-content class="alertMenu">
                    <md-content md-transparent flex>

                        <div layout="row" flex>
                            <div class="alertHeading" flex>Notifications</div>

                            <div class="alertItemText" flex="20">
                                <a href="" ng-click="$ctrl.dismissAllAlerts($event)">Clear All</a>
                            </div>
                        </div>

                        <md-divider flex inset></md-divider>

                        <div layout="column" class="alertItem" ng-repeat="alert in $ctrl.alerts | orderBy:'-dateIssued' as alerts" >
                            <div flex layout="row">
                                <div flex="15">
                                    <i ng-show="alert.priority==1" class="fa fa-exclamation-triangle" style="margin:12px;color:red"></i>
                                    <i ng-show="alert.priority==2" class="fa fa-exclamation" style="margin:12px"></i>
                                    <i ng-show="alert.priority==3" class="fa fa-info" style="margin:12px"></i>
                                </div>

                                <div layout="column" flex>
                                    <div layout="row">
                                        <div class="alertItemTitle" flex>
                                            <a ng-show="alert.link" href="{{alert.link}}">{{alert.title}}</a>
                                            <span ng-hide="alert.link">{{alert.title}}</span>
                                        </div>

                                        <div class="alertItemDate" flex="1">
                                            <a href="" ng-click="$ctrl.dismissAlert($event, alert)" md-prevent-menu-close="md-prevent-menu-close"><i class="fa fa-times"></i></a>
                                        </div>
                                    </div>

                                    <div class="alertItemText">{{alert.description}}</div>

                                    <div layout="row" class="alertItemDate">
                                        <div flex="50">{{alert.dateIssued | dateFormat:'MMM Do YYYY'}}</div>

                                        <div ng-show="alert.link" flex="1">
                                            <a href="{{alert.link}}"><i class="fa fa-eye"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <md-divider flex inset></md-divider>
                        </div>
                    </md-content>
                </md-menu-content>
            </md-menu>
        `,

        controller: function (Session, $http) {
            var ctrl = this;

            ctrl.$onInit = function() {
                if (ctrl.user == null) {
                    Session.currentUser.$promise.then(function (user) {
                        ctrl.user = user;
                        ctrl.alerts = user.activity.alerts;
                    });
                } else {
                    ctrl.alerts = ctrl.user.activity.alerts;
                }
            }

            ctrl.dismissAlert = function($event, alert) {
                var index = ctrl.alerts.indexOf(alert);

                $http.delete("/api/user/alert/" + alert._id).then(function (response) {
                }, function (response) {
                    // TODO: Handle error
                });

                ctrl.alerts.splice(index,1);
                $event.stopPropagation();
            }

            ctrl.dismissAllAlerts = function($event) {
                $http.delete("/api/user/alerts").then(function (response) {
                }, function (response) {
                    // TODO: Handle error
                });

                ctrl.alerts = [];
                $event.stopPropagation();
            }
        }
    });
})();
