/**
 * @ngdoc component
 * @name app.uiKit.component:progressBar
 * @author Nick Vorie
  *
 * @param {number} value The value of the progress bar. 0-100
 *
 * @description This component will render a progress bar with the given value
 */
(function () {
    "use strict";

    angular.module("app").component("progressBar", {
        bindings: {
            value: "=",
        },

        template: `
            <div class="progress" flex="100">
                <div class="progress-bar progress-bar-success progress-bar-striped" aria-valuenow="{{$ctrl.value}}" aria-valuemin="0" aria-valuemax="100" style="width:{{$ctrl.value}}%">
                    {{$ctrl.value}}%
                </div>
            </div>
        `,

        controller: function (Cvss) {
            var ctrl = this;

            ctrl.$onChanges = (changes) => {
            }
        }
    })
})();
