(function () {
    "use strict";

    angular.module("app").controller("EditBillingPlanController", function ($scope, $element, $mdToast, $mdDialog, Settings, Organization, ECommerce, organization, subscription) {

        $scope.ready = false;

        $scope.page = {
            plan: true,
            confirm: false,
            paymentInfo: false,
            paymentError: false,
            receipt: false,
            processing: false,
            checkingCard: false,
            canceling: false
        };

        $scope.btInstance = null;
        $scope.planChanged = false;

        this.$onInit = function () {

            $scope.org = organization;

            let promises = [];
            promises[0] = ECommerce.resource.getPricing().$promise;
            if (subscription) {
                $scope.subscription = subscription;
            } else {
                promises[1] = ECommerce.resource.getSubscription().$promise;
            }

            Promise.all(promises).then(function (results) {
                $scope.plan = results[0];
                if (!subscription) {
                    $scope.subscription = results[1];
                }
                setupForm();
                $scope.ready = true;
            }).catch(function (err) {
                console.log("CANNOT FETCH PRICING AND SUBSCRIPTION", err);
                $mdDialog.cancel();
            });

            // ECommerce.resource.getPricing().$promise.then(function (pricing) {
            //     $scope.plan = pricing;
            // }, function (err, result) {
            //     console.log("CANNOT FETCH PRICING", err, result)
            // });

            // if (subscription) {
            //     $scope.subscription = subscription;
            //     setupForm();
            // } else {
            //     ECommerce.resource.getSubscription().$promise.then(function (subscription) {
            //         $scope.subscription = subscription;
            //         setupForm();
            //     }, function (err, result) {
            //         console.log("cannot get payment info", err, result);
            //     });
            // }

        }

        function setupForm() {

            if ($scope.subscription) {
                if ($scope.subscription.transactions && $scope.subscription.transactions.length > 0) {
                    $scope.lastTransactionId = $scope.subscription.transactions[0].id;
                }
            }

            $scope.newPlan = {
                newUsers: 0,
                newContainers: 0,
                cost: 0,
                charge: 0,
                paymentMethodNonce: null
            }

            let defaultUsers = 1;
            let defaultContainers = 100;

            Settings.get("license.new.users", (error, value) => {
                if (error) {
                    return console.error(error);
                }

                defaultUsers = value;

                Settings.get("license.new.containers", (error, value) => {
                    if (error) {
                        return console.error(error);
                    }

                    defaultContainers = value;

                    if (!$scope.org.license.subscription || $scope.org.license.subscription.status != 'active' || $scope.org.license.type == "trial") {
                        $scope.users = 0;
                        $scope.containers = 0;
                        $scope.newPlan.users = defaultUsers;
                        $scope.newPlan.containers = defaultContainers;
                        $scope.newPlan.term = 'monthly';
                        $scope.newPlan.expiration = moment().add(1, 'M');
                        $scope.monthlyRenewalDate = moment($scope.newPlan.expiration).add(30, 'days');
                        $scope.annualRenewalDate = moment($scope.newPlan.expiration).add(30, 'days');
                    } else {
                        $scope.users = $scope.org.license.users;
                        $scope.containers = $scope.org.license.containers;
                        $scope.newPlan.users = $scope.org.license.users;
                        $scope.newPlan.containers = $scope.org.license.containers;
                        $scope.newPlan.term = $scope.org.license.term;
                        $scope.newPlan.expiration = moment($scope.org.license.expiration);
                        $scope.monthlyRenewalDate = moment($scope.newPlan.expiration).add(1, 'days');
                        $scope.annualRenewalDate = moment($scope.newPlan.expiration).add(1, 'days');
                    }
                });
            });

            $scope.calcCosts();

            $scope.validCard = false;

            if (!$scope.subscription || !$scope.subscription.paymentMethodToken || $scope.subscription.status == "Canceled") {
                ECommerce.resource.getToken().$promise.then(function (result) {

                    braintree.dropin.create({
                        authorization: result.token,
                        container: '#dropin-container',
                        card: {
                            cardholderName: {
                                required: false
                            }
                        }
                    }, function (createErr, btInstance) {
                        if (createErr) {
                            console.log("CREATE ERROR", createErr);
                        } else {
                            $scope.btInstance = btInstance;

                            btInstance.on('paymentMethodRequestable', function (event) {
                                $scope.validCard = true;
                                $scope.$apply();
                            });

                            btInstance.on('noPaymentMethodRequestable', function () {
                                $scope.validCard = false;
                                $scope.$apply();
                            });

                        }
                    });

                });
            }
        }

        $scope.cancel = function () {

            $mdDialog.cancel();
        }

        $scope.cancelSubscription = function (ev) {
            $scope.page.canceling = true;
        }

        $scope.confirmCancel = function (ev) {

            ECommerce.resource.cancelSubscription().$promise.then(function (result) {
                $mdDialog.hide(result.subscription);
                $mdToast.show($mdToast.simple().textContent("Account canceled."));
            }, function (err, result) {
                $mdToast.show(
                    $mdToast.simple()
                    .content('ERROR: Unable to cancel plan at this time.  Please try again or contact support. \n reference: ' + err.data.reference)
                    .position('top left')
                    .theme("error")
                    .action('CLOSE')
                    .hideDelay(0)
                );
                $mdDialog.cancel();
            });

        }

        $scope.submitMonthly = function () {
            $scope.newPlan.charge = $scope.monthlyProrated;
            $scope.newPlan.cost = $scope.monthlyCost;
            $scope.newPlan.renewalDate = $scope.monthlyRenewalDate;
            submitPlan('monthly');
        }

        $scope.submitAnnual = function () {
            $scope.newPlan.charge = $scope.annualProrated;
            $scope.newPlan.cost = $scope.annualCost;
            $scope.newPlan.renewalDate = $scope.annualRenewalDate;
            submitPlan('annual');
        }

        var submitPlan = function (term) {
            $scope.newPlan.term = term;
            $scope.page.plan = false;

            if ($scope.subscription && $scope.subscription.status != "Canceled" && $scope.subscription.paymentMethodToken) {
                $scope.page.confirm = true;
            } else {
                $scope.page.paymentInfo = true;
            }
        }

        $scope.submitPaymentInfo = function () {
            $scope.page.paymentInfo = false;
            $scope.page.checkingCard = true;

            $scope.btInstance.requestPaymentMethod(function (err, payload) {

                if (err) {
                    console.log(err);
                    $mdToast.show(
                        $mdToast.simple()
                        .content('ERROR: Unable to update payment at this time.  Please try again or contact support. \n reference: ' + err.data.reference)
                        .position('top left')
                        .theme("error")
                        .action('CLOSE')
                        .hideDelay(0)
                    );
                    $mdDialog.cancel();
                } else {
                    $scope.page.checkingCard = false;
                    $scope.page.confirm = true;
                    $scope.newPlan.paymentMethodNonce = payload.nonce;
                    $scope.$apply();
                }

            });

        }

        $scope.submitConfirmation = function () {

            $scope.page.confirm = false;
            $scope.page.processing = true;

            var data = {
                newPlan: $scope.newPlan
            }

            ECommerce.resource.updatePlan(data).$promise.then(function (result) {

                if (result.success) {
                    $scope.subscription = result.subscription;

                    if ($scope.subscription.transactions.length > 0) {
                        if ($scope.subscription.transactions[0].status == 'submitted_for_settlement') {
                            $scope.transaction = $scope.subscription.transactions[0];
                        } else {
                            $scope.transaction = null;
                        }
                    }

                    $scope.org.license.users = $scope.newPlan.users;
                    $scope.org.license.containers = $scope.newPlan.containers;
                    $scope.org.license.term = $scope.newPlan.term;
                    $scope.org.license.expiration = $scope.newPlan.expiration.toDate();

                    $mdToast.show($mdToast.simple().textContent("Account updated."));
                    $scope.page.processing = false;
                    $scope.page.receipt = true;
                    $scope.page.paymentError = false;

                    var trialChip = document.getElementById("trialChip");

                    if (trialChip) {
                        trialChip.style.display = "none";
                    }
                } else {
                    $scope.btInstance.clearSelectedPaymentMethod();
                    $scope.page.processing = false;
                    $scope.page.paymentInfo = true;
                    $scope.page.paymentError = true;
                }
            }, function (err, result) {
                console.log(err);

                $mdToast.show(
                    $mdToast.simple()
                    .content('ERROR: Unable to update plan at this time.  Please try again or contact support. \n reference: ' + err.data.reference)
                    .position('top left')
                    .theme("error")
                    .action('CLOSE')
                    .hideDelay(0)
                );
                $mdDialog.cancel();

            });

        }

        $scope.done = function () {
            $mdDialog.hide($scope.subscription);
        }

        $scope.calcCosts = function () {
            if ($scope.newPlan.containers < 100 || !$scope.newPlan.containers) {
                $scope.newPlan.containers = 100;
            }

            if ($scope.newPlan.users < 1 || !$scope.newPlan.users) {
                $scope.newPlan.users = 1;
            }

            if ($scope.newPlan.containers > 2000) {
                $scope.newPlan.containers = 2000;
            } else {
                $scope.newPlan.containers = Math.ceil($scope.newPlan.containers / 100) * 100;
            }

            var containerBlocks = $scope.newPlan.containers / 100 - 1;

            $scope.annualCost =
                $scope.plan.annual.baseCost +
                $scope.newPlan.users * $scope.plan.annual.userCost +
                containerBlocks * $scope.plan.annual.extraContainerCost;

            $scope.monthlyCost =
                $scope.plan.monthly.baseCost +
                $scope.newPlan.users * $scope.plan.monthly.userCost +
                containerBlocks * $scope.plan.monthly.extraContainerCost;

            $scope.planChanged = $scope.users != $scope.newPlan.users || $scope.containers != $scope.newPlan.containers;
        }
    });
})();