(function () {


    angular.module("app").component("containerTypeSelect", {
        template: `
            <md-input-container style="width:100%">
                <label for="type">{{$ctrl.label || "Type"}}</label>

                <md-select name="type" ng-model="$ctrl.bindTo" ng-model-options="{trackBy: '$value.title'}" ng-required="$ctrl.required" ng-disabled="$ctrl.disabled" md-on-close="$ctrl.selectChanged()">
                    <md-option ng-if="!$ctrl.required" ng-value="undefined" ng-selected="!$ctrl.bindTo">No type selected</md-option>
                    <md-option ng-value="type" ng-repeat="type in $ctrl.containerTypes">{{ type.title }}</md-option>
                </md-select>

            </md-input-container>
        `,

        bindings: {
            label: "@",
            bindTo: "=",
            typeFilter: "@",
            disabled: "=",
            required: "=",
            onChange: "&"
        },

        controller: function (Organization, Container) {
            var ctrl = this;

            ctrl.$onInit = function () {

                var qry = {
                    type: ctrl.typeFilter
                };

                Organization.current.$promise.then(function (org) {
                    if (org.industry) {
                        if (org.industry._id != "") {
                            qry = {
                                type: ctrl.typeFilter,
                                industries: org.industry._id
                            };
                        }
                    }

                    Container.typeResource.query(qry).$promise.then(function (types) {
                        ctrl.containerTypes = types;
                    });
                });
            }

            ctrl.selectChanged = function () {
                if (ctrl.onChange) {
                    ctrl.onChange();
                };
            }
        }
    });
})();