(function () {
    "use strict";

    angular.module("app").component("assessmentItemCard", {
        require: {
            assessment: "^^assessment"
        },

        bindings: {
            controlState: "=ngModel",
			readOnly: "=?",
            ngChange: "&?",
        },

        template: `
            <md-card layout="column">
                <control-card-header control="$ctrl.controlState.control" context="assessment" divider="true">
                    <control-state-radio ng-if="$ctrl.controlState.state.answers" read-only="$ctrl.readOnly" ng-model="$ctrl.controlState" ng-change="$ctrl.ngChange()"></control-state-radio>
                </control-card-header>

                <div layout="row" layout-align="end" style="max-height: 15rem;">
                    <attributes flex="50" ng-if="$ctrl.controlState.state.answer._id == 'in-place'" ng-model="$ctrl.controlState.state.attributes" ng-change="$ctrl.ngChange()"></attributes>

                    <messages flex="50" messages="$ctrl.comments" endpoint="{{$ctrl.assessment.containerType}}/{{$ctrl.assessment.container._id}}/control-state/{{$ctrl.controlState.control._id}}/comment" endpoint-query="select[]=controlState"></messages>
                </div>
            </md-card>
        `,

        controller: function (Control) {
            var ctrl = this;

            ctrl.$onInit = () => {
                Control.populateStateAnswers(ctrl.controlState.state, () => {}, []);

				if (ctrl.assessment.container.controlState.current) {
					ctrl.comments = ctrl.assessment.container.controlState.current.controlStates.find(controlState => {
					   return controlState.control._id == ctrl.controlState.control._id;
					}).comments;
				}
            }
        }
    });
})();
