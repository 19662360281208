angular.module('app').component('awarenessCard', {
    templateUrl: '/assets/html/awareness/awarenessCard.html',
    controller: AwarenessCardController,
    bindings: {
        awareness: '<',
        showRefresh: '<',
        user: '<'
    }
});

function AwarenessCardController($mdSidenav, Session, User, Awareness) {
    var ctrl = this;

    ctrl.ready = false;
    ctrl.acknowledged = false;
    ctrl.organization = {};

    ctrl.$onInit = function() {

        if (ctrl.showRefresh == null) {
            ctrl.showRefresh = true;
        }

        if (ctrl.user) {
            getAwareness();
        } else {

            Session.currentUser.$promise.then(function (user) {
                ctrl.user = user;
                ctrl.organization = user.organization;

                getAwareness();
            });
        }

    }

    getAwareness = function() {
        if (ctrl.awareness) {
            ctrl.ready = true;
        } else {
            Awareness.notviewedResource.get({}, function(awareness) {
                if (awareness._id) {
                    ctrl.awareness = awareness;
                } else {
                    ctrl.awareness = null;
                }
                ctrl.ready = true;
            });
        }
    }


    ctrl.refresh = function() {
        Awareness.notviewedResource.get({}, function(awareness) {
            ctrl.awareness = awareness;
        });
    }

   ctrl.showTip = function() {
        $mdSidenav("awarenessTip")
          .toggle()
          .then(function () {
          });
    }

    ctrl.closeTip = function () {
      $mdSidenav('awarenessTip').close()
          .then(function () {
          });
    }

}
