angular.module('app').component('reqTreeSelect', {
    templateUrl: '/assets/html/compliance/reqTreeSelect.html',
    controller: reqTreeSelectController,
    bindings: {
        specifications: '='
    }
});

function reqTreeSelectController($scope, $element, $mdSidenav, Session, Compliance) {
    var ctrl = this;

    ctrl.ready = false;
    ctrl.requirements = [];

    ctrl.$onInit = function() {

        angular.element(document).ready(function () {
            document.getElementById('mainControl').appendChild(document.getElementById('reqSideNav'));
        });

        Session.currentUser.$promise.then(function (user) {

            var reqs = user.organization.compliance.requirements;

            Compliance.resource.query({"id[]":reqs,"populate":["specifications"]}).$promise.then(function (requirements) {
                ctrl.requirements = requirements ;
                ctrl.ready = true;
            });
        });

    }


    ctrl.requirementNav = function() {
        $mdSidenav("requirementNav")
          .toggle()
          .then(function () {
          });
    }

    ctrl.closeReqNav = function () {
      $mdSidenav('requirementNav').close()
          .then(function () {
          });
    }


//        ctrl.togggle = function(scope) {
//            ctrl.toggle();
//        };

    $scope.reqTree = {
        dropped: function(event) {
            if (($(document).width() - event.pos.nowX) > 400) {
                var spec = event.source.nodeScope.$modelValue;
                ctrl.addSpec(spec);
            }
        },
    };

    ctrl.addSpec = function(spec) {
       var found=false;
        ctrl.specifications.forEach( function(chkSpec) {
            if (spec._id == chkSpec._id) {
                found = true;
            }
        });
        if (!found) {
            ctrl.specifications.push(spec);
        }

    }


}
