angular.module('app').component('setupVendor', {
    templateUrl: '/assets/html/setup/setup-vendor.html',
    controller: setupVendor,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<'
    }
});

function setupVendor($scope, $mdToast, $mdDialog, Vendor, Container) {
    var ctrl = this;
    $scope.vendors = [];

    ctrl.$onInit = function() {
        $scope.organization = ctrl.organization;

        Vendor.resource.query().$promise.then(function (vendors) {
            Container.typeResource.query(
                {
                    useInSetup: true,
                    type: "vendor",
                    industries: $scope.organization.industry._id,
                }
            ).$promise.then(function (types) {

                types.forEach(function(type) {
                    var found = false;
                    vendors.forEach(function(vendor) {
                        if(vendor.type._id == type._id) {
                            found = true;
                        }
                    });

                    if (!found) {
                        vendors.push({
                            "name":"",
                            "type":type,
                            "new":true
                        });
                    }
                })

                $scope.vendorTypes = types;
                $scope.vendors = vendors;
            });
        });

    }

    $scope.update = function() {

        var vendorPromises = [];
        $scope.vendors.forEach(function(vendor) {
            if(vendor.new && vendor.name.trim() != "") {
                var newVendor = new Vendor.resource();
                newVendor.name = vendor.name;
                newVendor.type = vendor.type;
                vendor.new = false;
                vendorPromises.push(newVendor.$save());
            }
        });
        Promise.all(vendorPromises).then(function(values) {
            showToast("New vendors saved.");
            ctrl.onNext();
        }, function(err, test) {
            // TODO: Add error handle
        });
    };

    $scope.toggleVendor = function(vendor) {

        if (vendor.selected) {
            vendor.selected = false;
        } else {
            vendor.selected = true;
        }
        return vendor.selected;
    }

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
