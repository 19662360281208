(function () {
    angular.module("app").controller("ControlsController", ControlsController);

    function ControlsController ($scope, $http, Control, Threat, $mdDialog, $mdToast, Import) {

        $scope.controls = Control.resource.query();
        $scope.containerType = "";
        $scope.threat = "";
        $scope.threatList = [];

        Threat.resource.query().$promise.then(function (threats) {
            $scope.threatList = threats ;
        });

        $scope.showDeleteDialog = function(control) {
            $scope.modalMessage = control.title;

            $mdDialog.show({
                contentElement: '#confirmDelete',
                clickOutsideToClose: true,
                controller: 'ControlController',
                parent: angular.element(document.body)
            }).then( function() {
                removeItem(control._id);
            }, function() {
                // cancel button was pressed
                //console.log("cancel");
            });


        };

        removeItem = function(id) {

            var control = Control.resource.get({id: id, _id: id}, function() {
                control.$delete().then(function(data) {
                    showToast("Control deleted: " + id);
                    $scope.controls = Control.resource.query();
                }, function(err, test) {
                    // save failed; should show user some kind if error
                    //console.log("Error deleteing: " + err);
                });
            });
        };

        $scope.clearFilter = function() {
            $scope.searchText = "";
            $scope.containerType = "";
            $scope.threat = "";
        }

        $scope.filterType = function(control) {
            if ($scope.containerType=="") {
                return true;
            } else {
                if (control.scope.container._id == $scope.containerType._id) {
                    return true;
                } else {
                    return false;
                }
            }
        }
        $scope.filterThreat = function(control) {

            if ($scope.threat=="") {
                return true;
            }

            var found = false;
            control.risk.threatPairs.forEach( function(pair) {
                if (pair.threat._id == $scope.threat._id) {
                    found = true;
                }
            });

            if (found) {
                return true;
            } else {
                return false;
            }
        }

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

        $scope.import = function ($event) {
            Import.import($event, {
                title: "Import Controls From CSV",
                endpoint: "/api/control/import"
            }, $scope.controls, function () {
            });

			return;

            $mdDialog.show({
                templateUrl: "/assets/html/control/import-modal.html",
                controller: ($scope, $mdDialog) => {
                    $scope.$mdDialog = $mdDialog;
                },
                parent: angular.element(document.body),
                bindToController: true,
                clickOutsideToClose: true,
                targetEvent: $event
            }).then(function (data) {
                $http.post("/api/control/import", {
                    data: data
                }).then((res) => {
                    showToast("Imported " + res.data.created + " controls from CSV");

                    setTimeout(function () {
                        window.location = "/controls";
                    }, 1000);
                }, (resp) => {
                    showToast("Could not import. Please check the CSV syntax")
                });
            });
        }

        $scope.closeDialog = function() {
              $mdDialog.hide();
        };

        $scope.cancelDialog = function() {
              $mdDialog.cancel();
        };

    }

})();
