angular.module('app').component('setupEmail', {
    templateUrl: '/assets/html/setup/setup-email.html',
    controller: setupEmail,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<'
    }
});

function setupEmail($scope, $mdToast, $http) {
    var ctrl = this;

    ctrl.$onInit = function() {

        $scope.organization = ctrl.organization;

        if (!$scope.organization.settings) {

            $scope.organization.settings = {
                notifications: {
                    email: {
                        enabled: true
                    }
                }
            };

        };

        $http.get("/api/info").then(res => {
            $scope.info = res.data;
        });

    }

    $scope.update = function() {

        $scope.organization.$save().then(function(org) {

            showToast("Notification preferences saved.");
            ctrl.onNext();

        }, function(err) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });

    };

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }

}
