(function () {
    "use strict";

    angular.module("app").factory("Vendor", VendorFactory);

    function VendorFactory ($resource, $http) {

        var VendorResource = $resource("/api/vendor/:vendorId", {
            vendorId: "@_id"
        });

        function VendorProvider() {
            var self = this;

            self.resource = VendorResource;

            return self;
        }

        return new VendorProvider();
    }

})();
