(function () {
    angular.module("app").controller("TrialController", TrialController);

    function TrialController ($scope, $mdToast, Setup) {

        $scope.userCreated = false;
        $scope.submitted = false;

        this.$onInit = function() {
            $scope.errors = [];
            $scope.trialUser = new Setup.trialUser({});
        }

        $scope.submit = function() {

            $scope.submitted = true;
            if ($scope.userCreated) return;

            $scope.trialUser.$save().then(function (result) {
                $scope.errorMsg = null;
                $scope.userCreated = true;

            }, function (err) {
                $scope.submitted = false;
                $scope.errorMsg = err.data.error[0].msg;
            });

        };



    }
})();
