(function () {
    angular
      .module("app")
      .controller("ControlController", ControlController);

    function ControlController ($scope, $location, $mdSidenav, Control, Compliance, ControlState, Threat, $mdDialog, $mdToast) {

        $scope.update = update;
        $scope.removeItem = removeItem;
        $scope.threatList = [];
        $scope.modalmessage = "";
        $scope.master = {};
        $scope.isNew = true;
        $scope.isReady = false;
        $scope.requirements = [];

        $scope.containerTypes = [
            {id: 'physical', name: 'Physical'},
            {id: 'technical', name: 'Technical'},
            {id: 'vendor', name: 'Vendor'},
            {id: 'people', name: 'People'}
        ];

        $scope.attributeTypes = ["radio"];


        Compliance.resource.query().$promise.then(function (requirements) {
            $scope.requirements = requirements ;
        });

        Threat.resource.query().$promise.then(function (threats) {
            $scope.threatList = threats ;
        });

        $scope.effectivenessList = ControlState.controlEffectivenesses;  // maybe someday change this to pull from database like threats

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

      // get the control
        if (id == "new") {
            $scope.control = new Control.resource();
            $scope.control.specifications = [];
            $scope.isReady = true;
        } else {
            var c = Control.resource.get({id: id, _id: id}, function() {
                $scope.control = c;
                $scope.isReady = true;
                $scope.isNew = false;
            });
        }

        $scope.master = angular.copy($scope.control);

        function update() {
            // quickly confirm ID is unique
            if($scope.isNew){
                var id = $scope.control.scope.container._id + "." + $scope.control.scope.base + "." + $scope.control.scope.index;
                Control.resource.get({id: id, _id: id}, function() {
                    // found existing control with same id; send error back
                    $scope.errMsg = "Control ID not unique. A control with this ID already exists.";
                    //$scope.controlForm.id.$setValidity('notUnique', false);
                }, function(err) {
                    // control is unique, so save it
                    $scope.control.id = id;
                    $scope.errMsg = null;
                    //$scope.controlForm.id.$setValidity('notUnique', true);
                    $scope.control.$save().then(function(data) {
                        // save worked, set _id and change isNew, so user can resubmit if desired
                        $scope.control._id = id;
                        delete $scope.control.id;
                        $scope.isNew = false;
                        showToast("New control '" + $scope.control.title + "' saved.");
                    }, function(err, test) {
                        // save failed; should show user some kind if error
                        console.log("ERROR", err);
                    });
                });
            } else {
              $scope.control.$save().then(function(data) {
                  // save worked
                 showToast("Control '" + $scope.control.title + "' saved.");
             }, function(err, test) {
                  // save failed, should tell the user
                  // console.log("save existing didn't work ", err, test);
              });

            }

        };

        $scope.addAttribute = function(control) {

            $scope.inserted = {
                title: '',
                tip: '',
                answerType: 'radio'
            };
            control.attributes.push($scope.inserted);
        };

        function removeItem(list, index) {
            list.splice(index, 1);
            update();
        };

        $scope.showEffectiveness = function(effectiveness) {
              for (i = 0; i < $scope.effectivenessList.length; i++) {
                  if (effectiveness._id == $scope.effectivenessList[i]._id) {
                      return $scope.effectivenessList[i].title;
                  }
              }
            return 'Not set';
        };

        $scope.showThreat = function(threat) {

            for (i = 0; i < $scope.threatList.length; i++) {
              if (threat._id == $scope.threatList[i]._id) {
                  return $scope.threatList[i].title;
              }
            }
            return 'Not set';
          };

        $scope.addThreat = function(control) {

            $scope.inserted = {
              threat: {title: ''},
              effectiveness: {title: ''}
            };

            if (!control.risk) {
                control.risk = {};
            }
            if (!control.risk.threatPairs) {
                control.risk.threatPairs = [];
            }

            control.risk.threatPairs.push($scope.inserted);
          };

        $scope.showDeleteDialog = function(item, index) {
            if(item[index].title){
                $scope.modalMessage = item[index].title;
            } else if (item[index].content) {
                $scope.modalMessage = "Answer: " + item[index].content;
            } else if (item[index].threat.title) {
                $scope.modalMessage = item[index].threat.title;
            } else {
                $scope.modalMessage = "";
            }

            $mdDialog.show({
                contentElement: '#confirmDelete',
                clickOutsideToClose: true,
                controller: 'ControlController',
                parent: angular.element(document.body)
            }).then( function() {
                removeItem(item, index);
            }, function() {
                // cancel button was pressed
                //console.log("cancel");
            });
        };

        $scope.closeDialog = function() {
              $mdDialog.hide();
        };

        $scope.cancelDialog = function() {
              $mdDialog.cancel();
        };

        $scope.resetForm = function() {
        $scope.control = angular.copy($scope.master);
        };

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
            );
        };

        $scope.cancel = function() {
              location = "/controls";
        };

    }

})();
