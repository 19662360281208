/**
 * @ngdoc service
 * @name app.compliance.service:Compliance
 * @author Terry Courtney, Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Compliance
 */
(function () {
    "use strict";

    angular.module("app").factory("Compliance", function ($resource, $http) {
        var self = this;

        var ComplianceResource = $resource("/api/compliance/:id", {
            id: "@_id"
        });
        self.resource = ComplianceResource;

        self.industryResource = $resource("/api/compliance/industries");

        var SpecificationResource = $resource("/api/compliance/specification/:id", {
            id: "@_id"
        });
        self.specificationResource = SpecificationResource;

        var RequirementsResource = $resource("/api/compliance/requirements");

        self.requirementsResource = RequirementsResource;

        /*
         * @ngdoc method
         * @methodOf app.compliance.service:Compliance
         * @name shouldRender
         *
         * @param {object} spec The Specification
         * @param {array}  reqs The Requirements
         *
         * @description Returns true if.requirement is contained within reqs
         * */
        function shouldRender (spec, reqs) {
            if (!reqs || !reqs.length) return true;

            return _.includes(reqs, spec.requirement);
        }

        self.shouldRender = shouldRender;

        return self;
    });
})();
