(function () {
    "use strict";

    angular.module("app").component("dashboardContent", {
        bindings: {
            id: "@?",
            view: "=?",
        },

        require: {
            dashboard: "^^"
        },

        transclude: {
            table: "?dashboardTable",
            grid: "?dashboardGrid",
            filter: "?dashboardFilter",
        },

        template: `
            <div ng-if="false" class="margin-2-x margin-t" layout="row" layout-align="end">
                <div>
                    <span>Layout</span>

                    <md-button class="md-icon-button" ng-click="$ctrl.toggleView()">
                        <md-tooltip>{{$ctrl.currentView.title}} view</md-tooltip>

						<span ng-if="$ctrl.currentView.id == 'grid'">
							<i class="fas fa-th-large"></i>
						</span>

						<span ng-if="$ctrl.currentView.id == 'table'">
							<i class="fas fa-th-list"></i>
						</span>
                    </md-button>
                </div>
            </div>

			<div class="margin-1-x margin-2-y">
				<div class="margin-x" ng-transclude="filter"></div>
			</div>

			<md-divider></md-divider>

            <div ng-show="$ctrl.dashboard.view == 'grid'" class="margin" ng-transclude="grid"></div>

            <div ng-show="$ctrl.dashboard.view == 'table'" class="margin" ng-transclude="table"></div>
        `,

        controller: function ($element) {
            var ctrl = this;

			ctrl.$onInit = function () {
				console.log(ctrl.dashboard)
			}

            ctrl.views = [{
                id: "grid",
                title: "Grid",
                icon: "fa check"
            }, {
                id: "table",
                title: "Table",
                icon: "fa th-list"
            }];

            ctrl.currentView = ctrl.views[0];

            ctrl.toggleView = function () {
                var viewCount = ctrl.views.length;
                var currentIndex = ctrl.views.indexOf(ctrl.currentView);

                if (viewCount - 1 == currentIndex) {
                    currentIndex = 0;
                } else {
                    currentIndex++;
                }

                ctrl.currentView = ctrl.views[currentIndex]
            }
        }
    });
})();
