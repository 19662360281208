(function () {
    "use strict";
    
    angular.module("app").component("floatingActionButton", {
        template: `
            <button class="btn btn-action btn-action-primary fixed" ng-click="$ctrl.onClick()"><icon type="play"></icon></button>
        `,
        
        bindings: {
            onClick: "="
        },
        
        controller: function (Task) {
            var ctrl = this;
        }
    }).run(function (ModalFactory) {
        ModalFactory.templates["floatingActionButton"] = {
            templateUrl: "/assets/html/core/material-strap/button/floating-action-button/modal.html",
            position: {
                modX: 0,
                modY: 0  
            }, 
        }
    })
})();
