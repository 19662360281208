/**
 * @ngdoc component
 * @name app.control.risk-profile.component:risk-profile
 * @author Nick Vorie
 *
 * @param {object}      controlState  The Control State
 *
 * @description
 * This component will render Threat Profile for a given ControlState
 */
(function () {
	"use strict";

	angular.module("app").component("riskProfile", {
		bindings: {
			container: "=container",
		},

		/* html */
		template: `
            <md-card flex="100" layout="column">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Risk Profile</span>
                    </md-card-title-text>
                </md-card-title>

                <md-divider></md-divider>

                <md-card-body layout="column">
					<risk-chart control-states="$ctrl.container.controlState.changes"></risk-chart>

					<div class="risk-metrics" layout="row">
						<div class="risk-metric" flex layout="column" layout-align="center center" ng-style="$ctrl.baseRiskSeverity.css">
							<div class="value" layout="row">
								<div>
									{{$ctrl.baseRisk}}
								</div>

								<div class="sub" layout="column" layout-align="center center">
									<div class="icon">
										<i class="fas fa-arrow-{{$ctrl.baseRiskTrend == 0 ? 'right' : $ctrl.acceptedRiskTrend > 0 ? 'up' : 'down'}}"></i>
									</div>

									<div>
										{{$ctrl.baseRiskTrend}}
									</div>
								</div>
							</div>

							<div>Base Risk Score</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center" ng-style="$ctrl.weightedRiskSeverity.css">
							<div class="value" layout="row">
								<div>
									{{$ctrl.weightedRisk}}
								</div>

								<div class="sub" layout="column" layout-align="center center">
									<div class="icon">
										<i class="fas fa-arrow-{{$ctrl.weightedRiskTrend == 0 ? 'right' : $ctrl.acceptedRiskTrend > 0 ? 'up' : 'down'}}"></i>
									</div>

									<div>
										{{$ctrl.weightedRiskTrend}}
									</div>
								</div>
							</div>

							<div>Weighted Risk Score</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center" ng-style="$ctrl.acceptedRiskSeverity.css">
							<div class="value" layout="row">
								<div>
									{{$ctrl.acceptedRisk}}
								</div>

								<div class="sub" layout="column" layout-align="center center">
									<div class="icon">
										<i class="fas fa-arrow-{{$ctrl.acceptedRiskTrend == 0 ? 'right' : $ctrl.acceptedRiskTrend > 0 ? 'up' : 'down'}}"></i>
									</div>

									<div>
										{{$ctrl.acceptedRiskTrend}}
									</div>
								</div>
							</div>

							<div>Accepted Risk Score</div>
						</div>
					</div>
				</md-card-body>

				<md-divider></md-divider>

                <md-card-title ng-if-start="false" class="padding-2">
                    <md-card-title-text>
                        <span>Risk Mangement Factors</span>
                    </md-card-title-text>
                </md-card-title>

                <md-card-body ng-if-end layout="column">
					<div class="risk-metrics" layout="row">
						<div class="risk-metric" flex="25" layout="column" layout-align="center center" ng-style="$ctrl.acceptedRiskSeverity.css">
							<div class="value">{{$ctrl.acceptedRisk}}</div>
							<div>Accecpted Risk</div>
						</div>

						<div flex layout="column">
							<div class="risk-metric title" flex="25" layout="column" layout-align="center center">
								<div>Remediation Plans</div>
							</div>

							<div flex layout="row">
								<div class="risk-metric" flex layout="column" layout-align="center center">
									<div class="value">{{$ctrl.remediation.tasks}}</div>
									<div>Tasks</div>
								</div>

								<div class="risk-metric" flex layout="column" layout-align="center center">
									<div class="value">{{$ctrl.remediation.riskBenefit}}</div>
									<div>Risk Benefit</div>
								</div>

								<div class="risk-metric" flex layout="column" layout-align="center center">
									<div class="value">{{$ctrl.remediation.complianceGain}}</div>
									<div>Compliance Gain</div>
								</div>
							</div>
						</div>
					</div>
				</md-card-body>

				<md-divider></md-divider>

                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Threat Model</span>
                    </md-card-title-text>
                </md-card-title>

                <md-card-body layout="column">
					<threat-chart control-state="$ctrl.container.controlState.current"></threat-chart>
				</md-card-body>

				<md-divider></md-divider>

                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Control State</span>
                    </md-card-title-text>
                </md-card-title>

                <md-card-body layout="column">
					<div class="risk-metrics" layout="row">
						<div class="risk-metric" flex layout="column" layout-align="center center">
							<div class="value">{{$ctrl.controls.inPlace}}</div>
							<div>In Place</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center">
							<div class="value">{{$ctrl.controls.notInPlace}}</div>
							<div>Not In Place</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center">
							<div class="value">{{$ctrl.controls.unknown}}</div>
							<div>Unknown</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center">
							<div class="value">{{$ctrl.controls.na}}</div>
							<div>N/A</div>
						</div>

						<div class="risk-metric" flex layout="column" layout-align="center center">
							<div class="value">{{$ctrl.controls.accepted}}</div>
							<div>Accepted Risk</div>
						</div>
					</div>
				</md-card-body>

                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Compliance</span>
                    </md-card-title-text>
                </md-card-title>

                <md-card-body layout="column">
					<div class="risk-metrics" layout="row">
						<div ng-repeat="compliance in $ctrl.container.controlState.current.compliance" class="risk-metric" flex layout="column" layout-align="center center" ng-style="compliance.css">
							<div class="value" ng-if="compliance.issues.length">{{compliance.issues.length}}</div>
							<div class="value" ng-if="!compliance.issues.length"><i class="fas fa-check"></i></div>
							<div>{{compliance.requirement.commonName || compliance.requirement.title}}</div>
						</div>
					</div>
				</md-card-body>
			</md-card>
        `,

		controller: function (Cvss, Organization) {
			var ctrl = this;

			ctrl.$onInit = function () {
				if (!ctrl.container.controlState) {
					return;
				}

				var controlStates = ctrl.container.controlState.current.controlStates;
				var previousControlState = ctrl.container.controlState.changes[ctrl.container.controlState.changes.length - 2];

				if (!previousControlState) {
					previousControlState = ctrl.container.controlState.changes[ctrl.container.controlState.changes.length - 1];
				}

				//Risk
				ctrl.baseRisk = _.round(_.get(ctrl.container.controlState.current.metrics, "2.value.max", 0), 2);
				ctrl.baseRiskSeverity = Cvss.getSeverity(ctrl.baseRisk);

				ctrl.weightedRisk = _.round(_.get(ctrl.container.controlState.current.metrics, "3.value", 0), 2);
				ctrl.weightedRiskSeverity = Cvss.getSeverity(ctrl.weightedRisk);

				ctrl.acceptedRisk = _.round(_.get(ctrl.container.controlState.current.metrics, "4.value", 0), 2);
				ctrl.acceptedRiskSeverity = Cvss.getSeverity(ctrl.acceptedRisk);

				//Calculate base risk trend
				ctrl.baseRiskTrend = _.round(ctrl.baseRisk - _.get(previousControlState.metrics, "2.value.max", 0), 2);
				ctrl.weightedRiskTrend = _.round(ctrl.weightedRisk - _.get(previousControlState.metrics, "3.value", 0), 2);
				ctrl.acceptedRiskTrend = _.round(ctrl.acceptedRisk - _.get(previousControlState.metrics, "4.value", 0), 2);

				//Remediation
				ctrl.remediation = {
					tasks: 0,
					riskBenefit: "N/A",
					complianceGain: "N/A",
				}

				if (_.get(ctrl.container, "remediation.current.steps")) {
					ctrl.remediation.tasks = ctrl.container.remediation.current.steps.length;
				}

				//Cotrol States
				var controlStateData = {
					inPlace: 0,
					notInPlace: 0,
					unknown: 0,
					accepted: 0,
					na: 0
				}

				controlStates.forEach(controlState => {
					if (!controlState.state.answer || controlState.state.answer._id == "dont-know") {
						controlStateData.unknown++;

						if (controlState.state.accepted) {
							controlStateData.accepted++;
						}
					} else if (controlState.state.answer._id == "in-place") {
						controlStateData.inPlace++;
					} else if (controlState.state.answer._id == "not-in-place") {
						controlStateData.notInPlace++;

						if (controlState.state.accepted) {
							controlStateData.accepted++;
						}
					} else if (controlState.state.answer._id == "na") {
						controlStateData.na++;
					}
				});

				ctrl.controls = controlStateData;

				//Compliance
				ctrl.container.controlState.current.compliance.forEach(compliance => {
					if (compliance.issues.length) {
						compliance.css = Cvss.getSeverity(8).css;
					} else {
						compliance.css = Cvss.getSeverity(0).css;
					}
				});
			}
		}
	});
})();