(function () {
    "use strict";

    angular.module("app").component("logo", {
        bindings: {
            default: "=?",
            fallback: "=?",
        },

        template: `
            <img ng-if="$ctrl.logo.url && !$ctrl.logo.deleted" class="logo" ng-src="{{$ctrl.logo.url}}">

            <img ng-if="$ctrl.default === true && !$ctrl.organization._id" src="https://cdn.virtualsecurity.center/assets/img/vsc-logo.png">

            <p ng-if="($ctrl.logo.deleted || !$ctrl.logo.url)">{{$ctrl.organization.name}}</p>
        `,

        controller: function ($scope, $element, $cookies, Session) {
            var ctrl = this;

            ctrl.logo = {
                url: $cookies.get("logo.url"),
                deleted: $cookies.get("logo.deleted") == "true",
            }

            Session.current.$promise.then(function () {
                loadLogo();
            });

            function loadLogo() {
                ctrl.organization = Session.current.organization;

                var template = _.get(ctrl.organization, "settings.template");

                if (template) {
                    var logo = {}

                    if (template.logo) {
                        logo.url = template.logo.url;
                        logo.deleted = template.logo.deleted;
                    }

                    ctrl.logo = logo;

                    $cookies.put("logo.url", logo.url, {
                        path: "/"
                    });

                    $cookies.put("logo.deleted", logo.deleted, {
                        path: "/"
                    });
                }
            }
        }
    });
})();