(function () {
    "use strict";
    
    angular.module("app").component("cvssMetric", {
        template: `
            <div>
                <label>{{$ctrl.metric.name}} ({{$ctrl.metric.id}})</label>
                <button-group ng-click="$ctrl.onClick();" elements="$ctrl.metric.values"></button-group>
            </div>
        `,

        bindings: {
            metric: "=",
            onClick: "&"
        }
    }).component("cvssMetricGroup", {
        template: `
            <div class="row">
                <cvss-metric ng-repeat="metric in $ctrl.metrics" metric="metric" on-click="$ctrl.onClick()" class="col-xs-12"></cvss-metric>
            </div>
        `,

        bindings: {
            metrics: "=",
            onClick: "&"
        }
    }).component("cvssMetricGroupEditor", {
        template: `
            <tab-container group="$ctrl.tabGroup">
                <div class="row m-b-2">
                    <div class="col-xs-12" style="padding-left: 6px;">
                        <tab-navigation></tab-navigation>
                    </div>
                </div>

                <tab-content ng-repeat="group in $ctrl.metrics" selector="{{group.id}}">
                    <cvss-metric-group metrics="group.metrics" on-click="$ctrl.onClick()"></cvss-metric-group>
                </tab-content>
            </tab-container>
        `,

        bindings: {
            metrics: "=",
            onClick: "&"
        },
        
        controller: function (CvssFactory, TabProvider) {
            var ctrl = this;
            
            ctrl.$onInit = function () {
                ctrl.tabGroup = TabProvider.create(CvssFactory.groupTabData);
            }
        }
    });
})();