(function () {
    "use strict";

    angular.module("app").component("alert", {
        bindings: {
            handler: "=",
            error: "@",
            success: "@",
        },

        template: `
            <div ng-if="!($ctrl.isEmpty($ctrl.error) && $ctrl.isEmpty($ctrl.handler.errorMessage))" class="alert alert-danger material">{{$ctrl.error || $ctrl.handler.errorMessage}}</div>

            <div ng-if="!($ctrl.isEmpty($ctrl.success) && $ctrl.isEmpty($ctrl.handler.successMessage))" class="alert alert-success material">{{$ctrl.success || $ctrl.handler.successMessage}}</div>
        `,

        controller: function () {
            var ctrl = this;

            ctrl.isEmpty = isEmpty;

            function isEmpty(message) {
                return !message || message == "";
            }
        }
    })
})();
