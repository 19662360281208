/**
 * @ngdoc component
 * @name app.remediation.component:remediationStepCard
 * @author Nick Vorie
 *
 * @param {object} step The Remediation step to render
 *
 * @description
 * This component will render a card for a step within a remediation plan
 */
(function () {
    "use strict";

    angular.module("app").component("remediationStepCard", {
        require: {
            remediation: "^^remediation",
        },

        bindings: {
            step: "=",
        },

        template: `
            <md-card>
                <control-card-header context="remediation" control="$ctrl.step.control" divider="true">
                    <div>
                        <chip class="margin-l">
                            <md-tooltip>Due Date</md-tooltip>
                            {{$ctrl.step.dueDate | date:"MMM d"}}
                        </chip>
                    </div>
                </control-card-header>

                <div layout="row" layout-align="end" style="max-height: 15rem;">
                    <md-list flex="50">
                        <md-list-item ng-if="$ctrl.step.action.enable || $ctrl.step.action.disable">
                            <md-checkbox class="md-accent" ng-model="$ctrl.step.action.acknowledged" ng-change="$ctrl.updateRemediationPlan()"></md-checkbox>

                            <span ng-if="$ctrl.step.action.enable">{{$ctrl.step.control.directive.add || 'Add the control'}}</span>

                            <span ng-if="$ctrl.step.action.disable">{{$ctrl.step.control.directive.remove || 'Remove the control'}}</span>
                        </md-list-item>

                        <md-list-item ng-if="$ctrl.step.action.attributes" ng-repeat="attribute in $ctrl.step.action.attributes" ng-class="{'text-muted': !$ctrl.step.action.acknowledged}" ng-disabled="!$ctrl.step.action.acknowledged">
                            <md-checkbox ng-disabled="!$ctrl.step.action.acknowledged" ng-model="attribute.action.acknowledged" ng-change="$ctrl.updateRemediationPlan()" class="md-accent margin-l"></md-checkbox>

                            <span ng-if="attribute.action.enable">{{attribute.directive.add || 'Add ' + attribute.title}}</span>

                            <span ng-if="attribute.action.disable">{{attribute.directive.remove || 'Remove ' + attribute.title}}</span>
                        </md-list-item>
                    </md-list>

                    <messages flex="50" messages="$ctrl.comments" endpoint="{{$ctrl.remediation.containerType}}/{{$ctrl.remediation.container._id}}/control-state/{{$ctrl.step.control._id}}/comment?select[]=controlState"></messages>
                </div>
            </md-card>
        `,

        controller: function () {
            var ctrl = this;

            ctrl.$onInit = () => {
                ctrl.comments = ctrl.remediation.container.controlState.current.controlStates.find(controlState => {
                   return controlState.control._id == ctrl.step.control._id;
                }).comments;
            }
        }
    });
})();
