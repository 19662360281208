(function () {
    angular.module("app").component("technologyCard", {
        bindings: {
            technologies: "=",
            technology: "=",
            show: "=?"
        },

        template: `
            <md-card>
                <md-card-content layout style="min-height:120px">
                    <div flex="20">
                        <md-icon md-font-icon="{{$ctrl.technology.type.icon || 'fa-laptop'}}" class="fa fa-3x margin-r" md-colors="::{color: 'default-primary-400'}"></md-icon>
                    </div>

                    <div flex>
                        <h5 class="truncate">{{$ctrl.technology.name}}</h5>
                        <h6>{{$ctrl.technology.type.display}}</h6>

                        <p ng-hide="$ctrl.technology.owner == null">Owner: {{$ctrl.technology.owner.profile.name}}</p>

                        <div ng-if="$ctrl.technology.type.category=='hardware'">
                            <p ng-hide="$ctrl.technology.hardware.model == null || $ctrl.technology.hardware.model == ''">Model: {{$ctrl.technology.hardware.model}}</p>
                            <p ng-hide="$ctrl.technology.hardware.serial == null || $ctrl.technology.hardware.serial == ''">Serial: {{$ctrl.technology.hardware.serial}}</p>
                        </div>

                        <div ng-if="$ctrl.technology.type.category=='software'">
                            <p ng-hide="$ctrl.technology.software.vendor == null || $ctrl.technology.software.vendor == ''">Vendor: {{$ctrl.technology.software.vendor}}</p>
                            <p ng-hide="$ctrl.technology.software.url == null || $ctrl.technology.software.url == ''">{{$ctrl.technology.software.url}}</p>
                        </div>

                        <div ng-if="$ctrl.technology.type.category=='network'">
                            <p ng-hide="$ctrl.technology.network.ipRange == null || $ctrl.technology.network.ipRange == ''">IP Range: {{$ctrl.technology.network.ipRange}}</p>
                        </div>

                        <div ng-if="$ctrl.technology.type.category=='media'">
                            <p ng-hide="$ctrl.technology.media.model == null || $ctrl.technology.media.model == ''">Model: {{$ctrl.technology.media.model}}</p>
                            <p ng-hide="$ctrl.technology.media.serial == null || $ctrl.technology.media.serial == ''">Serial: {{$ctrl.technology.media.serial}}</p>
                        </div>
                    </div>

                    <div ng-if="$ctrl.show.chip != false">
                        <a ng-if="$ctrl.technology.assessment.current" ng-href="/technology/{{$ctrl.technology._id}}/assessment">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Assessment</chip>
                        </a>
                        <a ng-if="$ctrl.technology.remediation.current" ng-href="/technology/{{$ctrl.technology._id}}/remediation">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Remediation</chip>
                        </a>
                    </div>
                </md-card-content>

                <md-divider ng-if-start="$ctrl.show.metrics"></md-divider>

                <control-state-metrics-dash ng-if-end threats="$ctrl.technology.controlState.current.threatRanges" compliance="$ctrl.technology.controlState.current.compliance" to-show="3"></control-state-metrics-dash>


                <md-divider></md-divider>

                <md-card-actions ng-if="$ctrl.show.actions != false" flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">

                        <md-button href="" ng-if="!$ctrl.isAuditor && $ctrl.show.assessmentIcon" ng-click="$ctrl.Assessment.start($event, $ctrl.technology, 'technology')" class="md-icon-button" aria-label="Assessment">
                            <md-tooltip>Schedule Assessment</md-tooltip>
                            <md-icon md-font-icon="fa-calendar" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="$ctrl.show.viewIcon" ng-href="/technology/{{$ctrl.technology._id}}" class="md-icon-button" aria-label="View">
                            <md-tooltip>Details</md-tooltip>
                            <md-icon md-font-icon="fa-eye" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.editIcon" class="md-icon-button" ng-click="$ctrl.Container.edit($event, $ctrl.technologies, $ctrl.technology, 'technology')" aria-label="Edit">
                            <md-tooltip>Edit</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && !$ctrl.technology.archived" ng-click="$ctrl.Container.archive($event, $ctrl.technologies, $ctrl.technology, 'technology')">
                            <md-tooltip>Archive</md-tooltip>
                            <md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
                        </md-button>
                                
                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && $ctrl.technology.archived" ng-click="$ctrl.Container.archive($event, $ctrl.technologies, $ctrl.technology, 'technology')">
                            <md-tooltip>Restore</md-tooltip>
                            <md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.Container.deleteItem($event, $ctrl.technologies, $ctrl.technology, 'technology')" aria-label="Delete">
                            <md-tooltip>Delete</md-tooltip>
                            <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                        </md-button>
                </md-card-actions>
            </md-card>
        `,

        controller: function (Container, Assessment, User) {
            var ctrl = this;

            ctrl.Container = Container;
            ctrl.Assessment = Assessment;

            ctrl.$onInit = () => {
                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });
            }
        },

    });
})();
