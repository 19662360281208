(function () {
    "use strict";

    angular.module("app").component("metricDonut", {
        bindings: {
            label: "@?",
            value: "@?",
            unit: "@?",
        },

        template: `
            <div class="d-flex justify-content-center">
                <canvas class="chart" width="150" height="50" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;}"></canvas>
            </div>
            <div class="d-flex justify-content-center" style="font-size: 12px; position: relative; top: -32px;">
                <span>{{$ctrl.value}}%</span>
            </div>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            ctrl.$onInit = function () {
                var value = ctrl.value;
                var offset = 100 - ctrl.value;

                setTimeout(function () {
                    var ctx = $element.find("canvas")[0].getContext("2d");
                    var config = {
                        type: 'doughnut',

                        data: {
                            datasets: [{
                                data: [offset, value],
                                backgroundColor: ["grey", "blue"],
                            }],
                        },

                        options: {
                            responsive: false,
                            //rotation: 1 * Math.PI,
                            //circumference: 1 * Math.PI,

                            hover: {
                               intersect: false
                            },

                            legend: {
                                display: false,
                                onHover: function(e, legendItem){},
                                onClick: function(e, legendItem){},
                            },

                            tooltips: {
                                enabled: false
                            },

                            animation: {
                                animateScale: true,
                                animateRotate: true
                            },

                            layout: {
                                padding: {
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                }
                            },
                        }
                    };

                    ctrl.chart = new Chart(ctx, config);

                    ctrl.$onChanges = function () {
                        setData();
                    }

                }, 500);

                function setData () {
                    var value = ctrl.value;
                    var offset = 100 - ctrl.value;

                    ctrl.chart.data.datasets = [{
                        data: [offset, value],
                        backgroundColor: ["grey", ctrl.severity.color, "grey"],
                    }];

                    ctrl.chart.update();
                }
            }
        }
    });

})();
