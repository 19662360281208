(function () {
    angular.module("app").controller("TrainingController", TrainingController);

    function TrainingController ($scope, Training,) {
        $scope.messages = [];

        Training.resource.query({
            enabledOnly: true
        }).$promise.then(function (messages) {
            $scope.messages = messages.filter(t => t.enabled);
        });
    }
})();
