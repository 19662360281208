// NOT USED

(function () {
    angular.module("app").controller("EditUserController", EditUserController);

    function EditUserController ($scope, $mdDialog, $mdToast, $http, user, Session, Users, UserRole, Upload) {

        $scope.ready = false;
        $scope.isNew = true;
        $scope.allRoles = {};
        $scope.searchText = "";
        $scope.avatarURL = "";
        $scope.errorMsg = null;

		if (!user.email) {
			user.emailonly = false;
		}

        Session.currentUser.$promise.then(function (user) {
            $scope.organization = user.organization;

            // get all of the available roles for the selection
            UserRole.resource.query().$promise.then(function (roles) {
                $scope.allRoles = roles;
            });

            let wfmPhrase = $scope.organization.phrase.employee;
            wfmPhrase = wfmPhrase.name.charAt(0).toUpperCase() + wfmPhrase.name.slice(1);;

            console.log(wfmPhrase)

            // get the current user if we have an id, or create a new user if not
            if (user._id) {
                $scope.user = Users.resource.get({id: user._id}, function () {
                    $scope.isNew = false;
                    $scope.ready = true;
                });
            } else {
                $scope.user = user;

                $scope.user.roles = [{
                    "_id" : "workforcemember",
                    "name" : wfmPhrase,
                }];

                $scope.ready = true;
            }

            $scope.allRoles.forEach(role => {
                if (role._id == "workforcemember") {
                    role.name = $scope.organization.phrase.employee;
                }

                role.name = role.name.charAt(0).toUpperCase() + role.name.slice(1);

                console.log(role)
            });
        });

        // save user
        $scope.update = function() {
            if (user.emailonly == true) {
                $scope.user.password = "";
                $scope.user.repeatPassword = "";
                $scope.user.username = "";
                $scope.user.invite = false;

                if ($scope.user.email==null || $scope.user.email=="") {
                    $scope.form.email.$setValidity('required', false);
                    return;
                } else {
                    $scope.form.email.$setValidity('required', true);
                }

            } else {
                if (($scope.user.username==null || $scope.user.username=="") && ($scope.user.email==null || $scope.user.email=="")) {
                    $scope.form.username.$setValidity('nameRequired', false);
                    return;
                } else {
                    $scope.form.username.$setValidity('nameRequired', true);
                }

                if (($scope.user.email==null || $scope.user.email=="") && user.invite == true) {
                    $scope.form.email.$setValidity('requiredForInvite', false);
                    return;
                } else {
                    $scope.form.email.$setValidity('requiredForInvite', true);
                }

                if (($scope.user.password==null || $scope.user.password=="") && $scope.isNew && user.invite != true) {
                    $scope.form.password.$setValidity('required', false);
                    return;
                } else {
                    if ($scope.form.password) {
                        $scope.form.password.$setValidity('required', true);
                    }
                }
            }

            if ($scope.user.password) {
                if ($scope.user.password != $scope.user.repeatPassword) {
                    $scope.form.repeatPassword.$setValidity('passwordMatch', false);
                    return;
                } else {
                    $scope.form.repeatPassword.$setValidity('passwordMatch', true);
                }

                if ($scope.user.password.length > 0 &&  $scope.user.password.length < 6) {
                    $scope.form.password.$setValidity('passwordLength', false);
                    return;
                } else {
                    $scope.form.password.$setValidity('passwordLength', true);
                }
            }

            $scope.user.$save().then(function (usr) {
                $scope.errorMsg = null;

                if (_.get($scope, "avatar.reset")) {
                    $scope.resetImage();
                } else if ($scope.form.avatar.$valid && $scope.avatar) {
                    $scope.uploadImage($scope.avatar);
                } else {
                    $mdDialog.hide($scope.user);
                }


                $mdDialog.hide($scope.user);
                showToast("Saved");

            }, function (err) {
                 // ToDo: Save failed.  Need cleaner way if getting message to the user.
                console.log("ERROR", err);
                $scope.errorMsg = err.data.error[0].msg;
            });
        };

        // show toaster message
        showToast = function (msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        }

        // cancel/close the edit modal
        $scope.cancelDialog = function () {
            $mdDialog.cancel();
        }


        $scope.checkRole = function (role) {
            var found = false;

            if ($scope.user) {
                if($scope.user.roles) {
                    $scope.user.roles.forEach(function(rl) {
                        if(role._id == rl._id) {
                            found = true;
                        }
                    })
                }
            }

            return found;
        }

        $scope.toggleRole = function (role) {
            var idx = -1;
            $scope.user.roles.forEach(function(rl, i) {
                if(role._id == rl._id) {
                    idx = i;
                }
            })

            if (idx > -1) {
                $scope.user.roles.splice(idx,1);
            } else {
                $scope.user.roles.push(role);
            }
        }

        $scope.resetAvatar = function (value) {
            if (!$scope.avatar) {
                $scope.avatar = {}
            }

            $scope.avatar.reset = value;
        }


        $scope.uploadImage = function (file) {
            Upload.upload({
                url: '/api/users/' + $scope.user._id + '/avatar',
                data: {file: file}
            }).then(function (resp) {
                $scope.user.profile.avatar = resp.data;
                $mdDialog.hide($scope.user);
                showToast("Image uploaded");
            }, function (resp) {
                showToast("Image upload failed");
            }, function (evt) {
                //var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                //console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        $scope.resetImage = function() {
            $http.delete('/api/users/' + $scope.user._id + '/avatar').then(function (resp) {
                $scope.user.profile.avatar = resp.data;

                $mdDialog.hide($scope.user);
                showToast("Image reset");
            }, function(resp) {
                showToast("Image failed");
            });
        }
    };
})();
