angular.module('app').component('setupProfileRoles', {
    templateUrl: '/assets/html/setup/setup-profile-roles.html',
    controller: setupProfileRoles,
    bindings: {
        onNext: '&',
        onBack: '&',
        roles: '<',
        user: '<'
    }
});

function setupProfileRoles($scope, $mdToast, $mdDialog, Session, Users) {
    var ctrl = this;

    ctrl.$onInit = function() {
        $scope.roles = ctrl.roles;
        $scope.user = ctrl.user;
    }


    $scope.checkRole = function (role) {
        return $scope.user.roles.findIndex(function(userRole) {
            return role._id == userRole
        }) > -1;
    }

    $scope.toggleRoleSelection = function(role) {
        var idx = $scope.user.roles.findIndex(function(userRole) {
            return role._id == userRole
        })

        if (idx > -1) {
            $scope.user.roles.splice(idx,1);
        } else {
            $scope.user.roles.push(role._id);
        }
    }

    $scope.update = function() {
        $scope.user.$save().then(function(usr) {
            showToast("Roles saved.");
            ctrl.onNext();
        }, function(err, test) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });
    };

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };
    $scope.back = function() {
        ctrl.onBack();
    }


    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

}
