(function () {
    "use strict";

    angular.module("app").component("userRoleInput", {
        template: `
            <div class="form-group-material">
                <div class="textarea">
                    <chip ng-repeat="role in $ctrl.roles" type="small">
                        <span>{{role.name}} <icon ng-click="$ctrl.remove(role)" type="times" class="icon"></icon></span>
                    </chip>
                </div>
                <label>Roles</label>
                <p ng-if="$ctrl.editable" class="text-right"><a ng-click="$ctrl.showAddModal()"><icon type="plus"></icon> Add Role</a></p>
            </div>
        `,

        bindings: {
            roles: "=",
            editable: "@"
        },

        controller: function (UserRole, Error, ModalFactory) {
            var ctrl = this;

            ctrl.errorHandler = Error.get("user-role-create");
            ctrl.UserRole = UserRole;

            ctrl.showAddModal = function () {
                ModalFactory.template("userRoleSelect", {
                    onSubmit: function (role) {
                        ctrl.roles.push(role)
                    }
                });
            }

            ctrl.remove = function (role) {
                ModalFactory.template("confirm", {
                    title: "Remove Role",
                    body: "Please confirm that you wish to remove this user role",
                    onConfirm: function () {
                        ctrl.roles.splice(ctrl.roles.indexOf(role));
                    }
                });
            }
        }
    });
})();
