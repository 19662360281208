(function () {
    angular.module("app").factory("Login", LoginFactory);

    function LoginFactory($http, Error) {
        function LoginFactory() {
            var self = this;

            self.errorHandler = Error.get("login");
            self.successMessage = null;

            self.login = login;

            function login (data, cb) {
                self.successMessage = null;
                self.errorHandler.message = null;

                $http.post("/api/auth/login", data).then(function (response) {
                    if (response.data.success) {
                        self.successMessage = "Login successful...";
                        if (cb) cb(null, true);
                    } else {
                        self.errorHandler.process(response.data);
                        if (cb) cb(response.data.error);
                    }
                }, function (response) {
                    self.errorHandler.process(response.data);
                    if (cb) cb(response.data.error);
                });
            }
        }

        return new LoginFactory();
    }
})();
