  (function () {
    angular.module("app").controller("PoliciesController", PoliciesController);

    function PoliciesController ($scope, $http, $mdDialog, $mdToast, $mdSidenav, Session, Policy, UserRole) {

        $scope.policy = {
            isNew: true
        };
        $scope.policies = [];
        $scope.categoryList = [];
//        $scope.sectionList = [];
        $scope.policyRoles = [];
        $scope.isReady = false;
        $scope.isAdmin = false;
        $scope.canEdit = false;
        $scope.filter = {
            searchText:"",
            category:"",
            role:"",
            showDefaults: true,
            showDisabled: true
        }
        $scope.submitting = false;

        this.$onInit = function () {

            UserRole.policyRoleResource.query().$promise.then(function (pr) {
                $scope.policyRoles = pr;

                Policy.getCategories().query().$promise.then(function (categories) {
                    $scope.categoryList = categories;
                });

            });

            Policy.orgPolicies().query({returnAll:true}).$promise.then(function (policies) {
                $scope.policies = policies;
                $scope.isReady = true;
            });

            Session.currentUser.$promise.then( function(user) {
                $scope.isAdmin = user.admin === true;
                $scope.canEdit = (user.roles.findIndex(function(role) {
                    return role.slug == 'cso' || role.slug == 'riskmanager'
                }) > -1);
            });

        }

        $scope.editPolicy = function(policy) {

            $scope.policy = policy;
//            $scope.policy = angular.copy(policy);
            $scope.getSections(policy.category);

            if($scope.policy.organization) {
                $scope.canDelete = true;
            } else {
                $scope.policy.default = true;

                if ($scope.isAdmin) {
                    $scope.canDelete = true;
                } else {
                    $scope.canDelete = false;
                }
            }

            $mdSidenav("editSideNav").toggle().then(function () {
            });
        }

        $scope.newPolicy = function() {

            $scope.policy = {
                isNew: true,
                enabled: false,
                roles:[{}]
            };

            $scope.editForm.$setUntouched();
            $scope.editForm.$setPristine();

            $mdSidenav("editSideNav").toggle().then(function () {
            });
        }

        $scope.cancelEdit = function() {
            $mdSidenav("editSideNav")
            .toggle()
            .then(function () {
            });
        }

        $scope.update = function() {

//            var data = {};
//            data.policy = $scope.policy;

            if ($scope.editForm.$valid) {

                $scope.submitting = true;
                Policy.resource.update($scope.policy).$promise.then( function (result) {

                    var idx = $scope.policies.findIndex(function(policy) {
                        if (result.replacesDocument) {
                            return result._id == policy._id || result.replacesDocument._id == policy._id;
                        } else {
                            return result._id == policy._id;
                        }
                    });

                    if (idx > -1) {
                        $scope.policies[idx] = result;
                    } else {
                        $scope.policies.push(result);
                    }

                    $scope.submitting = false;
                    showToast("Policy saved.");
                });

                $mdSidenav("editSideNav")
                    .toggle()
                    .then(function () {
                });

            }
        }

        $scope.deletePolicy = function(policy) {

            var confirm = $mdDialog.confirm()
                  .title('Confirm removal of this customized policy?')
                  .textContent(policy.title || policy.statement)
                  .ariaLabel('Confirm delete')
                  .ok('Yes, remove document')
                  .cancel('No, cancel');

            $mdDialog.show(confirm).then(function() {
                removeItem(policy);
            }, function() {
            });

        };

        removeItem = function(policy) {

            $scope.submitting = true;
            Policy.resource.delete({id: policy._id}).$promise.then( function(result) {

                Policy.orgPolicies().query({returnAll:true}).$promise.then(function (policies) {
                    $scope.policies = policies;

                    $mdSidenav("editSideNav")
                    .toggle()
                    .then(function () {
                    });

                    showToast("Policy removed: " + (policy.title || policy.statement));
                    $scope.submitting = false;
                });

            }, function(err, test) {
                // save failed; should show user some kind if error
                //console.log("Error deleteing: " + err);
            });

        };

        $scope.getSections = function(category) {
            Policy.getSections(category).query().$promise.then(function (sections) {
                    $scope.sectionList = sections;
            });
        }

        $scope.clearFilter = function() {
            $scope.filter.searchText="";
            $scope.filter.category="";
            $scope.filter.role="";
            $scope.filter.showDefaults= true;
            $scope.filter.showDisabled= true;
        }

        $scope.categoryFilter = function(policy) {
            if ($scope.filter.category == "") {
                return true;
            }

            if ($scope.filter.category == policy.category) {
                return true;
            } else {
                return false;
            }
        }

        $scope.roleFilter = function(policy) {

            if ($scope.filter.role=="") {
                return true;
            }

            var found = false;
            policy.roles.forEach( function(pRole) {
                if (pRole._id == $scope.filter.role._id) {
                    found = true;
                }
            });

            if (found) {
                return true;
            } else {
                return false;
            }
        }

        $scope.filterEnabled = function(policy) {
            return $scope.filter.showDisabled || policy.enabled
        }

        $scope.filterDefaults = function(policy) {
            return $scope.filter.showDefaults || policy.organization
        }

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

        $scope.closeDialog = function() {
              $mdDialog.hide();
        };

        $scope.cancelDialog = function() {
              $mdDialog.cancel();
        };

    }
})();
