(function () {
    angular.module("app").controller("DocumentController", DocumentController);

    function DocumentController ($scope, $location, $http, $mdToast, Document) {

        $scope.isReady = false;
        $scope.acknowledge = false;
        $scope.document = {};

        var docId;
        var userId;
        var orgId;
        var url = $location.absUrl();
        var urlQuery = url.split("?");
        if (urlQuery.length > 1) {
            var urlParams = urlQuery[1].split("=");
            if (urlParams.length > 1) {
                if (urlParams[0] == "acknowledge") {
                    if (urlParams[1] == "true") {
                        $scope.acknowledge = true;
                    }
                }
            }
        }

        var urlPieces = urlQuery[0].split("/");
        if (urlPieces.length == 7) {
            orgId = urlPieces[6];
            userId = urlPieces[5];
            docId = urlPieces[4];
        } else {
            var docId = urlPieces[urlPieces.length - 1];
        }

//        console.log("ACKNOWLEDGD", docId,userId,orgId,$scope.acknowledge)

        if ($scope.acknowledge) {
            $http.post(`/api/documents/acknowledge`, {
                docId: docId,
                orgId: orgId,
                userId: userId,
                returnDoc: true
            }).then( function(response) {

                $scope.document = response.data;
                $scope.isReady = true;

            }).catch(function(res){
                console.log(res);
            });

        } else {
            if (userId && orgId) {
                var url = `/api/documents/`+docId+'/'+userId+'/'+orgId+'?checkAcknowledgement=true&replaceSlugs=true&showChildren=true'
            } else {
                var url = `/api/documents/`+docId+'?checkAcknowledgement=true&replaceSlugs=true&showChildren=true';
            }
            $http.get(url).then( function(response) {

                $scope.document = response.data;
                $scope.isReady = true;

            }).catch(function(res){
                console.log(res);
            });

        }

        // acknowledge policies
        $scope.acknowledgeDocument = function() {

            $http.post(`/api/documents/acknowledge`, {
                docId: docId,
                orgId: orgId,
                userId: userId
            }).then( function(res) {
                $scope.document.userAcknowledgement.acknowledged = true;
                showToast("Document acknowledged.");
            }).catch(function(res){
                console.log(res);
            });

        }

        // show toaster message
        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };
    }
})();
