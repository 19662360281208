(function () {
    "use strict";

    angular.module("app").component("ieOnly", {
        template: `
            <md-card class="margin-b" ng-if="$ctrl.isIe" md-colors="::{backgroundColor: 'red'}">
                <md-card-content>
                    <h4>Unsupported Browser!</h4>
                    <p>Microsoft Internet Explorer browser is not a supported browser for Virtual Security Center. We recommend that you use Microsoft Edge, Chrome, Firefox, or Safari to access this application. If you do login with Internet Explorer, you can expect the unexpected.</p>
                </md-card-content>
            </md-card>
        `,

        controller: function ($http) {
            var ctrl = this;

            ctrl.$onInit = function () {
                ctrl.isIe = !!document.documentMode;
            }
        }
    })

})();
