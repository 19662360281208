(function () {
    "use strict";

    angular.module("app").component("toolbarActions", {
        transclude: true,
        template: `<span class="action-icons" ng-transclude></span>`,

        controller: function ($element) {
            var ctrl = this;

            $("page-title").after($($element));
        }

    })
})();
