(function () {
    angular.module("app").controller("ComplianceReportController", ComplianceReportController);

    function ComplianceReportController($scope, $location, Reporting) {

        var metrics = {};

        $scope.isReady = false;
        $scope.data = {
            hidePassing: false,
            showDashboard: true,
            showOverview: true,
            showDetails: true,
            showPlaces: true,
            showTech: true,
            showVendors: true,
            showDomains: true
        };

        var urlPieces = $location.absUrl().split("/");
        var reqId = urlPieces[urlPieces.length - 1];

        Reporting.complianceReport.get({ reqId: reqId }, function (report) {
            $scope.specs = report.specs;
            $scope.sites = report.sites;
            $scope.tech = report.tech;
            $scope.vendors = report.vendors;
            $scope.domains = report.domains;
            metrics = report.metrics;
            $scope.isReady = true;
            drawCharts();
        });

        $scope.filterPassing = function (spec) {
            if ($scope.data.hidePassing) {
                if (spec.compliant) {
                    return false;
                } else {
                    return true;
                }
            } else {
                return true;
            }
        };

        function drawCharts() {

            new Chart(document.getElementById("container-chart"), {
                type: 'pie',
                data: {
                    labels: ["Organization", "Places", "Technologies", "Vendors"],
                    datasets: [{
                        label: "Containers",
                        backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f"],
                        data: [
                            metrics.containers.domains,
                            metrics.containers.places,
                            metrics.containers.tech,
                            metrics.containers.vendors
                        ]
                    }]
                },
                options: {
                    title: {
                        display: true,
                        fontSize: 16,
                        text: 'Containers'
                    },
                    legend: {
                        display: true,
                        position: 'right'
                    }
                }
            });

            new Chart(document.getElementById("control-chart"), {
                type: 'pie',
                data: {
                    labels: ["In Place", "Not In Place", "Don't Know", "N/A", "Not Set"],
                    datasets: [{
                        label: "Controls",
                        backgroundColor: ["#3e95cd", "#8e5ea2", "#3cba9f", "#e8c3b9", "#c45850"],
                        data: [
                            metrics.controls.inPlace,
                            metrics.controls.notInPlace,
                            metrics.controls.dontKnow,
                            metrics.controls.na,
                            metrics.controls.notSet,
                        ]
                    }]
                },
                options: {
                    title: {
                        display: true,
                        fontSize: 16,
                        text: 'Controls'
                    },
                    legend: {
                        display: true,
                        position: 'right'
                    }
                }
            });


        }

    }
})();
