(function () {
    "use strict";

    angular.module("app").component("assessment", {
        bindings: {
            container: "=",
            containerType: "@",
        },

        /* html */
        template: `
            <nav-toolbar>
                <toolbar-title>
                    <md-breadcrumbs ng-if="!$ctrl.isVendorAssessment">
                        <a href="/">Home</a>

                        <a ng-if="$ctrl.isLicensed && $ctrl.isCSO && $ctrl.containerType == 'user'" href="/#user">People</a>
                        <a ng-if="$ctrl.isLicensed && $ctrl.isCSO && $ctrl.containerType == 'site'" href="/#site">Places</a>
                        <a ng-if="$ctrl.isLicensed && $ctrl.isCSO && $ctrl.containerType == 'domain'" href="/#domain">Organization</a>
                        <a ng-if="$ctrl.isLicensed && $ctrl.isCSO && $ctrl.containerType == 'technology'" href="/#technology">Technologies</a>
                        <a ng-if="$ctrl.isLicensed && $ctrl.isLicensed && $ctrl.isCSO && $ctrl.containerType == 'vendor'" href="/#vendor">Vendors</a>

                        <a ng-if="$ctrl.isLicensed && $ctrl.containerType == 'user'" ng-href="{{$ctrl.containerUrl}}">{{$ctrl.container.profile.name}}</a>
                        <a ng-if="$ctrl.isLicensed && $ctrl.containerType != 'user'" ng-href="{{$ctrl.containerUrl}}">{{$ctrl.container.name}}</a>

                        <a ng-href="#">Assessment</a>
                    </md-breadcrumbs>

                    <md-breadcrumbs ng-if="$ctrl.isVendorAssessment">
                        <a href="/">Home</a>
                        <a ng-href="#">Assessment</a>
                    </md-breadcrumbs>
                </toolbar-title>
            </nav-toolbar>

            <div ng-if="!$ctrl.container.assessment.current" flex="100" layout="row" layout-align="center">
                <span>No Assessment found</span>
            </div>

            <div ng-if-start="$ctrl.container.assessment.current" class="margin-b" layout="row" layout-align="start start">
                <float flex="40" layout="column" layout-align="start stretch">
                    <container-card container="$ctrl.container" type="{{$ctrl.containerType}}" show="{actions:false,chip:false,media:true}"></container-card>

                    <div class="padding-3" layout="column" layout-align="start end">
                        <md-progress-linear ng-if="false" md-mode="determinate" value="{{$ctrl.Assessment.controlsAnswered($ctrl.container.assessment.current.controlStates).percent}}"></md-progress-linear>

                        <progress-bar style="width: 100%;" value="$ctrl.Assessment.controlsAnswered($ctrl.container.assessment.current.controlStates).percent"></progress-bar>

                        <p class="text-muted">Due on {{$ctrl.container.assessment.current.date.due | date:"MMM d"}}</p>
                    </div>
                </float>

                <div flex="60" layout="column" layout-align="start stretch">
                    <assessment-item-card ng-repeat="controlState in $ctrl.container.assessment.current.controlStates | orderBy:'+control._id'" read-only="$ctrl.isAuditor" ng-model="controlState" ng-change="$ctrl.onChange()"></assessment-item-card>
                </div>
            </div>

            <div ng-if-end position-fixed-bottom-right layout="row" layout-align="end center">
				<div ng-if="!$ctrl.isAuditor">
					<md-button ng-click="$ctrl.AssessmentInstance.reset($event, $ctrl.container.assessment.current)" class="md-raised md-default">Reset</md-button>

					<md-button ng-click="$ctrl.AssessmentInstance.save($event, $ctrl.container.assessment.current)" class="md-raised md-primary">Save</md-button>

					<md-button ng-if="$ctrl.Assessment.controlsAnswered($ctrl.container.assessment.current.controlStates).percent == 100" ng-click="$ctrl.AssessmentInstance.submit($event, $ctrl.container.assessment.current)" class="md-raised md-primary">Submit</md-button>
				</div>
            </div>
        `,

        controller: function ($mdDialog, $mdToast, Control, Assessment, Session, User) {
            var ctrl = this;

            ctrl.Control = Control;
            ctrl.Assessment = Assessment;

            ctrl.isCSO = false;
            ctrl.isLicensed = false;

            Session.currentUser.$promise.then(function (user) {
                ctrl.isLicensed = user.organization.status.licensed;
                ctrl.isCSO = user.roles.some(function (role) {
                    return role._id == "cso" || role._id == "riskmanager"
                });
            });

            ctrl.$onInit = function () {
                ctrl.isVendorAssessment = ctrl.containerType == "vendor";

                ctrl.AssessmentInstance = Assessment.instance({
                    containerType: ctrl.containerType,
                    endpoint: "/api/" + ctrl.containerType,
                    containerId: ctrl.container._id,
                });

                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });

                User.checkRoles(["cso", "riskmanager", "auditor"]).then(result => {
                    if (result) {
                        ctrl.containerUrl = `/${ctrl.containerType}/${ctrl.container._id}`;
                    }
                });
            }
        }
    });
})();