(function () {
	angular.module("app").component("billingConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Account</h3>

					<span class="md-subhead md-block">
						<content content-id="config.account.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<account></account>
			</md-card-content>
        `,

		controller: function () {
			var ctrl = this;
		},
	});
})();
