(function () {
	angular.module("app").component("complianceConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Compliance</h3>

					<span class="md-subhead md-block">
						<content content-id="config.compliance.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>
			
			<md-card-content>
				<form name="ComplianceConfigurationForm">
					<div layout="row" layout-wrap flex>
						<div flex>
							<md-switch ng-repeat="req in $ctrl.requirements" value="{{ req._id }}" ng-checked="$ctrl.organization.compliance.requirements.indexOf(req._id) > -1" ng-click="$ctrl.toggleReqSelection(req._id)">
								{{req.title}}
							</md-switch>
						</div>
					</div>
				</form>
			</md-card-content>
        `,

		controller: function (Compliance) {
			var ctrl = this;

			Compliance.requirementsResource.query().$promise.then((reqs) => {
				ctrl.requirements = reqs;
			});

			ctrl.toggleReqSelection = (value) => {
				var idx = ctrl.organization.compliance.requirements.indexOf(value);

				if (idx > -1) {
					ctrl.organization.compliance.requirements.splice(idx, 1);
				} else {
					ctrl.organization.compliance.requirements.push(value);
				}
			}
		},
	});
})();