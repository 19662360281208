(function () {
    "use strict";

    angular.module("app").factory("Partner", function ($resource, $mdDialog, $mdToast) {
        var self = this;

        self.resource = $resource("/api/partner/:id", {
            id: "@_id"
        }, {
            update: {
                method:'POST',
                url: '/api/partner/:id',
                isArray: false
            }
        });

        self.editPartner = function(partner, $event) {

            var editController = function($scope, $mdDialog, partner) {
                $scope.partner = partner;

                this.$onInit = function() {

                }

                $scope.cancel = function() {
                    $mdDialog.cancel();
                }

                $scope.save = function() {
                    $mdDialog.hide($scope.partner);
                }
            }

            $mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: $event,
                templateUrl: '/assets/html/partner/edit-modal.html',
                locals: {
                    partner: partner
                },
                controller: editController
            }).then(function(docToSave) {

                console.log("SAVE")

                self.resource.update(docToSave).$promise.then( function (result) {

                    $mdToast.show (
                        $mdToast.simple()
                        .textContent("Partner saved")
                        .hideDelay(3000)
                    );
               });


            }, function() {
            });


        }

        return self;
    });

})();
