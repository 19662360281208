(function () {

	angular.module("app").component("orgTable", {
		template: `
			<md-card>
				<table class="material">
					<thead>
						<tr>
                            <th></th>
							<th>Name</th>
                            <th>Licensed</th>
							<th>Type</th>
							<th>Expiration</th>
							<th>Users</th>
							<th>Containers</th>
                            <th></th>
						</tr>
					</thead>

					<tbody>
						<tr ng-repeat="org in $ctrl.orgs | orderBy:'+name' | filter:$ctrl.filter.searchText" >
							<td style="max-width:100px">
								<img ng-if="org.settings.template.logo.url" style="height: 30px;max-width:100px" class="margin-r" ng-src="{{org.settings.template.logo.url}}">
								<md-icon ng-if="!org.settings.template.logo.url" md-font-icon="fas fa-2x fa-building" class="margin-r"></md-icon>
                            </td>
                            <td>
								<span>{{org.name}}</span>
							</td>
                            <td>
                                <i ng-show="org.status.licensed" style="color:green" class="fa fa-check fa-sm"></i>
                                <i ng-show="!org.status.licensed" style="color:red" class="fa fa-times fa-sm"></i>
                            </td>
							<td>
								<span ng-if="!org.license.subscription || org.license.subscription.status != 'past-due'">{{org.license.type}}</span>
								<chip ng-if="org.license.subscription.status == 'past-due'" type="xs danger">{{org.license.type}}</chip>
							</td>
                            <td>{{org.license.expiration | dateFormat: "alt"}}</td>
                            <td><chip type="xs {{org.userChipColor}}">{{org.userChipValue}}</chip></td>
                            <td><chip type="xs {{org.containerChipColor}}">{{org.containerChipValue}}</chip></td>

							<td class="action">

                                <md-menu md-position-mode="target-right target" md-offset="0 50">
									<md-button ng-click="$mdMenu.open($event)" class="md-icon-button">
										<i class="fas fa-ellipsis-v"></i>
									</md-button>

									<md-menu-content>

										<md-menu-item>
                                            <md-button ng-href="/users/{{org._id}}" aria-label="Users">
                                                <md-icon md-font-icon="fa-users" class="fa"></md-icon>
                                                <span>Users</span>
                                            </md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-click="$ctrl.onEdit({org:org})">
												<md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
												<span>Edit</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
                                            <md-button ng-click="$ctrl.onDelete({org:org})" aria-label="Delete">
                                                <md-tooltip>Delete</md-tooltip>
                                                <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                                                <span>Delete</span>
                                            </md-button>
										</md-menu-item>

									</md-menu-content>
								</md-menu>
							</td>
						</tr>
					</tbody>
				</table>
			</md-card>
		`,

		bindings: {
			orgs: "=",
			filter: "=",
			onDelete: "&",
			onEdit: "&"
		},

		controller: function () {
			var ctrl = this;

			ctrl.$onInit = function () {


			}

		},

	});
})();