(function () {

    angular.module("app").component("navMenu", {
        template: `

<style>

</style>
            <md-list ng-if="$ctrl.navItems.length">
                <md-list-item layout class="nav-menu-item" ng-href="{{item.href}}" ng-click="item.onClick()" ng-repeat-start="item in $ctrl.navItems">
                    <i class="fas fa-{{item.icon}} fa-lg" flex="5"></i>
                    <span style="padding-left:6px">{{item.title}}</span>
                </md-list-item>

                <md-list-item class="nav-menu-item" ng-href="{{item.href}}" ng-click="item.onClick()" ng-repeat-end ng-repeat="item in item.items">
                    <span style="padding-left:50px">{{item.title}}</span>
                </md-list-item>
            </md-list>
        `,

        bindings: {
        },

        controller: function ($http) {
            var ctrl = this;

            $http.get("/api/navigation").then(res => {
                ctrl.navItems = res.data || [];
            });
        }
    });

})();
