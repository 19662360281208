(function () {
    "use strict";

    angular.module("app").component("navProfile", {
        /* html */
        template: `
            <div class="clickable" ng-if="$ctrl.Session.currentUser._id">
                <md-menu md-position-mode="target-right target" md-offset="0 50">
                    <span ng-click="$mdMenu.open($event)">
						<span class="image-overlay">
							<img class="avatar-img" ng-src="{{$ctrl.user.profile.avatar.url}}">
							<span ng-if="$ctrl.user.profile.avatar._id == 'user.avatar.default'" class="text medium">{{$ctrl.initials()}}</span>
						</span>
                    </span>

                    <md-menu-content class="padding-0-y" width="3">
                        <md-list-item class="md-2-line md-info">
                          <div class="md-list-item-text" layout="column">
                            <p>{{$ctrl.Session.currentUser.profile.name}}</p>
                            <p>{{$ctrl.Session.currentUser.email || $ctrl.Session.currentUser.username}}</p>
                          </div>
                        </md-list-item>

                        <md-divider></md-divider>

                        <md-menu-item ng-if="$ctrl.Session.currentUser.organizations.length > 1" ng-repeat="o in $ctrl.Session.currentUser.organizations | orderBy:'organization.name'">
                            <md-button ng-click="$ctrl.Session.switchOrganization(o)" ng-disabled="o.disabled">
                                <span>{{o.organization.name}}</span>
                                <i ng-if="$ctrl.Session.currentUser.organization._id == o.organization._id" class="margin-l fa fa-check"></i>
                            </md-button>
                        </md-menu-item>

                        <md-divider ng-if="$ctrl.Session.currentUser.organizations.length > 1"></md-divider>

                        <md-menu-item ng-if="$ctrl.isAccountAdmin">
                            <md-button href="/account">
                                <span>Account</span>
                            </md-button>
                        </md-menu-item>

                        <md-menu-item>
                            <md-button href="/account/profile">
                                <span>Profile</span>
                            </md-button>
                        </md-menu-item>

                        <md-menu-item>
                            <md-button ng-click="$ctrl.Session.logout()">
                                <span>Sign Out</span>
                            </md-button>
                        </md-menu-item>
                    </md-menu-content>
                </md-menu>
            </div>
        `,

        controller: function (Session) {
            var ctrl = this;

            ctrl.Session = Session;

            Session.currentUser.$promise.then(() => {
                ctrl.user = Session.currentUser;
                ctrl.isAccountAdmin = ctrl.user.roles.find(function (role) {
                    return role._id == 'accountadmin';
                });
            });

            ctrl.initials = function () {
                return ctrl.user.profile.firstName.charAt(0) + ctrl.user.profile.lastName.charAt(0);
            }
        }
    })
})();