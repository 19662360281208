(function () {
    "use strict";

    angular.module("app").component("navHeader", {
        bindings: {
            node: "@"
        },

        /* html */
        template: `
            <md-toolbar id="primary-nav" class="md-hue-3">
                <div layout="row" class="md-toolbar-tools">
                    <md-button ng-if="$ctrl.loggedIn !== false && $ctrl.licensed" ng-click="$ctrl.$mdSidenav('left').toggle()" class="md-icon-button">
                        <icon type="bars"></icon>
                    </md-button>

                    <a href="/" flex="initial" class="nav-brand">
                        <logo default="$ctrl.loggedIn === false"></logo>
                    </a>

                    <span flex></span>

                    <span ng-if="$ctrl.loggedIn == false">
                        <a href="/login">Login</a>
                    </span>

                    <md-button id="trialChip" ng-if="$ctrl.trial" style="margin-right:12px" ng-click="$ctrl.updatePlan()">
                        <chip font-color="'white'" color="'red'">Trial Exp.{{$ctrl.trialExp | date: "M/d/yyyy"}}</chip>
                    </md-button>

                    <nav-help ng-if-start="$ctrl.loggedIn" class="margin-r" node="{{$ctrl.node}}"></nav-help>
                    <task-menu ng-if="$ctrl.licensed" class="margin-r-2"></task-menu>
                    <alert-menu ng-if="false" class="r"></alert-menu>
                    <nav-profile ng-if-end class="margin-l right" hide show-gt-xs></nav-profile>
                </div>
            </md-toolbar>
        `,

        controller: function ($mdSidenav, Session, Organization, ECommerce) {
            var ctrl = this;

            ctrl.$mdSidenav = $mdSidenav;

            ctrl.loggedIn = true;

            Session.current.$promise.then(function (user) {
                ctrl.loggedIn = true;
                ctrl.organization = user.organization;
                if (user.organization.license) {
                    ctrl.trial = user.organization.license.type == 'trial';
                    ctrl.trialExp = user.organization.license.expiration;
                }
                ctrl.licensed = user.organization.status.licensed;
            }, function (err) {
                ctrl.loggedIn = false;
            });

            ctrl.updatePlan = function () {
                ECommerce.editBillingPlan(ctrl.organization).then(function (resp) {
                    if (resp) {
                        ctrl.trial = false;
                    }
                });
            }
        }
    })
})();