/**
 * @ngdoc controller
 * @name app.controller:ConfigurationController
 * @element
 * @function
 *
 * @description
 * Controller used to configure an existing organization object.
 *   Includes code to upload organization logo.
 *
 * @example
 */
(function () {
    angular.module("app").controller("ConfigurationController", ConfigurationController);

    function ConfigurationController($scope, $mdToast, $mdDialog, $http, Session, User, Organization, Upload, Compliance, Container) {

        $scope.tabs = [];

        $scope.workAtList = ["workforce members' residences", "coffee shops and remote locations", "hotels or conferences", "vehicles", "third party facilities"];
        $scope.includeWorkerList = ["full-time employees", "part-time employees", "temporary workers", "contractors", "consultants", "volunteers", "trainees", "student interns", "auditors"];
        $scope.includeBAsList = ["contractors", "consultants", "auditors", "researchers", "interns"];

        $scope.requirementList = [];
        $scope.isReady = false;

        Organization.current.$promise.then(function (org) {
            $scope.organization = org;
            $scope.licensed = org.status.licensed;
            $scope.isVendor = org.license.type == 'vendor';

            User.checkRoles(['cso', 'riskmanager']).then((userIsAdmin) => {
                User.checkRoles(['accountadmin']).then((userIsAccountAdmin) => {
                    $scope.tabs = $scope.tabs.concat([{
                        title: "Organization",
                        order: 0
                    }, {
                        title: "Roles",
                        order: 1
                    }, {
                        title: "Features",
                        order: 2,
                    }, {
                        title: "Email",
                        order: 3
                    }, {
                        title: "Training",
                        order: 8
                    }]);

                    if (userIsAdmin && !$scope.isVendor) {
                        $scope.tabs = $scope.tabs.concat([{
                            title: "Notifications",
                            order: 4
                        }, {
                            title: "Risk Analysis",
                            order: 5
                        }, {
                            title: "Compliance",
                            order: 6
                        }]);

                        if ($scope.organization.policy.enabled) {
                            $scope.tabs.push({
                                title: "Policy",
                                order: 7
                            });
                        }
                    }

                    if (userIsAccountAdmin) {
                        $scope.tabs.push({
                            title: "Account",
                            order: 9
                        });
                    }

                    $scope.tabs = _.sortBy($scope.tabs, "order");

                    $scope.tab = $scope.tabs[0];
                    $scope.isReady = true;
                });
            });

        });

        // update organization
        $scope.updateOrg = function () {
            console.log("ORG", $scope.organization)
            $scope.organization.$save().then(function (data) {
                showToast("Settings saved");

                if (_.get(data, "settings.template.displayName")) {
                    _.set(Session.current.organization, "settings.template.displayName", _.get(data, "settings.template.displayName"));
                }
            }, function (err, test) {
                // save failed, should tell the user
                console.log("save didn't work ", err, test);
            });

        };

        $scope.toggleReqSelection = function (value) {
            var idx = $scope.organization.compliance.requirements.indexOf(value);

            if (idx > -1) {
                $scope.organization.compliance.requirements.splice(idx, 1);
            } else {
                $scope.organization.compliance.requirements.push(value);
            }
        }

        // show toaster message
        showToast = function (msg) {
            $mdToast.show(
                $mdToast.simple()
                    .textContent(msg)
                    .hideDelay(3000)
            );
        };

        $scope.setTab = function (t) {
            $scope.tab = t;
        }
    }
})();