(function () {
    angular.module("app").controller("ContainerController", function ($scope, $location, Container) {
        $scope.query = query;

        function query (type, populate) {
            var containers = Container.Resource.query({containerType: type, "select[]": populate || ["controlState", "assessment.current", "assessment.scheduled"]}, () => {
                $scope.containers = containers;
            });
        }

        $scope.load = load;

        function load (type, id, populate) {
            var container = Container.Resource.get({containerType: type, id: id, "select[]": populate || ["controlState", "assessment.current", "assessment.scheduled"]}, () => {
                $scope.container = container;
                $scope.loaded = true;
            }, () => {
                $scope.loaded = true;
            });
        }
    });
})();
