(function () {
    angular.module("app").controller("TechDetailController", TechDetailController);

    function TechDetailController ($scope, $location, Technology) {

        $scope.tech = {};
        $scope.ready = false;

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        Technology.resource.get({techId: id}, function(tech) {
                $scope.tech = tech;
                $scope.ready = true;
        });

    }

})();
