(function () {
    angular.module("app").controller("SiteController", SiteController);
    
    function SiteController ($scope, $timeout, $location, Tab, Site, Error) {
        $scope.Site = Site;
        $scope.errorHandler = Error.get("site");
        
        $scope.loadSite = loadSite;
        $scope.remove = remove;

        function remove () {
            Site.remove($scope.site).then(function (response) {
                $scope.errorHandler.setSuccess("Site removed, redirecting");
              
                $timeout(function () {
                    window.location = "/organization/sites"
                }, 500);
            }, function (response) {
                $scope.errorHandler.process(response.data)   
            });
        }
        
        function loadSite (id, populate) {
            $scope.site = Site.Resource.get({id: id, "select[]": populate || ["controlState", "assessment.current"]});
        }
    }
})();
