(function () {
    angular.module("app").controller("dashboardController", dashboardController);

    function dashboardController ($scope, Session, Organization) {

        $scope.isReady = false;

        Session.currentUser.$promise.then(function (user) {
            $scope.user = user;
            $scope.organization = user.organization;

            $scope.isReady = true;
        });

    }
})();
