(function () {
    "use strict";

    angular.module("app").component("cardHeader", {
        transclude: true,

        bindings: {
            description: "@",
        },

        template: `
            <div class="header" ng-transclude></div>
        `,
    })
})();
