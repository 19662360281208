(function () {
    angular.module("app").factory("User", UserProvider);

    function UserProvider ($q, $http, $resource, Session, Error) {

        var UserResource = $resource("/api/user");

        var PolicyAcknowledgementResource = $resource(
            "/api/user/ackPolicy",
            {},
            {
                acknowledge: {
                    method:'POST',
                    url: '/api/user/ackPolicy',
                    isArray: false
                }
            }
        );

        function checkRoles(roles) {
            var deferred = $q.defer();

            Session.current.$promise.then((user) => {
                var role = roles.find(function(role) {
                   return user.roles.find(function(userRole) {
                       return role == userRole.slug;
                   })
                });

                if (role) {
                    deferred.resolve(true);
                } else {
                    deferred.resolve(false);
                }
            });

            return deferred.promise;
        }

        function User() {
            var self = this;

            self.policyAcknowledgementResource = PolicyAcknowledgementResource;

            self.errorHandler = Error.get("user");
            self.resource = UserResource;
            
            self.checkRoles = checkRoles;
        }

        return new User();
    }
})();
