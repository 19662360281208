(function () {
    "use strict";
//left: training/awareness, right: other
    angular.module("app").component("dashboardMetric", {
        bindings: {
            metric: "="
        },

        template: `
            <div ng-if="$ctrl.metric.type == 'value-chip'" class="padding-4-y">
                <value-chip title="$ctrl.metric.title" value="$ctrl.metric.value"></value-chip>
            </div>

            <div ng-if="$ctrl.metric.type == 'risk-chip'" class="padding-4-y">
                <risk-chip title="$ctrl.metric.title" value="$ctrl.metric.value"></risk-chip>
            </div>

            <div ng-if="$ctrl.metric.type == 'percent-chip'" class="padding-4-y">
                <percent-chip title="$ctrl.metric.title" value="$ctrl.metric.value"></percent-chip>
            </div>

            <div ng-if="$ctrl.metric.type == 'progress'" class="padding-4-y" layout="row">
                <span flex="30">{{$ctrl.metric.title}}</span>

                <div class="progress" flex="60">
                    <div class="progress-bar progress-bar-success progress-bar-striped" aria-valuenow="{{$ctrl.metric.value}}" aria-valuemin="0" aria-valuemax="{{$ctrl.metric.value}}" style="width:{{$ctrl.metric.value}}%">
                        {{$ctrl.metric.value}}%
                    </div>
                </div>
            </div>
        `,

        controller: function (Session, Assessment, Remediation) {
            var ctrl = this;

			ctrl.$onInit = function () {
				ctrl.metric.title = _.truncate(ctrl.metric.title, {length: 20});
			}
        }
    });
})();
