  (function () {
      angular.module("app").controller("AdminDocsController", AdminDocsController);

      function AdminDocsController($scope, $http, $mdDialog, $mdToast, $mdSidenav, $element, Document, UserRole, Session) {

          $scope.document = {
              isNew: true
          };
          $scope.documents = [];
          $scope.policyRoles = [];
          $scope.isReady = false;
          $scope.isAdmin = false;
          $scope.canEdit = false;
          $scope.parentSearch = {
              title: ''
          };
          $scope.filter = {
              searchText: "",
              role: "",
              type: "",
              showDefaults: true,
              showDisabled: true
          };

          $scope.types = Document.types;

          this.$onInit = function () {

              Session.currentUser.$promise.then(function (user) {
                  $scope.isAdmin = user.admin;

                  var types = $scope.types.map(function (type) {
                      return type.type;
                  });

                  Document.orgDocuments().query({
                      returnAll: true,
                      type: types
                  }).$promise.then(function (documents) {
                      $scope.documents = documents;
                      $scope.isReady = true;
                  });
              });


              UserRole.policyRoleResource.query().$promise.then(function (pr) {
                  $scope.policyRoles = pr;

                  //                Document.getCategories().query().$promise.then(function (categories) {
                  //                    $scope.categoryList = categories;
                  //                });

              });

          };

          $scope.editDocument = function (document) {

              $scope.document = document;

              if ($scope.document.organization) {
                  $scope.canDelete = true;
              } else {
                  $scope.document.default = true;

                  if ($scope.isAdmin) {
                      $scope.canDelete = true;
                  } else {
                      $scope.canDelete = false;
                  }
              }

              $mdSidenav("editSideNav").toggle().then(function () {});
          };

          $scope.newDocument = function () {

              $scope.document = {
                  isNew: true,
                  enabled: false,
                  requireAcknowledgement: false,
                  roles: [{}]
              };
              $scope.canEdit = false;

              $scope.editForm.$setUntouched();
              $scope.editForm.$setPristine();

              $mdSidenav("editSideNav").toggle().then(function () {});
          };

          $scope.cancelEdit = function () {
              $mdSidenav("editSideNav")
                  .toggle()
                  .then(function () {});
          };

          $scope.update = function () {

              if (!$scope.document.roles) {
                  $scope.document.roles = [];
              };

              Document.resource.update($scope.document).$promise.then(function (result) {

                  var idx = $scope.documents.findIndex(function (document) {
                      if (result.replacesDocument) {
                          return result._id == document._id || result.replacesDocument._id == document._id;
                      } else {
                          return result._id == document._id;
                      }
                  });

                  if (idx > -1) {
                      $scope.documents[idx] = result;
                  } else {
                      $scope.documents.push(result);
                  }

                  showToast("Document saved.");
              });

              $mdSidenav("editSideNav")
                  .toggle()
                  .then(function () {});
          };

          $scope.deleteDocument = function (document) {

              var confirm = $mdDialog.confirm()
                  .title('Confirm removal of this customized document?')
                  .textContent(document.title)
                  .ariaLabel('Remove document')
                  .ok('Yes, remove document')
                  .cancel('No, cancel');

              $mdDialog.show(confirm).then(function () {
                  removeItem(document);
              }, function () {});

          };

          removeItem = function (document) {

              Document.resource.delete({
                  id: document._id
              }).$promise.then(function (result) {

                  var types = $scope.types.map(function (type) {
                      return type.type;
                  });

                  Document.orgDocuments().query({
                      returnAll: true,
                      type: types
                  }).$promise.then(function (documents) {
                      $scope.documents = documents;

                      $mdSidenav("editSideNav")
                          .toggle()
                          .then(function () {});

                      showToast("Document removed: " + document.title);
                  });

              }, function (err, result) {

              });

          };

          $scope.clearFilter = function () {
              $scope.filter.searchText = "";
              $scope.filter.role = "";
              $scope.filter.type = "";
              $scope.filter.showDefaults = true;
              $scope.filter.showDisabled = true;
          };

          $scope.typeFilter = function (document) {
              if ($scope.filter.type == "") {
                  return true;
              }

              return $scope.filter.type == document.type;
          }

          $scope.roleFilter = function (document) {

              if ($scope.filter.role == "") {
                  return true;
              }

              var found = false;
              document.roles.forEach(function (dRole) {
                  if (dRole._id == $scope.filter.role._id) {
                      found = true;
                  }
              });

              if (found) {
                  return true;
              } else {
                  return false;
              }
          };

          $scope.filterEnabled = function (document) {
              return $scope.filter.showDisabled || document.enabled
          };

          $scope.filterDefaults = function (document) {
              return $scope.filter.showDefaults || document.organization
          };

          showToast = function (msg) {
              $mdToast.show(
                  $mdToast.simple()
                  .textContent(msg)
                  .hideDelay(3000)
              );
          };

          $scope.closeDialog = function () {
              $mdDialog.hide();
          };

          $scope.cancelDialog = function () {
              $mdDialog.cancel();
          };

          $scope.clearParentSearch = function () {
              $scope.parentSearch = {
                  title: ''
              };
          };

          $element.find('input').on('keydown', function (ev) {
              ev.stopPropagation();
          });

      }
  })();