(function () {
    angular.module("app").controller("AwarenessReportController", function ($scope, $http, $filter, $mdDialog) {
        $scope.data = {
            filter: {
                awareness: null,
                date: undefined,
                assignedTo: null,
                showViewed: true,
                showGaps: false,
                user: {
                    status: {
                        active: true,
                        archived: false,
                        disabled: false,
                        pending: false
                    }
                },   
             }
        }

        $scope.showMetrics = true;

        let users = [];
        let filteredUsers = {};

        $http.get("/api/reporting/awareness").then(res => {
            $scope.data.report = res.data;
            $scope.data.loaded = true;

            $http.get("/api/users?activity=awareness").then(res => {
                users = res.data.map(user => {
                    return {
                        _id: user._id,
                        profile: user.profile,
                        status: user.status.state,
                        disabled: user.disabled,
                        activity: user.activity
                    }
                });

                generateReport();
            }).catch(console.error);
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);

        isOverdue = (entry) => {
            let date = null;

            if ($scope.data.filter.date) {
                date = moment().subtract($scope.data.filter.date, "months");
            }

            if (date) {
                if (entry.lastViewed) {
                    return date.isAfter(entry.lastViewed);
                } else {
                    return true;
                }
            } else {
                return !entry.lastViewed;
            }
        }

        $scope.isOverdue = isOverdue;

        function generateReport() {
            const entries = [];
            const options = $scope.data.filter;

            if (options.showViewed) {
                entries.push(...getViewedEntries());
            }

            if (options.showGaps) {
                console.log(getGapEntries())
                entries.push(...getGapEntries());
            }

            $scope.data.report.data.filtered = entries.filter(entry => {
                if (options.assignedTo) {
                    if (options.assignedTo._id != entry.user._id) {
                        return false;
                    }
                }

                if (!options.user.status.active) {
                    if (entry.user.status == "active") {
                        return false;
                    }
                }
        
                if (!options.user.status.archived) {
                    if (entry.user.status == "archived") {
                        return false;
                    }
                }
                
                if (!options.user.status.pending) {
                    if (entry.user.status == "pending") {
                        return false;
                    }
                }
                
                if (!options.user.status.disabled) {
                    if (entry.user.status == "disabled") {
                        return false;
                    }
                }

                return true;
            });

            filteredUsers = {};

            $scope.data.report.data.filtered.forEach(entry => {
                filteredUsers[entry.user._id] = entry.user;
            });

            $scope.data.report.metrics = generateMetrics();
        }

        function generateMetrics() {
            const metrics = {};

            const viewed = getViewedEntries();

            metrics.totalUsers = Object.keys(filteredUsers).length;
            metrics.someViewedUsers = users.length - getGapEntries().length;
            metrics.penetration = _.round(100 * (metrics.someViewedUsers / metrics.totalUsers), 1)

            if (isNaN(metrics.penetration)) {
                metrics.penetration = 0;
            }

            metrics.viewedAwarenesses = viewed.filter(entry => {
                return true || !!filteredUsers[entry.user._id];
            }).length;

            return metrics;
        }

        function getViewedEntries() {
            const options = $scope.data.filter;

            return $scope.data.report.data.entries.filter(entry => {
                if (options.awareness) {
                    if (options.awareness._id != entry.awareness._id) {
                        return false;
                    }
                }

                return true;
            }).filter(entry => {
                return !isOverdue(entry);
            });
        }

        function getGapEntries() {
            const options = $scope.data.filter;
            let date = null;

            if (options.date) {
                date = moment().subtract(options.date, "months");
            }

            return users.map(user => {
                const sorted = _.sortBy(user.activity.awareness.filter(a => a.lastViewed), a => a.lastViewed);

                const entry = sorted.find(entry => {
                    if (date) {
                        if (moment(entry.lastViewed).isBefore(date)) {
                            return false;
                        }
                    }

                    if (options.awareness) {
                        if (entry.awareness != options.awareness._id) {
                            return false;
                        }
                    }

                    return !!entry.lastViewed;
                });

                return {
                    user,
                    lastViewed: entry ? entry.lastViewed : null
                }
            }).filter(entry => {
                return isOverdue(entry);
            });
        }

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }

        $scope.showDetail = (item) => {
            $mdDialog.show({
                templateUrl: `/assets/html/reporting/awareness/detail-modal.html`,

                locals: {
                    item,
                },

                controller: ($scope, $mdDialog, item) => {
                    $scope.$mdDialog = $mdDialog;
                    $scope.item = item;
                }
            }).then(() => {

            });
        }
    });
})();