(function () {
    angular.module("app").component("riskConfiguration", {
        bindings: {
			organization: "="
        },

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Risk Analysis</h3>

					<span class="md-subhead md-block">
						<content content-id="config.risk-analysis.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>
			
			<md-card-content>
				<datatype-configuration organization="$ctrl.organization"></datatype-configuration>
			</md-card-content>
        `,

        controller: function () {
            var ctrl = this;
        },
    });
})();
