/**
 * @ngdoc component
 * @name app.uiKit.filter:postalCode
 * @author Nick Vorie
 *
 * @description This filter will format a zipcode
 */
(function () {
    angular.module("app").filter("postalCode", function () {
        return function (input) {
            if (!input) return input;

            var code = input.toString();

            if (code.length == 9) {
                return code.slice(0, 5) + "-" + code.slice(5);
            }

            return code;
        };
    });
})();
