(function () {
	"use strict";

	angular.module("app").component("containerTaskCard", {
		bindings: {
			container: "=",
			containerType: "@",
		},

		template: `
            <md-card ng-if="$ctrl.container.assessment.current || $ctrl.container.assessment.scheduled" flex="100">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Assessment</span>
                    </md-card-title-text>
                </md-card-title>

				<md-divider></md-divider>

				<md-tabs md-dynamic-height>
					<md-tab label="Current">
                		<md-card-content>
							<span ng-if="!$ctrl.container.assessment.current">No current assessment</span>

							<div ng-if="$ctrl.container.assessment.current" layout="row">
								<img class="avatar circle margin-2-r" ng-src="{{$ctrl.container.assessment.current.assignedTo.profile.avatar.url}}">

								<div layout="column">
									<span>Assigned To: {{$ctrl.container.assessment.current.assignedTo.profile.name}}</span>

									<div layout="row">
										<span class="padding-3-r">Questions: {{$ctrl.container.assessment.current.controlStates.length}}</span>
									</div>

									<div layout="row" class="margin-4-y">
										<span class="padding-3-r">Due: </span>
										<chip type="xs {{$ctrl.isOverdue($ctrl.container.assessment.current.date.due) ? 'danger' : ''}}">{{$ctrl.container.assessment.current.date.due | date:"MMM d, yyyy"}}</chip>
									</div>
								</div>
							</div>
						</md-card-content>

						<md-divider></md-divider>

						<md-card-actions ng-if="$ctrl.container.assessment.current" layout="row" layout-align="end center">
                    		<md-card-icon-actions layout="row" layout-align="end center">
								<md-button class="md-icon-button" ng-href="/{{$ctrl.containerType}}/{{$ctrl.container._id}}/assessment">
									<md-icon class="fa" md-font-icon="fa-clipboard"></md-icon>
									<md-tooltip>View Assessment</md-tooltip>
								</md-button>

								<md-button ng-href="/api/{{$ctrl.containerType}}/{{$ctrl.container._id}}/assessment/export" class="md-icon-button">
									<md-icon class="fa" md-font-icon="fa-download"></md-icon>
									<md-tooltip>Export Assessment</md-tooltip>
								</md-button>								
								
								<md-button ng-if="false" class="md-icon-button">
									<md-icon class="fa" md-font-icon="fa-pencil"></md-icon>
									<md-tooltip>Edit Assessment</md-tooltip>
								</md-button>

								<md-button ng-if="!$ctrl.isAuditor" class="md-icon-button" ng-click="$ctrl.Assessment.close($event, $ctrl.container, $ctrl.containerType, 'current')">
									<md-icon class="fa" md-font-icon="fa-trash-o"></md-icon>
									<md-tooltip>Cancel Assessment</md-tooltip>
								</md-button>
							</md-card-icon-actions>
						</md-card-actions>
					</md-tab>

					<md-tab label="Scheduled">
                		<md-card-content>
							<span ng-if="!$ctrl.container.assessment.scheduled">No scheduled assessment</span>

							<div ng-if="$ctrl.container.assessment.scheduled" layout="row">
								<img class="avatar circle margin-2-r" ng-src="{{$ctrl.container.assessment.scheduled.assignedTo.profile.avatar.url}}">

								<div layout="column">
									<span>Assigned To: {{$ctrl.container.assessment.scheduled.assignedTo.profile.name}}</span>

									<div layout="row" class="margin-4-y">
										<span class="padding-3-r">Start: </span>
										<chip type="xs">{{$ctrl.container.assessment.scheduled.date.start | date:"MMM d, yyyy"}}</chip>
									</div>

									<div ng-if="$ctrl.container.assessment.scheduled.repeat == 'recurring'" layout="row">
										<span class="padding-3-r">Repeats: {{$ctrl.container.assessment.scheduled.date.repeat}}</span>
									</div>
								</div>
							</div>
						</md-card-content>

						<md-divider></md-divider>

						<md-card-actions ng-if="$ctrl.container.assessment.scheduled" layout="row" layout-align="end center">
                    		<md-card-icon-actions layout="row" layout-align="end center">
								<md-button ng-if="false" class="md-icon-button">
									<md-icon class="fa" md-font-icon="fa-pencil"></md-icon>
									<md-tooltip>Edit Assessment</md-tooltip>
								</md-button>

								<md-button ng-if="!$ctrl.isAuditor" class="md-icon-button" ng-click="$ctrl.Assessment.close($event, $ctrl.container, $ctrl.containerType, 'scheduled')">
									<md-icon class="fa" md-font-icon="fa-trash-o"></md-icon>
									<md-tooltip>Cancel Assessment</md-tooltip>
								</md-button>
							</md-card-icon-actions>
						</md-card-actions>
					</md-tab>
				</md-tabs>
			</md-card>

            <md-card ng-if="!$ctrl.isAuditor && $ctrl.container.remediation.current" flex="100" layout="column">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span>Remediation</span>
                    </md-card-title-text>
                </md-card-title>

				<md-divider></md-divider>

				<md-card-content>
					<div layout="row">
						<img class="avatar circle margin-2-r" ng-src="{{$ctrl.container.remediation.current.assignedTo.profile.avatar.url}}">

						<div layout="column">
							<span>Assigned To: {{$ctrl.container.remediation.current.assignedTo.profile.name}}</span>

							<div layout="row">
								<span class="padding-3-r">Steps: {{$ctrl.container.remediation.current.steps.length}}</span>
							</div>

							<div layout="row" class="margin-4-y">
								<span class="padding-3-r">Due: </span>
								<chip type="xs {{$ctrl.isOverdue($ctrl.container.remediation.current.date.due) ? 'danger' : ''}}">{{$ctrl.container.remediation.current.date.due | date:"MMM d, yyyy"}}</chip>
							</div>
						</div>
					</div>
				</md-card-content>

				<md-divider></md-divider>

				<md-card-actions ng-if="$ctrl.container.remediation.current" layout="row" layout-align="end center">
					<md-card-icon-actions layout="row" layout-align="end center">
						<md-button class="md-icon-button" ng-href="/{{$ctrl.containerType}}/{{$ctrl.container._id}}/remediation">
							<md-icon class="fa" md-font-icon="fa-clipboard"></md-icon>
							<md-tooltip>View Remediation</md-tooltip>
						</md-button>

						<md-button class="md-icon-button" ng-click="$ctrl.Remediation.close($event, $ctrl.container, $ctrl.containerType)">
							<md-icon class="fa" md-font-icon="fa-trash-o"></md-icon>
							<md-tooltip>Cancel Remediation</md-tooltip>
						</md-button>
					</md-card-icon-actions>
				</md-card-actions>
			</md-card>
        `,

		controller: function (Assessment, Remediation, User) {
			var ctrl = this;

			ctrl.$onInit = () => {
				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});
			}

			//TODO fix hack
			if (ctrl.containerType == "site") {
				ctrl.containerType = "sites";
			}

			ctrl.isOverdue = function (date) {
				return moment().isAfter(date);
			}

			ctrl.Assessment = Assessment;
			ctrl.Remediation = Remediation;
		}
	});
})();
