(function () {
	angular.module("app").component("policyConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Policy</h3>

					<span class="md-subhead md-block">
						<content content-id="config.policy.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content layout="row">
				<div flex>
					<h5>In addition to your office locations, where else do workforce members work?</h5>
					
					<md-list>
						<md-list-item ng-repeat="location in $ctrl.workAtList" ng-click="$ctrl.togglePolicySelection('conductBusinessAt', location)">
							<md-checkbox class="margin-0-y" ng-click="$ctrl.togglePolicySelection('conductBusinessAt', location)" ng-checked="$ctrl.isChecked('conductBusinessAt', location)"></md-checkbox>
							<p>{{location}}</p>
						</md-list-item>
					</md-list>
				</div>

				<div flex>
					<h5>Who should we include as workforce members?</h5>

					<md-list>
						<md-list-item ng-repeat="worker in $ctrl.includeWorkerList" ng-click="$ctrl.togglePolicySelection('includeAsWorkers', worker)">
							<md-checkbox class="margin-0-y" ng-click="$ctrl.togglePolicySelection('includeAsWorkers', worker)" ng-checked="$ctrl.isChecked('includeAsWorkers', worker)"></md-checkbox>
							<p>{{worker}}</p>
						</md-list-item>
					</md-list>
				</div>

				<div flex>
					<h5>Mark any third parties you want to list specifically in your policy scope.</h5>

					<md-list>
						<md-list-item ng-repeat="ba in $ctrl.includeBAList" ng-click="$ctrl.togglePolicySelection('includeAsBAs', ba)">
							<md-checkbox class="margin-0-y" ng-click="$ctrl.togglePolicySelection('includeAsBAs', ba)" ng-checked="$ctrl.isChecked('includeAsBAs', ba)"></md-checkbox>
							<p>{{ba}}</p>
						</md-list-item>
					</md-list>
				</div>
			</md-card-content>
        `,

		controller: function ($timeout, $scope) {
			var ctrl = this;

			ctrl.workAtList = ["workforce members' residences", "coffee shops and remote locations", "hotels or conferences", "vehicles", "third party facilities"];
			ctrl.includeWorkerList = ["full-time employees", "part-time employees", "temporary workers", "contractors", "consultants", "volunteers", "trainees", "student interns", "auditors"];
			ctrl.includeBAList = ["contractors", "consultants", "auditors", "researchers", "interns"];

			ctrl.isChecked = function (list, id) {
				return ctrl.organization.policy[list].includes(id);
			}

			ctrl.togglePolicySelection = function (key, value) {
				var idx = ctrl.organization.policy[key].indexOf(value);

				if (idx > -1) {
					ctrl.organization.policy[key].splice(idx, 1);
				} else {
					ctrl.organization.policy[key].push(value);
				}

				ctrl.organization.policy[key] = _.clone(ctrl.organization.policy[key]);
			}
		},
	});
})();