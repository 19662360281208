(function () {
    angular.module("app").controller("AcknowledgePolicyController", AcknowledgePolicyController);

    function AcknowledgePolicyController ($scope, $location, $mdToast, Policy) {

        $scope.policies = [];
        $scope.policyRoles = [];
        $scope.policiesAcknowledged = true;
        $scope.isReady = false;

        var urlPieces = $location.absUrl().split("/");

        if (urlPieces.length == 7) {
            var orgId = urlPieces[5];
            var userId = urlPieces[6];
        } else {
            var userId = urlPieces[urlPieces.length - 1];
        }

        $scope.data = {
            showAcknowledged: false
        };

        Policy.acknowledgeResource.acknowledge({
            userId: userId,
            orgId: orgId
        }).$promise.then( function (result) {

            result.policies.forEach( function(policy) {

                // build unique list of roles from policies
                policy.roles.forEach(function(pRole) {
                    if (!$scope.policyRoles.find(function(p){
                        return pRole._id == p._id;
                    })) {
                        pRole.show = true;
                        pRole.display = true;
                        $scope.policyRoles.push(pRole);
                    };
                });

            });

            $scope.policies = result.policies;
            $scope.isReady = true;
        }, function(err) {
            console.log("ERROR", err);
            $scope.isReady = true;
        });

//        Policy.acknowledge(orgId, userId).save().$promise.then( function (result) {
//
//            result.policies.forEach( function(policy) {
//
//                // build unique list of roles from policies
//                policy.roles.forEach(function(pRole) {
//                    if (!$scope.policyRoles.find(function(p){
//                        return pRole._id == p._id;
//                    })) {
//                        pRole.show = true;
//                        pRole.display = true;
//                        $scope.policyRoles.push(pRole);
//                    };
//                });
//
//            });
//
//            $scope.policies = result.policies;
//            $scope.isReady = true;
//
//        }, function(error) {
//            console.log("ERROR", error);
//            $scope.isReady = true;
//        });


    }
})();
