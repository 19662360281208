(function () {
    angular.module("app").controller("EditIncidentController", EditIncidentController);

    function EditIncidentController ($scope, $mdDialog, $mdToast, $http, Incident, Session, incident, isNew) {

        var ctrl = this;

        $scope.isReady = true;
        $scope.dataTypes = {};
        $scope.scenarios = [
            {name:"Application or system misconfiguration identified"},
            {name:"Application or system vulnerability identified"},
            {name:"Improper disposal of electronic media"},
            {name:"Improper disposal of printed information"},
            {name:"Information disclosure, e-mail"},
            {name:"Information disclosure, fax"},
            {name:"Information disclosure, file transfer"},
            {name:"Information disclosure, paper/printed"},
            {name:"Information disclosure, phone/verbal"},
            {name:"Information disclosure, text/SMS"},
            {name:"Information disclosure, viewed electronic display"},
            {name:"Information disclosure, web"},
            {name:"Lost or stolen ID badge/key card"},
            {name:"Lost or stolen key"},
            {name:"Lost or stolen laptop or computer"},
            {name:"Lost or stolen media (such as CD, DVD, or tape)"},
            {name:"Lost or stolen printed document"},
            {name:"Lost or stolen storage device"},
            {name:"Natural disaster or weather event"},
            {name:"Network outage/downtime"},
            {name:"Password forgotten"},
            {name:"Password stolen or used by another"},
            {name:"Phone system outage/downtime"},
            {name:"Power outage"},
            {name:"Security or access badge system failure"},
            {name:"System error/failure"},
            {name:"System outage/downtime"},
            {name:"Unauthorized access to facility"},
            {name:"Unauthorized access to network"},
            {name:"Unauthorized access to system"},
            {name:"Unauthorized access to user account"},
            {name:"User account disabled"},
            {name:"Violation of information security policy"},
            {name:"Violation of privacy policy"},
            {name:"Unknown"},
            {name:"Other"}
        ];
        $scope.phiTypes = [
            {
                name:"Clinical",
                types: [{
                    name:"Diagnosis/Conditions"
                },{
                    name:"Lab Results"
                },{
                    name:"Medications"
                },{
                    name:"Other Treatment Information"
                }]
            },{
                name:"Demographic",
                types: [{
                    name:"Address/ZIP"
                },{
                    name:"Date of Birth"
                },{
                    name:"Driver’s License"
                },{
                    name:"Name"
                },{
                    name:"SSN"
                },{
                    name:"Other Identifier"
                }]
            },{
                name:"Financial",
                types: [{
                    name:"Claims Information"
                },{
                    name:"Credit Card/Bank Acct #"
                },{
                    name:"Other Financial Information"
                }]
            },{
                name:"Other"
            }
        ];
        $scope.unauthedParties = [
            'Business associate',
            'Malicious individual',
            'Physician office',
            'Member',
            'Workforce member',
            'Unknown'
        ];
        $scope.breachTypes = [
            "Hacking/IT Incident",
            "Improper Disposal",
            "Loss",
            "Theft",
            "Unauthorized Access/Disclosure"
        ];
        $scope.locationTypes = [
            {name:"Desktop Computer"},
            {name:"Electronic Medical Record"},
            {name:"Email"},
            {name:"Laptop"},
            {name:"Network Server"},
            {name:"Other Portable Electronic Device"},
            {name:"Paper/Films"},
            {name:"Other"}
        ];
        $scope.actionsTaken = [
            {name:"Adopted encryption technologies"},
            {name:"Changed password/strengthened password requirements"},
            {name:"Created a new/updated Security Rule Risk Management Plan"},
            {name:"Implemented new technical safeguards"},
            {name:"Implemented periodic technical and nontechnical evaluations"},
            {name:"Improved physical security"},
            {name:"Performed a new/updated Security Rule Risk Analysis"},
            {name:"Provided business associate with additional training on HIPAA requirements"},
            {name:"Provided individuals with free credit monitoring"},
            {name:"Revised business associate contracts"},
            {name:"Revised policies and procedures"},
            {name:"Sanctioned workforce members involved (including termination)"},
            {name:"Took steps to mitigate harm"},
            {name:"Trained or retrained workforce members"},
            {name:"Other"}
        ];
        $scope.safeguards = [
            {name:"None"},
            {name:"Privacy Rule Safeguards (Training, Policies and Procedures, etc.)"},
            {name:"Security Rule Administrative Safeguards (Risk Analysis, Risk Management, etc.)"},
            {name:"Security Rule Physical Safeguards (Facility Access Controls, Workstation Security, etc.)"},
            {name:"Security Rule Technical Safeguards (Access Controls, Transmission Security, etc.)"}
        ];


        ctrl.$onInit = function () {
            $scope.isNew = ctrl.isNew;
            $scope.isEdit = ctrl.isNew;
            $scope.incident = ctrl.incident;

            // get the types of data enabled for this organization
            $http.get("/api/data-type/forOrganization").then(function(result) {
                $scope.dataTypes = result.data;

                initCheckbox($scope.incident.scope.dataTypes, $scope.dataTypes);
            });

            // get current user to populate Reported By info
            Session.currentUser.$promise.then(function (user) {
                if (isNew) {
                    $scope.incident.reportedBy = user;
                    $scope.incident.organization = user.organization;
                }
            });

            if (!$scope.incident.comments) {
                $scope.incident.comments = [];
            }

            initCheckbox($scope.incident.scope.locations, $scope.locationTypes);
            initCheckbox($scope.incident.scope.safeguards, $scope.safeguards);
            initCheckbox($scope.incident.response.actionsTaken, $scope.actionsTaken);
            initCheckbox($scope.incident.phi.phiTypes, $scope.phiTypes);

        };

        function initCheckbox(checkThese, againstThese) {
            checkThese.forEach(function(check) {
               againstThese.forEach(function(type) {
                   if (check == type.name || (check._id && check._id == type._id) || (check.name && type.name && check.name == type.name)) {
                       type.enabled = true;
                        if (check.types && type.types) {
                            initCheckbox(check.types, type.types);
                        }
                   }
               })
            });
        }

        $scope.update = function() {
            if (!$scope.isEdit) return;

            Incident.resource.update($scope.incident).$promise.then( function (result) {

                $mdDialog.hide(result);
                showToast("Incident saved.");
           });

        };

        $scope.updateComment = function() {

            Incident.resource.update($scope.incident).$promise.then( function (result) {

                showToast("Comments updated.");
           });

        };

        $scope.edit = function() {
            $scope.isEdit = true;
        }

        $scope.toggleDataType = function(dt) {
            $scope.incident.phi.isPhi = false;
            $scope.incident.scope.dataTypes = $scope.dataTypes.filter(function(dt) {
                if(dt.enabled && dt._id == "protected-health-information") {
                    $scope.incident.phi.isPhi = true;
                }
                return dt.enabled;
            });
        };

        $scope.toggleLocation = function(location) {
            var locations = $scope.locationTypes.filter(function(location) {
                return location.enabled;
            });
            $scope.incident.scope.locations = locations.map(function(location) {
                return location.name;
            })
        };

        $scope.toggleSafeguard = function(safeguard) {
            var safeguards = $scope.safeguards.filter(function(safeguard) {
                return safeguard.enabled;
            });
            $scope.incident.scope.safeguards = safeguards.map(function(safeguard) {
                return safeguard.name;
            })
        };

        $scope.toggleAction = function(action) {
            var actions = $scope.actionsTaken.filter(function(action) {
                return action.enabled;
            });
            $scope.incident.response.actionsTaken = actions.map(function(action) {
                return action.name;
            })
        };

        $scope.togglePhiType = function(type, subType) {
            var phiTypes = [];
            $scope.phiTypes.forEach(function(type) {
                if (type.enabled) {
                    var phiType = {name:type.name, types:[]};
                    if (type.types){
                        type.types.forEach(function(subType) {
                           if(subType.enabled) {
                               phiType.types.push({name:subType.name});
                           }
                        });
                    };
                    phiTypes.push(phiType);
                }
            })

            $scope.incident.phi.phiTypes = phiTypes;


//            var locations = $scope.locationTypes.filter(function(location) {
//                return location.checked;
//            });
//            $scope.incident.locations = locations.map(function(location) {
//                return location.name;
//            })
        };

        $scope.cancelDialog = function () {
            if ($scope.isNew) {
                $mdDialog.cancel();
            } else {
                if ($scope.isEdit) {
                    $scope.isEdit = false;
                } else {
                    $mdDialog.cancel();
                }
            };
        };

        showToast = function (msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        }
    }
})();
