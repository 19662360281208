(function () {
    angular.module("app").factory("Message", Message);

    function Message ($http, $mdToast, $mdDialog, ModalFactory, Session) {
        function Message () {
            var self = this;

            self.instance = instance;

            function instance (options, group) {
                var endpoint = "/api/" + options.endpoint;
                var endpointQuery = "?";

                if (options.endpointQuery) {
                    endpointQuery += options.endpointQuery;
                }

                return {
                    add: add,
                    edit: edit,
                    delete: _delete,
                }

                function add (content) {
                    var comment = {
                        date: Date.now(),
                        content: content,
                        user: Session.currentUser
                    }

                    if (!group) {
                        group = [];
                    }

                    group.push(comment);

                    $http.post(endpoint + endpointQuery, comment).then((res) => {
                        group.pop();
                        group.push(res.data);

                        $mdToast.simple().content("Added").theme("success-toast");
                    }, () => {
                        group.pop();
                        $mdToast.simple().content("Could not add").theme("warn-toast")
                    });

                    return group;
                }

                /*
                 * Display a confirm model to edit an existing comment
                 * */
                function edit ($event, comment) {
                    $mdDialog.show({
                        template: `
                            <md-dialog aria-label="Start Assessment" flex="100" flex-gt-sm="40">
                                <md-toolbar class="md-hue-3">
                                    <div class="md-toolbar-tools">
                                        <h3>Edit Message</h3>
                        
                                        <span flex></span>
                        
                                        <md-button class="md-icon-button" ng-click="cancel()">
                                            <md-icon md-font-icon="fa-times" class="fa"></md-icon>
                                        </md-button>
                                    </div>
                                </md-toolbar>
                        
                                <md-dialog-content class="margin">
                                    <md-input-container class="md-block">
                                        <label>Message</label>
                                        <textarea ng-model="message" md-maxlength="1000" rows="5" md-select-on-focus required></textarea>
                                    </md-input-container>
                                </md-dialog-content>

                                <md-dialog-actions layout="row">
                                    <span flex></span>
                                    <md-button ng-click="$mdDialog.cancel();" class="md-button">Cancel</md-button>
                                    <md-button ng-click="$mdDialog.hide(message);" class="md-button md-primary md-raised">Save</md-button>
                                </md-dialog-actions>
                            </md-dialog>                        
                        `,

                        controller: ($scope, $mdDialog) => {
                            $scope.$mdDialog = $mdDialog;
                            $scope.message = comment.content;
                        },

                        parent: angular.element(document.body),
                        bindToController: true,
                        clickOutsideToClose: true,
                        targetEvent: $event
                    }).then(function (data) {
                        if (data) {
                            comment.content = new String(data).trim();

                            $http.post(endpoint + "/" + comment._id + endpointQuery, comment).then((data) => {
                                $mdToast.show($mdToast.simple().content("Edit saved").theme("success-toast"));
                            }, (data) => {
                                comment.content = originalContent;
                                $mdToast.show($mdToast.simple().content("Could not edit").theme("warn-toast"))
                            });
                        }
                    });

                    return;

                    var originalContent = comment.content;

                    var editDialog = $mdDialog.prompt()
                        .title("Edit Message")
                        .placeholder("Message")
                        .initialValue(comment.content)
                        .targetEvent($event)
                        .required(true)
                        .ok("Save")
                        .cancel("Cancel");


                    $mdDialog.show(editDialog).then(function (result) {
                        comment.content = result;

                        $http.post(endpoint + "/" + comment._id + endpointQuery, comment).then((data) => {
                            $mdToast.show($mdToast.simple().content("Edit saved").theme("success-toast"));
                        }, (data) => {
                            comment.content = originalContent;
                            $mdToast.show($mdToast.simple().content("Could not edit").theme("warn-toast"))
                        });
                    });
                }

                /*
                 * Display a confirm model to delete an existing comment
                 * */
                function _delete ($event, comment) {

                    var confirm = $mdDialog.confirm().title("Please confirm").textContent("This message will be removed").targetEvent($event).ok("Confirm").cancel("Cancel");

                    $mdDialog.show(confirm).then(() => {
                        var index = group.indexOf(comment);
                        group.splice(index, 1);

                        $http.delete(endpoint + "/" + comment._id + endpointQuery, comment).then((data) => {
                            $mdToast.show($mdToast.simple().content("Edit saved").theme("success-toast"));
                        }, (data) => {
                            //re add
                            $mdToast.show($mdToast.simple().content("Could not delete").theme("warn-toast"))
                        });
                    }, () => {
                    });
                }
            }

            return self;
        }

        return new Message();
    };
})();
