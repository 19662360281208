(function () {
    angular.module("app").component("vendorCard", {
        bindings: {
            vendors: "=",
            vendor: "=",
            show: "=?"
        },

        template: `
            <md-card class="vendorCard">
                <md-card-content layout style="min-height:126px" flex>
                    <div flex="1">
                        <img width="80" style="margin-right:10px" ng-src="{{$ctrl.vendor.vendor.organization.settings.template.logo.url || $ctrl.vendor.image.url || 'https://cdn.virtualsecurity.center/assets/img/vendor/default.png'}}">

                        <div style="padding-top:4px">
                             <chip ng-show="$ctrl.vendor.vendor.linkVendor" color="'gray'" font-color="'white'" type="xs">
                                 <span ng-show="!$ctrl.vendor.vendor.inviteAccepted">Pending</span>
                                 <span ng-show="$ctrl.vendor.vendor.inviteAccepted">Connected</span>
                            </chip>
                        </div>
                    </div>

                    <div flex>
                        <h5 class="truncate">{{$ctrl.vendor.name}}</h5>

                        <address name="$ctrl.vendor.vendor.contact.profile.name || $ctrl.vendor.primaryContact.name" phone="$ctrl.vendor.primaryContact.phone" phone-extention="$ctrl.vendor.primaryContact.phoneExtention" email="$ctrl.vendor.vendor.contact.email || $ctrl.vendor.primaryContact.email" address="$ctrl.vendor.address" extra="$ctrl.extraAddressInfo">
                            <div ng-if="false && $ctrl.vendor.baa.inPlace" flex layout="row">
                                <icon flex="10" type="file"></icon>
                                <div>BAA expires {{ $ctrl.vendor.baa.expirationDate | date:MM/dd/yyyy}}</div>
                            </div>
                        </address>

                        <p ng-if="$ctrl.vendor.owner">
                            Owner: {{$ctrl.vendor.owner.profile.name}}
                        </p>
                        <p ng-if="$ctrl.vendor.baa.inPlace">
                            BAA:  {{ $ctrl.vendor.baa.executionDate | date:MM/dd/yyyy}} - {{ $ctrl.vendor.baa.expirationDate | date:MM/dd/yyyy}}
                        </p>

                    </div>

                    <div ng-if="$ctrl.show.chip != false">
                        <a ng-if="$ctrl.vendor.assessment.current" ng-href="/vendor/{{$ctrl.vendor._id}}/assessment">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Assessment</chip>
                        </a>
                        <a ng-if="$ctrl.vendor.remediation.current" ng-href="/vendor/{{$ctrl.vendor._id}}/remediation">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Remediation</chip>
                        </a>
                    </div>
                </md-card-content>

                <md-divider ng-if-start="$ctrl.show.metrics"></md-divider>

                <control-state-metrics-dash ng-if-end threats="$ctrl.vendor.controlState.current.threatRanges" compliance="$ctrl.vendor.controlState.current.compliance" to-show="3"></control-state-metrics-dash>

                <md-divider></md-divider>

                <md-card-actions ng-if="$ctrl.show.actions != false" flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">
                        <md-button href="" ng-if="!$ctrl.isAuditor && $ctrl.vendor.vendor.linkVendor && !$ctrl.vendor.vendor.organization.settings.setupComplete" ng-click="$ctrl.resendInvitation($ctrl.vendor)" class="md-icon-button" aria-label="Resend invite">
                            <md-tooltip>Resend vendor invitation</md-tooltip>
                            <md-icon md-font-icon="fa-envelope" class="fa"></md-icon>
                        </md-button>

                        <md-button href="" ng-if="!$ctrl.isAuditor && $ctrl.show.assessmentIcon" ng-click="$ctrl.Assessment.start($event, $ctrl.vendor, 'vendor')" class="md-icon-button" aria-label="Assessment">
                            <md-tooltip>Schedule Assessment</md-tooltip>
                            <md-icon md-font-icon="fa-calendar" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="$ctrl.show.viewIcon" ng-href="/vendor/{{$ctrl.vendor._id}}" class="md-icon-button" aria-label="View">
                            <md-tooltip>Vendor Details</md-tooltip>
                            <md-icon md-font-icon="fa-eye" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.editIcon" href="" class="md-icon-button" ng-click="$ctrl.Container.edit($event, $ctrl.vendors, $ctrl.vendor, 'vendor')" aria-label="Edit">
                            <md-tooltip>Edit Vendor</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>


                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && !$ctrl.vendor.archived" ng-click="$ctrl.Container.archive($event, $ctrl.vendors, $ctrl.vendor, 'vendor')">
                            <md-tooltip>Archive</md-tooltip>
                            <md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
                        </md-button>
                                
                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && $ctrl.vendor.archived" ng-click="$ctrl.Container.archive($event, $ctrl.vendors, $ctrl.vendor, 'vendor')">
                             <md-tooltip>Restore</md-tooltip>
                            <md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" href="" class="md-icon-button" ng-click="$ctrl.Container.deleteItem($event, $ctrl.vendors, $ctrl.vendor, 'vendor')" aria-label="Delete">
                            <md-tooltip>Delete Vendor</md-tooltip>
                            <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                        </md-button>
                </md-card-actions>
            </md-card>
        `,

        controller: function (Assessment, Container, $http, $mdDialog, $mdToast, User) {
            var ctrl = this;

            ctrl.Container = Container;
            ctrl.Assessment = Assessment;

            ctrl.$onInit = () => {
                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });
            }

            ctrl.resendInvitation = function (vendor) {
                let email = null;

                if (vendor.vendor.contact) {
                    email = vendor.vendor.contact.email;
                } else if (vendor.primaryContact) {
                    email = vendor.primaryContact.email;
                }

                if (!email) {
                    //return;
                }

                $mdDialog.show($mdDialog.confirm({
                    title: "Please Confirm",
                    textContent: "Resend email invitation to: " + email,
                    ok: "Resend Invite",
                    cancel: "Cancel"
                })).then(function () {
                    $http.post(`/api/vendor/${vendor._id}/resend-invite`).then(function (result) {
                        if (result.data.success) {
                            $mdToast.show($mdToast.simple().textContent("Invitation resent"));
                        } else {
                            $mdToast.show(
                                $mdToast
                                    .simple()
                                    .hideDelay(0)
                                    .action('Close')
                                    .highlightAction(true)
                                    .textContent("Invite NOT sent. " + result.data.message)
                            );
                        };

                    }).catch(function () {
                        $mdToast.show(
                            $mdToast
                                .simple()
                                .hideDelay(0)
                                .action('Close')
                                .highlightAction(true)
                                .textContent("Error resending invitation")
                        );
                    });
                }).catch()
            };
        }
    });
})();