(function () {
    angular.module("app").factory("Content", function ($http) {
		const _cache = {};

		function Content () {
			var self = this;

			self.get = function (id, cb) {
				if (_cache[id]) {
					return cb(null, _cache[id]);
				}

				$http.get("/api/content/" + id).then(response => {
					_cache[id] = response.data;
					cb(null, _cache[id]);
				}).catch(response => {
					cb(response.data);
				});
			}
		}

		return new Content();
	});
})();
