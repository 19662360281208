(function () {
    angular.module("app").component("comments", {
         templateUrl: '/assets/html/ui-kit/comments/comments-component.html',

        bindings: {
            comments: "=",
            onUpdate: "&"
        },

        controller: function (Session) {
            var ctrl = this;

            ctrl.$onInit = function() {
                Session.currentUser.$promise.then(function (user) {
                    ctrl.user = user;
                });
            };

            ctrl.update = function() {
                ctrl.onUpdate();
            }

        }
    });
})();
