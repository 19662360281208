(function () {
    angular.module("app").controller("SetupController", SetupController);

    function SetupController ($scope, Session, UserRole, Organization, Technology) {

        $scope.index = 0;
        $scope.indexMax = 10;
        $scope.indexPercent = 0;
        $scope.ready = false;


        this.$onInit = function() {
            $scope.indexPercent = $scope.index / $scope.indexMax * 100;

            Session.currentUser.$promise.then(function (user) {
                $scope.user = user;

                UserRole.policyRoleResource.query().$promise.then(function (roles) {
                    $scope.roles = roles;
                    $scope.ready = true;
                });
            });

            Organization.current.$promise.then(function (org) {
                $scope.organization = org;
            });

            Technology.Resource.query({}, {name:1,type:1}).$promise.then(function (techs) {
                $scope.technologies = techs;
            });
        }

        $scope.next = function() {
            if ($scope.index >= $scope.indexMax) {
                $scope.index = 0;
                $scope.indexPercent = 0;
            } else {
                $scope.index = $scope.index + 1;
                $scope.indexPercent = $scope.index / $scope.indexMax * 100;
            }
        }

        $scope.back = function() {
            if ($scope.index <= 0) {
                $scope.index = 0;
            } else {



//                var elements = document.getElementsByClassName("slide");
//                for (i = 0; i < elements.length; i++) {
//                    console.log("style", elements[i].style);
//                }

                $scope.index = $scope.index - 1;
                $scope.indexPercent = $scope.index / $scope.indexMax * 100;
            }
        }
    }
})();
