(function () {
    angular.module("app").controller("RiskReportController", function ($scope, $http, $filter, $mdDialog, Cvss) {
        $scope.data = {
            toggle: {},

            filter: {
                search: "",

                dataTypes: null,
                threats: null,
                compliance: null,

                risk: {
                    dataTypes: [],
                    threats: [],
                    compliance: [],

                    cvss: {
                        min: 0,
                        max: 10,
                    },
                },

                showDisabled: false,
                showArchived: false,
            },

            containerTypes: [{
                _id: "site",
                name: "Sites"
            }, {
                _id: "technology",
                name: "Technology"
            }, {
                _id: "vendor",
                name: "Vendors"
            }, {
                _id: "organization",
                name: "Organization"
            }],

            threatLevels: [{
                title: "Total",
                color: "white",
                background: "grey",
                value: 30
            }, {
                title: "Critical",
                color: "white",
                background: "red",
                value: 5
            }, {
                title: "High",
                color: "white",
                background: "tomato",
                value: 10
            }, {
                title: "Medium",
                color: "white",
                background: "orange",
                value: 10
            }, {
                title: "Low",
                color: "#333",
                background: "yellow",
                value: 5
            }, ],

            dataTypes: []
        }

        $scope.resetFilter = function () {
            $scope.data.filter = {
                search: "",

                manager: null,
                containerCategory: null,
                containerType: null,

                risk: {
                    dataTypes: null,
                    threats: null,
                    compliance: null,

                    cvss: {
                        min: 0,
                        max: 10,
                    },
                },

                showDisabled: false,
                showArchived: false,
            };
        }

        $scope.showMetrics = true;

        $http.get("/api/data-type").then(result => {
            $scope.data.dataTypes = result.data;
        });

        $http.get("/api/threat").then(result => {
            $scope.data.threats = result.data;
        });

        $http.get("/api/reporting/risk").then(res => {
            $scope.data.report = res.data;
            $scope.data.loaded = true;

            console.log($scope.data.report)

            let users = {}

            $scope.data.report.data.entries.forEach(entry => {
                if (entry.manager) {
                    users[entry.manager._id] = entry.manager;
                } else if (entry.owner) {
                    users[entry.owner._id] = entry.owner;
                }
            });


            $scope.data.filteredUsers = Object.values(users);
            console.log($scope.data.filteredUsers)

            $http.get("/api/users?activity=awareness").then(res => {
                users = res.data;

                generateReport();
            }).catch(console.error);
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);


        function generateReport() {
            const options = $scope.data.filter;

            $scope.data.report.data.entries.forEach(e => {
                e.controlState.scores.base.severity = Cvss.getSeverity(e.controlState.scores.base.value);
                e.controlState.scores.weighted.severity = Cvss.getSeverity(e.controlState.scores.weighted.value);
            });

            const filter = filterRiskReportEntry(options);

            $scope.data.report.data.filtered = $scope.data.report.data.entries.filter(entry => {
                return filter(entry);
            });

            $scope.data.report.metrics = generateMetrics();
        }

        function generateMetrics() {
            const metrics = {};

            $scope.data.report.data.filtered.forEach(e => {
                let values = metrics[e.type.type];

                if (!values) {
                    values = {
                        total: {
                            severity: {
                                name: "Total",

                                css: {
                                    background: "gray",
                                    color: "white"
                                }
                            },

                            value: 0
                        }
                    };

                    Object.values(Cvss.severityScale).reverse().forEach(severity => {
                        values[severity.id] = {
                            severity,
                            value: 0,
                        }
                    })
                }

                const scores = e.controlState.threats.map(t => {
                    return t.weightedScore;
                });

                const severity = Cvss.getSeverity(scores);

                let metric = values[severity.id];

                if (metric) {
                    metric.value++;
                } else {
                    metric = {
                        severity,
                        value: 1,
                    };
                }

                values.total.value++;
                values[severity.id] = metric;

                metrics[e.type.type] = values;
            });

            console.log("METRICS", metrics);

            return metrics;
        }

        $scope.toggle = (context, id) => {
            $scope.data.toggle[context][id] = !$scope.data.toggle[context][id];
        }

        $scope.check = (context, id) => {
            if (!$scope.data.toggle[context]) {
                $scope.data.toggle[context] = {};
            }

            if (!$scope.data.toggle[context][id]) {
                $scope.data.toggle[context[id]] = false;
            }

            return $scope.data.toggle[context][id];
        }

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }
    });
})();

function filterRiskReportEntry(options) {
    const search = options.search.toLowerCase();

    return function (item) {
        console.log(options);

        // Container type
        if (options.containerType) {
            if (options.containerType._id != item.type._id) {
                return false;
            }
        }

        // Manager
        if (options.manager != null) {
            console.log(options.manager, item.manager);

            if (!item.manager) {
                return false;
            }

            if (options.manager._id != item.manager._id) {
                return false;
            }
        }

        // Data Types
        if (options.risk.dataTypes) {
            if (!item.dataTypes.includes(options.risk.dataTypes._id)) {
                return false;
            }
        }

        // Threats
        if (options.risk.threats) {
            if (!item.threats.includes(options.risk.threats._id)) {
                return false;
            }
        }

        // CVSS
        if (_.get(options, "risk.cvss.min") !== undefined) {
            if (item.controlState.scores.weighted.value < _.get(options, "risk.cvss.min")) {
                return false;
            }
        }

        if (_.get(options, "risk.cvss.max") !== undefined) {
            if (item.controlState.scores.weighted.value > _.get(options, "risk.cvss.max")) {
                return false;
            }
        }

        // Search
        if (options.search.length) {
            return (item.name || item.profile.name).toLowerCase().includes(search);
        }

        return true;
    }
}