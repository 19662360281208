/**
 * @ngdoc component
 * @name app.compliance.component:complianceChips
 * @author Nick Vorie
  *
 * @param {array}   specs                 The array of Specifications to render
 * @param {array}   requirements          The array of Requirements to render specs for
 * @param {boolean} issue                 Reflects the style class of the chips (true = danger)
 * @param {string}  layout [*column, row] The layout in which to render the chips
 *
 * @description
 */
(function () {
    "use strict";

    angular.module("app").component("complianceChips", {
        bindings: {
            specs: "=",
            requirements: "=",

            context: "@?",

            issue: "=?",
            layout: "@?",
        },

        template: `
            <div ng-if="$ctrl.context == 'requirements'" layout="{{$ctrl.layout}}">
                <chip ng-class="$ctrl.paddingClass" ng-repeat="req in $ctrl.requirements | limitTo:3" type="xs {{$ctrl.issue ? 'danger' : ''}}">
                    <span>{{req.commonName}}</span>
                </chip>
            </div>

            <div ng-if="$ctrl.context == 'specifications'" layout="{{$ctrl.layout}}">
                <chip ng-if="$ctrl.Compliance.shouldRender(spec, $ctrl.requirements)" ng-class="$ctrl.paddingClass" ng-repeat="spec in $ctrl.specs" type="xs {{$ctrl.issue ? 'danger' : ''}}">
                    <span>{{spec.label}}</span>
                </chip>
            </div>
        `,

        controller: function (Compliance, $element) {
            var ctrl = this;

            ctrl.Compliance = Compliance;

            ctrl.$onInit = () => {
                if (!ctrl.context) ctrl.layout = "specifications";
                if (!ctrl.layout) ctrl.layout = "column";

                if (ctrl.layout == "row") {
                    ctrl.paddingClass = "padding-4-l";
                } else if (ctrl.layout == "column") {
                    ctrl.paddingClass = "padding-4-y";
                }
            }
        }
    });
})();
