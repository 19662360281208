(function () {
    angular.module("app").component("invoices", {
        bindings: {
            subscription: "="
        },

        template: `
            <md-card>
                <md-card-header><md-card-header-text><h4>Transaction History</h4></md-card-header-text></md-card-header>
                <md-card-content style="min-height:110px">
                    <div style="padding:16px" class="md-subheader" layout="row">
                        <span flex="10">Id</span>
                        <span flex="20">Date</span>
                        <span flex="15">Purchase</span>
                        <span flex="20"></span>
                        <span flex="10">Amount</span>
                        <span flex="25">Credit Card</span>
                    </div>
                    <md-list>
                        <md-list-item layout="row" ng-repeat="transaction in $ctrl.transactions()" ng-click="null">
                            <div flex="10">{{transaction.id}}</div>
                            <div flex="20">{{transaction.createdAt | date :  "short"}}</div>
                            <div flex="15">{{transaction.planId == 'vsc-monthly' ? "VSC Monthly" : transaction.planId == 'vsc-annual' ? "VSC Annual" : transaction.planId}}</div>
                            <div flex="20">
                                <div style="line-height: 1.5" ng-repeat="addOn in transaction.addOns">{{addOn.quantity}} {{addOn.name}} @ {{addOn.amount}} per</div>
                            </div>
                            <div flex="10">{{transaction.amount| currency}}</div>
                            <div flex="25">{{transaction.creditCard.maskedNumber}} <img style="width:40px;" src="{{transaction.creditCard.imageUrl}}"/></div>
                        </md-list-item>
                    <md-divider ></md-divider>
                    </md-list>
                </md-card-content>

            </md-card>
        `,

        controller: function () {
            var ctrl = this;

            ctrl.$onInit = function () {

                if (!ctrl.subscription) {
                    ECommerce.resource.getSubscription().$promise.then(function (subscription) {
                        ctrl.subscription = subscription;
                    }, function (err, result) {
                        console.log("cannot get payment info", err, result);
                    });
                }

            }

            ctrl.transactions = function () {
                return ctrl.subscription.transactions.filter(function (transaction) {
                    return transaction.processorResponseText == "Approved";
                });
            }
        },

    });
})();
