(function () {
    "use strict";

    angular.module("app").component("radialProgress", {
        templateUrl: "/assets/html/core/material-strap/progress/radial.html",
        bindings: {
            value: "@?"
        },
        controller: function ($scope, $element) {
            var self = this;

            var progress = $element.find("progress");

            if (self.value >= 90) {
                progress.addClass("green");
            } else if (self.value >= 80) {
                progress.addClass("yellow");
            } else if (self.value >= 70) {
                progress.addClass("orange");
            } else {
                progress.addClass("red");
            }
        }
    });
    
})();
