(function () {
    "use strict";

    angular.module("app").component("dropDownMenu", {
        template: `
            <div class="menu btn-group">
                <div class="trigger" ng-transclude="trigger"></div>

                <div class="dropdown-menu dropdown-menu-{{$ctrl.align}}">
                    <div ng-transclude="items"></div>
                </div>
            </div>
        `,
        replace: true,

        bindings: {
            align: "@?"
        },

        transclude: {
            trigger: "trigger",
            items: "items"
        },
        
        controller: function ($element) {
            var self = this;
            
            var menuElement = $element.find(".menu");
            var triggerElement = $element.find(".trigger");
            var dropDownElement = $element.find(".dropdown-menu");
            
            $(document).click(function (event) {
                menuElement.removeClass("show");
            });

            $(triggerElement).click(function (event) {
                menuElement.toggleClass("show");
                
                event.stopPropagation();
                return false;
            });
        }
    }).component("menuItem", {
        templateUrl: "/assets/html/core/material-strap/menu/item.html",
        require: "^^menu",
        replace: true,
        bindings: {
            text: "@",
            icon: "@",
            onClick: "=",
        },
        
        controller: function ($element) {
            var ctrl = this;
            
            var itemElement = $element.find(".dropdown-item");

        }
    }).component("menuItemTrans", {
        template: '<span ng-transclude>/<span>',
        require: "^^menu",
        replace: true,
        transclude: true,
        
        controller: function ($element) {
            var ctrl = this;
            var itemElement = $element.find(".dropdown-item");
        }
    });
    
})();
