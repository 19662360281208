/**
 * @ngdoc component
 * @name app.data-type.component:dataTypeSelection
 * @author Nick Vorie

 * @description This component will render a series of check boxes used to specify what data is stored on a given Container
 */
(function () {
    angular.module("app").component("datatypeSelection", {
        template: `
			<md-card>
				<md-card-content layout="column">
					<p class="padding-2-b">Data accessed, stored, transmitted or processed</p>

					<md-checkbox ng-repeat="v in $ctrl.dataTypes" ng-model="v.enabled" ng-click="$ctrl.update()">{{v.dataType.name}}</md-checkbox>
				</md-card-content>
			</md-card>
        `,

		bindings: {
			container: "="
        },

        controller: function ($http, $timeout, Organization) {
            var ctrl = this;

			ctrl.$onInit = function () {
				ctrl.dataTypes = [];

				$http.get("/api/data-type").then(result => {
					result.data.forEach(dataType => {
						var value = false;

						if (ctrl.container.dataTypes) {
							value = ctrl.container.dataTypes.some(dataType_ => {
								return dataType._id == (dataType_._id || dataType_);
							});
						}

						if (Organization.current.dataTypes.some(v => v.dataType == dataType._id && v.enabled)) {
							ctrl.dataTypes.push({
								dataType,
								enabled: value
							});
						}
					});
				});
			}

			ctrl.update = function () {
				$timeout(() => {
					ctrl.container.dataTypes = ctrl.dataTypes.filter(dataType => dataType.enabled).map(dataType => {
						return dataType.dataType._id;
					});
				});
			}
        }
    })
})();
