(function () {
    angular.module('app').component('userChangePassword', {
        templateUrl: '/assets/html/user/change-password.html',
        controller: userChangePassword,
        bindings: {
        }
    });
    
    function userChangePassword ($scope, $http, $mdToast) {
        $scope.data = {};
        $scope.errors = [];

        $scope.changePassword = function () {
            if($scope.data.password != $scope.data.repeatPassword) {
                return;
            }

            $http.post("/api/user/change-password", $scope.data).then(function (response) {
                $scope.errors = response.data.error;
                showToast("Password updated.");
            }, function (response) {
                $scope.errors = response.data.error;
            });
        }

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };
    }
})();
