  (function () {
    angular.module("app").controller("ProceduresController", ProceduresController);

    function ProceduresController ($scope, Procedure) {

        $scope.procedures = [];
        $scope.containerTypes = [];
        $scope.isReady = false;
        $scope.filter = {
            searchText: "",
            containerType:"",
            showCompleted:true,
            showScheduled:false
        }

        this.$onInit = function () {

            Procedure.procedures.query({}).$promise.then(function (procedures) {
                $scope.procedures = procedures;
                $scope.isReady = true;

                $scope.containerTypes = _.uniq(procedures.map(p => p.containerType.title));
            });

        };

        $scope.procedureProgress = function(procedure) {

            var complete = 0;
            procedure.steps.forEach(function(step) {
                if (step.complete) {
                    complete = complete + 1;
                };
            });

            return Math.round(complete / procedure.steps.length * 100);
        }

        $scope.onRowClick = function (procedure) {
            window.location = "/procedure/" + procedure._id
        }

        $scope.filterContainerType = function(procedure) {
            if ($scope.filter.containerType == "") {
                return true;
            }

            return $scope.filter.containerType == procedure.containerType.title;
        }

        $scope.filterCompleted = function(procedure) {
            if ($scope.filter.showCompleted == true) {
                return true;
            }

            return !procedure.status.complete;
        }

        $scope.filterScheduled = function(procedure) {
            if ($scope.filter.showScheduled == true) {
                return true;
            }

            return procedure.status.active;
        }

        $scope.clearFilter = function() {
            $scope.filter.searchText = "";
            $scope.filter.containerType = "";
            $scope.filter.showCompleted = true;
            $scope.filter.showScheduled = false;
        }

    }
})();
