(function () {
    angular.module("app").directive("userHistory", userHistory);

    function userHistory() {
        return {
            templateUrl: "/assets/html/user/history.html",
            controller: function ($scope, $http, Error, Session, DeviceIcons) {
                $scope.Session = Session;
                $scope.DeviceIcons = DeviceIcons;

                $scope.parseDate = function (date) {
                    date = moment(date);
                    return date.format("MMMM Do, h:mm a");
                }
            }
        }
    }
})();
