(function () {
    //'use strict';

    angular.module("app").component("taskMenu", {
        bindings: {
            tasks: "=?"
        },

        template: `
            <md-menu md-offset="0 50">
                <md-button class="md-icon-button md-button" type="button" ng-click="$mdMenu.open($event)" >
                    <i class="fa fa-check-square-o fa-2x" ></i>
                    <badge ng-if="$ctrl.Task.currentTasks.length">{{$ctrl.Task.currentTasks.length}}</badge>
                </md-button>

                <md-menu-content class="padding-0" width="3">
                    <h4 class="padding-3">My Security Tasks</h4>
                    <md-divider></md-divider>

                    <md-content md-transparent flex>
                        <task-list tasks="$ctrl.tasks"></task-list>
                    </md-content>
                </md-menu-content>
            </md-menu>
        `,

        controller: function (Task) {
            var ctrl = this;

			ctrl.Task = Task;
        }
    });
})();
