(function () {
    "use strict";

    angular.module("app").component("siteCard", {
        template: `
            <md-card style="position:relative">
                <div style="position:relative">
                    <a ng-if="$ctrl.show.viewIcon" ng-href="/site/{{$ctrl.site._id}}">
                        <img ng-src="{{$ctrl.site.image.url || 'https://cdn.virtualsecurity.center/assets/img/site/default.png'}}" style="width:100%; height:150px; object-fit: cover">
                    </a>

                    <img ng-if="!$ctrl.show.viewIcon" ng-src="{{$ctrl.site.image.url || 'https://cdn.virtualsecurity.center/assets/img/site/default.png'}}" style="width:100%; height:150px; object-fit: cover">

                    <div class="text-overlay" style="" >
                        <h5>{{$ctrl.site.name}}</h5>
                        <p>{{$ctrl.site.description}}</p>
                    </div>
                </div>

                <span style="position: absolute; right: 1rem; top: 1rem;" >
                    <a ng-if="$ctrl.show.viewIcon && $ctrl.site.assessment.current && $ctrl.show.chip != false" ng-href="/site/{{$ctrl.site._id}}/assessment">
                        <chip color="'#0277BD'" font-color="'white'" type="xs">Assessment</chip>
                    </a>

                    <chip ng-if="!$ctrl.show.viewIcon && $ctrl.site.assessment.current && $ctrl.show.chip != false" color="'#0277BD'" font-color="'white'" type="xs">Assessment</chip>

                    <a ng-if="$ctrl.site.remediation.current && $ctrl.show.chip != false" ng-href="/site/{{$ctrl.site._id}}/remediation">
                        <chip color="'#0277BD'" font-color="'white'" type="xs">Remediation</chip>
                    </a>
                </span>

                <md-card-content style="min-height:110px">
                    <address name="$ctrl.site.manager.profile.name" phone="$ctrl.site.phone" phone-extention="$ctrl.site.phoneExtention" email="$ctrl.site.manager.email" address="$ctrl.site.address"></address>
                </md-card-content>

                <md-divider ng-if="$ctrl.show.metrics"></md-divider>

                <control-state-metrics-dash ng-if="$ctrl.show.metrics" threats="$ctrl.site.controlState.current.threatRanges" compliance="$ctrl.site.controlState.current.compliance" to-show="3"></control-state-metrics-dash>

                <md-divider></md-divider>

                <md-card-actions ng-if="$ctrl.show.actions !== false" flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">
                        <md-button href="" ng-if="!$ctrl.isAuditor && $ctrl.show.assessmentIcon" ng-click="$ctrl.Assessment.start($event, $ctrl.site, 'site')" class="md-icon-button" aria-label="Assessment">
                            <md-tooltip>Schedule Assessment</md-tooltip>
                            <md-icon md-font-icon="fa-calendar" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="$ctrl.show.viewIcon" ng-href="/site/{{$ctrl.site._id}}" class="md-icon-button" aria-label="View">
                            <md-tooltip>Site Details</md-tooltip>
                            <md-icon md-font-icon="fa-eye" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.editIcon" href="" class="md-icon-button" ng-click="$ctrl.Container.edit($event, $ctrl.sites, $ctrl.site, 'site')" aria-label="Edit">
                            <md-tooltip>Edit Site</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && !$ctrl.site.archived" ng-click="$ctrl.Container.archive($event, $ctrl.sites, $ctrl.site, 'site')">
                            <md-tooltip>Archive</md-tooltip>
                            <md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
                        </md-button>
                                
                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && $ctrl.site.archived" ng-click="$ctrl.Container.archive($event, $ctrl.sites, $ctrl.site, 'site')">
                            <md-tooltip>Restore</md-tooltip>
                            <md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" href="" class="md-icon-button" ng-click="$ctrl.Container.deleteItem($event, $ctrl.sites, $ctrl.site, 'site')" aria-label="Delete">
                            <md-tooltip>Delete Site</md-tooltip>
                            <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                        </md-button>
                </md-card-actions>
            </md-card>
        `,

        bindings: {
            sites: "=",
            site: "=",
            show: "=?"
        },

        controller: function ($http, Site, Container, Assessment, User, $timeout) {
            var ctrl = this;

            ctrl.Site = Site;
            ctrl.Assessment = Assessment;
            ctrl.Container = Container;

            ctrl.$onInit = () => {
                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });
            }
        }
    })
})();
