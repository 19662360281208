(function () {
	angular.module("app").component("featuresConfiguration", {
		bindings: {
			organization: "=",
			isVendor: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Features</h3>

					<span class="md-subhead md-block">
						<content content-id="config.features.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>
		
			<form name="FeaturesConfigurationForm">
				<md-list>
					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.policy.enabled" aria-label="Enable policies" ng-disabled="$ctrl.isVendor || $ctrl.isRiskAnalysisOnly"></md-switch>
						<p>Policies</p>
					</md-list-item>

					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.training.enabled" aria-label="Enable training" ng-disabled="$ctrl.isVendor || $ctrl.isRiskAnalysisOnly"></md-switch>
						<p>Training</p>
					</md-list-item>

					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.awareness.enabled" aria-label="Enable awareness"></md-switch>
						<p>Awareness</p>
					</md-list-item>

					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.documents.enabled" aria-label="Enable documents" ng-disabled="$ctrl.isVendor || $ctrl.isRiskAnalysisOnly"></md-switch>
						<p>Documents</p>
					</md-list-item>

					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.procedure.enabled" aria-label="Enable procedures" ng-disabled="$ctrl.isVendor || $ctrl.isRiskAnalysisOnly"></md-switch>
						<p>Procedures</p>
					</md-list-item>
					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.settings.dashboardMessage.enabled" aria-label="Enable message of the day" ng-disabled="$ctrl.isVendor"></md-switch>
						<p>Message Of The Day</p>
					</md-list-item>
					<md-list-item>
						<md-switch class="margin-0-y" ng-model="$ctrl.organization.incident.enabled" aria-label="Enable incident tracking" ng-disabled="$ctrl.isVendor || $ctrl.isRiskAnalysisOnly"></md-switch>
						<p>Incident tracking</p>
					</md-list-item>
				</md-list>
			</form>
        `,

		controller: function () {
			var ctrl = this;
			console.log("Vendor", ctrl.isvendor);

			ctrl.$onInit = () => {
				console.log(ctrl.organization)
				ctrl.isRiskAnalysisOnly = ctrl.organization.license.type == "risk-analysis";
			}
		},
	});
})();
