(function () {
    "use strict";
    
    angular.module("app").component("stepControls", {
        templateUrl: "/assets/html/core/wizard/controls.html",
        
        require: {
            parent: "^^wizard"   
        },
    });    
})();
