(function () {

	angular.module("app").component("userCard", {
		/* html */
		template: `
			<md-card ng-show="$ctrl.cardReady">
				<md-card-content class="divider" layout style="min-height:120px" flex>
					<div flex>
						<h5 class="truncate">{{$ctrl.user.profile.name}}</h5>
						<p>{{$ctrl.user.profile.title}}</p>
						<p>{{$ctrl.user.profile.phone}} {{$ctrl.user.profile.phoneExtention}}</p>

						<a ng-if="$ctrl.user.email" ng-href="mailto:{{$ctrl.user.email}}">
							{{$ctrl.user.email}}
						</a>
						<p ng-if="$ctrl.user.username">
							{{$ctrl.user.username}}
						</p>

						<div ng-if="false && !$ctrl.user.disabled && $ctrl.user.status.state != 'archived'">
							<div ng-if="$ctrl.user.status.state != 'pending'" class="chips margin-3-t" ng-hide="$ctrl.show.chips == false">
								<chip type="xs success" ng-show="$ctrl.user.activity.policiesAcknowledged">Policy</chip>
								<chip type="xs danger" ng-hide="$ctrl.user.activity.policiesAcknowledged">Policy</chip>

								<chip type="xs success" ng-show="$ctrl.user.activity.trainingAcknowledged">Training</chip>
								<chip type="xs danger" ng-hide="$ctrl.user.activity.trainingAcknowledged">Training</chip>

								<chip type="xs success" ng-show="$ctrl.user.activity.awarenessAcknowledged">Awareness</chip>
								<chip type="xs danger" ng-hide="$ctrl.user.activity.awarenessAcknowledged">Awareness</chip>

								<chip type="xs success" ng-show="$ctrl.user.activity.documentsAcknowledged">Documents</chip>
								<chip type="xs danger" ng-hide="$ctrl.user.activity.documentsAcknowledged">Documents</chip>
							</div>

							<div ng-if="$ctrl.user.status.state == 'pending'" class="chips margin-3-t">
								<chip type="xs">Pending</chip>
							</div>
						</div>

						<div ng-if="$ctrl.user.status.state == 'archived'" class="chips margin-3-t">
							<chip type="xs">Archived</chip>
						</div>

						<div ng-if="$ctrl.user.disabled" class="chips margin-3-t">
							<chip type="xs">Disabled</chip>
						</div>
					</div>

					<div flex="1">
						<span class="image-overlay">
							<img style="width:70px; height:70px" class="avatar-img" ng-src="{{$ctrl.user.profile.avatar.url}}">
							<span ng-if="$ctrl.user.profile.avatar._id == 'user.avatar.default'" class="text large">{{$ctrl.initials()}}</span>
						</span>
					</div>
				</md-card-content>

				<md-card-actions ng-if="$ctrl.show.actions !== false" layout="row" layout-align="end center">
					<md-button ng-if="!$ctrl.isAuditor && $ctrl.user.email && $ctrl.licensed" ng-click="$ctrl.resendNotifications()"class="md-icon-button" aria-label="Resend notificatons">
						<md-tooltip>Resend Notifications</md-tooltip>
						<md-icon md-font-icon="fa-check-square" class="fa"></md-icon>
					</md-button>						
					
					<md-button ng-if="!$ctrl.isAuditor && $ctrl.user.email && $ctrl.user.status.state == 'pending' && $ctrl.licensed" ng-click="$ctrl.resendInvitation()"class="md-icon-button" aria-label="View">
						<md-tooltip>Resend User Inivitation</md-tooltip>
						<md-icon md-font-icon="fa-envelope" class="fa"></md-icon>
					</md-button>					
					
					<md-button ng-if="!$ctrl.isAuditor && $ctrl.user.emailonly && $ctrl.licensed" ng-click="$ctrl.resendInvitation()"class="md-icon-button" aria-label="View">
						<md-tooltip>Resend Welcome Message</md-tooltip>
						<md-icon md-font-icon="fa-envelope" class="fa"></md-icon>
					</md-button>

					<md-button ng-if="$ctrl.show.viewIcon && $ctrl.licensed" ng-href="/user/{{$ctrl.user._id}}" class="md-icon-button" aria-label="View">
						<md-tooltip>User details</md-tooltip>
						<md-icon md-font-icon="fa-eye" class="fa"></md-icon>
					</md-button>

					<md-button ng-if="!$ctrl.isAuditor && $ctrl.show.editIcon" class="md-icon-button" ng-click="$ctrl.editUserDialog($event)" aria-label="Edit">
						<md-tooltip>Edit user</md-tooltip>
						<md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
					</md-button>

					<md-button ng-show="!$ctrl.user.disabled" ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.disableUser($ctrl.user)" aria-label="Disable">
						<md-tooltip>Disable user</md-tooltip>
						<md-icon md-font-icon="fa-times" class="fa"></md-icon>
					</md-button>
					<md-button ng-show="$ctrl.user.disabled" ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.disableUser($ctrl.user)" aria-label="Enable">
						<md-tooltip>Enable user</md-tooltip>
						<md-icon md-font-icon="fa-check" class="fa"></md-icon>
					</md-button>

					<md-button ng-show="$ctrl.user.status.state != 'archived'" ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.archiveUser($ctrl.user)" aria-label="Archive">
						<md-tooltip>Archive user</md-tooltip>
						<md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
					</md-button>
					<md-button ng-show="$ctrl.user.status.state == 'archived'" ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.archiveUser($ctrl.user)" aria-label="Restore">
						<md-tooltip>Restore user</md-tooltip>
						<md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
					</md-button>

					<md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.deleteUser($ctrl.user)" aria-label="Delete">
						<md-tooltip>Delete user</md-tooltip>
						<md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
					</md-button>
				</md-card-actions>
			</md-card>
		`,

		bindings: {
			user: "=",
			users: "=",
			show: "=?",
			organization: "=?",
		},

		controller: function ($mdDialog, $mdToast, $http, User, Users, Policy, Training, Awareness, Organization, Assessment) {
			var ctrl = this;

			ctrl.Assessment = Assessment;

			ctrl.policiesAcknowledged = true;
			ctrl.trainingAcknowledged = true;
			ctrl.awarenessViewed = true;
			ctrl.cardReady = false;

			ctrl.$onInit = function () {
				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});

				if (!ctrl.user || ctrl.user.length == 0) {
					ctrl.user = User.current;
				}

				if (ctrl.show.chips != false && ctrl.user.activity) { } else {
					ctrl.show.chips = false;
				}

				if (!ctrl.organization) {
					ctrl.organization = Organization.current;

					Organization.current.$promise.then((organization) => {
						ctrl.organization = organization;
						ctrl.licensed = ctrl.organization.status.licensed;
					});
				} else {
					ctrl.licensed = ctrl.organization.status.licensed;
				}

				ctrl.cardReady = true;
			}

			ctrl.initials = function () {
				return ctrl.user.profile.firstName.charAt(0) + ctrl.user.profile.lastName.charAt(0);
			}


			ctrl.resendNotifications = function () {
				$mdDialog.show($mdDialog.confirm({
					title: "Please Confirm",
					textContent: "Resend notifications?",
					ok: "Confirm",
					cancel: "Cancel"
				})).then(function () {
					$http.post("/api/users/resend-notifications/" + ctrl.user._id).
						then(function (result) {
							if (result.data.success) {
								$mdToast.show($mdToast.simple().textContent("Notifications resent"));
							} else {
								$mdToast.show(
									$mdToast
										.simple()
										.hideDelay(0)
										.action('Close')
										.highlightAction(true)
										.textContent("Error: " + result.data.message)
								);
							};
						}).catch(function () {
							$mdToast.show(
								$mdToast
									.simple()
									.hideDelay(0)
									.action('Close')
									.highlightAction(true)
									.textContent("Error")
							);
						});
				}).catch()
			}

			ctrl.resendInvitation = function () {
				$mdDialog.show($mdDialog.confirm({
					title: "Please Confirm",
					textContent: "Resend invitation?",
					ok: "Confirm",
					cancel: "Cancel"
				})).then(function () {
					$http.post("/api/users/resend-invite/" + ctrl.user._id).
						then(function (result) {

							if (result.data.success) {
								$mdToast.show($mdToast.simple().textContent("Invitation resent"));
							} else {
								$mdToast.show(
									$mdToast
										.simple()
										.hideDelay(0)
										.action('Close')
										.highlightAction(true)
										.textContent("Invite NOT sent. " + result.data.message)
								);
							};

						}).catch(function () {
							$mdToast.show(
								$mdToast
									.simple()
									.hideDelay(0)
									.action('Close')
									.highlightAction(true)
									.textContent("Error resending invitation")
							);
						});
				}).catch()
			}

			ctrl.editUserDialog = function (ev) {
				Users.userModal(ctrl.user, ctrl.organization).then(user => {
					ctrl.user = user;

					if (ctrl.users) {
						var users = Users.resource.query();

						users.$promise.then(function () {
							ctrl.users = users;
						});
					}
				});
			}

			ctrl.disableUser = function (user) {
				if (user.disabled) {
					return toggle();
				}

				$mdDialog.show({
					template: '<div class="md-dialog-container" id="confirmDisable">' +
						'<md-dialog>' +
						'<md-dialog-content class="md-dialog-content">' +
						'<h2 class="md-title">Are you sure you want to disable this user?</h2>' +
						'<p>' + ctrl.user.profile.name + '</p>' +
						'</md-dialog-content>' +
						'<md-dialog-actions>' +
						'<md-button ng-click="cancelDialog()" class="md-button">Cancel</md-button>' +
						'<md-button ng-click="closeDialog()" class="md-primary md-button">Disable</md-button>' +
						'</md-dialog-actions>' +
						'</md-dialog>' +
						'</div>',
					clickOutsideToClose: true,

					controller: function ($scope, $mdDialog) {
						var ctrl = this;

						$scope.closeDialog = function () {
							$mdDialog.hide();
						}

						$scope.cancelDialog = function () {
							$mdDialog.cancel();
						}
					},

					parent: angular.element(document.body)
				}).then(function () {
					toggle();
				});

				function toggle() {
					$http.post(`/api/user/${user._id}/disable`).then(result => {
						const state = result.data.disabled;

						user.disabled = state;

						if (state) {
							ctrl.showToast("User disabled");
						} else {
							ctrl.showToast("User enabled");
						}
					}).catch(error => {
						console.error(error);
						ctrl.showToast("User could not be disabled");
					});
				}
			}

			ctrl.archiveUser = function (user) {
				Users.archiveUser(user);
			}

			ctrl.deleteUser = function (user) {
				Users.deleteUser(user);
			}

			ctrl.showToast = function (msg) {
				$mdToast.show(
					$mdToast.simple()
						.textContent(msg)
						.hideDelay(3000)
				);
			};

		}
	});
})();