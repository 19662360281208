(function () {
    angular.module("app").controller("OrganizationsController", OrganizationsController);

    function OrganizationsController ($scope, $mdDialog, $mdToast, $http, Organization) {

        $scope.isReady = false;
        $scope.tableView = true;
        $scope.filter = {
            searchText:""
        }
        $scope.stats = {}

        this.$onInit = function () {

            Organization.customers.query().$promise.then(function (orgs) {
                $scope.orgs = [];

                const chunks = _.chunk(orgs, 20);
                
                chunks.forEach((chunk, index) => {
                    setTimeout(function () {
                        $scope.orgs.push(...chunk);
                        console.log(index, chunks.length)

                        if (index === chunks.length - 1) {
                            console.log("DONE");

                            $scope.stats = $scope.getStats($scope.orgs);
                            $scope.isReady = true;
                        }

                        $scope.$apply();
                    }, index * 200);
                });

            });

        }


        $scope.getStats = function(orgs) {
            function getStats(stats, org) {

                var containerCount = org.siteCount + org.vendorCount + org.techCount;

                stats.customerCount = stats.customerCount + 1;
                stats.userCount = stats.userCount + org.userCount;
                stats.siteCount = stats.siteCount + org.siteCount;
                stats.vendorCount = stats.vendorCount + org.vendorCount;
                stats.techCount = stats.techCount + org.techCount;
                stats.containerCount = stats.containerCount + containerCount;

                if (org.license) {
                    stats.licensedUsers = stats.licensedUsers + (org.license.users || 0);
                    stats.licensedContainers = stats.licensedContainers + (org.license.containers || 0);
                    if (org.userCount > org.license.users) {
                        stats.unlicensedUsers = stats.unlicensedUsers + (org.userCount - org.license.users);
                    }
                    if (containerCount > org.license.containers) {
                        stats.unlicensedContainers = stats.unlicensedContainers + (containerCount - org.license.containers);
                    }
                } else {
                    stats.unlicensedContainers = stats.unlicensedContainers + containerCount;
                    stats.unlicensedUsers = stats.unlicensedUsers + org.userCount;
                }

                if (org.license && org.userCount >= org.license.users) {
                    org.userChipColor = 'danger';
                } else {
                    org.userChipColor = 'primary';
                }
                if (org.status.licensed && org.license) {
                    org.userChipValue = org.userCount + "/" + org.license.users;
                } else {
                    org.userChipValue = org.userCount;
                }
                if (org.license && org.containerCount >= org.license.containers) {
                    org.containerChipColor = 'danger';
                } else {
                    org.containerChipColor = 'primary';
                }
                if (org.status.licensed && org.license) {
                    org.containerChipValue = org.containerCount + "/" + org.license.containers;
                } else {
                    org.containerChipValue = org.containerCount;
                }
                return stats;
            }

            return orgs.reduce(
                getStats,
                {
                    customerCount: 0,
                    userCount:0,
                    siteCount:0,
                    vendorCount:0,
                    techCount:0,
                    containerCount: 0,
                    licensedUsers:0,
                    unlicensedUsers:0,
                    licensedContainers: 0,
                    unlicensedContainers:0
                });
        }

        $scope.newDialog = function(ev) {

            var newOrg = new Organization.resource();

            $mdDialog.show({
                templateUrl: '/assets/html/organization/edit-org-modal.html',
                controller: 'EditOrgController',
                parent: angular.element(document.body),
                locals: {org: newOrg},
                bindToController: true,
                clickOutsideToClose: true,
                targetEvent: ev
            }).then(function(resp) {

                Organization.customers.query().$promise.then(function (orgs) {
                    $scope.orgs = orgs;
                    $scope.stats = $scope.getStats(orgs);
                });

            }, function() {
                // cancel
            });

        }

        $scope.editOrg = function(org) {

            $mdDialog.show({
                templateUrl: '/assets/html/organization/edit-org-modal.html',
                controller: 'EditOrgController',
                parent: angular.element(document.body),
                locals: {org: org},
                bindToController: true,
                clickOutsideToClose: true
            })
            .then(function(resp) {

                Organization.customers.query().$promise.then(function (orgs) {
                    $scope.orgs = orgs;

                    $scope.stats = $scope.getStats(orgs);
                });

                $mdDialog.hide();
            }, function(resp) {
                $mdDialog.hide();
            });
        }

        $scope.deleteOrg = function(org) {
            $mdDialog.show({
                template:
                    '<div class="md-dialog-container" id="confirmDelete">' +
                        '<md-dialog>' +
                            '<md-dialog-content class="md-dialog-content">' +
                                '<h2 class="md-title">Are you sure you want to delete this organization?</h2>' +
                                '<p>' + org.name + '</p>' +
                            '</md-dialog-content>' +
                            '<md-dialog-actions>' +
                                '<md-button ng-click="cancelDialog()" class="md-button">Cancel</md-button>' +
                                '<md-button ng-click="closeDialog()" class="md-primary md-button">Delete</md-button>' +
                            '</md-dialog-actions>' +
                        '</md-dialog>' +
                    '</div>',
                clickOutsideToClose: true,
                controller: function($scope, $mdDialog) {
                    $scope.closeDialog = function() {
                          $mdDialog.hide();
                    };

                    $scope.cancelDialog = function() {
                          $mdDialog.cancel();
                    };
                },
                parent: angular.element(document.body)
            }).then( function() {

                $http.delete('/api/organization/' + org._id).then(function (resp) {

                    $scope.orgs.splice($scope.orgs.indexOf(org), 1);
                    $scope.stats = $scope.getStats($scope.orgs);

                    showToast("Organization removed");
                }, function(resp) {
                });

            }, function() {
                // cancel button was pressed
            });

        }

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

        $scope.toggleView = function() {
            $scope.tableView = !$scope.tableView;
        }
    }
})();
