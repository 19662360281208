(function () {

	angular.module("app").component("partnerTable", {
		template: `
			<md-card>
				<table class="material">
					<thead>
						<tr>
							<th>Name</th>
							<th>Owner</th>
							<th>Partner Contact</th>
							<th>Partner Email</th>
                            <th>Assessment</td>
							<th></th>
						</tr>
					</thead>

					<tbody>
						<tr ng-repeat="partner in $ctrl.partners | orderBy:'+name' | filter:$ctrl.filter" >
							<td>
								<img ng-if="partner.organization.image.url" style="width: 30px; height: 30px;" class="avatar-img margin-r" ng-src="{{partner.organization.image.url}}">
								<md-icon ng-if="!partner.organization.image.url" md-font-icon="fas fa-2x fa-building" class="margin-r"></md-icon>

								<span>{{partner.organization.name}}</span>
							</td>

                            <td>{{partner.vendor.contact.profile.name}}</td>
                            <td>{{partner.owner.profile.name}}</td>
                            <td>{{partner.owner.email}}</td>
                            <td>
                                <chip ng-if="partner.assessment.current" type="xs">{{partner.assessment.current.date.start | dateFormat: "alt"}}
                            </td>

							<td class="action">

                                <md-menu ng-if="!$ctrl.isAuditor" md-position-mode="target-right target" md-offset="0 50">
									<md-button ng-click="$mdMenu.open($event)" class="md-icon-button">
										<i class="fas fa-ellipsis-v"></i>
									</md-button>

									<md-menu-content>

										<md-menu-item ng-if="partner.assessment.current" >
											<md-button ng-href="/vendor/{{partner._id}}/assessment">
												<md-icon md-font-icon="fa-clipboard" class="fa"></md-icon>
												<span>Complete Assessment</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-click="$ctrl.Partner.editPartner(partner, $event)">
												<md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
												<span>Edit</span>
											</md-button>
										</md-menu-item>

									</md-menu-content>
								</md-menu>
							</td>
						</tr>
					</tbody>
				</table>
			</md-card>
		`,

		bindings: {
			partners: "=",
			filter: "=",
			organization: "=",
		},

		controller: function (User, Partner) {
			var ctrl = this;
			ctrl.Partner = Partner;

			ctrl.$onInit = () => {
				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});
			}

			ctrl.onRowClick = function (site) {
				window.location = "/site/" + site._id
			}

		},

	});
})();