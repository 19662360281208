
angular.module('app').component('alertCard', {
    templateUrl: '/assets/html/alerts/alertCard.html',
    controller: AlertCardController,
    bindings: {
        user: '<'
    }
});

function AlertCardController(Session, $http) {
    var ctrl = this;
    ctrl.ready = false;

    ctrl.$onInit = function() {

        if (ctrl.user == null) {
            Session.currentUser.$promise.then(function (user) {
                ctrl.user = user;
                ctrl.alerts = user.activity.alerts;
                ctrl.ready = true;
            });
        } else {
            ctrl.alerts = ctrl.user.activity.alerts;
            ctrl.ready = true;
        }

    }

    ctrl.dismissAlert = function($event, alert) {
        var index = ctrl.alerts.indexOf(alert);

        $http.delete("/api/user/alert/" + alert._id).then(function (response) {
        }, function (response) {
            // TODO: Handle error
        });


        ctrl.alerts.splice(index,1);
//        $event.stopPropagation();

    }

    ctrl.dismissAllAlerts = function($event) {

        $http.delete("/api/user/alerts").then(function (response) {
        }, function (response) {
            // TODO: Handle error
        });


        ctrl.alerts = [];
//        $event.stopPropagation();

    }

}
