(function () {
    "use strict";

    angular.module("app").directive("autoScroll", () => {
        return {
            restrict: "A",

            scope: {
                scrollWatch: "="
            },

            link: (scope, element, attr) => {
                var scrollElement = element[0];

                scope.$watch(() => scope.scrollWatch.length, scroll);

                function scroll () {
                    scope.$evalAsync(() => {
                        scrollElement.scrollTop = scrollElement.scrollHeight;
                    });
                }
            }
        };
    });
})();
