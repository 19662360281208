(function () {
    angular.module("app").factory("Collapse", function () {
        function Tab () {
            var self = this;

            self.new = function (defaults) {
                var instance = defaults || {
                    state: false
                };

                instance.toggle = function () {
                    instance.state = !instance.state;
                    $(instance.target).slideToggle(250, function () {});
                }

                return instance;
            }
        }

        return new Tab();
    });
})();
