(function () {
    "use strict";

    angular.module("app").component("assessmentCard", {
        bindings: {
            assessments: "=?"
        },

        /* html */
        template: `
            <md-card>
                <md-toolbar class="md-hue-3">
                    <div class="md-toolbar-tools">
                        <icon type="search" class="margin-r"></icon>
                        <h3>Assessments</h3>
                    </div>
                </md-toolbar>

                <md-list>
                    <md-list-item class="md-3-line" ng-repeat="assessment in $ctrl.assessments | orderBy:'+date.due'" ng-href="/{{assessment.container.type.type}}/{{assessment.container._id}}/assessment">
                        <img ng-if="assessment.container.image.url" ng-src="{{assessment.container.image.url}}" class="md-avatar" alt="" />

                        <div ng-if="!assessment.container.image.url && assessment.container.type.icon" class="md-avtar-icon">
                            <icon type="{{assessment.container.type.icon}}"></icon>
                        </div>

                        <div class="md-list-item-text">
                            <h3>{{assessment.container.name}}</h3>
                            <h4>Due {{assessment.date.due | date:"MM/DD/YYYY"}}</h4>
                            <p>{{assessment.controlStates.length}} questions</p>
                        </div>

                        <md-button ng-if="$ctrl.moment().isAfter(assessment.date.due)" class="md-warn md-icon-button md-secondary">
                            <md-tooltip>Overdue</md-tooltip>
                            <icon type="clock-o"></icon>
                        </md-button>

                        <md-divider ng-if="!$last"></md-divider>
                    </md-list-item>

                    <md-list-item ng-if="!$ctrl.assessments.length">
                        <span>No assessments</span>
                    </md-list-item>
                </md-list>
            </md-card>
        `,

        controller: function (Session, Assessment) {
            var ctrl = this;

            ctrl.moment = moment;
            ctrl.Assessment = Assessment;
        }
    });
})();