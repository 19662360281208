(function () {
    "use strict";
    //left: training/awareness, right: other
    angular.module("app").component("userDashboard", {
        /* html */
        template: `
            <loader flex="100" loaded="$ctrl.ready">
                <div flex="100" layout="row" layout-align="center" layout-wrap>
                    <div ng-if="$ctrl.shouldRenderLeftColumn()" flex="100" flex-gt-sm="50" flex-gt-md="33">
                        <message-card organization="$ctrl.organization" edit="$ctrl.isCso"></message-card>

						<security-tasks-card flex="100" tasks="$ctrl.tasks"></security-tasks-card>

						<incident-card  ng-if="$ctrl.organization.incident.enabled" organization="$ctrl.organization" edit="$ctrl.isCso"></incident-card>


						<alert-card ng-if="false" flex="100" ng-if="$ctrl.dashboardSettings.alerts.show"></alert-card>
                    </div>

                    <div ng-if="$ctrl.user.organization.policy.enabled || $ctrl.user.organization.documents.enabled" flex="100" flex-gt-sm="50" flex-gt-md="33">
                        <security-docs-card flex="100" user="$ctrl.user"></security-docs-card>
                    </div>

                    <div ng-if="$ctrl.organization.awareness.enabled || $ctrl.organization.training.enabled" flex="100" flex-gt-sm="50" flex-gt-md="33">
                        <awareness-card flex="100" ng-if="$ctrl.organization.awareness.enabled"></awareness-card>
                        <training-card flex="100" ng-if="$ctrl.organization.training.enabled"></training-card>
                    </div>
                </div>
            </loader>
        `,

        controller: function (Task, Session, Assessment, Remediation) {
            var ctrl = this;
            ctrl.$onInit = function () {

                ctrl.tasks = Task.Resource.query();

                //Bind current User and Organization
                Session.currentUser.$promise.then((user) => {
                    ctrl.user = user;
                    ctrl.organization = user.organization;
                    ctrl.isCso = (user.roles.findIndex(function (role) {
                        return role.slug == 'cso'
                    }) > -1);
                    //                    ctrl.dashboardSettings = ctrl.isCso ? ctrl.organization.settings.dashboards.cso : ctrl.organization.settings.dashboards.default;

                    ctrl.ready = true;
                });

                ctrl.shouldRenderLeftColumn = () => {
                    return true;

                    if (!ctrl.dashboardSettings) return false;

                    return (ctrl.isCso && ctrl.dashboardSettings.setupWizard.show) || ctrl.remediationPlans.length || ctrl.assessments.length || (ctrl.dashboardSettings.alerts.show && ctrl.user.activity.alerts.length);
                }

            }
        }
    });
})();