(function () {
    angular.module("app").controller("TechController", TechController);

    function TechController ($scope, $mdDialog, $mdToast, Container, Technology) {

        $scope.techs = [];

        $scope.Container = Container;

        Technology.Resource.query().$promise.then(function (techs) {
                $scope.techs = techs;
        });
    }
})();
