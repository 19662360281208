/**
 * @ngdoc component
 * @name app.uiKit.component:metric
 * @author Nick Vorie
 *
 * @description This component is simply a wrapper for formatting a Metric's measure and description
 *
 * @transclude measure     The rendering of the Metric's measure
 * @transclude description The Metric's description
 *
 * @example
 * <example module="app">
 *      <file name="index.html">
 *          <metric>
 *              <measure>
 *                  <metric-range-gauge ...
 *              </measure>
 *
 *              <description>Example Metric</description>
 *          </metric>
 *      </file>
 * </example>
 */
(function () {
    "use strict";

    angular.module("app").component("metric", {
        transclude: {
            measure: "measure",
            description: "description",
        },

        template: `
            <div class="metric">
                <div class="measure" ng-transclude="measure"></div>
                <div class="description color-muted pt-1" ng-transclude="description"></div>
            </div>
        `
    })
})();
