
angular.module('app').component('standardsCard', {
    templateUrl: '/assets/html/standards/standardsCard.html',
    controller: StandardsCardController,
    bindings: {
        user: '<'
    }
});

function StandardsCardController(Standard, Session) {
    var ctrl = this;
    ctrl.ready = false;
    ctrl.standards = [];
    ctrl.policyCount = 0;
    ctrl.policiesToReview = 0;

    ctrl.$onInit = function() {

        // get all
        Standard.resource.query().$promise.then(function (standards) {
            ctrl.standards = standards;
            if (ctrl.user == null) {
                Session.currentUser.$promise.then(function (user) {
                    checkPolicies(user);
                    ctrl.user = user;
                    ctrl.ready = true;
                });
            } else {
                checkPolicies(ctrl.user);
                ctrl.ready = true;
            }
        });

    }
    checkPolicies = function(user) {
        ctrl.policyCount = user.activity.policies.length;
        user.activity.policies.forEach(function(policy){
            if (!policy.acknowledged) {
                ctrl.policiesToReview = ctrl.policiesToReview + 1;
            }
        })
    }

    ctrl.openStandard = function(standard) {
         location.href = "/standards/" + standard._id;
    }

}
