//TODO: Refactor into a component
(function () {
    angular.module("app").controller("loginForm", function ($scope, Login) {
        $scope.Login = Login;

        $scope.successLocation = "/"
        $scope.redirect = false;

        $scope.loginData = {
            email: "",
            password: "",
            token: ""
        };

        $scope.state = "login";
        
        $scope.setState = function (state) {
            $scope.state = state;
        }

        $scope.step1 = function () {
            Login.login($scope.loginData, function (error, result) {
                if (error) { 
                    if (error.twoFactor) {
                        if (error.twoFactor == "enabled") {
                            $scope.setState("two-factor");
                            Login.errorMessage.message = null;
                        }
                    }
                } else {
                    if (result) {
                        if ($scope.redirect) {
                            window.location = $scope.redirectLocation;
                        } else {
                            window.location = $scope.successLocation;
                        }
                    }
                }
            })
        }

        $scope.step2 = function () {
            Login.login($scope.loginData, function (error, result) {
                if (error) {
                    if (!error.twoFactor) {
                        $scope.setState("login");
                    }
                } else if (result) {
                    if ($scope.redirect) {
                        window.location = $scope.redirectLocation;
                    } else {
                        window.location = $scope.successLocation;
                    }
                }
            });
        }
    });
})();
