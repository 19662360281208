(function () {
    "use strict";

    angular.module("app").component("cardBlock", {
        transclude: true,

        bindings: {
            padding: "="
        },

        template: `<div class="card-block" ng-class="{'p-1': $ctrl.padding}" ng-transclude></div>`,
    })
})();
