(function () {
    angular.module("app").factory("Error", Error);
    
    function Error () {
        function ErrorHandler(name) {
            var self = this;

            self.name = name;
            self.message = null;
            self.errorMessage = null;
            self.successMessage = null;
            self.data = "Todo";

            self.clear = function () {
                self.setError();
                self.setSuccess();
            }

            self.setSuccess = function (message) {
                self.successMessage = message;
            }

            self.setError = function (message, data) {
                self.message = message;
                self.errorMessage = message;
                self.data = data;
                self.errors = {};
            }

            self.process = function (data) {
                self.message = null;
                self.data = null;

                if (data instanceof Array) {
                    self.errors = {};

                    data.forEach(function (error) {
                        if (error.message) error.msg = error.message
                        self.errors[error.param || error.path] = error;
                    });

                    return;
                }

                if (typeof data == "string") {
                   return self.setError(data);
                } else if (data instanceof Object) {
//                    if (!!data.redirect) {
//                        setTimeout(function() {
//                            window.location = data.redirect;
//                        }, 550);
//                    }

                    if (!!data.success) {
                        return self.successMessage = data.success;
                    }

                    if (!!data.error) {
                        return self.process(data.error);
                    } else if (!!data.message) {
                        return self.process(data.message);
                    } else if (data.errors) {
                        if (data.errors instanceof Object){
                            data.errors = Object.keys(data.errors).map(function (key) {
                                return data.errors[key]
                            });
                        }

                        return self.process(data.errors);
                    }
                }

                self.message = "Unknown error";
                console.error("Could not process error", name, data);
            }
        }

        function Error() {
            var self = this;
            var registry = {};

            self.exists = function (name) {
                return registry[name] != null;
            }

            self.register = function (name) {
                if (self.exists(name)) {
                    console.warn("Error handler already registered", name);
                    return registry[name];
                }

                return registry[name] = new ErrorHandler(name);
            }

            self.get = function (name) {
                if (!self.exists(name)) {
                    return self.register(name);
                }

                return registry[name];
            }
        }

        return new Error();
    }
})();
