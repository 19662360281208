/**
 * @ngdoc component
 * @name app.uiKit.component:valueChip
 * @author Nick Vorie
  *
 * @param {object} threatPair The ThreatPair to render a chip for
 *
 * @description This component will render a CVSS color-coded risk score for a given ThreatPair
 */
(function () {
    "use strict";

    angular.module("app").component("valueChip", {
        bindings: {
            title: "=",
            value: "=",
            valueColor: "=?",
            type: "=?",
        },

        template: `
            <chip type="{{$ctrl.type}}">
                <span class="value" ng-class="$ctrl.valueColor">{{$ctrl.value}}</span>
                <span>{{$ctrl.title}}</span>
            </chip>
        `,

        controller: function (Cvss) {
            var ctrl = this;

            ctrl.$onInit = function () {
				if (!ctrl.type) {
					ctrl.type = "xs";
				}

                if (!ctrl.valueColor) {
                    ctrl.valueColor = "";
                }
            }
//
            ctrl.Cvss = Cvss;
        }
    })
})();
