(function () {
	angular.module("app").component("emailConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Email</h3>

					<span class="md-subhead md-block">
						<content content-id="config.email.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<form name="EmailConfigurationForm">
					<div layout="row" layout-align="start center">
						<md-input-container flex="25">
							<label>Send Email As</label>
							<input type="text" ng-model="$ctrl.organization.settings.notifications.email.sendAs">
						</md-input-container>

						<i class="fa fa-at fa- margin-2-x margin-2-b"></i>

						<md-input-container flex="25" style="margin-bottom: 42px;">
							<md-select ng-model="$ctrl.organization.settings.notifications.email.sendFrom" md-on-close="$ctrl.selectChanged()">
								<md-option value="default">{{$ctrl.info.server}}</md-option>
								<md-option ng-repeat="domain in $ctrl.organization.domains" value="{{domain.name}}">{{domain.name}}</md-option>
							</md-select>
						</md-input-container>

						<md-input-container ng-if="false" flex="25">
							<input type="text" ng-value="$ctrl.info.server" disabled>
						</md-input-container>

						<div class="margin-2-b">
							<md-button class="md-icon-button" ng-click="$ctrl.organization.settings.notifications.email.sendAs = 'robot'">
								<md-tooltip>Reset</md-tooltip>
								<i class="fa fa-times"></i>
							</md-button>
						</div>
					</div>

					<div layout layout-align="start start">
						<md-input-container flex>
							<md-checkbox ng-model="$ctrl.organization.settings.notifications.userNotifications.enabled" aria-label="User Notifications">Send notifications to <b>new users</b> (welcome email, new user invitation, etc.)</md-checkbox>
						</md-input-container>
					</div>

					<div layout layout-align="start start">
						<md-input-container flex>
							<md-checkbox ng-model="$ctrl.organization.tasks.sendNotice" aria-label="Task Notifications">Send notification of new <b>tasks</b></md-checkbox>
						</md-input-container>
					</div>

					<div layout layout-align="start start">
						<md-input-container flex="30">
							<md-checkbox ng-model="$ctrl.organization.tasks.sendReminder" aria-label="Task Reminders">Send incomplete <b>task</b> reminders </md-checkbox>
						</md-input-container>

						<md-input-container flex="10">
							<label for="taskRemindEvery">days after</label>
							<input type="number" min="1" max="90" name="taskRemindEvery" ng-model="$ctrl.organization.tasks.remindEvery" ng-required="$ctrl.organization.tasks.sendReminder" ng-disabled="!$ctrl.organization.tasks.sendReminder">

							<div ng-messages="OrgSetupForm.taskRemindEvery.$error">
								<div ng-message="required">This is required!</div>
							</div>
						</md-input-container>

						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.tasks.remindAt" ng-required="$ctrl.organization.tasks.sendReminder" ng-disabled="!$ctrl.organization.tasks.sendReminder">
						</md-input-container>
					</div>

					<div ng-if="$ctrl.organization.policy.enabled" layout layout-align="start start">
						<md-input-container flex="35">
							<md-checkbox ng-model="$ctrl.organization.policy.sendAcknowledgements" aria-label="Onboarding Training">Create <b>policy</b> acknowledgment tasks daily </md-checkbox>
						</md-input-container>

						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.policy.sendAcknowledgementsAt" ng-required="$ctrl.organization.policy.sendAcknowledgements" ng-disabled="!$ctrl.organization.policy.sendAcknowledgements">
						</md-input-container>
					</div>

					<div ng-if="$ctrl.organization.documents.enabled" layout layout-align="start start">
						<md-input-container flex="40">
							<md-checkbox ng-model="$ctrl.organization.documents.sendAcknowledgements" aria-label="Document Acknowledgements">Create <b>document</b> acknowledgment tasks daily </md-checkbox>
						</md-input-container>

						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.documents.sendAcknowledgementsAt" ng-required="$ctrl.organization.documents.sendAcknowledgements" ng-disabled="!$ctrl.organization.documents.sendAcknowledgements">
						</md-input-container>
					</div>

					<div ng-if="$ctrl.organization.procedure.enabled" layout layout-align="start start">
						<md-input-container flex="35">
							<md-checkbox ng-model="$ctrl.organization.procedure.createTasks.enabled" aria-label="Create Procedure Tasks">Create assigned <b>procedure</b> tasks daily</md-checkbox>
						</md-input-container>

						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.procedure.createTasks.at" ng-required="$ctrl.organization.documents.sendAcknowledgements" ng-disabled="!$ctrl.organization.procedure.createTasks.enabled">
						</md-input-container>
					</div>
				</form>
			</md-card-content>
        `,

		controller: function ($http) {
			var ctrl = this;

			$http.get("/api/info").then(res => {
				ctrl.info = res.data;
			});

			ctrl.selectChanged = function () {
				console.log(ctrl.organization.settings.notifications.email.sendFrom)
			}

			ctrl.hasVerifiedDomains = function () {
				return !!ctrl.organization.domains.some(d => d.verified);
			}

			ctrl.$onInit = function () {
				console.log(ctrl.organization)
				if (!ctrl.organization.settings.notifications.email.sendFrom) {
					ctrl.organization.settings.notifications.email.sendFrom = "default";
				}
			}
		},
	});
})();