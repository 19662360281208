/**
 * @ngdoc component
 * @name app.control.component:controlStateGrapth
 * @author Nick Vorie
  *
 * @param {arraay}   controlStates The ControlStates to generate the graph for
 * @param {function} onClick Callback with index of clicked ControlState
 *
 * @description This component will render a graph for a set of given ControlStates
 */
(function () {
    "use strict";

    angular.module("app").component("threatChart", {
        bindings: {
            controlState: "=",
            onClick: "=?"
        },

        template: `
            <div flex layout="row" layout-align="center center" class="padding-1-b">
                <canvas class="chart" width="400" height="300"></canvas>
            </div>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            var container = $element.find("div")[0];
            ctrl.width = container.offsetWidth - 20;

            ctrl.$onInit = function () {
				var threats = _.sortBy(ctrl.controlState.threatRanges, pair => pair.threat._id);

				var threatNames = threats.map(pair => pair.threat.title);

				var inherentRisk = threats.map(pair => pair.threat.inherentRisk.baseScore);
				var residualRisk = threats.map(pair => pair.score.max);

				var weightedRisk = threats.map(pair => pair.weightedScore || 0);
				var acceptedRisk = threats.map(pair => pair.acceptedScore || 0);
				var plannedRisk = threats.map(pair => pair.score.min);

                setTimeout(function () {
                    var ctx = $element.find("canvas")[0].getContext("2d");

                    var config = {
					  type: 'radar',
					  options: {
						scale: {
							ticks: {
								beginAtZero: true,
								min: 0,
								max: 10
							}
						}
					  },
					  data: {
						labels: threatNames,
						datasets: [
						  {
							data: inherentRisk,
							label: "Inherent",
							borderColor: "#4894f7",
							borderWidth: 4,
							backgroundColor: "rgba(88,149,240,0.1)",
							pointHoverBackgroundColor: "#75b4f9",
							pointHoverBorderColor: "#75b4f9",
							pointHoverRadius: 5,
							pointBackgroundColor: "#4894f7",
							pointBorderColor: "#4894f7",
							pointRadius: 3,
							fill: true,
						  },
						  {
							data: residualRisk,
							label: "Base",
							borderColor: "#000000",
							borderWidth: 4,
							backgroundColor: "rgba(0,0,0,0.1)",
							pointHoverBackgroundColor: "#666666",
							pointHoverBorderColor: "#666666",
							pointHoverRadius: 5,
							pointBackgroundColor: "#000000",
							pointBorderColor: "#000000",
							pointRadius: 3,
							fill: true,
						  },
						  {
							data: weightedRisk,
							label: "Weighted",
							borderColor: "#AA2D2C",
							borderWidth: 4,
							backgroundColor: "rgba(158,55,50,0.1)",
							pointHoverBackgroundColor: "#e08585",
							pointHoverBorderColor: "#e08585",
							pointHoverRadius: 5,
							pointBackgroundColor: "#AA2D2C",
							pointBorderColor: "#AA2D2C",
							pointRadius: 3,
							fill: true,
						  },
						  {
							data: acceptedRisk,
							label: "Accepted",
							borderColor: "#aaaaaa",
							borderWidth: 4,
							backgroundColor: "rgba(170,170,170,0.1)",
							pointHoverBackgroundColor: "#cccccc",
							pointHoverBorderColor: "#cccccc",
							pointHoverRadius: 5,
							pointBackgroundColor: "#aaaaaa",
							pointBorderColor: "#aaaaaa",
							pointRadius: 3,
							fill: true,
						  },
//							  {
//							data: plannedRisk,
//							label: "Planned",
//							borderColor: "#73AE57",
//							borderWidth: 4,
//							backgroundColor: "rgba(128,171,97,0.1)",
//							pointHoverBackgroundColor: "#b7d6a9",
//							pointHoverBorderColor: "#b7d6a9",
//							pointHoverRadius: 5,
//							pointBackgroundColor: "#73AE57",
//							pointBorderColor: "#73AE57",
//							pointRadius: 3,
//							fill: true,
//						  }
						]
					  }
					}

                    ctrl.chart = new Chart(ctx, config);
                }, 500);
            }
        }
    });
})();
