(function () {
    "use strict";

    angular.module("app").factory("Standard", StandardFactory);

    function StandardFactory ($resource, $http) {

        var StandardResource = $resource("/api/standards/:id", {
            id: "@_id"
        });

        function StandardProvider() {
            var self = this;

            self.resource = StandardResource;

            return self;
        }

        return new StandardProvider();
    }

})();
