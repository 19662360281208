(function () {
    "use strict";

    angular.module("app").component("checkbox", {
        template: `
            <label class="c-input c-checkbox">
                <input type="checkbox" ng-model="$ctrl.bindTo" ng-disabled="$ctrl.disabled" ng-change="$ctrl.handle()">
                <span class="c-indicator"></span>
                <span class="c-blur"></span>
                <span ng-transclude style="vertical-align: top;line-height: 20px;"></span>
            </label>
        `,
        
        bindings: {
            bindTo: "=",
            disabled: "=",
            onChange: "&?"
        },
        
        transclude: true,
        
        controller: function () {
            var ctrl = this;
            
            ctrl.handle = function () {
                setTimeout(ctrl.onChange, 100);
            }
        }
    });
})();