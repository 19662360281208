(function () {
    "use strict";

    angular.module("app").filter("phone", function () {
        return function (val, args) {
            val = val || "";

            return val.replace(/(\d{3})(\d{3})(\d{4})/, "$1-$2-$3");
        }
    });
})();
