(function () {
    angular.module("app").controller("TemplateAdminController", TemplateAdminController);

    function TemplateAdminController($scope, $http, $mdDialog, $mdToast, $mdSidenav, Procedure, Container, Session) {

        $scope.controls = [];
        $scope.containers = [];
        $scope.procedure = {
            isNew: true
        };
        $scope.filter = {
            searchText: "",
            container: "",
            showDefaults: true,
            showDisabled: true
        };
        $scope.isReady = false;
        $scope.isAdmin = false;
        $scope.canDelete = false;

        this.$onInit = function () {

            Session.currentUser.$promise.then(function (user) {
                $scope.isAdmin = user.admin;
            });

            Procedure.templates.query({ returnAll: true }).$promise.then(function (procedures) {
                $scope.procedures = procedures;
                $scope.isReady = true;
            });

            Container.typeResource.query({}).$promise.then(function (containers) {
                $scope.containers = containers;
            });

        };

        $scope.edit = function (procedure) {

            $scope.procedure = procedure;

            $scope.getControls();

            if ($scope.procedure.organization) {
                $scope.canDelete = true;
            } else {
                $scope.procedure.default = true;

                if ($scope.isAdmin) {
                    $scope.canDelete = true;
                } else {
                    $scope.canDelete = false;
                }
            }

            $mdSidenav("editSideNav").toggle().then(function () {

            });
        };

        $scope.getControls = function () {

            if ($scope.procedure.type) {
                var containers = $scope.procedure.type.map(function (type) {
                    return type._id;
                })

                var query = {
                    params: {
                        container: containers
                    }
                };

                $http.get(`/api/control`, query).then(function (result) {
                    $scope.controls = result.data;
                }).catch(function (res) {
                    console.log(res);
                });
            } else {
                $scope.controls = [];
            }
        };

        $scope.newDocument = function () {

            $scope.procedure = {
                isNew: true,
                enabled: false,
                steps: [],
                type: []
            };
            $scope.canEdit = false;

            $scope.editForm.$setUntouched();
            $scope.editForm.$setPristine();

            $mdSidenav("editSideNav").toggle().then(function () {
            });
        };

        $scope.cancelEdit = function () {
            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        };

        $scope.update = function (ev) {

            Procedure.templates.update($scope.procedure).$promise.then(function (result) {

                var idx = $scope.procedures.findIndex(function (procedure) {
                    if (result.replacesDocument) {
                        return result._id == procedure._id || result.replacesDocument._id == procedure._id;
                    } else {
                        return result._id == procedure._id;
                    }
                });

                if (idx > -1) {
                    $scope.procedures[idx] = result;
                } else {
                    $scope.procedures.push(result);
                }

                showToast("Procedure saved.");
            });

            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        };

        $scope.delete = function (procedure) {

            var confirm = $mdDialog.confirm()
                .title('Confirm removal of this customized template?')
                .textContent(procedure.title)
                .ariaLabel('Remove template')
                .ok('Yes, remove template')
                .cancel('No, cancel');

            $mdDialog.show(confirm).then(function () {
                removeItem(procedure);
            }, function () {
            });

        };

        removeItem = function (procedure) {

            Procedure.templates.delete({ id: procedure._id }).$promise.then(function (result) {

                Procedure.templates.query({ returnAll: true }).$promise.then(function (procedures) {
                    $scope.procedures = procedures;

                    $mdSidenav("editSideNav")
                        .toggle()
                        .then(function () {
                        });

                    showToast("Template removed: " + procedure.title);
                });

            }, function (err, result) {

            });

        };

        $scope.removeStep = function (step) {
            var idx = $scope.procedure.steps.findIndex(function (stp) {
                return step._id == stp._id;
            });
            if (idx > -1) {
                $scope.procedure.steps.splice(idx, 1);
            };
            if ($scope.procedure.steps.length < 1) {
                $scope.addStep();
            }
        }

        $scope.addStep = function () {

            var maxOrder = $scope.procedure.steps.reduce(function (max, stp) {
                if (stp.order > max) {
                    return stp.order
                } else {
                    return max
                }
            }, 0) + 1;

            $scope.procedure.steps.push({
                order: maxOrder,
                controls: []
            });
        }

        $scope.filterControls = function (query) {
            return $scope.controls.filter(function (control) {
                return control.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
        };

        $scope.filterContainers = function (query) {
            return $scope.containers.filter(function (container) {
                return container.title.toLowerCase().indexOf(query.toLowerCase()) > -1;
            });
        }

        $scope.clearFilter = function () {
            $scope.filter.searchText = "";
            $scope.filter.container = "";
            $scope.filter.showDefaults = true;
            $scope.filter.showDisabled = true;
        };

        $scope.filterContainer = function (document) {
            if ($scope.filter.container == "") {
                return true;
            }

            return $scope.filter.container == document.container._id;
        }

        $scope.filterEnabled = function (document) {
            return $scope.filter.showDisabled || document.enabled
        };

        $scope.filterDefaults = function (document) {
            return $scope.filter.showDefaults || document.organization
        };

        showToast = function (msg) {
            $mdToast.show(
                $mdToast.simple()
                    .textContent(msg)
            );
        };

    }
})();
