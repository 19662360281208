(function () {
    "use strict";

    angular.module("app").component("remediationCard", {
        bindings: {
            plans: "=?"
        },

        template: `
            <md-card>
                <md-toolbar class="md-hue-3">
                    <div class="md-toolbar-tools">
                        <icon type="cogs" class="margin-r"></icon>
                        <h3>Remediation Plans</h3>
                    </div>
                </md-toolbar>

                <md-list>
                    <md-list-item class="md-3-line" ng-repeat="plan in $ctrl.plans | orderBy:'+date.due'" ng-href="/{{plan.container.type.type}}/{{plan.container._id}}/remediation">
                        <img ng-if="plan.container.image.url" ng-src="{{plan.container.image.url}}" class="md-avatar" alt="" />

                        <div ng-if="!plan.container.image.url && plan.container.type.icon" class="md-avtar-icon">
                            <icon type="{{plan.container.type.icon}}"></icon>
                        </div>

                        <div class="md-list-item-text">
                            <h3>{{plan.container.name}}</h3>
                            <h4>Earliest due {{plan.date.due | date:"MMM d, yyyy"}}</h4>
                            <p>{{plan.steps.length}} tasks</p>
                        </div>

                        <md-button ng-if="$ctrl.moment().isAfter(plan.date.due)" class="md-warn md-icon-button md-secondary">
                            <md-tooltip>Overdue</md-tooltip>
                            <icon type="clock-o"></icon>
                        </md-button>

                        <md-divider ng-if="!$last"></md-divider>
                    </md-list-item>

                    <md-list-item ng-if="!$ctrl.plans.length">
                        <span>No remediation plans</span>
                    </md-list-item>
                </md-list>
            </md-card>
        `,

        controller: function (Session, Remediation) {
            var ctrl = this;

            ctrl.moment = moment;
            ctrl.Remediation = Remediation;
        }
    });
})();
