(function () {
    "use strict";

    angular.module("app").component("contentIcon", {
        bindings: {
            grow: "="
        },

        transclude: true,

        template: `<span class="icon" ng-class="{'fill': $ctrl.grow}" ng-transclude></span>`,
    })
})();
