(function () {

    angular.module("app").component("userGrid", {
        template: `
			<div layout="row" layout-wrap>
				<user-card flex="100" flex-gt-xs="50" flex-gt-sm="33" flex-gt-md="25"
					ng-repeat="user in $ctrl.users | orderBy:'+profile.name' | filter:$ctrl.filter | startAt:$ctrl.perPage*$ctrl.page | limitTo:50"
					user="user"
					users="$ctrl.users"
					trainings="trainings"
					awarenesses="awarenesses"
					show="{metrics:true,media:true,viewIcon:true,editIcon:true,deleteIcon:true}">
				</user-card>
			</div>

			<pagination ng-if="$ctrl.users.length" items="$ctrl.usersFiltered().length" per-page="$ctrl.perPage" page="$ctrl.page"></pagination>
		`,

        bindings: {
            users: "=",
            filter: "=",
            organization: "=",
        },

        controller: function ($scope, $mdDialog, $mdToast, $http, $filter, Users, Assessment, Organization) {
			var ctrl = this;

			ctrl.page = 0;
			ctrl.perPage = 50;

			if (!ctrl.organization) {
				ctrl.organization = Organization.current;
			}

			ctrl.usersFiltered = function () {
				return $filter("filter")(ctrl.users, ctrl.filter);
			}
		},

    });
})();
