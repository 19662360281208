(function () {
    "use strict";

    angular.module("app").component("securityDocsCard", {
        /* html */
        template: `
            <md-card ng-if="$ctrl.user.organization.policy.enabled || $ctrl.user.organization.documents.enabled" id="documentsCard" ng-show="$ctrl.ready">
                <md-toolbar class="md-hue-3">
                    <div class="md-toolbar-tools">
                        <md-icon md-font-icon="fa-book" class="fal margin-r"></md-icon>
                        <h2>Documentation</h2>
                    </div>
                </md-toolbar>

                <md-card-content class="padding-0">
                    <md-content md-transparent>
                        <md-list class="padding-0">
                            <md-list-item ng-if="$ctrl.user.organization.policy.enabled" class="md-2-line" ng-href="/policy/myPolicy">
                                <div class="md-list-item-text">
                                    <h3>My Policies</h3>
                                    <p ng-show="$ctrl.policiesToReview > 0">You have {{$ctrl.policiesToReview}} of {{$ctrl.policyCount}} requirements to be reviewed.</p>
                                    <p ng-hide="$ctrl.policiesToReview > 0">You have {{$ctrl.policyCount}} requirements.</p>
                                </div>

                                <md-button style="min-width:120px" ng-if="$ctrl.policiesToReview > 0" class="md-raised md-primary md-button md-ink-ripple" ng-href="/policy/myPolicy">Acknowledge</md-button>

                                <md-button ng-if="$ctrl.policiesToReview < 1" class="md-raised md-button md-ink-ripple" ng-href="/policy/myPolicy">View</md-button>

                                <md-divider></md-divider>
                            </md-list-item>

                            <md-list-item ng-if="$ctrl.user.organization.documents.enabled" class="md-2-line" ng-repeat="document in $ctrl.documents | orderBy:'title'" ng-click="$ctrl.openDocument(document)">
                                <div class="md-list-item-text">
                                    <h3>{{document.title | replaceOrgName:$ctrl.user.organization}}</h3>
                                    <p>{{document.description | replaceOrgName:$ctrl.user.organization}}</p>
                                </div>

                                <div>
                                <md-button ng-show="document.requireAcknowledgement && !document.acknowledged" class="md-raised md-primary md-button md-ink-ripple" ng-click="$ctrl.openDocument(document)">Acknowledge</md-button>

                                <md-button ng-hide="document.requireAcknowledgement && !document.acknowledged" class="md-raised md-button md-ink-ripple" ng-click="$ctrl.openDocument(document)">View</md-button>
                                </div>
                                <md-divider></md-divider>
                            </md-list-item>
                        </md-list>

                    </md-content>
                </md-card-content>
            </md-card>
        `,

        bindings: {
            user: '<'
        },

        controller: function (Document, Session) {
            var ctrl = this;

            ctrl.ready = false;
            ctrl.documents = [];
            ctrl.policyCount = 0;
            ctrl.policiesToReview = 0;

            ctrl.$onInit = function () {
                if (ctrl.user == null) {
                    Session.currentUser.$promise.then(function (user) {
                        if (user.organization.documents && user.organization.documents.enabled) {
                            getDocuments(user);
                        };
                        checkPolicies(user);
                        ctrl.user = user;
                        ctrl.ready = true;
                    });
                } else {
                    if (ctrl.user.organization.documents && ctrl.user.organization.documents.enabled) {
                        getDocuments(ctrl.user);
                    };
                    checkPolicies(ctrl.user);
                    ctrl.ready = true;
                }

            }

            function getDocuments(user) {
                var roles = user.roles.map(function (role) {
                    return role._id;
                });
                Document.orgDocuments().query({
                    roles: roles,
                    titlesOnly: true,
                    type: Document.types.map(t => t._id)
                }).$promise.then(function (documents) {
                    documents = documents.filter(d => d.type != "help");

                    console.log(documents);

                    documents.forEach(function (document) {
                        var userDoc = user.activity.documents.find(function (userDoc) {
                            return document._id + "" == userDoc.document + ""
                        });
                        if (userDoc) {
                            document.acknowledged = userDoc.acknowledged;
                            document.requireAcknowledgement = true;
                        } else {
                            document.requireAcknowledgement = false;
                            document.acknowledged = false;
                        }
                    });

                    ctrl.documents = documents.filter(d => d.enabled);
                });
            }

            function checkPolicies(user) {
                ctrl.policyCount = user.activity.policies.length;

                user.activity.policies.forEach(function (policy) {
                    if (!policy.acknowledged) {
                        ctrl.policiesToReview = ctrl.policiesToReview + 1;
                    }
                });
            }

            ctrl.openDocument = function (document) {
                location.href = "/documents/" + document._id;
            }
        }
    });
})();