(function () {
    "use strict";

    angular.module("app").component("controlNew", {
        templateUrl: "/assets/html/control/new.html",

        bindings: {
            task: "="
        },

        controller: function (Control, Error, TabProvider, ModalFactory) {
            var ctrl = this;

            ctrl.$onInit = function () {
                ctrl.Control = Control;
                ctrl.ModalFactory = ModalFactory;

                ctrl.tabGroup = TabProvider.set("control-new", [{
                    id: "meta",
                    content: "Control"
                }, {
                    id: "risk",
                    content: "Risk"
                }, {
                    id: "scope",
                    content: "Scope"
                }, {
                    id: "policy",
                    content: "Policy"
                }]);

                ctrl.new = {};
                ctrl.errorHandler = Error.register("new-control");

                ctrl.submit = submit;
            }

            function submit () {
                var control = new Control.resource(ctrl.new);

                ctrl.errorHandler.clear();

                control.$save().then(function (response) {
                    ctrl.errorHandler.setSuccess("Control created, redirecting...");

                    $timeout(function () {
                        //window.location = "/controls"
                    }, 500);
                }, function (response) {
                    console.log(response.data)
                    ctrl.errorHandler.process(response.data);
                });
            }
        }
    })
})();
