(function () {
    angular.module("app").directive("userSessions", userSessions);

    function userSessions() {
        return {
            templateUrl: "/assets/html/user/sessions.html",
            controller: function ($scope, $http, Error, Session, DeviceIcons) {
                $scope.Session = Session;
                $scope.DeviceIcons = DeviceIcons;

                $scope.sessions = [];
                $scope.errorHandler = Error.get("user-sessions");

                $scope.loadSessions = loadSessions;
                $scope.logOutSession = logOutSession;
                $scope.formatDate = formatDate;

                loadSessions();

                function logOutSession (session) {
                    $scope.errorHandler.clear();

                    $http.delete("/api/user/sessions/" + session._id).then(function (responce) {
                        $scope.loadSessions();
                        $scope.errorHandler.setSuccess("Session with SID " + session._id + " has been logged out");
                    }, function (response) {
                        $scope.loadSessions();
                        $scope.errorHandler.process(response.data);
                    });
                }

                function loadSessions () {
                    $http.get("/api/user/sessions").then(function (response) {
                        $scope.sessions = response.data;
                    }, function (response) {
                        $scope.errorHandler.process(response.data);
                    });
                }
                
                function formatDate (date) {
                    return moment(date).format("MMM Do");
                }
            }
        }
    }
})();
