angular.module('app').component('proceduresCard', {
    templateUrl: '/assets/html/procedure/proceduresCard.html',
    controller: ProceduresCardController,
    bindings: {
        container: "=",
        containerType: "@",
    }
});

function ProceduresCardController($mdDialog, $http, $mdToast, Organization, Procedure) {
    var ctrl = this;
    ctrl.ready = false;
    ctrl.procedures = [];

    ctrl.$onInit = function () {

        Organization.current.$promise.then(function (org) {
            ctrl.enabled = org.procedure.enabled;
            if (ctrl.enabled) {
                Procedure.procedures.query({ container: ctrl.container._id }).$promise.then(function (procedures) {
                    ctrl.procedures = procedures;
                    ctrl.ready = true;
                });
            }
        });


    };

    ctrl.viewProcedure = function (ev, procedure) {

        $mdDialog.show({
            templateUrl: '/assets/html/procedure/procedure-modal.html',
            controller: 'ProcedureModalController',
            parent: angular.element(document.body),
            locals: {
                procedure: procedure,
                isNew: false
            },
            bindToController: true,
            clickOutsideToClose: false,
            targetEvent: ev
        });

    };

    ctrl.newProcedure = function (ev) {

        var procedure = {
            container: ctrl.container,
            containerType: ctrl.container.type,
            date: {
                start: new Date(),
                dueAfter: 7
            }
        };

        Procedure.templates.query({ type: ctrl.container.type._id }).$promise.then(function (templates) {

            $mdDialog.show({
                templateUrl: '/assets/html/procedure/procedure-schedule-modal.html',
                controller: NewProcedureController,
                parent: angular.element(document.body),
                locals: {
                    procedure: procedure,
                    templates: templates
                },
                bindToController: true,
                clickOutsideToClose: false,
                targetEvent: ev
            }).then(function (procedure) {

                Procedure.procedures.create(procedure).$promise.then(function (result) {
                    if (result[0]) {
                        ctrl.procedures.push(result[0]);
                    }
                    if (result[1]) {
                        ctrl.procedures.push(result[1]);
                    }
                    $mdToast.show(
                        $mdToast.simple().textContent("Procedure created.")
                    );
                }, function (err, result) {
                    console.log("ERROR", err, result);
                });

            });

        });

        function NewProcedureController($scope, $mdDialog, procedure, templates) {
            $scope.procedure = procedure;
            $scope.templates = templates;
            $scope.container = ctrl.container;
            $scope.isNew = true;
            $scope.minDate = new Date();

            $scope.onSelectTemplate = function () {
                $scope.procedure.title = $scope.procedure.template.title;
                $scope.procedure.description = $scope.procedure.template.description;
            }

            $scope.submit = function () {
                if ($scope.scheduleForm.$valid) {
                    $mdDialog.hide(procedure);
                }
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        };

    }

    ctrl.editProcedure = function (ev, procedure) {

        $mdDialog.show({
            templateUrl: '/assets/html/procedure/procedure-schedule-modal.html',
            controller: ScheduleProcedureController,
            parent: angular.element(document.body),
            locals: {
                procedure: procedure,
                container: ctrl.container,
                isNew: false
            },
            bindToController: true,
            clickOutsideToClose: false,
            targetEvent: ev
        }).then(function (procedure) {


            console.log("api", procedure)


            $http.post(`/api/procedure/schedule/${procedure._id}`, procedure).then(console.log);


            // Procedure.procedures.schedule(procedure).$promise.then(function (result) {

            //     procedure.date.due = result.date.due;

            //     $mdToast.show(
            //         $mdToast.simple().textContent("Procedure saved.")
            //     );

            // }, function (err, result) {
            //     console.log("ERROR", err, result);
            // });

        });

        function ScheduleProcedureController($scope, $mdDialog, procedure) {
            $scope.procedure = procedure;
            $scope.container = ctrl.container;
            $scope.isNew = false;
            $scope.minDate = moment().add(1, 'days').toDate();

            $scope.submit = function () {
                console.log($scope.procedure);
                $mdDialog.hide($scope.procedure);
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };
        };

    }

    ctrl.removeProcedure = function (procedure) {

        var confirm = $mdDialog.confirm()
            .title('Confirm removal of this procedure?')
            .textContent(procedure.title)
            .ariaLabel('Remove Procedure')
            .ok('Yes, remove procedure')
            .cancel('No, cancel');

        $mdDialog.show(confirm).then(function () {
            var id = procedure._id;

            procedure.$delete().then(function (result) {
                ctrl.procedures = ctrl.procedures.filter(function (procedure) {
                    return procedure._id != id;
                });
                $mdToast.show(
                    $mdToast.simple().textContent("Procedure removed.")
                );
            });
        }, function () {
        });



    };

    ctrl.percentComplete = function (procedure) {
        var steps = procedure.steps.length;
        var complete = procedure.steps.reduce(function (total, step) {
            if (step.complete) {
                return total + 1;
            } else {
                return total;
            }
        }, 0);
        return complete / steps * 100;
    };

    ctrl.isOverdue = function (procedure) {
        return moment(procedure.date.due).isBefore(moment());
    }

}

