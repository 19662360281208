(function () {


    angular.module("app").component("monthSelect", {
        template: `
            <md-select ng-model="$ctrl.bindTo" ng-disabled="$ctrl.disabled">
                <md-option ng-if="$ctrl.allOption" ng-value="0"><em>All Months</em></md-option>
                <md-option ng-repeat="month in $ctrl.months" ng-value="month.month">
                    {{month.name}}
                </md-option>
            </md-select>
        `,

        bindings: {
            allOption: "<",
            bindTo: "=",
            disabled: "="
        },

        controller: function (Organization, Container) {
            var ctrl = this;

            ctrl.months = [
                {name:"January",month:1},
                {name:"February",month:2},
                {name:"March",month:3},
                {name:"April",month:4},
                {name:"May",month:5},
                {name:"June",month:6},
                {name:"July",month:7},
                {name:"August",month:8},
                {name:"September",month:9},
                {name:"October",month:10},
                {name:"November",month:11},
                {name:"December",month:12}
            ];

            ctrl.$onInit = function() {

                if (!ctrl.allOption) {
                    ctrl.allOption = false;
                }

            }
        }
    });
})();
