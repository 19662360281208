(function () {
    "use strict";

    angular.module("app").component("controlStateRadio", {
        bindings: {
            controlState: "=ngModel",
            ngChange: "&?",
            readOnly: "=?",
        },

        template: `
            <button-group ng-if="$ctrl.controlState.state.answers.length" on-select="$ctrl.select()" read-only="$ctrl.readOnly" elements="$ctrl.controlState.state.answers"></button-group>
        `,

        controller: function (Control) {
            var ctrl = this;

            ctrl.select = select;

            function select (value) {
                if (ctrl.readOnly) return;

                if (!value) {
                    value = ctrl.controlState.state.answers.find(function (a) {
                        return a.selected;
                    });
                }

                if (value) {
                    if (value.selected) {
                        ctrl.controlState.state.answer = value;
                    } else {
                        ctrl.controlState.state.answer = null;
                    }
                } else {
                    ctrl.controlState.state.answer = null;
                }

                if (ctrl.ngChange) ctrl.ngChange();
            }
        }
    })
})();
