angular.module('app').component('setupSoftware', {
    templateUrl: '/assets/html/setup/setup-software.html',
    controller: setupSoftware,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
        user: '<',
        technologies: '<'
    }
});

function setupSoftware($scope, $mdToast, $mdDialog, Technology, Container) {
    var ctrl = this;

    $scope.technologies = [];

    ctrl.$onInit = function() {

        $scope.Container = Container;
        $scope.organization = ctrl.organization;
        $scope.techs = [];

        ctrl.technologies.forEach( function(tech) {
            if(tech.type.category == "software") {
                $scope.techs.push(tech);
            }
        });

        Container.typeResource.query(
            {
                type: "technology",
                category: "software",
                useInSetup: true,
                industries: $scope.organization.industry._id,
            }).$promise.then(function (types) {

            types.forEach(function(type) {
                var found = false;
                $scope.techs.forEach(function(tech) {
                    if(tech.type._id == type._id) {
                        found = true;
                    }
                });

                if (!found) {
                    $scope.techs.push({
                        "name":type.title,
                        "type":type,
                        "new":true,
                        "checked":true
                    });
                }
            })

            $scope.techTypes = types;
        });

    }

    $scope.update = function() {

        var techPromises = [];
        $scope.techs.forEach(function(tech) {
            if(tech.new && tech.checked) {
                var newTech = new Technology.Resource();
                newTech.name = tech.name;
                newTech.type = tech.type;
                newTech.owner = ctrl.user;
                tech.new = false;
                techPromises.push(newTech.$save());
            }
        });

        Promise.all(techPromises).then(function(values) {
            showToast("Technologies saved.");
            ctrl.onNext();
        }, function(err, test) {
            // TODO: Add error handle
        });

    };


    $scope.checkTech = function(tech) {
        if (tech.new) {
            return tech.checked;
        } else {
            return true;
        }

    }

    $scope.toggleTech = function(tech) {
        if (tech.checked) {
            tech.checked = false;
        } else {
            tech.checked = true;
        }
        return tech.checked;
    }

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
