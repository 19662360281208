(function () {
    "use strict";

    angular.module("app").component("navToolbar", {
        transclude: true,

        template: `
            <md-toolbar class="page-header" ng-transclude></md-toolbar>
        `,

        controller: function ($element) {
            var ctrl = this;

            $("#primary-nav").after($($element));
        }
    })
})();
