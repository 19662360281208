(function () {
    angular.module("app").factory("Settings", function ($http) {
        const _cache = {};

        function Content() {
            var self = this;

            self.get = function (id, cb) {
                if (_cache[id]) {
                    return cb(null, _cache[id]);
                }

                $http.get("/api/settings/" + id).then(response => {
                    _cache[id] = response.data;
                    cb(null, _cache[id].value);
                }).catch(response => {
                    cb(response.data);
                });
            }
        }

        return new Content();
    });
})();