(function () {
    angular.module("app").factory("DeviceIcons", DeviceIcons);
    
    function DeviceIcons () {
        return {
            deriveClass: deriveClass
        }
        
        function deriveClass(userAgent) {
            if (userAgent) {
                if (userAgent.isSmartTV) return "fa fa-tv";
                if (userAgent.isKindleFire) return "fa fa-amazon";
                if (userAgent.isAndroid) return "fa fa-android";
                if (userAgent.isLinux64 || userAgent.isLinux || userAgent.isRaspberry) return "fa fa-linux";
                if (userAgent.isMac || userAgent.isiPhone || userAgent.isiPod || userAgent.isiPad) return "fa fa-apple";
                if (userAgent.isWindows) return "fa fa-windows";
                if (userAgent.isMobile) return "fa fa-mobile";
                if (userAgent.isTablet) return "fa fa-tablet";
                if (userAgent.isDesktop) return "fa fa-desktop";
            }

            return "fa fa-question";
        }
    };
})();