(function () {
    angular.module("app").controller("RequirementController", ($scope, $http, $element, Import) => {
		$scope.id = $element[0].attributes["requirement-id"].value;

		$http.get("/api/compliance/requirements/" + $scope.id).then(response => {
			$scope.requirement = response.data;
		}).catch();

		$scope.import = ($event) => {
            Import.import($event, {
                title: "Import " + $scope.requirement.title + " Specifications",
                endpoint: "/api/compliance/requirements/" + $scope.requirement._id + "/specifications/import",
				replace: true
            }, $scope.requirement.specifications, function () {

			});
		}
	});
})();
