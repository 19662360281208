(function () {
    "use strict";

    angular.module("app").component("cardActions", {
        transclude: true,

        bindings: {
            align: "@?"
        },

        template: `
            <div class="card-actions">
                <div ng-class="{'pull-right': $ctrl.align == 'right'}" ng-transclude></div>
            </div>
        `,
    })
})();
