(function () {

	angular.module("app").component("userTable", {
		/* html */
		template: `
			<md-card>
				<table class="material">
					<thead>
						<tr>
							<th>Name</th>
							<th>Email</th>
							<th>Status</th>

							<th class="center">Tasks</th>
							<th ng-if="$ctrl.features.policy" class="center">Policy</th>
							<th ng-if="$ctrl.features.documents" class="center">Documents</th>
							<th ng-if="$ctrl.features.training" class="center">Training</th>

							<th class="action">
								<md-menu>
									<md-button class="md-icon-button md-button padding-0-r margin-0-r" type="button" ng-click="$mdMenu.open($event)" >
										<i class="fa fa-ellipsis-v"></i>
									</md-button>

									<md-menu-content class="padding-0" width="3">
										<md-menu-item ng-if="$ctrl.licensed">
											<md-button ng-click="$ctrl.resendNotifications()">
												<md-icon md-font-icon="fa-check-square" class="fa"></md-icon>
												<span>Resend All Notifications</span>
											</md-button>		
										</md-menu-item>	
									</md-menu-content>
								</md-menu>
							</th>
						</tr>
					</thead>

					<tbody>
						<tr ng-click="$ctrl.onRowClick(user)" 
						ng-repeat="user in $ctrl.users | orderBy:'+profile.name' | filter:$ctrl.customFilter() | startAt:$ctrl.perPage*$ctrl.page | limitTo:50"
						ng-style="$ctrl.getStyle(user)">
							<td>
								<span class="image-overlay margin-r">
									<img style="width: 30px; height: 30px;" class="avatar-img" ng-src="{{user.profile.avatar.url}}">
									<span ng-if="user.profile.avatar._id == 'user.avatar.default'" class="text">{{$ctrl.initials(user)}}</span>
								</span>

								<span>{{user.profile.name}}</span>
							</td>

							<td>{{user.email || user.username}}</td>

							<td>{{$ctrl.getState(user)}}</td>

							<td class="center">
								<chip type="xs">{{user.numTasks}}</chip>
							</td>

							<td ng-if="$ctrl.features.policy" class="center">
								<chip type="xs {{user.activity.policiesAcknowledged ? '' : 'danger'}}">{{user.activity.numPoliciesAcknowledged}}/{{user.activity.numPolicies}}</chip>
							</td>

							<td ng-if="$ctrl.features.documents" class="center">
								<chip type="xs {{user.activity.documentsAcknowledged ? '' : 'danger'}}">{{user.activity.numDocumentsAcknowledged}}/{{user.activity.numDocuments}}</chip>
							</td>

							<td ng-if="$ctrl.features.training" class="center">
								<chip type="xs {{user.activity.trainingAcknowledged ? '' : 'danger'}}">{{user.activity.numTrainingAcknowledged}}/{{user.activity.numTraining}}</chip>
							</td>

							<td class="action">
								<md-menu ng-if="!$ctrl.isAuditor" md-position-mode="target-right target" md-offset="0 50">
									<md-button ng-click="$mdMenu.open($event)" class="md-icon-button">
										<i class="fas fa-ellipsis-v"></i>
									</md-button>

									<md-menu-content>
										<md-menu-item ng-if="user.email && $ctrl.licensed">
											<md-button ng-click="$ctrl.resendNotifications(user)">
												<md-icon md-font-icon="fa-check-square" class="fa"></md-icon>
												<span>Resend Notifications</span>
											</md-button>		
										</md-menu-item>	
										
										<md-menu-item ng-if="user.email && user.status.state == 'pending'">
											<md-button ng-click="$ctrl.resendInvitation(user)">
												<md-icon md-font-icon="fa-envelope" class="fa"></md-icon>
												<span>Resend User Inivitation</span>
											</md-button>
										</md-menu-item>

										<md-menu-item ng-if="$ctrl.clickable !== false && $ctrl.licensed">
											<md-button ng-href="/user/{{user._id}}">
												<md-icon md-font-icon="fa-eye" class="fa"></md-icon>
												<span>Details</span>
											</md-button>
										</md-menu-item>

										<md-menu-item ng-if="user.status.state != 'archived'">
											<md-button ng-click="$ctrl.editUser(user)">
												<md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
												<span>Edit</span>
											</md-button>
										</md-menu-item>

										<md-menu-item ng-show="$ctrl.currentId != user._id && user.status.state != 'archived'">
											<md-button ng-if="!user.disabled" ng-click="$ctrl.disableUser(user)">
												<md-icon md-font-icon="fa-times" class="fas"></md-icon>
												<span>Disable</span>
											</md-button>

											<md-button ng-if="user.disabled" ng-click="$ctrl.disableUser(user)">
												<md-icon md-font-icon="fa-check" class="fas"></md-icon>
												<span>Enable</span>
											</md-button>
										</md-menu-item>

										<md-menu-item ng-show="$ctrl.currentId != user._id && user.status.state != 'pending'">
											<md-button ng-if="user.status.state != 'archived'" ng-click="$ctrl.archiveUser(user)">
												<md-icon md-font-icon="fa-file-download" class="fas"></md-icon>
												<span>Archive</span>
											</md-button>
		
											<md-button ng-if="user.status.state == 'archived'" ng-click="$ctrl.archiveUser(user)">
												<md-icon md-font-icon="fa-file-upload" class="fas"></md-icon>
												<span>Restore</span>
											</md-button>
										</md-menu-item>

										<md-menu-item>
											<md-button ng-click="$ctrl.deleteUser(user)">
												<md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
												<span>Delete</span>
											</md-button>
										</md-menu-item>
									</md-menu-content>
								</md-menu>
							</td>
						</tr>
					</tbody>
				</table>

				<pagination ng-if="$ctrl.users.length" items="$ctrl.usersFiltered().length" per-page="$ctrl.perPage" page="$ctrl.page"></pagination>
			</md-card>
		`,

		bindings: {
			users: "=",
			filter: "=",
			organization: "=?",
			clickable: "=?",
		},

		controller: function ($scope, $mdDialog, $mdToast, $http, $filter, Session, Users, Assessment, Organization, User) {
			var ctrl = this;


			ctrl.Assessment = Assessment;

			ctrl.page = 0;
			ctrl.perPage = 50;

			Session.currentUser.$promise.then(function (user) {
				ctrl.currentId = user._id;
			});

			ctrl.$onInit = () => {
				User.checkRoles(["auditor"]).then(result => {
					ctrl.isAuditor = result;
				});
			}

			if (!ctrl.organization) {
				Organization.current.$promise.then(result => {
					ctrl.organization = result;
					setup();
				});
			} else {
				setup();
			}

			function setup() {
				ctrl.licensed = ctrl.organization.status.licensed;

				ctrl.features = {
					policy: ctrl.organization.policy.enabled,
					documents: ctrl.organization.documents.enabled,
					training: ctrl.organization.training.enabled,
					awareness: ctrl.organization.awareness.enabled,
				}
			}


			ctrl.getStyle = function (user) {
				if (user.status.state == "archived") {
					return {
						opacity: .25
					}
				}

				return {};
			}

			ctrl.customFilter = function () {
				return function (container) {
					if (!ctrl.filter) {
						return false;
					}

					if (container.status.state == "archived") {
						if (!ctrl.filter.archived) {
							return false;
						}
					}

					if (_.get(ctrl.filter, "profile.name")) {
						return container.profile.name.toLowerCase().includes(ctrl.filter.profile.name.toLowerCase());
					}

					return true;
				}
			}

			ctrl.usersFiltered = function () {
				const f = ctrl.customFilter();

				return ctrl.users.filter(f);
			}

			ctrl.onRowClick = function (user) {
				if (ctrl.clickable === false) {
					return;
				}

				if (ctrl.licensed) {
					window.location = "/user/" + user._id
				}
			}

			ctrl.initials = function (user) {
				return user.profile.firstName.charAt(0) + user.profile.lastName.charAt(0);
			}

			//TODO: move these to user factory?
			ctrl.getState = function (user) {
				if (user.status.state == "archived") {
					return "Archived";
				}

				if (user.disabled) {
					return "Disabled";
				}

				if (user.status.state == "pending") {
					return "Pending";
				}

				if (user.emailonly) {
					return "Email";
				}

				return "Active";
			}

			//Actions
			ctrl.editUser = function (user) {
				Users.userModal(user, Organization.current).then(u => {
					user = u;

					var users = Users.resource.query();

					users.$promise.then(function () {
						ctrl.users = users;
					});
				});
			}

			ctrl.disableUser = function (user) {
				$http.post(`/api/user/${user._id}/disable`).then(result => {
					const state = result.data.disabled;

					user.disabled = state;

					if (state) {
						ctrl.showToast("User disabled");
					} else {
						ctrl.showToast("User enabled");
					}
				}).catch(error => {
					console.error(error);
					ctrl.showToast("User could not be disabled");
				});
			}

			ctrl.archiveUser = function (user) {
				Users.archiveUser(user);
			}

			ctrl.deleteUser = function (user) {
				Users.deleteUser(user, ctrl.users);
			}

			
			ctrl.resendNotifications = function (user) {
				let userId = "";

				if (user) {
					userId = user._id;
				}

				$mdDialog.show($mdDialog.confirm({
					title: "Please Confirm",
					textContent: "Resend notifications?",
					ok: "Confirm",
					cancel: "Cancel"
				})).then(function () {
					$http.post("/api/users/resend-notifications/" + userId).
						then(function (result) {
							if (result.data.success) {
								$mdToast.show($mdToast.simple().textContent("Notifications resent"));
							} else {
								$mdToast.show(
									$mdToast
										.simple()
										.hideDelay(0)
										.action('Close')
										.highlightAction(true)
										.textContent("Error: " + result.data.message)
								);
							};
						}).catch(function () {
							$mdToast.show(
								$mdToast
									.simple()
									.hideDelay(0)
									.action('Close')
									.highlightAction(true)
									.textContent("Error")
							);
						});
				}).catch()
			}

			ctrl.resendInvitation = function (user) {
				$mdDialog.show($mdDialog.confirm({
					title: "Please Confirm",
					textContent: "Resend invitation?",
					ok: "Confirm",
					cancel: "Cancel"
				})).then(function () {
					$http.post("/api/users/resend-invite/" + user._id).then(function (result) {
						if (result.data.success) {
							$mdToast.show($mdToast.simple().textContent("Invitation resent"));
						} else {
							$mdToast.show(
								$mdToast
									.simple()
									.hideDelay(0)
									.action('Close')
									.highlightAction(true)
									.textContent("Invite NOT sent. " + result.data.message)
							);
						}
					}).catch(function () {
						$mdToast.show(
							$mdToast
								.simple()
								.hideDelay(0)
								.action('Close')
								.highlightAction(true)
								.textContent("Error resending invitation")
						);
					});
				}).catch();
			}

			//Util
			ctrl.showToast = function (msg) {
				$mdToast.show(
					$mdToast.simple()
						.textContent(msg)
						.hideDelay(3000)
				);
			}
		},

	});
})();