/**
 * @ngdoc component
 * @name app.control.component:controlStateMetrics
 * @author Nick Vorie
  *
 * @param {object} controlState The ControlState to generate metrics for
 *
 * @description This component will render a series of metrics for a given ControlState
 */
(function () {
    "use strict";

    angular.module("app").component("controlStateMetrics", {
        bindings: {
            controlState: "=",
        },

        template: `
            <div layout="column" layout-fill class="padding-3">
                <div layout="row" layout-align="end start">
                    <div ng-if="$ctrl.controlState.threatScores.length" flex="50" class="padding-4-x" layout="column">
                        <h6>Threats</h6>

                        <div ng-repeat="pair in $ctrl.controlState.threatScores" class="padding-4-y">
                            <threat-chip threat-pair="pair"></threat-chip>
                        </div>
                    </div>

                    <div ng-if="$ctrl.controlState.control.specifications.length" flex="50" class="padding-4-x" layout="column">
                        <div ng-if="$ctrl.renderSpecs">
                            <h6>Compliance</h6>
                            <compliance-chips ng-if="$ctrl.organization.compliance.requirements" context="specifications" specs="$ctrl.controlState.control.specifications" requirements="$ctrl.organization.compliance.requirements" issue="!$ctrl.ControlState.inPlace($ctrl.controlState)"></compliance-chips>
                        </div>
                    </div>
                </div>
            </div>
        `,

        controller: function (ControlState, Cvss, Session) {
            var ctrl = this;

            ctrl.ControlState = ControlState;
            ctrl.Cvss = Cvss;

			Session.current.$promise.then(() => {
            	ctrl.organization = Session.current.organization;
                ctrl.renderSpecs = !!ctrl.organization.compliance.requirements.length;
			});
        }
    })
})();
