(function () {
	angular.module("app").factory("Instruction", ($http, $mdToast) => {
		function Instruction() {
			var self = this;

			self.get = (id, cb) => {
				$http.get("/api/instruction/" + id).then(response => {
					cb(null, response.data);
				}).catch(response => {
					cb(response.data);
				});
			}

		}

		return new Instruction();
	});
})();