(function () {
    "use strict";

    angular.module("app").component("metricRangeGauge", {
        bindings: {
            label: "@?",
            min: "@?",
            max: "@?",
            unit: "@?",
        },

        template: `

            <div flex layout="row" layout-align="center center" style="height: 50px;">
                <canvas class="chart" width="150" height="50"></canvas>
            </div>

            <div flex layout="row" layout-align="center center" style="font-size: 11px; position: relative; top: -17px;">
                <span ng-if="$ctrl.min == $ctrl.max || !($ctrl.min && $ctrl.max)">{{$ctrl.max || $ctrl.min}}</span>
                <span ng-if="$ctrl.min != $ctrl.max && ($ctrl.min && $ctrl.max)">{{$ctrl.min}} - {{$ctrl.max}}</span>
            </div>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            ctrl.widthModifier = .3;

            ctrl.$onInit = function () {
                setTimeout(function () {
                    var ctx = $element.find("canvas")[0].getContext("2d");
                    var config = {
                        type: "doughnut",

                        data: {
                        },

                        options: {
                            responsive: false,
                            rotation: 1 * Math.PI,
                            circumference: 1 * Math.PI,

                            hover: {
                               intersect: false
                            },

                            legend: {
                                display: false,
                                onHover: function(e, legendItem){},
                                onClick: function(e, legendItem){},
                            },

                            tooltips: {
                                enabled: false
                            },

                            animation: {
                                animateScale: true,
                                animateRotate: true
                            },

                            layout: {
                                padding: {
                                    left: 0,
                                    right: 0,
                                    top: 0,
                                    bottom: 0,
                                }
                            },
                        }
                    };

                    ctrl.chart = new Chart(ctx, config);

                    setData();

                    ctrl.$onChanges = function () {
                        setData();
                    }
                }, 200);

                function setData () {
                    var offsetLeft, width, offsetRight;

                    ctrl.severity = Cvss.getSeverity([ctrl.min, ctrl.max]);

                    if (ctrl.min && ctrl.max) {
                        offsetLeft = ctrl.min;
                        width = ctrl.max - ctrl.min;
                        offsetRight = 10 - ctrl.max;
                    } else {
                        var value = ctrl.min || ctrl.max;

                        width = ctrl.widthModifier;
                        offsetLeft = value - ctrl.widthModifier;
                        offsetRight = 10 - value - ctrl.widthModifier;
                    }

                    ctrl.chart.data.datasets = [{
                        data: [offsetLeft, width, offsetRight],
                        backgroundColor: ["grey", ctrl.severity.color, "grey"],
                    }];

                    ctrl.chart.update();
                }
            }
        }
    });
})();
