(function () {
    "use strict";

    angular.module("app").controller("EditPaymentInfoController", function ($scope, $element, $mdToast, $mdDialog, ECommerce, paymentMethod) {

        $scope.processing = false;

        this.$onInit = function () {

            $scope.submitButton = $element.find("#paymentSubmitButton")[0];
            $scope.dropInContainer = $element.find("#dropin-container")[0];

            ECommerce.resource.getToken().$promise.then( function (result) {

                braintree.dropin.create({
                    authorization: result.token,
                    container: '#dropin-container',
                    card: {
                        cardholderName: {
                            required: false
                        }
                      }
                    }, function (createErr, btInstance) {
                    if (createErr) {
                        $mdToast.show(
                            $mdToast.simple()
                                .content('ERROR: Unable to update payment at this time.  Please try again or contact support. \n reference: ' + err.data.reference)
                                .position('top left')
                                .theme("error")
                                .action('CLOSE')
                                .hideDelay(0)
                        );
                        $mdDialog.cancel();
                        console.log(createErr);
                    } else {
                        $scope.btInstance = btInstance;

                        btInstance.on('paymentMethodRequestable', function (event) {
                            $scope.submitButton.removeAttribute('disabled');
                        });

                        btInstance.on('noPaymentMethodRequestable', function () {
                            $scope.submitButton.setAttribute('disabled', true);
                        });

                    }
                });

            });
        } 

        $scope.submitPaymentInfo = function() {
            $scope.submitButton.setAttribute('disabled', true);
            $scope.dropInContainer.style.opacity = "0";

            $scope.btInstance.requestPaymentMethod(function (err, result) {

                if (err) {
                    console.log(err);
                    $mdToast.show(
                        $mdToast.simple()
                            .content('ERROR: Unable to update payment method at this time.  Please try again or contact support. \n reference: ' + err.data.reference)
                            .position('top left')
                            .theme("error")
                            .action('CLOSE')
                            .hideDelay(0)
                    );
                    $mdDialog.cancel();
                } else {
                    ECommerce.resource.updatePaymentMethod(result).$promise.then( function (result) {
                        $mdDialog.hide(result);

                        $mdToast.show($mdToast.simple().textContent("Account updated."));
                    }, function(err, result) {
                        console.log("save didn't work ", err, result);
                        $mdDialog.hide();
                    });
                }
 
            });

        }

        $scope.cancel = function() {
            $mdDialog.cancel();
        }

		$scope.cancelSubscription = function (ev) {

            var confirm = $mdDialog.confirm()
              .title('Are you sure you want to cancel your VSC subscription?')
              .textContent('Your account will remain active until the expiration date.')
              .targetEvent(ev)
              .ok("Yes, Cancel Account")
              .cancel('No, Not Now');

            $mdDialog.show(confirm).then(function() {
                $mdToast.show($mdToast.simple().textContent("Account canceled."));
            }, function() {
            });


			$mdDialog.cancel();
		}
    });

})();
