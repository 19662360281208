(function () {
    angular.module("app").factory("Layout", function () {
        function Layout () {
            var self = this;

            self.container = null;
            self.drawer = null;
            self.drawerState = false;
            self.togglingDrawer = false;

            self.navItems = [
            ];

            self.toggleNav = function () {
                if (self.togglingDrawer) return;

                if (self.drawerState) {
                    self.refocusContainer();
                } else {
                    self.openDrawer();
                }

                self.drawerState = !self.drawerState;
            }

            self.openDrawer = function () {
                if (self.togglingDrawer) return;
                setLock();

                self.drawer.addClass("in");
                self.container.addClass("in");

                resetLock();
            }

            self.refocusContainer = function () {
                if (self.togglingDrawer || !(self.drawer && self.container)) return;
                setLock();

                self.drawer.removeClass("in");
                self.container.removeClass("in");

                resetLock();
            }

            function setLock () {
                self.togglingDrawer = true;
            }

            function resetLock () {
                setTimeout(function () {
                    self.togglingDrawer = false;
                }, 100);
            }

        }

        return new Layout();
    });
})();
