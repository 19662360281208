(function () {
    angular.module("app").controller("ActivateAccountController", ActivateAccountController);

    function ActivateAccountController($scope, $http, $timeout, Error) {
        $scope.errorHandler = Error.get("activate-account");

        $scope.data = {};
        $scope.initData = {};
        
        $scope.form = {
            password: "",
            passwordRepeat: ""
        };

        $scope.loadToken = loadToken;
        $scope.activateAccount = activateAccount;

        function loadToken(userId, secret) {
            $http.get("/api/auth/activate/" + userId + "/" + secret).then(function (response) {
                $scope.data = response.data;
            }, function (response) {
                $scope.errorHandler.process(response.data);
            });
        }

        function activateAccount() {
            $scope.errorHandler.clear();

            $http.post("/api/auth/activate/" + $scope.data.user._id + "/" + $scope.data.token.secret, $scope.form).then(function (response) {
                $scope.form = {};
                $scope.errorHandler.setSuccess("Your account has been activated, redirecting to login...");
                
                $timeout(function () {
                    window.location = "/login";
                }, 500);
            }, function (response) {
                $scope.errorHandler.process(response.data);
            });
        }
    };
})();