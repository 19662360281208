(function () {
    angular.module("app").controller("Sites", OrganizationSites);

    function OrganizationSites ($scope, Site, Container, $mdToast, $mdDialog) {

        $scope.sites = [];

        $scope.Container = Container;

        $scope.sites = Container.query("site");

//        Site.Resource.query().$promise.then(function (sites) {
//                $scope.sites = sites;
//        });

    }
})();
