(function () {
    angular.module("app").component("account", {
        bindings: {
        },
        template: `
            <div layout="row" ayout-align="center center">
                <billing-plan ng-if="$ctrl.ready" organization="$ctrl.organization" subscription="$ctrl.subscription" flex="50"></billing-plan>
                <payment-info ng-if="$ctrl.ready && $ctrl.subscription" organization="$ctrl.organization" subscription="$ctrl.subscription" flex="50"></payment-info>
            </div>
            <invoices ng-if="$ctrl.ready && $ctrl.subscription" subscription="$ctrl.subscription"></invoices>
        `,

        controller: function (Organization, ECommerce) {
            var ctrl = this;
            ctrl.ready = false;
            ctrl.type = '';
            ctrl.managed = false;
            ctrl.trial = false;

            ctrl.$onInit = function () {

                Organization.current.$promise.then(function (org) {
                    ctrl.organization = org;
                    ctrl.type = org.license.type;

                    if (ctrl.type == 'subscription') {
                        ECommerce.resource.getSubscription().$promise.then(function (subscription) {
                            ctrl.subscription = subscription;
                            ctrl.ready = true;
                        }, function (err, result) {
                            console.log("cannot get payment info", err, result);
                        });
                    } else {
                        if (ctrl.type == 'managed') {
                            ctrl.managed = true;
                        } else {
                            ctrl.trial = true;
                        }
                        ctrl.ready = true;
                    }
                });

            }

        },

    });
})();
