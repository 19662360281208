(function () {
    "use strict";

    angular.module("app").factory("Training", TrainingFactory);

    function TrainingFactory($resource, $http, $mdDialog) {

        function TrainingProvider() {
            var self = this;

            self.resource = $resource(
                "/api/training/:id",
                {
                    id: "@_id"
                },
                {
                    update: {
                        method: 'POST',
                        url: '/api/training/:id',
                        isArray: false
                    },
                    sendNow: {
                        method: 'POST',
                        url: '/api/training/:id/sendNow',
                        isArray: false
                    }
                }
            );

            self.acknowledgeResource = $resource(
                "/api/training/acknowledge",
                {},
                {
                    acknowledge: {
                        method: 'POST',
                        url: '/api/training/acknowledge',
                        isArray: false
                    }
                }
            );

            self.forUser = $resource("/api/training/forUser");

            self.trainingAndUser = $resource("/api/training/:trainingId/:userId/:orgId", {
                trainingId: "xxx",
                userId: "xxx",
                orgId: "xxx"
            });

            self.openTrainingModal = function (training, optionsIn) {

                var options = _.defaults(optionsIn, {
                    showAcknowledge: !training.acknowledged,
                    title: training.title,
                    clickOutsideToClose: false,
                    showDontShowAgain: false
                });

                return $mdDialog.show({
                    templateUrl: "/assets/html/training/trainingModal.html",
                    controller: trainingModalController,
                    parent: angular.element(document.body),
                    clickOutsideToClose: options.clickOutsideToClose,
                    locals: {
                        training,
                        options
                    }
                });
            }

            function trainingModalController($scope, $mdDialog, Training, training, options) {

                $scope.training = training;
                $scope.showAcknowledge = options.showAcknowledge;
                $scope.title = options.title;
                $scope.showDontShowAgain = options.showDontShowAgain;
                $scope.data = {
                    dontShowAgain: false
                };

                $scope.cancelVideoDialog = function () {
                    $('video').trigger('pause');
                    $mdDialog.cancel();
                };

                $scope.closeVideoDialog = function () {
                    $('video').trigger('pause');

                    if ($scope.data.dontShowAgain) {
                        Training.acknowledgeResource.acknowledge({ trainingId: $scope.training._id }).$promise.then(function (result) {
                            $mdDialog.hide($scope.training);
                        });
                    } else {
                        $mdDialog.hide($scope.training);
                    }

                };

                $scope.acknowledgeTraining = function (training) {
                    $('video').trigger('pause');

                    var data = {};
                    data.trainingId = $scope.training._id;

                    Training.acknowledgeResource.acknowledge(data).$promise.then(function (result) {
                        $scope.training.acknowledged = true;
                    });

                    $mdDialog.hide(training);
                };

                $scope.canAcknowledge = false;

                setTimeout(() => {
                    $("video").bind("timeupdate", function () {
                        var currentTime = this.currentTime;
                        var percentage = (currentTime / this.duration) * 100;

                        if (percentage >= 90) {
                            $scope.canAcknowledge = true;
                        }

                        $scope.$apply();
                    });
                }, 1500);
            }

            return self;
        }



        return new TrainingProvider();
    }



})();
