(function () {

    angular.module("app").component("pagination", {
        template: `
			<div layout="row" class="padding-y-2">
				<div flex></div>

				<md-button ng-click="$ctrl.setPage(0)">First</md-button>

				<md-button ng-if="$ctrl.moreBefore" class="margin-0" disabled>...</md-button>

				<md-button ng-repeat="page in $ctrl.pages" ng-class="{'md-fab': ($ctrl.page == page)}" ng-click="$ctrl.setPage(page)">{{page + 1}}</md-button>

				<md-button ng-if="$ctrl.moreAfter" class="margin-0" disabled>...</md-button>

				<md-button ng-click="$ctrl.setPage($ctrl.totalPages - 1)">Last</md-button>

				<div flex></div>
			</div>
		`,

        bindings: {
            items: "=",

            page: "=",
            perPage: "=",
            pagesDisiplayed: "@",
        },

        controller: function ($scope) {
			var ctrl = this;

			var prevItems = 0;
			var prevPage = 0;

			ctrl.$onInit = () => {
				if (!ctrl.pagesDisplayed) {
					ctrl.pagesDisplayed = 5;
				}

				prevItems = ctrl.items;

				ctrl.totalPages = Math.ceil(ctrl.items / ctrl.perPage);
				calculatePages();
			}

			ctrl.$doCheck = () => {
				if (prevItems != ctrl.items) {
					prevItems = ctrl.items;

					ctrl.totalPages = Math.ceil(ctrl.items / ctrl.perPage);
					ctrl.page = 0;

					calculatePages();
				}
			}

			ctrl.setPage = (page) => {
				ctrl.page = page;
				calculatePages();
			}

			function calculatePages () {
				var toGenerate = ctrl.pagesDisplayed - 1;
				var maxDirection = Math.ceil(toGenerate / 2);

				var before = ctrl.page - maxDirection;
				var beforeExtra = 0;

				if (0 > before) {
					beforeExtra = Math.abs(before);
					before = 0;
				}

				var after = ctrl.page + maxDirection + 1;
				var afterExtra = 0;

				if (beforeExtra) {
					after += _.min([maxDirection, beforeExtra])
				}

				if (after > ctrl.totalPages) {
					afterExtra = after - ctrl.totalPages;
					after = ctrl.totalPages;
				}

				if (afterExtra) {
					before -= _.min([maxDirection, afterExtra]);

					if (0 > before) {
						before = 0;
					}
				}

				ctrl.moreBefore = before > 0;
				ctrl.moreAfter = after < ctrl.totalPages;

				var pages = [];

				for (var p = before; p < after; p++) {
					pages.push(p);
				}

				ctrl.pages = pages;
			}
		}
    });
})();
