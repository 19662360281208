(function () {
	angular.module("app").component("trainingConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Training</h3>

					<span class="md-subhead md-block">
						<content content-id="config.training.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<form name="TrainingConfigurationForm">
					<div ng-if="$ctrl.organization.awareness.enabled" layout layout-align="start start">
						<md-input-container flex="25">
							<md-checkbox ng-model="$ctrl.organization.awareness.sendAwareness.enabled" aria-label="Awareness Reminders">Send <b>awareness</b> reminders </md-checkbox>
						</md-input-container>
				
						<md-input-container ng-if="false" flex="20">
							<label>start on</label>
				
							<md-select ng-model="$ctrl.organization.awareness.sendAwareness.on" ng-required="$ctrl.organization.awareness.sendAwareness.enabled" ng-disabled="!$ctrl.organization.awareness.sendAwareness.enabled">
								<md-option value="Sunday">Sunday</md-option>
								<md-option value="Monday">Monday</md-option>
								<md-option value="Tuesday">Tuesday</md-option>
								<md-option value="Wednesday">Wednesday</md-option>
								<md-option value="Thursday">Thursday</md-option>
								<md-option value="Friday">Friday</md-option>
								<md-option value="Saturday">Saturday</md-option>
							</md-select>
						</md-input-container>
				
						<md-input-container ng-if="false" flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="8:00 AM" ng-model="$ctrl.organization.awareness.sendAwareness.at" ng-required="$ctrl.organization.awareness.sendAwareness.enabled" ng-disabled="!$ctrl.organization.awareness.sendAwareness.enabled">
						</md-input-container>
				
						<md-input-container flex="20">
							<label>send every</label>

							<md-select ng-model="$ctrl.organization.awareness.sendAwareness.every" ng-required="$ctrl.organization.awareness.sendAwareness.enabled" ng-disabled="!$ctrl.organization.awareness.sendAwareness.enabled">
								<md-option value="1 day">Day</md-option>
								<md-option value="7 days">Week</md-option>
								<md-option value="2 weeks">Two Weeks</md-option>
								<md-option value="1 month">Month</md-option>
							</md-select>
						</md-input-container>
				
						<span flex></span>
					</div>
				
					<div ng-if="$ctrl.organization.training.enabled" layout layout-align="start start">
						<md-input-container flex>
							<md-checkbox ng-model="$ctrl.organization.training.sendOnboarding" aria-label="Onboarding Training">Assign onboarding <b>training</b> for new users </md-checkbox>
						</md-input-container>
					</div>
				
					<div ng-if="$ctrl.organization.training.enabled" layout layout-align="start start">
						<md-input-container flex="40">
							<md-checkbox ng-model="$ctrl.organization.training.sendPeriodic" aria-label="Periodic Training">Assign periodic <b>training</b> every 3 months after hire</md-checkbox>
						</md-input-container>
				
						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.training.sendPeriodicAt" ng-required="$ctrl.organization.training.sendPeriodic" ng-disabled="!$ctrl.organization.training.sendPeriodic">
						</md-input-container>
					</div>
				
					<div ng-if="$ctrl.organization.training.enabled" layout layout-align="start start">
						<md-input-container flex="30">
							<md-checkbox ng-model="$ctrl.organization.training.sendRefresher" aria-label="Refresher Training">Assign annual refresher <b>training</b> daily </md-checkbox>
						</md-input-container>
				
						<md-input-container flex="15">
							<label>at (UTC)</label>
							<input type="text" placeholder="9:00 AM" ng-model="$ctrl.organization.training.sendRefresherAt" ng-required="$ctrl.organization.training.sendRefresher" ng-disabled="!$ctrl.organization.training.sendRefresher">
						</md-input-container>
					</div>
				</form>
			</md-card-content>
        `,

		controller: function () {
			var ctrl = this;
		},
	});
})();