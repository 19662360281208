(function () {
    "use strict";

    angular.module("app").component("controlStateCard", {
        bindings: {
            state: "=",
            containerId: "=?",
            containerType: "@",
            readOnly: "=?",
            changeState: "=?",
        },

        require: {
            parent: "^^controlState"
        },

        /* html */
        template: `
<md-card layout="row">
    <div class="padding-2-b" flex layout="column" layout-align="space-between start">
        <control-card-header context="control-state" control="$ctrl.state.control"></control-card-header>

        <control-state-radio class="margin-2-x" ng-if="$ctrl.state != undefined" ng-model="$ctrl.state"
            ng-change="$ctrl.updateControlState()" read-only="$ctrl.readOnly"></control-state-radio>

        <div class="margin-3-x"
            ng-if="!$ctrl.state.state.answer._id || ['not-in-place', 'dont-know'].includes($ctrl.state.state.answer._id)">
            <md-switch class="md-primary" ng-change="$ctrl.updateControlState()" ng-model="$ctrl.state.state.accepted"
                ng-disabled="$ctrl.readOnly">Accept Risk</md-switch>

            <div ng-if="$ctrl.state.state.accepted">
                <user-select flex="100" label="Accepted By" bind-to="$ctrl.state.state.acceptedBy"></user-select>
            </div>
        </div>

        <attributes ng-if="false && $ctrl.state.state.answer._id == 'in-place'" ng-model="$ctrl.state.state.attributes"
            ng-change="$ctrl.updateControlState()" read-only="$ctrl.readOnly"></attributes>

        <span flex="grow"></span>

        <div ng-if="$ctrl.state.state.answeredBy" class="margin-2-a text-muted" layout="column">
            <small>Last set by {{$ctrl.state.state.answeredBy.profile.name}} at
                {{$ctrl.state.state.answeredAt | dateFormat:"altTime"}}</small>
        </div>
    </div>

    <md-divider></md-divider>

    <md-tabs flex md-border-bottom md-dynamic-height>
        <md-tab ng-if="$ctrl.state.state.answer._id != 'na' && $ctrl.state.control.risk" id="risk">
            <md-tab-label>
                <md-tooltip md-direction="left">Risk</md-tooltip>
                <icon type="tachometer"></icon>
            </md-tab-label>

            <md-tab-body>
                <control-state-metrics control-state="$ctrl.state"></control-state-metrics>
            </md-tab-body>
        </md-tab>

        <md-tab id="remediation" ng-if="$ctrl.state.state.answer._id != 'na'">
            <md-tab-label>
                <md-tooltip md-direction="left">Remediation</md-tooltip>
                <icon type="cogs"></icon>
            </md-tab-label>

            <md-tab-body>
                <remediation-builder container="$ctrl.parent.container" control-state="$ctrl.state">
                </remediation-builder>
            </md-tab-body>
        </md-tab>

        <md-tab id="comments">
            <md-tab-label>
                <md-tooltip md-direction="left">Comments</md-tooltip>
                <icon type="comment">
                    <badge ng-if="$ctrl.state.comments.length">{{$ctrl.state.comments.length}}</badge>
                </icon>
            </md-tab-label>

            <md-tab-body>
                <messages messages="$ctrl.state.comments"
                    endpoint="{{$ctrl.containerType}}/{{$ctrl.containerId}}/control-state/{{$ctrl.state.control._id}}/comment"
                    endpoint-query="select[]=controlState" read-only="$ctrl.readOnly"></messages>
            </md-tab-body>
        </md-tab>
    </md-tabs>
</md-card>
`,

        controller: function ($http, $element, $filter, $mdToast, Cvss, Control, ControlState, Assessment, Threat) {
            var ctrl = this;

            ctrl.ControlState = ControlState;

            ctrl.$onInit = function () {
                if (!ctrl.state) ctrl.state = {}
                if (!ctrl.state.state) ctrl.state.state = {}

                if (!ctrl.state.state.answers) {
                    //ControlState.populateThreatRanges(ctrl.state);
                    Control.populateStateAnswers(ctrl.state.state, function () { });
                }

                ctrl.dueDate = moment().add(1, "week");

                //TODO: move to ControlState factory?
                if (_.get(ctrl.state.control, "risk.threatPairs", []).length) {
                    ControlState.scoreThreatPairs(ctrl.state, (threatPairs) => {
                        ctrl.state.threatScores = threatPairs;
                        ctrl.state.threatRange = ControlState.calculateThreatRange(ctrl.state.threatScores);
                    });
                }
            }

            ctrl.updateControlState = function () {
                if (ctrl.onStateChange) ctrl.onStateChange();
                if (ctrl.changeState) ctrl.changeState();

                //return;

                ControlState.scoreThreatPairs(ctrl.state, (threatPairs) => {
                    ctrl.state.threatScores = threatPairs;
                    ctrl.state.threatRange = ControlState.calculateThreatRange(ctrl.state.threatScores);

                    //TODO: Need to depricate ctrl.changeState
                    // if (ctrl.onStateChange) ctrl.onStateChange();
                    //if (ctrl.changeState) ctrl.changeState();
                });
            }

            ctrl.Cvss = Cvss;
        }
    })
})();