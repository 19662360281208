(function () {
    "use strict";

    angular.module("app").directive("formGroupMaterial", formGroupMaterial);
    
    function formGroupMaterial () {
        function validate (input) {
            return function () {
                if (input.val().length > 0) {
                    input.addClass("active");
                } else {
                    input.removeClass("active");
                }           
            }
        }

        return {
            restrict: "C",
            link: function ($scope, $element, $attrs) {
                $element.find("input, textarea").each(function (index, input) {
                    input = $(input);
                    input.addClass("active");
                    input.on("input", validate(input));

                    setInterval(validate(input), 250);
                });
            }
        };
    }
})();