(function () {
    angular.module("app").controller("ResetPasswordController", ForgotPasswordController);

    function ForgotPasswordController($scope, $http, $timeout) {

        $scope.errors = null;
        $scope.data = {};
        $scope.initData = {};
        $scope.passwordReset = false;
        $scope.validToken = true;
        $scope.ready = false;
        
        $scope.form = {
            password: "",
            passwordRepeat: ""
        };

        $scope.loadToken = function(userId, secret) {
            $http.get("/api/auth/reset-password/" + userId + "/" + secret).then(function (response) {
                $scope.data = response.data;
                $scope.validToken = true;
                $scope.ready = true;
            }, function (response) {
                $scope.validToken = false;
                $scope.ready = true;
            });
        }

        $scope.resetPassword = function() {
            $scope.errors = null;

            $http.post("/api/auth/reset-password/" + $scope.data.user._id + "/" + $scope.data.token.secret, $scope.form).then(function (response) {

                $scope.passwordReset = true;

            }, function (response) {
                $scope.errors = response.data.error;
            });
        }
    };
})();
