(function () {
    "use strict";

    angular.module("app").factory("CvssFactory", CvssFactory).factory("Cvss", CvssFactory);

    function CvssFactory($resource, $http) {
        /*
         * Metric and scoring data sructures
         * */
        var version = "3.0";

        var defaultData = {
            cvss: version,
        }

        /*
         * Scale for calculating severity 
         * */
        var severityScale = {
            none: {
                id: "none",
                name: "None",
                max: 0,

                color: "#27b215",
                fontColor: "white",

                css: {
                    color: "white",
                    backgroundColor: "#1f8312"
                },

            },
            low: {
                id: "low",
                name: "Low",

                color: "#FFCC00",
                fontColor: "inherit",

                css: {
                    color: "inherit",
                    backgroundColor: "#FFCC00"
                },

                min: 0.1,
                max: 3.9,
            },
            medium: {
                id: "medium",
                name: "Medium",

                color: "#FF6600",
                fontColor: "white",

                css: {
                    color: "white",
                    backgroundColor: "#FF6600"
                },

                min: 4,
                max: 6.9,
            },
            high: {
                id: "high",
                name: "High",

                color: "#FF3300",
                fontColor: "white",

                css: {
                    color: "white",
                    backgroundColor: "#FF3300"
                },

                min: 7,
                max: 8.9,
            },
            critical: {
                id: "critical",
                name: "Critical",

                color: "#990000",
                fontColor: "white",

                css: {
                    color: "white",
                    backgroundColor: "#990000"
                },

                min: 9,
                max: 10,
            },
        }

        /*
         * Object contianing templates of each metric answer (scores not stored in template)
         * */
        var metricValues = {
            notDefined: {
                name: "Not Defined",
                id: "X",
                selected: true
            },
            unavailable: {
                name: "Unvailable",
                id: "U"
            },
            unknown: {
                name: "Unknown",
                id: "U"
            },
            required: {
                name: "Required",
                id: "R",
            },
            none: {
                name: "None",
                id: "N",
            },
            low: {
                name: "Low",
                id: "L",
            },
            medium: {
                name: "Medium",
                id: "M",
            },
            high: {
                name: "High",
                id: "H",
            },
            changed: {
                name: "Changed",
                id: "C",
            },
            unchanged: {
                name: "Unchanged",
                id: "U",
            },
            network: {
                name: "Network",
                id: "N",
            },
            adjacent: {
                name: "Adjacent",
                id: "A",
            },
            local: {
                name: "Local",
                id: "L",
            },
            physical: {
                name: "Physical",
                id: "P",
            },
            unproven: {
                name: "Unproven",
                id: "U",
            },
            proofOfConcept: {
                name: "Proof-of-concept",
                id: "P",
            },
            functional: {
                name: "Functional",
                id: "F",
            },
            officalFix: {
                name: "Offical Fix",
                id: "O",
            },
            temporaryFix: {
                name: "Temporary Fix",
                id: "T",
            },
            workaround: {
                name: "Workaround",
                id: "W",
            },
            reasonable: {
                name: "Reasonable",
                id: "R",
            },
            confirmed: {
                name: "Confirmed",
                id: "C",
            }
        }

        /*
         * Base score metric group
         * */
        var baseGroup = {
            name: "Base",
            id: "base",

            metrics: [{
                name: "Attack Vector",
                mandatory: true,
                type: "exploitability",
                id: "av",

                values: [
                    copyMetricValue("network", 0.85),
                    copyMetricValue("adjacent", 0.62),
                    copyMetricValue("local", 0.55),
                    copyMetricValue("physical", 0.2),
                ]
            }, {
                name: "Attack Complexity",
                mandatory: true,
                type: "exploitability",
                id: "ac",

                values: [
                    copyMetricValue("low", 0.77),
                    copyMetricValue("high", 0.44),
                ]
            }, {
                name: "Privileges Required",
                mandatory: true,
                type: "exploitability",
                id: "pr",

                values: [
                    copyMetricValue("none", 0.85),
                    copyMetricValue("low", {
                        default: 0.62,
                        changed: 0.68,
                    }),
                    copyMetricValue("high", {
                        default: 0.27,
                        changed: 0.50,
                    }),
                ]
            }, {
                name: "User Interaction",
                mandatory: true,
                type: "exploitability",
                id: "ui",

                values: [
                    copyMetricValue("none", 0.85),
                    copyMetricValue("required", 0.62),
                ]
            }, {
                name: "Scope",
                mandatory: true,
                type: "",
                id: "s",

                values: [
                    copyMetricValue("unchanged", 0.00),
                    copyMetricValue("changed", 0.00),
                ]
            }, {
                name: "Confidentiality",
                mandatory: true,
                type: "impact",
                id: "c",

                values: [
                    copyMetricValue("none", 0.00),
                    copyMetricValue("low", 0.22),
                    copyMetricValue("high", 0.56),
                ]
            }, {
                name: "Integrity",
                mandatory: true,
                type: "impact",
                id: "i",

                values: [
                    copyMetricValue("none", 0.00),
                    copyMetricValue("low", 0.22),
                    copyMetricValue("high", 0.56),
                ]
            }, {
                name: "Availability",
                mandatory: true,
                type: "impact",
                id: "a",

                values: [
                    copyMetricValue("none", 0.00),
                    copyMetricValue("low", 0.22),
                    copyMetricValue("high", 0.56),
                ]
            }]
        }

        /*
         * Temporal score metric group
         * */
        var temporalGroup = {
            name: "Temporal",
            id: "temporal",

            metrics: [{
                name: "Exploit Code Maturity",
                mandatory: false,
                id: "e",

                values: [
                    copyMetricValue("notDefined", 1.00),
                    copyMetricValue("unproven", 0.91),
                    copyMetricValue("proofOfConcept", 0.94),
                    copyMetricValue("functional", 0.97),
                    copyMetricValue("high", 1.00),
                ]
            }, {
                name: "Remediation Level",
                mandatory: false,
                id: "rl",

                values: [
                    copyMetricValue("notDefined", 1.00),
                    copyMetricValue("officalFix", 0.95),
                    copyMetricValue("temporaryFix", 0.96),
                    copyMetricValue("workaround", 0.97),
                    copyMetricValue("unavailable", 1.00),
                ]
            }, {
                name: "Report Confidence",
                mandatory: false,
                id: "rc",

                values: [
                    copyMetricValue("notDefined", 1.00),
                    copyMetricValue("unknown", 0.92),
                    copyMetricValue("reasonable", 0.96),
                    copyMetricValue("confirmed", 1.00),
                ]
            }]
        }

        /*
         * Enviromental score metric group
         * */
        var enviromentalGroup = {
            name: "Environmental",
            id: "environmental",

            //black magic
            metrics: copyMetrics(baseGroup.metrics, "impact", function (metric) {
                metric.name = metric.name + " Requirement";
                metric.id = metric.id + "r";
                metric.mandatory = false;

                metric.values = [
                    copyMetricValue("notDefined", 0.00),
                    copyMetricValue("low", 0.50),
                    copyMetricValue("medium", 1.00),
                    copyMetricValue("high", 1.50),
                ]

                return metric;
            }).concat(copyMetrics(baseGroup.metrics, "*", function (metric) {
                metric.name = "Modified " + metric.name;
                metric.id = "m" + metric.id;
                metric.mandatory = false;
                metric.values.unshift(copyMetricValue("notDefined", 1.00));

                return metric;
            }))
        }

        /*
         * All metric groups
         * */
        var groups = [
            baseGroup,
            temporalGroup,
            enviromentalGroup
        ];

        /*
         * Map groups to tab data
         * */
        var groupTabData = groups.map(function (group) {
            return {
                id: group.id,
                content: group.name,
            }
        });


        /*
         * Creates a new metric instane
         * */
        function newCvssInstance() {
            var metrics = newMetricSet();

            return {
                metrics: metrics,
                vector: formatString(metrics),
                scores: scoreMetrics(metrics),
            }
        }

        function updateInstance(data) {
            return {
                metrics: data.metrics,
                vector: formatString(data.metrics),
                scores: scoreMetrics(data.metrics),
            }
        }

        /*
         * Return a copy a set of metrics filtered on type and preform a map with the cb parameter
         * */
        function copyMetrics(metrics, type, cb) {
            return metrics.filter(function (metric) {
                return type == "*" || metric.type == type;
            }).map(function (metric) {
                return cb(angular.copy(metric));
            });
        }

        /*
         * Return a copy of a metric value with an optional, per-metric modifier
         * */
        function copyMetricValue(name, numericScore) {
            return angular.extend({}, {
                score: numericScore
            }, metricValues[name]);
        }

        /*
         * Return a copy of a default metric state
         * */
        function newMetricSet() {
            return groups.map(function (group) {
                group.metrics = (group.metrics || []).map(function (metric) {
                    metric.id = metric.id.toUpperCase();

                    metric.values = metric.values.map(function (value) {
                        value.id = value.id.toUpperCase();
                        value.content = value.name + " (" + value.id + ")";

                        return value;
                    });

                    metric.values[0].selected = true;

                    return metric;
                });

                return group;
            });
        }

        /*
         * Scoring and vector string
         * */

        function extractMetricData(metrics, vector) {
            var data = {};

            metrics.forEach(function (group) {
                group.metrics.filter(vector ? includeMetricInVector : isMetricAnswered).forEach(function (metric) {
                    var selected = getSelectedValue(metric.values);

                    data[metric.id.toLowerCase()] = {
                        id: selected.id,
                        score: selected.score
                    };
                });
            });

            return data;
        }

        function scoreMetrics(metrics) {
            var metricData = extractMetricData(metrics);
            var subScores = {};

            //Base
            subScores.enviromental = subScores.base = calculateBaseScore();

            //Temporal
            if (metricData.e && metricData.rl && metricData.rc) {
                subScores.temporal = calculateTemporalScore();
            } else {
                subScores.temporal = 0;
            }

            //Enviromental
            subScores.enviromental = calculateEnviromentalScore();

            function roundUp(number) {
                return Math.ceil(number * 10) / 10;
            }

            function getScore(score) {
                if (typeof score == "number") return score;
                if (metricData.s.score == 1) return score.changed;

                return score.default;
            }

            function calculateBaseScore() {
                function calculateImpactScore() {
                    if (metricData.s.id == "C") { //Scope changed
                        return 7.52 * (subScores.impactMultiplier - 0.029) - 3.25 * Math.pow(subScores.impactMultiplier - 0.02, 15);
                    } else if (metricData.s.id == "U") {
                        return 6.42 * subScores.impactMultiplier;
                    }
                }

                function calculateExploitabilityScore() {
                    return 8.22 * metricData.av.score * metricData.ac.score * getScore(metricData.pr.score) * metricData.ui.score;
                }

                subScores.impactMultiplier = 1 - ((1 - metricData.c.score) * (1 - metricData.i.score) * (1 - metricData.a.score));
                subScores.impact = calculateImpactScore();
                subScores.exploitability = calculateExploitabilityScore();

                if (subScores.impact <= 0) {
                    return 0;
                } else if (metricData.s.id == "C") { //Scope changed
                    return roundUp(Math.min(1.08 * (subScores.impact + subScores.exploitability), 10));
                } else if (metricData.s.id == "U") {
                    return roundUp(Math.min(subScores.impact + subScores.exploitability, 10));
                } else {
                    return 0;
                }
            }

            function calculateTemporalScore() {
                return roundUp(subScores.base * metricData.e.score * metricData.rl.score * metricData.rc.score)
            }

            function calculateEnviromentalScore() {
                var scores = {};

                var scopeChanged = metricData.ms.id == "C" || metricData.s.id == "C";

                function calculateImpactMultiplier() {
                    return Math.min((1 - (1 - metricData.mc.score * metricData.c.score) * (1 - metricData.mi.score * metricData.i.score) * (1 - metricData.ma.score * metricData.a.score)), 0.915);
                }

                function calculateImpactScore() {
                    if (scopeChanged) { //Scope changed
                        return 7.52 * (scores.impactMultiplier - 0.029) - 3.25 * Math.pow(scores.impactMultiplier - 0.02, 15);
                    } else {
                        return 6.42 * scores.impactMultiplier;
                    }
                }

                function calculateExploitabilityScore() {
                    return 8.22 * metricData.mav.score * metricData.mac.score * getScore(metricData.mpr.score) * metricData.mui.score;
                }


                scores.impactMultiplier = calculateImpactMultiplier();
                scores.impact = calculateImpactScore();
                scores.exploitability = calculateExploitabilityScore();

                console.log(scores)

                if (scores.impact <= 0) return 0;

                if (scopeChanged) { //Scope changed
                    return roundUp(roundUp(Math.min(1.08 * (scores.impact + scores.exploitability), 10)) * metricData.e.score * metricData.rl.score * metricData.rc.score);
                } else {
                    return roundUp(roundUp(Math.min((scores.impact + scores.exploitability), 10)) * metricData.e.score * metricData.rl.score * metricData.rc.score);
                }
            }

            Object.keys(subScores).map(function (key) {
                var score = subScores[key];
                var severity = getSeverity(score);

                subScores[key] = {
                    name: key,
                    score: score,
                    severityString: score + " (" + severity.name + ")",
                    rating: severity
                }
            });

            return subScores;
        }

        function getSeverity(scores) {
            var score = scores;

            if (scores.length) {
                score = _.max(scores.map(Number));
            }

            return severityScale[Object.keys(severityScale).find(function (key) {
                var rating = severityScale[key];

                return score >= rating.min && score <= rating.max;
            })] || severityScale.none;
        }

        /*
         * Generate a CVSS vector string from a metric state
         * */
        function formatString(metrics) {
            var data = angular.copy(extractMetricData(metrics, true));
            var metaData = angular.copy(defaultData);

            var metaValues = Object.keys(metaData).map(function (key) {
                return (key + ":" + metaData[key]).toUpperCase();
            });

            var metricValues = Object.keys(data).map(function (key) {
                var value = data[key].id || metricValues.notDefined.id;
                return (key + ":" + value).toUpperCase();
            });

            return metaValues.concat(metricValues).join("/");
        }

        /*
         * Utilities
         * */
        function selectedFilter(e) {
            return e.selected;
        }

        function getSelectedValue(values) {
            return values.find(selectedFilter);
        }

        function includeMetricInVector(metric) {
            var selection = getSelectedValue(metric.values);

            if (selection === undefined || selection.id == metricValues.notDefined.id) return false;
            return true;
        }

        function isMetricAnswered(metric) {
            var selection = getSelectedValue(metric.values);
            if (selection === undefined) return false;

            return true;
        }

        /*
         * Angular provider function
         * */
        function CvssFactory() {
            var self = this;

            self.severityScale = severityScale;
            self.groups = groups;
            self.groupTabData = groupTabData;

            self.newMetricSet = newMetricSet;
            self.newCvssInstance = newCvssInstance;
            self.updateInstance = updateInstance;

            self.formatString = formatString;
            self.scoreMetrics = scoreMetrics;
            self.getSeverity = getSeverity;

            return self;
        }

        return new CvssFactory();
    }
})();