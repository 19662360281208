(function () {
    "use strict";

    angular.module("app").component("controlCardHeader", {
        bindings: {
            control: "=",
            context: "@",
            divider: "=",
        },

        transclude: true,

        template: `
            <div layout="row" flex="100" layout-fill class="padding-2-a">
                <div flex class="line-height-reset">
                    <h6><small class="ng-binding">{{$ctrl.control._id}}</small> {{$ctrl.control.title}}</h6>

                    <div ng-if="$ctrl.context == 'control-state' || $ctrl.context == 'remediation'">{{$ctrl.control.description}}</div>

                    <div ng-if="$ctrl.context == 'assessment'">{{$ctrl.control.question}}</div>
                </div>

                <div layout="row" class="margin-2-l">
                    <div layout="column" layout-align="center center" ng-transclude></div>
                </div>
            </div>

            <md-divider ng-if="$ctrl.divider"></md-divider>
        `,
    });
})();
