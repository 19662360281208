(function () {
    angular.module("app").controller("VendorsController", VendorsController);

    function VendorsController ($scope, $mdDialog, $mdToast, Vendor, Container) {

        $scope.vendors = [];

        $scope.Container = Container;

        Vendor.resource.query().$promise.then(function (vendors) {
                $scope.vendors = vendors;
        });

    }
})();
