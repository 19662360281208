(function () {
    angular.module("app").controller("StandardsController", StandardsController);

    function StandardsController ($scope, $mdDialog, $mdToast, Standard) {
        console.log("StandardsController");

        $scope.standards = [];

        // get all
        Standard.resource.query().$promise.then(function (standards) {
                $scope.standards = standards;
        });

        $scope.showDeleteDialog = function(standards, $index) {
            $scope.modalMessage = standards[$index].title;

            $mdDialog.show({
                contentElement: '#confirmDelete',
                clickOutsideToClose: true,
                controller: 'StandardsController',
                parent: angular.element(document.body)
            }).then( function() {
                removeItem(standards, $index);
            }, function() {
                // cancel button was pressed
                //console.log("cancel");
            });


        };

        removeItem = function(standards, $index) {

            var id = standards[$index]._id;
            var title = standards[$index].title;
            Standard.resource.get({id: id, _id: id}, function(standard) {
                standard.$delete().then(function(data) {
                    showToast("Standard deleted: " + title);

                    $scope.standards.splice($index, 1);

//                    Standard.resource.query().$promise.then(function (standards) {
//                            $scope.standards = standards;
//                    });

                }, function(err, test) {
                    // save failed; should show user some kind if error
                    //console.log("Error deleteing: " + err);
                });
            });
        };

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

        $scope.closeDialog = function() {
              $mdDialog.hide();
        };

        $scope.cancelDialog = function() {
              $mdDialog.cancel();
        };


    }
})();
