(function () {
    angular.module("app").factory("Tab", function () {
        function Tab () {
            var self = this;

            var groups = {}

            self.group = function (tabs) {
                var group = {
                    current: null,
                    tabs: tabs || []
                };

                if (group.tabs.length > 1) {
                    group.current = _.find(group.tabs, function (tab) {
                        return tab.active == true;
                    });

                    //group.tabs[0].active = true;
                }

                group.add = function (instance) {
                    if (!group.tabs[instance.id]) group.tabs[instance.id] = instance;
                }

                group.click = function (instance) {
                    _.keys(group.tabs).forEach(function (id) {
                        group.tabs[id].active = false;
                    });

                    instance.active = true;
                    group.current = instance;
                }

                return group;
            }
        }

        return new Tab();
    });
})();
