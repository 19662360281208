(function () {
    "use strict";

    angular.module("app").component("metricRange", {
        bindings: {
            label: "@?",
            min: "@?",
            max: "@?",
            unit: "@?",
        },

        template: `
            <chip type="no-hover" color="$ctrl.severity.color" font-color="$ctrl.severity.fontColor">
                <span ng-if="!($ctrl.min && $ctrl.max)">
                    {{$ctrl.max || $ctrl.min}}{{$ctrl.unit}}
                </span>

                <span ng-if="$ctrl.min && $ctrl.max">
                    {{$ctrl.min}}{{$ctrl.unit}} - {{$ctrl.max}}{{$ctrl.unit}}
                </span>
            </chip>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            ctrl.$onInit = function () {
                var values = [ctrl.min, ctrl.max];

                if (ctrl.unit == "%") {
                    values = [10 - (ctrl.max / 10)];
                } else {
                    ctrl.severity = Cvss.getSeverity(values);
                }
            }
        }
    })

})();
