(function () {
    "use strict";

    angular.module("app").component("pageGuide", {
        bindings: {
            instructionId: "@",
        },

        /* html */
        template: `
            <ul ng-if="$ctrl.instruction._id" id="tlyPageGuide" data-tourtitle="{{$ctrl.instruction.title}}">
                <li ng-repeat="step in $ctrl.instruction.steps" class="tlypageguide_{{step.direction}}" data-tourtarget="{{step.target}}">
                    <div ng-bind-html="step.content"></div>    
                </li>
            </ul>
        `,

        controller: function (Instruction) {
            var ctrl = this;

            console.log(ctrl)

            ctrl.$onInit = () => {
                Instruction.get(ctrl.instructionId, (error, instruction) => {
                    ctrl.instruction = instruction;

                    setTimeout(() => {
                        tl.pg.init({
                            pg_caption: ctrl.caption || "VSC Guide"
                        });
                    }, 1500);
                });
            }
        }
    })
})();