(function () {
    "use strict";

    angular.module("app").filter("monthName", function () {

        return function (val, args) {

            var months = [
                "All",
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December"
            ];

            if (val < 0 || val > 12) {
                return "";
            } else {
                return months[val];
            }

        }

    });
})();
