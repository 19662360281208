(function () {
    "use strict";

    angular.module("app").component("float", {
        transclude: true,

        template: `
            <div class="float-outer-wrapper">
                <div class="float-inner-wrapper">
                    <div class="float" ng-class="{'float-fixed': $ctrl.float}" ng-transclude><div>
                </div>
            </div>
        `,

        bindings: {
            yOffset: "@?"
        },

        controller: function ($scope, $window, $document, $element) {
            var ctrl = this;

            ctrl.$onInit = function () {
                ctrl.yOffset = ctrl.yOffset || 0;

                ctrl.outerWrapperElement = $element.find(".float-outer-wrapper")[0];
                ctrl.innerWrapperElement = $element.find(".float-inner-wrapper")[0];
                ctrl.floatElement = $element.find(".float")[0];

                ctrl.top = ctrl.outerWrapperElement.offsetTop;

                angular.element($window).bind("scroll", onScroll);
            }

            function onScroll () {
                var nextFloatValue = this.pageYOffset >= ctrl.top;

                //Check for change before calling $scope.$apply()
                if (ctrl.float != nextFloatValue) {
                    ctrl.float = nextFloatValue;

                    ctrl.floatElement.style.width = ctrl.innerWrapperElement.clientWidth + "px";
                    ctrl.floatElement.style.top = ctrl.float ? ctrl.yOffset + "px" : "0px";

                    $scope.$apply();
                }
            }
        }
    })
})();
