(function () {
    angular.module("app").controller("SingleTrainingAnonymousController", SingleTrainingAnonymousController);

    function SingleTrainingAnonymousController ($scope, $location, $mdSidenav, Training) {

        var trainingId;
        var userId;
        var orgId;
        var urlPieces = $location.absUrl().split("/");

        if (urlPieces.length == 8) {
            orgId = urlPieces[7];
            userId = urlPieces[6];
            trainingId = urlPieces[5];
        } else if (urlPieces.length == 7) {
            userId = urlPieces[6];
            trainingId = urlPieces[5];
        } else {
            var trainingId = urlPieces[urlPieces.length - 1];
        }

        $scope.acknowledged = true;
        $scope.training = [];

        Training.trainingAndUser.get({
            trainingId: trainingId,
            userId: userId,
            orgId: orgId
        }).$promise.then(function (training) {
            $scope.acknowledged = training.acknowledged;
            $scope.training = training;
        });

        $scope.acknowledgeTraining = function(training) {
            $('video').trigger('pause');

//            var data = {};
//            data.userId = userId;
//            data.trainingId = trainingId;

            Training.acknowledgeResource.acknowledge({
                trainingId: trainingId,
                userId: userId,
                orgId: orgId
            }).$promise.then( function (result) {
                $scope.acknowledged = true;
            });

        };

       $scope.showTip = function() {
            $mdSidenav("trainingTip")
              .toggle()
              .then(function () {
              });
        }

        $scope.closeTip = function () {
            $mdSidenav('trainingTip').close().then(function () {});
    	}

		$scope.canAcknowledge = false;

		setTimeout(() => {
			$("video").bind("timeupdate", function () {
				var currentTime = this.currentTime;
				var percentage = (currentTime / this.duration) * 100;

				if (percentage >= 90) {
					$scope.canAcknowledge = true;
				}

				$scope.$apply();
			});
		}, 2500);
    }
})();
