  (function () {
    angular.module("app").controller("HelpController", HelpController);

    function HelpController ($scope, Document) {

        $scope.documents = [];
        $scope.isReady = false;

        this.$onInit = function () {

            Document.orgDocuments().query({
                rootDocsOnly:true,
                titlesOnly:true,
                type:'help'
            }).$promise.then(function (documents) {
                $scope.documents = documents;
                $scope.isReady = true;
            });

        };

    }
})();
