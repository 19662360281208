(function () {
    angular.module("app").controller("ProcedureController", ProcedureController);

    function ProcedureController ($scope, $mdToast, $mdDialog, $location, Procedure, Session) {

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        $scope.isCSO = false;
        $scope.isLicensed = false;
        $scope.percentComplete = 0;
        var stepsComplete = 0;

        Session.currentUser.$promise.then( function(user) {
            $scope.isLicensed = user.organization.status.licensed;
            $scope.isCSO = user.roles.findIndex(function(role) {
                return role._id == "cso" || role._id == "riskmanager"
            }) > -1;
        });

        Procedure.procedures.get({id: id}, function(result) {

            result.steps = mapSteps(result.steps);

            $scope.percentComplete = Math.round(stepsComplete / result.steps.length * 100);
            $scope.procedure = result;
            $scope.isReady = true;
        });

        mapSteps = function(steps) {

            return steps.map(function(step) {
                if (step.complete) {
                    stepsComplete = stepsComplete + 1;
                };
                step.answers = [{
                    complete: true,
                    applicable: true,
                    icon: "check",
                    class: "md-green",
                    selected: step.complete && step.applicable
                } , {
                    complete: false,
                    applicable: true,
                    icon: "times",
                    class: "md-warn",
                    selected: !step.complete && step.applicable
                } , {
                    complete: false,
                    applicable: false,
                    icon: "minus",
                    class: "md-primary",
                    selected: !step.applicable
                }];

                return step;
            });

        };

        $scope.selectChanged = function(step) {
            var answer = step.answers.find(function(answer) {
                return answer.selected;
            });
            if (answer) {
                step.complete = answer.complete;
                step.applicable = answer.applicable;
            };
            stepsComplete = 0;
            $scope.procedure.steps.forEach(function(step) {
               if (step.complete) {
                    stepsComplete = stepsComplete + 1;
               }
            });
            $scope.percentComplete = Math.round(stepsComplete / $scope.procedure.steps.length * 100);
        }

        $scope.reset = function() {

            $scope.procedure.steps = $scope.procedure.steps.map(function(step) {

                step.answers = step.answers.map(function(answer) {
                    answer.selected = false;
                    return answer;
                })

                return step;
            })

        };

        $scope.save = function() {

            Procedure.procedures.update($scope.procedure).$promise.then( function (result) {
                $mdToast.show (
                    $mdToast.simple().textContent("Procedure saved.")
                );
            }, function(err, result) {
                console.log("ERROR", err, result);
            });

        };

        $scope.submit = function() {
            var confirm = $mdDialog.confirm()
                  .title('Once submitted, you cannot update this procedure. Confirm?')
                  .textContent($scope.procedure.title)
                  .ariaLabel('Submit Procedure')
                  .ok('Yes, submit procedure')
                  .cancel('No, cancel');

            $mdDialog.show(confirm).then(function() {

                Procedure.procedures.submit($scope.procedure).$promise.then( function (result) {

                    result.steps = mapSteps(result.steps);
                    $scope.procedure = result;

                    $mdToast.show (
                        $mdToast.simple().textContent("Procedure submitted.")
                    );
                }, function(err, result) {
                    console.log("ERROR", err, result);
                });

            }, function() {
            });



        };
    }
})();
