(function () {
    "use strict";

    angular.module("app").component("report", {
        bindings: {
            endpoint: "@"
        },

        transclude: {
            filter: true,
            data: true
        },

        /* html */
        template: `
            <div>
                <div ng-transclude="fliter"></div>
                <div ng-transclude="data"></div>
            </div>
        `,

        controller: function () {
            var ctrl = this;
        }
    })
})();