(function () {
    angular.module("app").controller("MyPoliciesController", MyPoliciesController);

    function MyPoliciesController ($scope, $location, $mdToast, Session, Policy, UserRole, User) {

        $scope.policies = [];
        $scope.policyRoles = [];
        $scope.policiesAcknowledged = true;
        $scope.isReady = false;

        $scope.data = {
            showAcknowledged: false
        };

        // get user and org; we use the org to replace slugs in copy
        Session.currentUser.$promise.then(function (user) {
            $scope.user = user;
            $scope.organization = user.organization;

            // now get the policies
            Policy.getByUser().query({categorize:true}).$promise.then(function (policies) {

                policies.forEach( function(category) {
                    category.sections.forEach(function(section) {
                        section.policies.forEach(function(policy) {

                            if (!policy.acknowledged) {
                                $scope.policiesAcknowledged = false;
                            }

                            // build unique list of roles from policies
                            policy.roles.forEach(function(pRole) {
                                if (!$scope.policyRoles.find(function(p){
                                    return pRole._id == p._id;
                                })) {
                                    pRole.show = true;
                                    pRole.display = true;

                                    if (pRole._id == "workforcemember") {
                                        pRole.name = $scope.organization.phrase.employee;
                                    }

                                    pRole.name = pRole.name.charAt(0).toUpperCase() + pRole.name.slice(1);

                                    $scope.policyRoles.push(pRole);
                                };
                            });

                        });
                    })

                });

                $scope.policies = policies;
                $scope.isReady = true;
            });
        });


        // acknowledge policies
        $scope.acknowledgePolicies = function() {

            Policy.acknowledgeResource.acknowledge({
                userId: $scope.user._id,
                orgId: $scope.organization._id
            }).$promise.then( function (result) {
                $scope.policiesAcknowledged = true;
            });

        }

        // show toaster message
        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

    }
})();
