/**
 * @ngdoc component
 * @name app.control.component:attributes
 * @author Nick Vorie
  *
 * @param {array}      ngModel  The Attributes to bind to
 * @param {expression} ngChange Callback on change
 * @param {boolean}    readOnly Read-only mode
 *
 * @description
 * This component will render an array of Attributes that will notify via the ngChange callback when they are edited
 */
(function () {
    "use strict";

    angular.module("app").component("attributes", {
        bindings: {
            attributes: "=ngModel",
            ngChange: "&?",
            readOnly: "=?",
        },

        template: `
            <md-list ng-if="$ctrl.attributes.length" ng-disabled="$ctrl.readOnly" condensed>
                <md-list-item ng-repeat="attribute in $ctrl.attributes">
                    <md-switch layout-margin-none class="md-accent" ng-model="attribute.selected" ng-change="$ctrl.ngChange()" ng-disabled="$ctrl.readOnly">
                        <span ng-class="{'text-muted': !attribute.selected}">
                            <span>{{attribute.tip}}</span>
                        </span>
                    </md-switch>
                </md-list-item>
            </md-list>
        `,
    });
})();
