(function () {
    angular.module("app").component("paymentInfo", {
        bindings: {
            org: "=",
            subscription: "="
        },

        template: `
            <md-card>
                <md-card-header><md-card-header-text><h4>Payment Information</h4></md-card-header-text></md-card-header>
                <md-card-content layout="column" style="min-height:110px">
                    <div ng-if="$ctrl.paymentMethod.cardholderName" layout="row">
                        <label flex="30">Name</label>
                        <p flex>{{$ctrl.paymentMethod.cardholderName}}</p>
                    </div>
                    <div layout="row">
                        <label flex="30">Account</label>
                        <p flex>
                            {{$ctrl.paymentMethod.maskedNumber}} 
                            <img style="max-width: 40px; margin-left: 12px" ng-src="{{$ctrl.paymentMethod.imageUrl}}"/>
                        </p>
                    </div>
                    <div layout="row">
                        <label flex="30">Card Exp</label>
                        <p flex>{{$ctrl.paymentMethod.expirationDate}} <chip ng-show="$ctrl.paymentMethod.expired" type="xs" color="'red'">Expired</chip></p>
                    </div>
                    <div ng-if="$ctrl.subscription.status" layout="row">
                        <label flex="30">Subscription</label>
                        <p ng-hide="$ctrl.subscription.status == 'Canceled'" flex>{{$ctrl.subscription.status}}</p>
                        <chip ng-show="$ctrl.subscription.status == 'Canceled'" font-color="'white'" color="'red'">Subscription Canceled</chip>
                        <chip ng-show="$ctrl.subscription.status == 'PastDue'" font-color="'white'" color="'red'">Subscription Past Due</chip>
                    </div>
                    <div ng-hide="$ctrl.subscription.status == 'Canceled'" layout="row">
                        <label flex="30">Next Bill Amount</label>
                        <p flex>{{$ctrl.subscription.nextBillingPeriodAmount | currency}}</p>
                    </div>
                    <div ng-hide="$ctrl.subscription.status == 'Canceled'" layout="row">
                        <label flex="30">Next Bill Date</label>
                        <p flex>{{$ctrl.subscription.nextBillingDate | date :  "short"}}</p>
                    </div>
                </md-card-content>

                <md-divider></md-divider>

                <md-card-actions ng-hide="$ctrl.subscription.status == 'Canceled'" flex layout="row" layout-align="end center">
                        <md-button class="" ng-click="$ctrl.editPayment()" aria-label="Update">
                            Edit Info
                        </md-button>
                </md-card-actions>
            </md-card>
        `,

        controller: function (ECommerce) {
            var ctrl = this;
            ctrl.canceled = false;

            function getPaymentInfo() {
                var data = {};

                if (ctrl.subscription.paymentMethodToken) {
                    data.token = ctrl.subscription.paymentMethodToken;
                }

                ECommerce.resource.getPaymentMethod(data).$promise.then(function (result) {

                    if (result) {
                        ctrl.paymentMethod = result;
                    }

                }, function (err, result) {
                    console.log("cannot get payment info", err, result);
                });
            }

            ctrl.$onInit = function () {
                getPaymentInfo();
            }

            ctrl.editPayment = function () {
                ECommerce.editPaymentInfo(ctrl.paymentMethod).then(function (resp) {
                    if (resp) {
                        ctrl.subscription = resp;
                        getPaymentInfo();
                    }

                }, function () {
                    // cancel
                });
            }
        },

    });
})();
