(function () {
    "use strict";

    angular.module("app").component("incidentCard", {
        template: `
            <md-card ng-if="true">
                <md-card-content>
                    <md-content md-transparent style="padding:8px">
                        <h2>{{$ctrl.organization.incident.cardTitle}}</h2>
                        <p>{{$ctrl.organization.incident.cardContent}}</p>
                        <md-button style="float: right" class="md-button md-raised" ng-click="$ctrl.reportIncidentDialog($event)">Report Event</md-button>
                    </md-content>
                </md-card-content>

                <md-card-actions ng-if="$ctrl.edit" layout="row" layout-align="end center">
                    <md-button class="md-icon-button" ng-click="$ctrl.editCopyDialog($event)" aria-label="Edit">
                        <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                    </md-button>
                </md-card-actions>
            </md-card>
        `,

        bindings: {
            organization: '<',
            edit: '<'
        },

        controller: IncidentCardController

    });


    function IncidentCardController($mdDialog, $mdToast) {
        var ctrl = this;

        ctrl.$onInit = function () {

        }

        ctrl.editCopyDialog = function(ev) {

            $mdDialog.show({
                template: dialogTemplate,
                controller: DialogControler,
                parent: angular.element(document.body),
                locals: {
                    organization: ctrl.organization,
                    title: ctrl.title,
                    content: ctrl.content
                },
                bindToController: true,
                clickOutsideToClose: true,
                targetEvent: ev
            })
            .then(function(resp) {
                ctrl.organization = resp;
                ctrl.hasMessage = true;
                $mdDialog.hide();
            }, function(resp) {
                $mdDialog.hide();
            });
        };

        ctrl.reportIncidentDialog = function(ev) {

            $mdDialog.show({
                template: reportIncidentTemplate,
                controller: ReportIncidentControler,
                parent: angular.element(document.body),
                locals: {
                    organization: ctrl.organization
                },
                bindToController: true,
                clickOutsideToClose: true,
                targetEvent: ev
            })
            .then(function(resp) {
                $mdDialog.hide();
            }, function(resp) {
                $mdDialog.cancel();
            });
        }

    }

    var reportIncidentTemplate = '\
        <md-dialog>\
            <form name="form" ng-submit="update()">\
                <md-toolbar>\
                    <div class="md-toolbar-tools">\
                        <h2>Report Event</h2>\
                    </div>\
                </md-toolbar>\
                <p style="padding:22px">Provide a brief description of the event. Consider explaining who was involved, what happened, where the event happened, who discovered the event, and what impact the event had or may have. Avoid including sensitive information but securely preserve any additional materials that may be needed in order to investigate and resolve the event.</p>\
                <md-dialog-content style="min-width:600px;padding:12px">\
                    <md-input-container class="md-block">\
                        <label>Description</label>\
                        <textarea ng-model="incident.description" md-maxlength="1000" rows="5" md-select-on-focus required></textarea>\
                    </md-input-container>\
                    <md-input-container flex style="margin-right: 12px" class="md-block">\
                        <label>Date & Time Discovered</label>\
                        <input mdc-datetime-picker="" date="true" time="true" type="text" id="eventstart" show-todays-date="" short-time="true" show-icon="true" click-outside-to-close="true" placeholder="YYYY-MM-DD HH:MM" show-icon="true" ng-model="incident.date.discoveredStart" class="md-input">\
                    </md-input-container>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                    <md-button ng-click="cancelDialog()" class="md-button">Cancel</md-button>\
                    <md-button type="submit" class="md-button md-primary md-raised">Save</md-button>\
                </md-dialog-actions>\
            </form>\
        </md-dialog>\
    ';

    function ReportIncidentControler($scope, $mdDialog, $mdToast, Incident) {
        var ctrl = this;

        ctrl.$onInit = function () {
            $scope.incident = {
                date: {
                    discoverdStart:''
                }
            };
        }

        $scope.cancelDialog = function() {
          $mdDialog.cancel();
        };

        $scope.update = function() {

            Incident.resource.update($scope.incident).$promise.then( function (result) {

                $mdDialog.hide(result);

                $mdToast.show (
                    $mdToast.simple()
                    .textContent("Incident saved.")
                    .hideDelay(3000)
                );
            });

        }
    };

    var dialogTemplate = '\
        <md-dialog>\
            <form name="form" ng-submit="update()">\
                <md-toolbar>\
                    <div class="md-toolbar-tools">\
                        <h2>Edit Incident Tracking Card</h2>\
                    </div>\
                </md-toolbar>\
                <md-dialog-content style="min-width:600px;padding:12px;padding-top:22px">\
                    <md-input-container class="md-block" flex>\
                        <label class="md-label">Title</label>\
                        <input ng-model="organization.incident.cardTitle" required>\
                    </md-input-container>\
                    <md-input-container class="md-block" flex>\
                        <label class="md-label">Content</label>\
                        <textarea ng-model="organization.incident.cardContent" rows="5" md-select-on-focus required></textarea>\
                    </md-input-container>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                    <md-button ng-click="cancelDialog()" class="md-button">Cancel</md-button>\
                    <md-button type="submit" class="md-button md-primary md-raised">Save</md-button>\
                </md-dialog-actions>\
            </form>\
        </md-dialog>\
    ';

    function DialogControler($scope, $mdDialog, $mdToast, Organization) {
        var ctrl = this;

        ctrl.$onInit = function () {

            Organization.current.$promise.then( function(org) {
                $scope.organization = org;
            });

        }

        $scope.cancelDialog = function() {
          $mdDialog.cancel();
        };

        $scope.update = function() {
            $scope.organization.$save().then(function (org) {

                $mdDialog.hide(org);

                $mdToast.show (
                    $mdToast.simple()
                    .textContent("Incident tracking updated.")
                    .hideDelay(3000)
                );

            }, function (err) {
                console.log("ERROR", err);
            });

        }
    }

})();
