(function () {
    angular.module("app").controller("AdminAwarenessController", AdminAwarenessController);

    function AdminAwarenessController($scope, $mdSidenav, $mdDialog, $mdToast, $http, Session, Awareness, Users, UserRole, Upload) {

        $scope.messages = [];
        $scope.currentMessage = null;
        $scope.isAdmin = false;
        $scope.canEdit = false;
        $scope.uploadPercent = 0;
        $scope.showImageProgress = false;
        $scope.submitting = false;
        $scope.themes = [];
        //        $scope.requirements = [];
        $scope.filter = {
            searchText: "",
            theme: "",
            showDefaults: true,
            showDisabled: true
        };

        this.$onInit = function () {

            //            // get a list of users
            Users.resource.query({}).$promise.then(function (users) {
                $scope.users = users.map(function (user) {
                    return {
                        _id: user._id,
                        email: user.email,
                        profile: user.profile
                    }
                });

                // get a list of roles
                UserRole.resource.query().$promise.then(function (userRoles) {
                    $scope.userRoles = userRoles;
                    $scope.usersAndRoles = $scope.users.concat(userRoles);
                });

            });

            Session.currentUser.$promise.then(function (user) {
                $scope.isAdmin = user.admin === true;
                $scope.canEdit = (user.roles.findIndex(function (role) {
                    return role.slug == 'cso' || role.slug == 'riskmanager'
                }) > -1);
            });

            $scope.themes = Awareness.themesResource.query().$promise.then(function (themes) {
                $scope.themes = themes;
            });

            Awareness.resource.query().$promise.then(function (messages) {
                $scope.messages = messages;
            });

        }

        $scope.update = function () {
            $scope.submitting = true;

            Awareness.resource.update($scope.currentMessage).$promise.then(function (result) {

                var idx = $scope.messages.findIndex(function (document) {
                    if (result.replacesDocument) {
                        return result._id == document._id || result.replacesDocument._id == document._id;
                    } else {
                        return result._id == document._id;
                    }
                });

                if (idx > -1) {
                    $scope.messages[idx] = result;
                } else {
                    $scope.messages.push(result);
                }

                $scope.submitting = false;
                showToast("Awareness message saved.");
            });

            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        }

        $scope.deleteDocument = function (document) {

            var confirm = $mdDialog.confirm()
                .title('Confirm removal of this customized awareness?')
                .textContent(document.title)
                .ariaLabel('Remove document')
                .ok('Yes, remove awareness')
                .cancel('No, cancel');

            $mdDialog.show(confirm).then(function () {
                removeItem(document);
            }, function () {
            });

        }

        removeItem = function (document) {
            $scope.submitting = true;

            Awareness.resource.delete({ id: document._id }).$promise.then(function (result) {

                Awareness.resource.query().$promise.then(function (messages) {
                    $scope.messages = messages;

                    $mdSidenav("editSideNav")
                        .close()
                        .then(function () {
                        });

                    $scope.submitting = false;
                    showToast("Awareness removed: " + document.title);
                });

            }, function (err, result) {

            });

        }

        showToast = function (msg) {
            $mdToast.show(
                $mdToast.simple()
                    .textContent(msg)
                    .hideDelay(3000)
            );
        }

        $scope.sendNow = function (document, $event) {

            sendNowController = function ($scope, $mdDialog, Users, document) {
                $scope.document = document;
                $scope.document.sendTo = [];

                this.$onInit = function () {
                    Users.resource.query({}).$promise.then(function (users) {
                        $scope.users = users.filter(user => {
                            if (user.status.state == "archived" || user.disabled) {
                                return false;
                            }

                            return true;
                        }).map(function (user) {
                            return {
                                _id: user._id,
                                email: user.email,
                                profile: user.profile
                            }
                        });

                        // get a list of roles
                        UserRole.resource.query().$promise.then(function (userRoles) {
                            $scope.userRoles = userRoles;
                            $scope.usersAndRoles = $scope.users.concat(userRoles);
                        });
                    });

                }

                $scope.cancel = function () {
                    $mdDialog.cancel();
                }

                $scope.send = function () {
                    $mdDialog.hide($scope.document);
                }

                $scope.filterUsers = function (query) {
                    return $scope.usersAndRoles.filter(function (user) {
                        if (user.profile) {
                            return user.profile.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        } else {
                            return user.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        }
                    });
                }
            }

            $mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: $event,
                templateUrl: '/assets/html/awareness/sendNowModal.html',
                locals: {
                    document: document,
                    usersAndRoles: $scope.usersAndRoles
                },
                controller: sendNowController
            }).then(function (docToSend) {

                Awareness.resource.sendNow(docToSend).$promise.then(function (result) {
                    showToast("Awareness sent.");
                });

            }, function () {
            });


        }

        $scope.editDocument = function (message) {

            if (message.sendOnce == null) {
                message.sendOnce = {};
            }
            if (message.sendOnce.to == null) {
                message.sendOnce.to = [];
            }

            $scope.currentMessage = message;

            if ($scope.currentMessage.organization) {
                $scope.canDelete = true;
            } else {
                $scope.currentMessage.default = true;

                if ($scope.isAdmin) {
                    $scope.canDelete = true;
                } else {
                    $scope.canDelete = false;
                }
            }

            if ($scope.currentMessage.video && $scope.currentMessage.video.url) {
                var player = document.getElementById('videoPlayer');
                var source = document.getElementById('mp4Source');
                player.pause();
                source.src = $scope.currentMessage.video.url;
                player.load();
            }

            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        }

        $scope.newDocument = function () {

            $scope.currentMessage = {
                isNew: true,
                enabled: false,
                requireAcknowledgement: false
            };
            $scope.canEdit = false;

            $scope.editForm.$setUntouched();
            $scope.editForm.$setPristine();

            $mdSidenav("editSideNav").toggle().then(function () {
            });
        }

        $scope.cancelEdit = function () {
            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        };

        $scope.filterEnabled = function (message) {
            if (!$scope.filter.showDisabled && !message.enabled) {
                return false;
            } else {
                return true;
            }
        }

        $scope.filterTheme = function (message) {
            if ($scope.filter.theme == "") {
                return true;
            }
            if ($scope.filter.theme == message.theme) {
                return true;
            }

            return false;
        }

        $scope.uploadFile = function (file) {
            Upload.upload({
                url: '/api/awareness/' + $scope.currentMessage._id + '/image',
                data: { file: file }
            }).then(function (resp) {

                resp.data.url += `?${_.random(100)}=${_.random(100)}`;
                $scope.currentMessage.image = resp.data;
                showToast("Upload complete.");
                $scope.showImageProgress = false;
                $scope.submitting = false;
                $scope.uploadPercent = 0;

            }, function (resp) {
                console.log('Error status: ' + resp.status);
                $scope.showImageProgress = false;
                $scope.submitting = false;
                $scope.uploadPercent = 0;
            }, function (evt) {
                $scope.submitting = true;
                $scope.showImageProgress = true;
            });
        };

        $scope.resetFile = function () {
            $http.delete("/api/awareness/" + $scope.currentMessage._id + "/image").then(function (resp) {
                $scope.currentMessage.image = resp.data;
            }, function (resp) {
                //                ctrl.errorHandler.process(response.data);
            });
        }

        $scope.clearFilter = function () {
            $scope.filter.searchText = "";
            $scope.filter.theme = "";
            $scope.filter.showDefaults = true;
            $scope.filter.showDisabled = true;
        };

        $scope.filterDefaults = function (document) {
            return $scope.filter.showDefaults || document.organization;
        };

    }
})();
