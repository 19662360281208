(function () {
    angular.module("app").component("domainCard", {
        bindings: {
            domains: "=",
            domain: "=",
            show: "=?"
        },

        template: `
            <md-card>
                <md-card-content layout>
                    <div flex="20">
                        <md-icon md-font-icon="{{$ctrl.domain.type.icon || 'fa-laptop'}}" class="fa fa-3x margin-r" md-colors="::{color: 'default-primary-400'}"></md-icon>
                    </div>

                    <div flex>
                        <h5 class="truncate">{{$ctrl.domain.name}}</h5>
                    </div>

                    <div ng-if="$ctrl.show.chip != false">
                        <a ng-if="$ctrl.domain.assessment.current" ng-href="/domain/{{$ctrl.domain._id}}/assessment">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Assessment</chip>
                        </a>
                        <a ng-if="$ctrl.domain.remediation.current" ng-href="/domain/{{$ctrl.domain._id}}/remediation">
                            <chip color="'#0277BD'" font-color="'white'" type="xs">Remediation</chip>
                        </a>
                    </div>
                </md-card-content>

                <md-divider ng-if-start="$ctrl.show.metrics"></md-divider>

                <control-state-metrics-dash ng-if-end threats="$ctrl.domain.controlState.current.threatRanges" compliance="$ctrl.domain.controlState.current.compliance" to-show="3"></control-state-metrics-dash>

                <md-divider></md-divider>

                <md-card-actions ng-if="$ctrl.show.actions != false" flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">
                        <md-button href="" ng-if="!$ctrl.isAuditor && $ctrl.show.assessmentIcon" ng-click="$ctrl.Assessment.start($event, $ctrl.domain, 'domain')" class="md-icon-button" aria-label="Assessment">
                            <md-tooltip>Schedule Assessment</md-tooltip>
                            <md-icon md-font-icon="fa-calendar" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="$ctrl.show.viewIcon" ng-href="/domain/{{$ctrl.domain._id}}" class="md-icon-button" aria-label="View">
                            <md-tooltip>Details</md-tooltip>
                            <md-icon md-font-icon="fa-eye" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.editIcon" class="md-icon-button" ng-click="$ctrl.Container.edit($event, $ctrl.domains, $ctrl.domain, 'domain')" aria-label="Edit">
                            <md-tooltip>Edit</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>


                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && !$ctrl.domain.archived" ng-click="$ctrl.Container.archive($event, $ctrl.domains, $ctrl.domain, 'domain')">
                            <md-tooltip>Archive</md-tooltip>
                            <md-icon md-font-icon="fa-file-download" class="fa"></md-icon>
                        </md-button>
                                
                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon && $ctrl.domain.archived" ng-click="$ctrl.Container.archive($event, $ctrl.domains, $ctrl.domain, 'domain')">
                            <md-tooltip>Restore</md-tooltip>
                            <md-icon md-font-icon="fa-file-upload" class="fa"></md-icon>
                        </md-button>

                        <md-button ng-if="!$ctrl.isAuditor && $ctrl.show.deleteIcon" class="md-icon-button" ng-click="$ctrl.Container.deleteItem($event, $ctrl.domains, $ctrl.domain, 'domain')" aria-label="Delete">
                            <md-tooltip>Delete</md-tooltip>
                            <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                        </md-button>
                	</md-card-actions>
            	</md-card-icon-actions>
			</md-card>
        `,

        controller: function (Container, Assessment, User) {
            var ctrl = this;

            ctrl.Container = Container;
            ctrl.Assessment = Assessment;

            ctrl.$onInit = () => {
                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });
            }
        },

    });
})();
