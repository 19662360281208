(function () {
    "use strict";

    angular.module("app").factory("Setup", SetupFactory);

    function SetupFactory ($resource, $http) {

        function SetupProvider() {
            var self = this;

            self.setupResource = $resource(
                "/api/setup",
                {},
                {
                    complete: {
                        method:'POST',
                        url: '/api/setup/complete',
                        isArray: false
                    }
                }
            );

            self.trialUser = $resource(
                "/api/setup/trialUser",
            );

            return self;
        }

        return new SetupProvider();
    }

})();
