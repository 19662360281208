(function () {
    angular.module("app").controller("PolicyReportController", function ($scope, $http) {
        let users = [];

        $scope.data = {
            filter: {
                policy: null,
                containerType: null,
                assignedTo: null,
                showAcknowledged: true,
                showPending: false,
                showUnassigned: false,
                search: "",
                user: {
                    status: {
                        active: true,
                        archived: false,
                        disabled: false,
                        pending: false
                    }
                },  
            }
        }

        $scope.showMetrics = true;

        $scope.toggleMetrics = () => {
            $scope.showMetrics = !$scope.showMetrics;
        }

        $http.get("/api/reporting/policy").then(res => {
            $scope.data.result = res.data;
            $scope.data.loaded = true;

            $http.get("/api/users?activity=policy").then(res => {
                users = res.data.map(user => {
                    return {
                        _id: user._id,
                        profile: user.profile,
                        status: user.status.state,
                        disabled: user.disabled,
                        activity: user.activity
                    }
                });
                
                generateReport();
            }).catch(console.error);
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);

        function filterFn (item) {
            const options = $scope.data.filter;

            if (!options.user.status.active) {
                if (item.user.status == "active") {
                    return false;
                }
            }

            if (!options.user.status.archived) {
                if (item.user.status == "archived") {
                    return false;
                }
            }
            
            if (!options.user.status.pending) {
                if (item.user.status == "pending") {
                    return false;
                }
            }
            
            if (!options.user.status.disabled) {
                if (item.user.status == "disabled") {
                    return false;
                }
            }

            // Filter specific user
            if (options.assignedTo) {
                if (options.assignedTo._id != item.user._id) {
                    return false;
                }
            }

            // Filter specific policy
            if (options.policy) {
                if (options.policy._id != item.policy._id) {
                    return false;
                }
            }

            // Filter unassigned docs
            if (!item.added && !options.showUnassigned) {
                return false;
            }

            // Filter assigned but un-ack'd docs
            if (item.added && !item.acknowledged && !options.showPending) {
                return false;
            }

            //Filter ack'd docs
            if (item.acknowledged && !options.showAcknowledged) {
                return false;
            }

            // Filter text search
            if (options.search.length) {
                const search = options.search.toLowerCase();
                const value = item.policy.title.toLowerCase() || item.policy.statement.toLowerCase();

                return item.user.profile.name.toLowerCase().includes(search) ||
                    value.includes(search);
            }

            return true;
        }

        $scope.filterFn = filterFn;

        function generateReport() {
            console.log("generating report");

            $scope.data.report = $scope.data.result;
            $scope.data.report.metrics = generateMetrics($scope.data.report.data);
        }

        function generateMetrics(report) {
            const metrics = {};
            const filter = filterFn;

            metrics.totalUsers = users.length;

            metrics.acknowledgedUsers = users.filter(user => {
                return user.activity.policies.every(entry => {
                    return entry.acknowledged;
                });
            }).length;

            metrics.percentTrained = _.round(100 * (metrics.acknowledgedUsers / metrics.totalUsers), 1);

            metrics.assignedPolicies = report.entries.filter(entry => {
                if (!filter(entry)) {
                    return false;
                }

                return entry.notified && !entry.acknowledged;
            }).length;

            metrics.acknowledgedPolicies = report.entries.filter(entry => {
                if (!filter(entry)) {
                    return false;
                }

                return entry.acknowledged;
            }).length;

            return metrics;
        }
    });
})();

function filterEntry(options) {
    return (item) => {
        if (!options.showDisabledUsers) {
            if (item.user.disabled) {
                return false;
            }
        }

        if (!item.added && !options.showUnassigned) {
            return false;
        }

        if (item.added && !item.acknowledged && !options.showPending) {
            return false;
        }

        if (item.acknowledged && !options.showAcknowledged) {
            return false;
        }

        if (options.policy) {
            if (options.policy._id != item.policy._id) {
                return false;
            }
        }

        if (options.assignedTo) {
            if (options.assignedTo._id != item.user._id) {
                return false;
            }
        }

        if (options.search.length) {
            const search = options.search.toLowerCase();

            return item.user.profile.name.toLowerCase().includes(search) ||
                (item.policy.title || item.policy.statement).toLowerCase().includes(search);
        }

        return true;
    }
}