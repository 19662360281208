/**
 * @ngdoc component
 * @name app.uiKit.component:buttonGroup
 * @author Nick Vorie
 *
 * @param {array}      elements An array representing the button elements to render
 * @param {expression} onSelect Callback on selection
 * @param {boolean}    readOnly Read-only mode
 *
 * @description
 * This component will render a group of buttons. Callback is provided via onSelect
 */
(function () {
    "use strict";

    angular.module("app").component("buttonGroup", {
        bindings: {
            elements: "=",
            onSelect: "&?",
            readOnly: "=?",
        },

        template: `
            <div ng-if="$ctrl.elements.length" class="button-group">
                <md-button class="md-raised" ng-repeat="element in $ctrl.elements" ng-click="$ctrl.select(element)" ng-class="element.selected ? element.class : {}" ng-disabled="$ctrl.readOnly">
					<md-tooltip md-delay="1000">{{element.content}}</md-tooltip>
                    <span ng-if="!element.icon">{{element.content}}</span>
                    <span ng-if="element.icon"><icon type="{{element.icon}}"></icon></span>
                </md-button>
            </div>
        `,

        controller: function () {
            var ctrl = this;

            ctrl.$onInit = () => {
               ctrl.setClasses();
            }

            ctrl.$onChanges = (changes) => {
                ctrl.setClasses();
            }

            ctrl.setClasses = function () {
                ctrl.elements.forEach(element => {
                    if (!element.class) element.class = "md-primary";
                })
            }

            ctrl.select = function (element) {
                if (ctrl.readOnly) return;

                element.selected = !element.selected;

                if (ctrl.selectType != "check") ctrl.elements.forEach((e) => {
                    if (e != element) e.selected = false;
                });

                ctrl.setClasses();

                if (ctrl.onSelect) ctrl.onSelect();
            }
        }
    });
})();
