(function () {
    "use strict";

    angular.module("app").component("userSelect", {
        template: `
			<md-input-container class="md-block">
				<label>{{$ctrl.label || "Select User"}}</label>

				<md-select ng-model="$ctrl.bindTo" ng-model-options="{trackBy: '$value._id'}" ng-name="$ctrl.name" ng-required="$ctrl.required">
                    <md-option ng-if="!$ctrl.required" ng-value="undefined" ng-selected="!$ctrl.bindTo">No user selected</md-option>
                    <md-option ng-repeat="user in $ctrl.users | orderBy:['vendorContact', 'profile.name']" ng-value="user">{{user.profile.name}}</md-option>
				</md-select>
			</md-input-container>
        `,

        bindings: {
            label: "@",
            bindTo: "=",
            required: "=?",
            users: "=?",
            ids: "=?",
            name: "@?",
            excludeEmailOnly: "=?",
            exclude: "=?",
            excludeArchived: "=?",
        },

        controller: function ($http) {
            var ctrl = this;

            ctrl.$onInit = () => {
                if (ctrl.excludeArchived == undefined) {
                    ctrl.excludeArchived = true;
                }

                if (!ctrl.name) {
                    ctrl.name = "user";
                }

                if (!ctrl.users) {
                    $http.get("/api/users/simple").then(function (result) {
                        ctrl.users = result.data.filter(user => {
                            if (ctrl.excludeArchived) {
                                if (user.status.state == "archived") {
                                    return false;
                                }
                            }

                            if (ctrl.excludeEmailOnly) {
                                if (user.emailonly) {
                                    return false;
                                }
                            }

                            if (ctrl.exclude) {
                                if (user._id == ctrl.exclude) {
                                    return false;
                                }
                            }

                            return !user.disabled;
                        });
                    });
                }
            }
        }
    });
})();
