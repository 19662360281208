(function () {
    "use strict";

    angular.module("app").factory("ECommerce", ECommerceProvider);

    function ECommerceProvider($resource, $mdDialog) {

        function ECommerceProvider() {
            var self = this;

            self.resource = $resource(
                "/api/ecommerce",
                {},
                {
                    updatePlan: {
                        method: 'POST',
                        url: '/api/ecommerce/subscription',
                        isArray: false
                    },
                    getPaymentMethod: {
                        method: 'GET',
                        url: '/api/ecommerce/paymentMethod',
                        isArray: false
                    },
                    getToken: {
                        method: 'GET',
                        url: '/api/ecommerce/getToken',
                        isArray: false
                    },
                    getSubscription: {
                        method: 'GET',
                        url: '/api/ecommerce/subscription',
                        isArray: false
                    },
                    cancelSubscription: {
                        method: 'DELETE',
                        url: '/api/ecommerce/subscription',
                        isArray: false
                    },
                    getClientToken: {
                        method: 'GET',
                        url: '/api/ecommerce/getClientToken',
                        isArray: false
                    },
                    updatePaymentMethod: {
                        method: 'POST',
                        url: '/api/ecommerce/paymentMethod',
                        isArray: false
                    },
                    updateCustomer: {
                        method: 'POST',
                        url: '/api/ecommerce/customer',
                        isArray: false
                    },
                    getPricing: {
                        method: 'GET',
                        url: '/api/ecommerce/pricing'
                    },
                });

            self.editBillingPlan = function (organization, subscription) {

                return $mdDialog.show({
                    templateUrl: "/assets/html/ecommerce/edit-billing-plan-modal.html",
                    controller: "EditBillingPlanController",
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,
                    locals: {
                        organization: organization,
                        subscription: subscription
                    }
                });
            }

            self.editPaymentInfo = function (paymentMethod) {

                return $mdDialog.show({
                    templateUrl: "/assets/html/ecommerce/edit-payment-info-modal.html",
                    controller: "EditPaymentInfoController",
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,
                    locals: {
                        paymentMethod: paymentMethod
                    }
                });
            }

            return self;
        }

        return new ECommerceProvider();
    }
})();
