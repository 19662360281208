(function () {
    angular.module("app").factory("Session", SessionProvider);

    function SessionProvider($q, $cookies, $http, $resource, Error) {
        var UserResource = $resource("/api/user");

        function Session() {
            var self = this;

            self.errorHandler = Error.get("session");

            self.current = UserResource.get();
            self.currentUser = self.current;

            self.loadUser = loadUser;
            self.switchOrganization = switchOrganization;
            self.logout = logout;

            self.current.$promise.then(function () {}, function (a, b) {});

            function loadUser(cb) {
                self.current = UserResource.get();
                self.currentUser = self.current;
            }

            function switchOrganization(organization) {
                if (organization.disabled) {
                    return;
                }

                $cookies.remove("logo.url");
                $cookies.remove("logo.display");
                $cookies.remove("logo.deleted");

                $http.post("/api/auth/switch-organization/" + organization._id).then(res => {
                    window.location = "/";
                }).catch(res => {
                    console.error(res)
                });
            }

            function logout(cb) {
                $http.get("/api/auth/logout").then(function (response) {
                    window.location = "/login";
                    if (cb) cb();
                }, function (response) {
                    self.errorHandler.process(response.data);
                    if (cb) cb(self.errorMessage);
                });
            }
        }

        return new Session();
    }

    //TODO factory
    function getQueryVariable(variable) {
        var query = window.location.search.substring(1);
        var vars = query.split('&');

        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');

            if (decodeURIComponent(pair[0]) == variable) {
                return decodeURIComponent(pair[1]);
            }
        }
    }
})();