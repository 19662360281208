(function () {
    "use strict";

    angular.module("app").factory("ControlMetrics", function () {
        var self = this;

        self.containerTypeMetrics = containerTypeMetrics;

        function containerTypeMetrics (containers) {
            var metrics = {
                containers: containers.length,

                risk: {
                    controls: {
                        min: null,
                        max: null,
                    }
                }
            }

            var sortedByControls = _.sortBy(containers, (c) => {
                return c.controlState.current.metrics[0].value;
            })

            metrics.risk.controls.max = _.last(sortedByControls)
            metrics.risk.controls.min = _.head(sortedByControls)

            return metrics;
        }

        return self;
    });
})();
