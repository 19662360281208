/**
 * @ngdoc component
 * @name app.container.component:containerCard
 * @author Nick Vorie
 *
 * @param {object} container The Container to render the view for
 * @param {string} type      The type of Container
 *
 * @description
 * This component will render the card for a given Component
 */
(function () {
    "use strict";

    angular.module("app").component("containerCard", {
        bindings: {
            container: "=",
            type: "@",
            show: "=?"
        },

        template: `
            <user-card ng-if="$ctrl.type == 'user'" user="$ctrl.container" trainings="$ctrl.trainings" awarenesses="$ctrl.awarenesses" show="$ctrl.show"></user-card>

			<domain-card ng-if="$ctrl.type == 'domain'" domain="$ctrl.container" show="$ctrl.show"></domain-card>

			<site-card ng-if="$ctrl.type == 'site'" site="$ctrl.container" show="$ctrl.show"></site-card>

            <vendor-card ng-if="$ctrl.type == 'vendor'" vendor="$ctrl.container" show="$ctrl.show"></vendor-card>

            <technology-card ng-if="$ctrl.type == 'technology'" technology="$ctrl.container" show="$ctrl.show"></technology-card>
        `,

        controller: function (Awareness, Training) {
            var ctrl = this;

            ctrl.$onInit = () => {
                if (!ctrl.show) {
                    ctrl.show = {
                        media: true,
                        actions: false,
                        chip: false
                    }
                }

				if (ctrl.type == "user") {
					ctrl.awarenesses = Awareness.resource.query();
					ctrl.trainings = Training.resource.query();
				}
            }
        }
    });
})();
