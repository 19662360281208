(function () {
    angular.module("app").component("billingPlan", {
        bindings: {
            organization: "=",
            subscription: "="
        },
        template: `
            <md-card>
                <md-card-header><md-card-header-text><h4>Plan Information</h4></md-card-header-text></md-card-header>
                <md-card-content layout="column" style="min-height:110px">
                        <div layout="row">
                            <label flex="30">License</label>
                            <p flex>
                                {{$ctrl.organization.status.licensed ? "Active" : "Inactive"}}
                                <span ng-if="$ctrl.organization.license"> | Users: {{$ctrl.organization.license.users}} | Containers: {{$ctrl.organization.license.containers}}</span>
                            </p>
                        </div>
                        <div layout="row" ng-if="$ctrl.organization.license.type">
                            <label flex="30">Type</label>
                            <p flex>{{$ctrl.organization.license.type}}</p>
                        </div>
                        <div ng-if="$ctrl.organization.license" layout="row">
                            <label flex="30">Term</label>
                            <p flex>{{$ctrl.organization.license.term=='monthly' ? 'Monthly' : $ctrl.organization.license.term=='annual' ? 'Annual' : ''}}</p>
                        </div>
                        <div ng-if="$ctrl.organization.license" layout="row">
                            <label flex="30">Expiration</label>
                            <p flex>{{$ctrl.organization.license.expiration | date:  "MMMM d, y"}}</p>
                        </div>
                        <div ng-if="$ctrl.organization.license" layout="row">
                            <label flex="30">Contact</label>
                            <div flex>
                                {{$ctrl.billingContact.profile.name}}
                                <md-button class="md-icon-button" ng-click="$ctrl.editContact()" style="padding:0px; line-height: inherit; min-height: inherit; height: inherit">
                                    <md-tooltip>Change billing contact</md-tooltip>
                                    <i class="fas fa-edit"></i>
                                </md-button>
                            </div>
                        </div>

                </md-card-content>

                <md-divider></md-divider>

                <md-card-actions ng-hide="$ctrl.organization.license.type=='managed'" flex layout="row" layout-align="end center">
                    <md-button class="" ng-click="$ctrl.editPlan()" aria-label="Update">
                        Edit Plan
                    </md-button>
                </md-card-actions>
            </md-card>
        `,

        controller: function ($mdToast, $http, Organization, ECommerce, Users) {
            var ctrl = this;

            ctrl.$onInit = function () {

                if (!ctrl.organization) {
                    Organization.current.$promise.then(function (organization) {
                        ctrl.organization = organization;

                        Users.resource.get({
                            id: ctrl.organization.license.contact
                        }).$promise.then(function (user) {
                            ctrl.billingContact = user;
                        });

                        if (ctrl.organization.license.type == 'subscription') {
                            ECommerce.resource.getSubscription().$promise.then(function (subscription) {
                                ctrl.subscription = subscription;
                            }, function (err, result) {
                                ctrl.sysError("Unable to get subscription details at this time.", err);
                            });
                        }
                    });
                } else {
                    Users.resource.get({
                        id: ctrl.organization.license.contact
                    }).$promise.then(function (user) {
                        ctrl.billingContact = user;
                    });
                    if (!ctrl.subscription && ctrl.organization.license.type == 'subscription') {
                        ECommerce.resource.getSubscription().$promise.then(function (subscription) {
                            ctrl.subscription = subscription;
                        }, function (err, result) {
                            ctrl.sysError("Unable to get subscription details at this time.", err);
                        });
                    }
                }


            }

            ctrl.editPlan = function () {
                ECommerce.editBillingPlan(ctrl.organization, ctrl.subscription).then(function (resp) {
                    if (resp) {
                        ctrl.subscription = resp;

                        $http.get("/api/organization/current").then(function (result) {
                            ctrl.organization = result.data;
                            Users.resource.get({
                                id: ctrl.organization.license.contact
                            }).$promise.then(function (user) {
                                ctrl.billingContact = user;
                            });
                        });

                    }

                }, function () {
                    // cancel
                });
            }

            ctrl.editContact = function () {
                Users.selectUser({
                    instruction: "Select a person as your primary billing contact.  This person will receive purchase receipts via email.",
                    saveButton: "Select User"
                }).then(function (user) {

                    if (ctrl.organization.license.type == 'subscription') {
                        var data = {
                            billingContact: user
                        }

                        ECommerce.resource.updateCustomer(data).$promise.then(function (result) {
                            ctrl.billingContact = user;
                            $mdToast.show($mdToast.simple().textContent("Billing contact updated."));
                        }, function (err, result) {
                            ctrl.sysError("Unable to update plan at this time.", err);
                        });
                    } else {
                        ctrl.organization.license.contact = user;
                        ctrl.organization.$save().then(function (org) {
                            ctrl.billingContact = user;
                            $mdToast.show($mdToast.simple().textContent("Billing contact updated."));
                        }, function (err) {
                            ctrl.sysError("Unable to update billing contact at this time.", err);
                        });

                    }



                }, function () {});
            }

            ctrl.sysError = function (msg, err) {
                $mdToast.show(
                    $mdToast.simple()
                    .content('ERROR: ' + msg + ' Please try again or contact support. \n reference: ' + err.data.reference)
                    .position('top left')
                    .theme("error")
                    .action('CLOSE')
                    .hideDelay(0)
                );
            }
        },

    });
})();