(function () {
    "use strict";

    angular.module("app").component("messageCard", {
        template: `
            <md-card ng-if="$ctrl.organization.settings.dashboardMessage.enabled && ($ctrl.hasMessage || $ctrl.edit)">
                <md-card-content>
                    <md-content md-transparent style="padding:8px">
                        <h2 ng-bind-html="$ctrl.organization.settings.dashboardMessage.title"></h2>
                        <p ng-bind-html="$ctrl.organization.settings.dashboardMessage.body"></p>
                        <p ng-hide="$ctrl.hasMessage">Edit this card to display a message to all {{$ctrl.organization.phrase.employee}}s</p>
                    </md-content>
                </md-card-content>

                <md-card-actions ng-if="$ctrl.edit" layout="row" layout-align="end center">
                    <md-button class="md-icon-button" ng-click="$ctrl.editMessageDialog($event)" aria-label="Edit">
                        <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                    </md-button>

                    <md-button class="md-icon-button" ng-click="$ctrl.deleteMessage($event)" aria-label="Delete">
                        <md-icon md-font-icon="fa-trash-o" class="fa"></md-icon>
                    </md-button>
                </md-card-actions>
            </md-card>
        `,

        bindings: {
            organization: '<',
            edit: '<'
        },

        controller: MessageCardController

    });

    var dialogTemplate = '\
        <md-dialog>\
            <form name="form" ng-submit="update()">\
                <md-toolbar>\
                    <div class="md-toolbar-tools">\
                        <h2>Edit Message</h2>\
                    </div>\
                </md-toolbar>\
                <md-dialog-content style="min-width:600px;padding:12px;padding-top:22px">\
                    <md-input-container class="md-block" flex>\
                        <label class="md-label">Title</label>\
                        <input ng-model="organization.settings.dashboardMessage.title" required>\
                    </md-input-container>\
                    <md-input-container class="md-block" flex>\
                        <label class="md-label">Content</label>\
                        <text-angular ng-model="organization.settings.dashboardMessage.body"></text-angular>\
                    </md-input-container>\
                </md-dialog-content>\
                <md-dialog-actions layout="row">\
                    <md-button ng-click="cancelDialog()" class="md-button">Cancel</md-button>\
                    <md-button type="submit" class="md-button md-primary md-raised">Save</md-button>\
                </md-dialog-actions>\
            </form>\
        </md-dialog>\
    ';

    function DialogControler($scope, $mdDialog, $mdToast, Organization) {
        var ctrl = this;

        ctrl.$onInit = function () {

            Organization.current.$promise.then(function (org) {
                $scope.organization = org;
            });

        }

        $scope.cancelDialog = function () {
            $mdDialog.cancel();
        };

        $scope.update = function () {
            $scope.organization.$save().then(function (org) {

                $mdDialog.hide(org);

                $mdToast.show(
                    $mdToast.simple()
                        .textContent("Saved message")
                        .hideDelay(3000)
                );

            }, function (err) {
                console.log("ERROR", err);
            });

        }
    }

    function MessageCardController($mdDialog, $mdToast, Organization) {
        var ctrl = this;
        var msg = "";

        ctrl.$onInit = function () {

            if (ctrl.organization.settings.dashboardMessage) {
                msg = ctrl.organization.settings.dashboardMessage.title + ctrl.organization.settings.dashboardMessage.body;

                if (msg && msg != "") {
                    ctrl.hasMessage = true;
                } else {
                    ctrl.hasMessage = false;
                }
            } else {
                ctrl.hasMessage = false;
            }

        }

        ctrl.editMessageDialog = function (ev) {

            $mdDialog.show({
                template: dialogTemplate,
                controller: DialogControler,
                parent: angular.element(document.body),
                locals: { organization: ctrl.organization },
                bindToController: true,
                clickOutsideToClose: true,
                targetEvent: ev
            })
                .then(function (resp) {
                    ctrl.organization = resp;
                    ctrl.hasMessage = true;
                    $mdDialog.hide();
                }, function (resp) {
                    $mdDialog.hide();
                });
        }

        ctrl.deleteMessage = function (ev) {
            Organization.current.$promise.then(function (org) {
                org.settings.dashboardMessage.title = "";
                org.settings.dashboardMessage.body = "";

                org.$save().then(function (org) {
                    ctrl.organization = org;
                    ctrl.hasMessage = false;

                    $mdToast.show(
                        $mdToast.simple()
                            .textContent("Message cleared")
                            .hideDelay(3000)
                    );

                }, function (err) {
                    console.log("ERROR", err);
                });
            });

        }

    }

})();
