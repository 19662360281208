(function () {
    "use strict";

    angular.module("app").component("badge", {
        bindings: {
            options: "@?"
        },

        transclude: true,

        template: `
            <span class="md-badge" ng-class="$ctrl.options" ng-transclude></span>
        `,

        controller: function ($element) {
            var ctrl = this;

            ctrl.$onInit = () => {
                //Fix for border-radius clipping badges in some cases
                $element.parent().css("overflow", "visible");
                $element.parent().css("position", "relative");
            }
        }
    });
})();
