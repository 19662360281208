(function () {
    "use strict";

    angular.module("app").component("remediationBuilder", {
        bindings: {
            controlState: "=",
            container: "=",
        },

        require: {
            parent: "^^controlStateCard"
        },

        template: `
            <div layout-align="space-between start">
                <div layout="column" layout-grow>
                    <div class="padding-x padding-2-y" layout="row" ng-if="!$ctrl.parent.readOnly">
                        <p flex="50">Due Date</p>
                        <md-datepicker name="date" flex="50" ng-model="$ctrl.remediation.dueDate" md-placeholder="Due Date"></md-datepicker>
                    </div>

                    <md-list layout-padding-none condensed>
                        <md-list-item ng-if="$ctrl.controlState.state.answer._id != 'in-place'">
                            <md-checkbox class="md-accent" ng-model="$ctrl.remediation.action.enable" ng-change="$ctrl.updateRemediationPlan()" ng-disabled="$ctrl.parent.readOnly"></md-checkbox>
                            <p>{{$ctrl.controlState.control.directive.add || 'Add the control'}}</p>
                        </md-list-item>

                        <md-list-item ng-if="$ctrl.controlState.state.answer._id == 'in-place'">
                            <md-checkbox class="md-accent" ng-model="$ctrl.remediation.action.disable" ng-change="$ctrl.updateRemediationPlan()" ng-disabled="$ctrl.parent.readOnly"></md-checkbox>
                            <p>{{$ctrl.controlState.control.directive.remove || 'Remove the control'}}</p>
                        </md-list-item>

                        <md-list-item ng-repeat="attribute in $ctrl.remediation.attributes" ng-if="$ctrl.remediation.action.enable">
                            <md-checkbox ng-model="attribute.directive.enable" ng-change="$ctrl.updateRemediationPlan()" class="md-accent margin-l" ng-disabled="$ctrl.parent.readOnly"></md-checkbox>
                            <p>Add {{attribute.title}}</p>
                        </md-list-item>

                        <md-list-item ng-repeat="attribute in $ctrl.remediation.attributes" ng-if="($ctrl.controlState.state.answer._id == 'in-place' && !$ctrl.remediation.action.disable)">
                            <md-checkbox ng-if-start="$ctrl.controlState.state.attributes[$index].selected" ng-model="attribute.remediation.disable" ng-change="$ctrl.updateRemediationPlan()" class="md-accent margin-l" ng-disabled="$ctrl.parent.readOnly"></md-checkbox>
                            <p ng-if-end>Remove {{attribute.title}}</p>

                            <md-checkbox ng-if-start="!$ctrl.controlState.state.attributes[$index].selected" ng-model="attribute.remediation.enable" ng-change="$ctrl.updateRemediationPlan()" class="md-accent margin-l" ng-disabled="$ctrl.parent.readOnly"></md-checkbox>
                            <p ng-if-end>Add {{attribute.title}}</p>
                        </md-list-item>
                    </md-list>
                </div>
            </div>
        `,

        controller: function ($scope, Remediation) {
            var ctrl = this;

            ctrl.Remediation = Remediation;

            ctrl.$onInit = () => {
                //Set remediation data or create defaults
                if (ctrl.controlState.remediation) {
                    ctrl.remediation = ctrl.controlState.remediation;
                } else {
                    ctrl.remediation = {
                        control: ctrl.controlState.control._id,

                        dueDate: moment().add(1, "week").toDate(),

                        action: {
                            enable: false,
                            disable: false,

                            attributes: []
                        }
                    }

                    //Copy attributes
                    if (ctrl.controlState.control.attributes) {

                    }
                }

                var containerType = _.get(ctrl, "container.type.type", "users");

                if (containerType == "organization") {
                    containerType = "domain";
                }

                //Setup instance for api operations
                ctrl.RemediationInstance = new Remediation.instance({
                    endpoint: "/api/" + containerType,
                    containerId: ctrl.container._id,
                });

                //Listen for when control state changes. higher prefomance than using $watch
                ctrl.parent.onStateChange = () => {
                    if (ctrl.controlState.state.answer && ctrl.controlState.state.answer._id == "in-place") {
                        ctrl.remediation.action.enable = false;
                    } else {
                        ctrl.remediation.action.disable = false;
                    }
                }
            }

            console.log("REM1", ctrl.remediation);

            ctrl.updateRemediationPlan = () => {
                console.log("REM2", ctrl.remediation);

                //Reset attributes
                if (ctrl.remediation.action.attributes) {
                    ctrl.remediation.action.attributes.forEach((a) => {
                        if (ctrl.remediation.action.disable) a.remediation.enable = false;
                        if (ctrl.remediation.action.enable) a.remediation.disable = false;
                    });
                }

                //Save plan
                console.log("REM3", ctrl.remediation);

                ctrl.RemediationInstance.add(null, ctrl.remediation, (res) => {
                    if (!ctrl.container.remediation) {
                        ctrl.container.remediation = {};
                    }

                    ctrl.container.remediation.current = res.data.remediation.current
                });
            }
        }
    });
})();