/**
 * @ngdoc service
 * @name app.utility.service:Import
 * @author Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Imports
 */
(function () {
    "use strict";

    angular.module("app").factory("Import", function ($http, $mdDialog, $mdToast) {
        var self = this;

        /*
         * @ngdoc method
         * @methodOf app.utility.service:Import
         * @name import
         *
         * @description Display an import modal
         * */
        function _import($event, options, documents, cb) {
            //Call dialog

            console

            $mdDialog.show({
                templateUrl: `/assets/html/utility/import/modal.html`,

                locals: {
                    options: options,
                },

                controller: ($scope, options) => {
                    $scope.data = {
                        csv: "",
                    }

                    $scope.options = options;

                    //Update row count
                    $scope.$watch(function () {
                        return $scope.data.csv;
                    }, function (value) {
                        if (!value.length) {
                            $scope.data.rows = 0;
                        } else {
                            $scope.data.rows = value.split(/\r\n|\n|\r/).length - 1;
                        }
                    })

                    //Read file locally and set dava.csv
                    $scope.uploadFile = function ($file) {
                        if (!$file) {
                            return;
                        }

                        var reader = new FileReader();

                        reader.onload = function (e) {
                            //Wrap async call in $apply
                            $scope.$apply(function () {
                                $scope.data = {
                                    csv: e.target.result,
                                }
                            });
                        }

                        reader.readAsBinaryString($file);
                    }

                    //Save callback
                    $scope.save = function () {
                        var data = $scope.data.csv

                        $scope.error = null;
                        $scope.errors = null;

                        $scope.loading = true;

                        $http.post(options.endpoint, {
                            data
                        }).then((res) => {
                            $scope.loading = false;

                            $mdDialog.hide(res.data.documents);
                            $mdToast.show($mdToast.simple().textContent("Imported " + res.data.documents.length + " items"));
                        }, (res) => {
                            $scope.loading = false;

                            $mdToast.show($mdToast.simple().textContent("Could not import"));

                            //TODO make directive/filter?

                            $scope.error = res.data.error;

                            if (res.data.error.description) {
                                $scope.error = res.data.error.description;
                            }

                            if (res.data.error.message) {
                                $scope.error = res.data.error.message;

                                if (typeof res.data.error.message == "object") {
                                    $scope.error = null;
                                    $scope.errors = res.data.error.message;
                                }
                            }
                        });
                    }

                    //Cancel callback
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    }
                },

                targetEvent: $event,
                parent: angular.element(document.body),
                bindToController: true,
                clickOutsideToClose: true,
            }).then((imported) => {
                if (options.replace) {
                    documents = imported;
                } else {
                    documents.push(...imported);
                }

                if (cb) {
                    cb();
                }
            }).catch(() => {});
        }

        self.import = _import;

        return self;
    });
})();