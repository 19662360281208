(function () {
    "use strict";

    angular.module("app").factory("Incident", IncidentFactory);

    function IncidentFactory ($resource, $http) {

        function IncidentProvider() {
            var self = this;

            self.resource = $resource("/api/incident/:id", {
                id: "@_id"
            }, {
                update: {
                    method:'POST',
                    url: '/api/incident/:id',
                    isArray: false
                }
            });

                return self;
            }

        return new IncidentProvider();
    }

})();
