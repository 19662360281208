(function () {
    angular.module("app", ["ngCookies", "ngAnimate", "ngResource", "ngFileUpload", "xeditable", "ui.mask", "ngMaterial", "ngMessages", "ngSanitize", "ui.tree", "ngIdle", "ngMaterialDatePicker", "textAngular"])
        .config(function ($locationProvider, $compileProvider, $provide, $mdAriaProvider, $mdThemingProvider, $mdDateLocaleProvider, IdleProvider, KeepaliveProvider) {
            $compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|mailto|otpauth|tel):/);

            $provide.decorator("$exceptionHandler", function ($delegate) {
                return function (exception, cause) {
                    $delegate(exception, cause);

                    setTimeout(function () {
                        throw exception;
                    });
                };
            });

            $mdAriaProvider.disableWarnings();

            $mdThemingProvider.theme("default")
                .primaryPalette("light-blue", {
                    "default": "800",
                    "hue-1": "700",
                    "hue-2": "500",
                    "hue-3": "900"
                })
                .accentPalette("blue")
                .warnPalette("red", {
                    default: "800"
                })
                .backgroundPalette("grey");

            $mdThemingProvider.theme("error").primaryPalette('red');

            // $mdDateLocaleProvider.parseDate = function (dateString) {
            //     var m = moment(dateString, 'L', false);
            //     return m.isValid() ? m.toDate() : new Date(NaN);
            // }

            // $mdDateLocaleProvider.formatDate = function (date) {
            //     var m = moment(date);
            //     return m.isValid() ? m.format('L') : '';
            // }

            $mdDateLocaleProvider.formatDate = function (date) {
                var m = moment(date);
                return m.isValid() ? m.format('L') : '';
            };

            $mdDateLocaleProvider.parseDate = function (dateString) {
                var m = moment(dateString, 'L', false);
                return m.isValid() ? m.toDate() : new Date(NaN);
            };

            IdleProvider.idle(15 * 60);
            IdleProvider.timeout(2 * 60);
            KeepaliveProvider.interval(5 * 60);
            KeepaliveProvider.http("/api/keep-alive");
        }).run(function ($rootScope, Idle, $http, $mdDialog, Session) {
            Session.current.$promise.then(user => {
                if (user) {
                    Idle.watch();

                    $rootScope.$on('IdleStart', function () {
                        var confirm = $mdDialog.confirm()
                            .title("Session Expiring Soon")
                            .textContent("We've noticed you have been idle, so you will be signed out in two minutes.")
                            .ok("Sign Out")
                            .cancel("Cancel");

                        $mdDialog.show(confirm).then(function () {
                            $http.get("/api/auth/logout").then(logout, logout);
                        }, function () {});
                    });

                    $rootScope.$on('IdleTimeout', function () {
                        $http.get("/api/auth/logout").then(function () {
                            logout(true);
                        }, function () {
                            logout(true);
                        });
                    });

                    function logout(redirect) {
                        const base = `/login`;

                        if (redirect) {
                            const params = encodeURIComponent(`${window.location.pathname}`);
                            const url = `${base}?redirectUrl=${params}`;

                            return window.location.href = url;
                        }

                        return window.location.href = base;
                    }
                }
            })
        }).directive("initials", function () {
            return {
                template: '<span>{{initials}}</span>',
                scope: {
                    input: "@"
                },
                link: function ($scope) {
                    $scope.initials = "";
                    $scope.input.split(" ").forEach(function (string) {
                        $scope.initials += string.substr(0, 1);
                    });
                }
            }
        }).directive("capitaliseFirst", function () {
            function capitaliseFirst(string) {
                return string.charAt(0).toUpperCase() + string.slice(1);
            }

            return function (scope, element, attrs) {
                element.html(capitaliseFirst(attrs.capitaliseFirst));
            }
        }).directive("conlectio", function () {
            return {
                template: '<span>{{content}}</span>',
                scope: {
                    input: "=",
                    showEmpmty: "="
                },
                controller: function ($scope) {
                    var data = $scope.input;
                    var showEmpmty = $scope.showEmpmty;

                    var content = "";
                    var trackedDataInputs = [];

                    angular.forEach(data, function (value, key) {
                        if (!value) {
                            return this.push(value = " 0 " + key + "s");
                        }

                        this.push(value.length + " " + key + (value.length != 1 ? "s" : ""));
                    }, trackedDataInputs);

                    var delimiter = "";

                    angular.forEach(trackedDataInputs, function (value, key) {
                        content += delimiter + value;

                        if (key == trackedDataInputs.length - 2) {
                            delimiter = " and ";
                        } else if (key == 0) {
                            delimiter = ", ";
                        } else {
                            delimiter = ""; //TODO
                        }
                    });

                    $scope.content = content;
                }
            }
        }).filter("startAt", () => {
            return (input, start) => {
                if (!input) {
                    return;
                }

                start = +start;
                return input.slice(start);
            }
        }).filter("trusted", function ($sce){
            return function(html){
                return $sce.trustAsHtml(html)
            }
         });
})();