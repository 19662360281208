(function () {
    "use strict";

    angular.module("app").controller("SelectUserController", function ($scope, $mdDialog, $http, options) {

        $scope.users = [];
        $scope.selectedUser = null;
        $scope.searchText = "";

        this.$onInit = function () {

            $scope.options = _.defaults(options, {
                title: "Select User",
                instruction: "Select a user",
                placeholder: "Type to search for a user",
                saveButton: "Save",
                cancelButton: "Cancel"
            });


            $http.get("/api/users/simple").then(function (result) {
                $scope.users = result.data.filter(user => {
                    return !user.disabled;
                });

            });

        }

        $scope.saveAndClose = function () {

            $mdDialog.hide($scope.selectedUser);
        }

        $scope.cancel = function () {
            $mdDialog.cancel();
        }

        $scope.querySearch = function (criteria) {

            return $scope.users.filter(function (user) {
                return user.profile.name.toLowerCase().includes(criteria.toLowerCase());
            });

        }

    });

})();
