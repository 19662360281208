(function () {
    "use strict";

    angular.module("app").factory("ControlState", ControlStateFactory);

    function ControlStateFactory ($resource, $http, $mdToast, Session, Cvss) {
        var ControlStateValueResource = $resource("/api/control/control-state-value");

        var controlEffectivenesses = [];
        var effectivenessPromise = $http.get("/api/control/effectiveness");

        effectivenessPromise.then(res => {
            controlEffectivenesses = res.data;
        });

        function ControlStateProvider () {
            var self = this;

			//Utilities
			self.controlsInPlace = function (controlStates) {
				var totalControls = 0;
				var inPlace = 0;

				controlStates.filter(controlState => {
					if (_.get(controlState, "state.accepted")) {
						return false;
					}

					if (_.get(controlState, "state.answer._id") == "na") {
						return false;
					}

					return true;
				}).forEach(controlState => {
					if (_.get(controlState, "state.answer._id") == "in-place") {
						inPlace++;
					}

					totalControls++;
				});

				return _.round((inPlace / totalControls) * 100);
			}

			self.threatCount = function (threatScores) {
				var counts = {
					critical: 0,
					high: 0,
					medium: 0,
					low: 0
				}

				threatScores.forEach(threatScore => {
					var severity = Cvss.getSeverity(threatScore.weightedScore);
					counts[severity.name.toLowerCase()]++;
				});

				return counts;
			}

            //TC - exposed controlEffectivenesses to use as selection list when editing controls
            self.controlEffectivenesses = controlEffectivenesses;

			var ControlEffectiveness = {
                cached: function (id, cb) {

                    if (!controlEffectivenesses.length) {
                        effectivenessPromise.then(() => {
                            find();
                        })
                    } else {
                        find();
                    }

                    function find () {
                        cb(_.find(controlEffectivenesses, (e) => {
                            return e._id == id;
                        }))
                    }
                }, byOrder: function (order, cb) {
                    if (order < 1) order = 1;
                    if (order > 8) order = 8;

                    if (!controlEffectivenesses.length) {
                        effectivenessPromise.then(() => {
                            find();
                        })
                    } else {
                        find();
                    }

                    function find () {
                        cb(_.find(controlEffectivenesses, (e) => {
                            return e.order == order;
                        }))
                    }
                }
            }

            self.populateThreatRanges = populateThreatRanges;

            //@depricated - use calculateThreatRange
            function populateThreatRanges(controlState) {
                if (!controlState.threatScores || controlState.threatScores.length == 0) {
                    return null;
                }

                var scores = controlState.threatScores.map(function (score) {
                    return score.score;
                });

                var min = _.round(_.min(scores), 1);
                var max = _.round(_.max(scores), 1);

                var vals = [min];

                if (min > 0 && max > 0 && max > min) vals.push(max);

                controlState.threatRange = vals;

                return controlState;
            }

            self.calculateThreatRange = calculateThreatRange;

            function calculateThreatRange (threatPairs) {
                var scores = threatPairs.map(function (pair) {
                    if (pair.score) return pair.score;

                    return pair.threat.inherentRisk.baseScore;
                });

                var min = _.round(_.min(scores), 1);
                var max = _.round(_.max(scores), 1);

                var range = {
                    min: min
                }

                if (min > 0 && max > 0 && max > min) range.max = max;

                return range;
            }

            self.calculateThreatScores = calculateThreatScores;

            function calculateThreatScores (controlStates) {
                var threatPairs = _.flatten(controlStates.filter(state => !checkAnswer(state, "na")).map((controlState) => {
                    return controlState.threatScores;
                }));

                var threatIndex = {}

                threatPairs.forEach((pair) => {
                    var cached = threatIndex[pair.threat._id];

                    if (cached) {
                        if (pair.score > cached.score.max) {
                            cached.score.max = pair.score;
                        } else if (pair.score < cached.score.min || (cached.score.min == -1 && cached.score.max != pair.score)) {
                            cached.score.min = pair.score;
                        }

                        threatIndex[pair.threat._id] = cached;
                    } else {
                        threatIndex[pair.threat._id] = {
                            threat: pair.threat,

                            score: {
                                min: -1,
                                max: pair.score,
                            }
                        }
                    }
                });

                return _.orderBy(_.values(threatIndex), ["score.max", "score.min"], ["desc", "desc"]);
            }

            //Control state

            self.scoreThreatPairs = scoreThreatPairs;

            function scoreThreatPairs (controlState, cb) {
				var self = this;

//				if (!ControlState.applies(controlState)) {
//					return cb([]);
//				}

				var threatScores = [];
				var modifier = getAttributeModifier(controlState.state);
				var inPlace = controlState.state.answer && (controlState.state.answer == "in-place" || controlState.state.answer._id == "in-place")

				async.each(_.get(controlState, "control.risk.threatPairs", []), (pair, cb) => {
					if (inPlace) {
						ControlEffectiveness.cached(pair.effectiveness._id || pair.effectiveness, (eff) => {
							//If effectiveness is unadjusted don't re-query
							if (modifier == 0 ||eff._id == "essential") {
								scoreThreatPair(eff);
							} else  {
								//Query for adjusted effectiveness from order offset-modifier
								ControlEffectiveness.byOrder(eff.order + modifier, (err, adjEff) => {
									scoreThreatPair(adjEff);
								});
							}
						});
					} else {
						ControlEffectiveness.cached("none", scoreThreatPair);
					}

					function scoreThreatPair (adjustedEff) {
						var residualRisk = pair.threat.inherentRisk.baseScore;

						if (pair.threat.inherentRisk && adjustedEff._id != "none") {
							residualRisk = _.round(pair.threat.inherentRisk.baseScore * (1 - adjustedEff.weight), 1);
						}

						threatScores.push({
							threat: pair.threat,
							score: residualRisk,
							weightedScore: residualRisk,
							accepted: controlState.state.accepted,
							effectiveness: adjustedEff,
						});

						cb();
					}
				}, (err) => {
					cb(threatScores);
				});
			}

            self.scoreControlStates = scoreControlStates;

            function scoreControlStates (controlStates, cb) {
                var self = this;

                async.map(controlStates, (controlState, cb) => {
//                    if (checkAnswer(state, "na")) {
//                        return cb(controlState);
//                    }

                    scoreThreatPairs(controlState, (threatScores) => {
                        controlState.threatScores = threatScores;
                        cb(null, controlState);
                    });
                }, (err, controlStates) => {
                    cb(controlStates);
                });
            }

            self.scoreMetrics = scoreMetrics;

            function scoreMetrics (controlStates, cb) {
                var metrics = [];

                var totalControls = 0;
                var controlsInPlace = 0;

                controlStates.forEach((value) => {
                    totalControls++;

                    if (value.state && value.state.answer) {
                        if (value.state.answer == "na" || value.state.answer._id == "na") {
                            totalControls--;
                        }

                        if (value.state.answer == "in-place" || value.state.answer._id == "in-place") {
                            controlsInPlace++;
                        }
                    }
                });

                var inherentRisks = [1];


//				_.flatten(controlStates.filter((controlState) => !!controlState.control.risk.threatPairs).map((controlState) => {
//                    return controlState.control.risk.threatPairs.map((pair) => pair.threat.inherentRisk.baseScore);
//                }));

                var residualScores = [1];

//				_.flatten(controlStates.filter((controlState) => {
//                   return controlState.threatScores && controlState.threatScores.length > 0;
//                }).map((controlState) => {
//                    return controlState.threatScores.map((pair) => {
//                        return pair.score;
//                    });
//                }));

                if (controlStates.length) {
                    metrics.push({
                        title: "Controls in Place",
                        type: "percent",
                        value: _.round((controlsInPlace / totalControls) * 100) || 0,
                    });

                    metrics.push({
                        title: "Inherent Risk",
                        type: "range",
                        value: {
                            min: _.min(inherentRisks),
                            max: _.max(inherentRisks),
                        }
                    });

                    metrics.push({
                        title: "Residual Risk",
                        type: "range",
                        value: {
                            min: _.min(residualScores),
                            max: _.max(residualScores),
                        }
                    });
                } else {
                    metrics.push({
                        title: "Controls in Place",
                        type: "percent",
                        value: 0,
                    });

                    metrics.push({
                        title: "Inherent Risk",
                        type: "range",
                        value: {
                            min: 0,
                            max: 0,
                        }
                    });

                    metrics.push({
                        title: "Residual Risk",
                        type: "range",
                        value: {
                            min: 0,
                            max: 0,
                        }
                    });
                }

                cb(metrics);
            }

            function getAttributeModifier (state) {
                var modifier = 0;

                if (state && state.attributes && state.attributes.length > 0) {
                    modifier = _.max(state.attributes.filter((a) => !!a.selected).map((a) => {
                        return a.modifier;
                    })) || 0;
                }

                return modifier;
            }

            /*
             * @description Check if a ControlState's answer is equal to argument answer
             * */
            function checkAnswer (controlState, answer) {
                return controlState.state && controlState.state.answer && (controlState.state.answer._id || controlState.state.answer) == answer;
            }

            self.checkAnswer = checkAnswer;

            /*
             * @description Check if a ControlState is in place
             * */
            function inPlace (controlState) {
                return self.checkAnswer(controlState, "in-place");
            }

            self.inPlace = inPlace;

            return self;
        }

        return new ControlStateProvider();
    }
})();
