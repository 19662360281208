(function () {
    "use strict";

    angular.module("app").component("card", {
        transclude: true,

        bindings: {
        },

        template: `<div class="card" ng-transclude></div>`,

        controller: function () {}
    })
})();
