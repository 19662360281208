(function () {
	"use strict";

	angular.module("app").controller("UserAddController", function ($scope, $mdDialog, $mdToast, $http, Organization, UserRole, user, organization) {
		$scope.roleData = {
			"email-only": {
				id: "email-only",
				title: "Email Only",
				helpText: "All tasks are managed via email from VSC to the user.",

				data: {
					emailonly: true,
				}
			},

			"standard": {
				id: "standard",
				title: "Standard",
				helpText: "Login access but no administrative permissions.  Tasks are managed via email and/or when logged into VSC.",

				data: {
					emailonly: false,
				}
			},

			"auditor": {
				id: "auditor",
				title: "Auditor",
				helpText: "Login access with read-only access to VSC’s risk analysis and reporting features.",

				data: {
					roles: ["auditor"],
					emailonly: false,
				}
			},

			"user-admin": {
				id: "user-admin",
				title: "User Admin",
				helpText: "Login access and administrative permissions to VSC’s users.",

				data: {
					roles: ["user-admin"],
					emailonly: false,
				}
			},

			"risk-manager": {
				id: "risk-manager",
				title: "Risk Manager",
				helpText: "Login access and administrative permissions to VSC’s risk analysis, reporting, content, configuration, and users.",

				data: {
					roles: ["riskmanager"],
					emailonly: false,
				}
			}
		};

		$scope.organization = Organization.current;

		$scope.organization.$promise.then(() => {
			$scope.emailLink = emailLink();
		});

		$scope.accessLevels = Object.values($scope.roleData);

		$scope.access = {
			level: "email-only"
		}

		//Init user
		init();

		function init() {
			$scope.ready = false;
			$scope.step = 1;
			$scope.errors = {}

			$scope.data = {
				input: ""
			}

			resetUser();
		}

		//Reset a user's data
		function resetUser() {
			$scope.editing = false;
			$scope.userExists = false;

			if (user) {
				$scope.access.level = "standard";

				$scope.step = 2;
				$scope.editing = true;
				$scope.userExists = true;

				$scope.user = _.clone(user);

				var _roles = user.roles.map(r => r._id);
				var roles = {};

				if (user.emailonly) {
					$scope.access.level = "email-only";
				} else if (_roles.includes("auditor")) {
					$scope.access.level = "auditor";
				} else if (_roles.includes("riskmanager") || _roles.includes("cso")) {
					$scope.access.level = "risk-manager";
				} else if (_roles.includes("user-admin")) {
					$scope.access.level = "user-admin";
				}

				$scope.user.roles.forEach(r => roles[r._id || r] = true);
				$scope.user.roles = roles;
			} else {
				$scope.newUser = true;

				$scope.user = {
					forceNewPassword: true,

					roles: {
						workforcemember: true
					}
				}
			}

			$scope.oldUser = _.cloneDeep($scope.user);
		}

		//Fetch roles
		UserRole.resource.query().$promise.then(function (roles) {
			$scope.roles = roles.filter(function (role) {
				if (role._id == "reselleradmin") {
					return organization.license.type == "reseller";
				} else {
					return true;
				};
			}).map(role => {
				if (role._id == "workforcemember") {
					role.name = organization.phrase.employee;
				}

				role.name = role.name.charAt(0).toUpperCase() + role.name.slice(1);

				return role;
			});

			$scope.ready = true;
		});

		//Remove admin roles when email-only is selected
		$scope.updateRoles = function () {
			var disableRoles = $scope.user.registration == "email-only" || $scope.user.emailonly;

			if (disableRoles) {
				$scope.roles.forEach(role => {
					if (role.loginRequired) {
						$scope.user.roles[role._id] = false;
					}
				});
			}
		}

		function emailLink() {
			const organization = $scope.organization;
			var name = organization.shortName || organization.name;
			var body = organization.invitation.user;
			var body1 = body.replace(/{orgName}/g, name).replace(/{url}/g, `https://${window.location.hostname}`);
			body = encodeURI(body1);

			return "mailto:" + $scope.user.email + "?subject=Virtual Security Center Notification&body=" + body;
		}

		$scope.sendEmail = function () {
			$http.get("/api/organization/current").then(res => {
				var name = res.data.shortName || res.data.name;
				var body = res.data.invitation.user;
				var body1 = body.replace(/{orgName}/g, name).replace(/{url}/g, `https://${window.location.hostname}`);
				body = encodeURI(body1);

				window.location.href = "mailto:" + $scope.user.email + "?subject=Virtual Security Center Notification&body=" + body;

			});
		}

		//Hide modal
		$scope.cancel = function () {
			$mdDialog.cancel();
		}

		//Go Back
		$scope.back = function () {
			init();
		}

		//Check if user exists
		$scope.checkUser = function () {
			if (!$scope.data.input) {
				return;
			}

			$scope.userAddForm.input.$setValidity("username-unavailable", true);
			$scope.userAddForm.input.$setValidity("duplicate-user", true);

			$http.get("/api/users/exists/" + $scope.data.input + "/" + (organization._id || "")).then(res => {
				if (res.data.result == "email-unavailable") {
					$scope.user.email = $scope.data.input;
					$scope.userExists = true;
					$scope.step = 2;
				} else if (res.data.result == "email-available") {
					$scope.user.email = $scope.data.input;
					$scope.step = 2;
				} else if (res.data.result == "username-available") {
					$scope.user.username = $scope.data.input;
					$scope.user.registration = "password";
					$scope.step = 2;
				} else if (res.data.result == "duplicate-user") {
					$scope.userAddForm.input.$setValidity("duplicate-user", false);
				} else if (res.data.result == "username-unavailable") {
					$scope.userAddForm.input.$setValidity("username-unavailable", false);
				}

				$scope.notificationEmailUrl = emailLink();
			}).catch(res => {
				console.log(res);
			});
		}

		//Reset avatar
		$scope.resetAvatar = function () {
			$scope.avatar = null;
		}

		//Create new user or save
		$scope.submit = function () {
			var data = _.clone($scope.user);
			var roles = _.keys($scope.user.roles).filter(k => $scope.user.roles[k]);

			data.roles = roles;

			const accessLevel = $scope.roleData[$scope.access.level];

			if (accessLevel) {
				data.roles = data.roles.filter(role => {
					if (role == "riskmanager" || role == "auditor" || role == "user-admin") {
						return false;
					}

					return true;
				});

				const accessLevelData = accessLevel.data;

				if (accessLevelData.roles) {
					accessLevelData.roles.forEach(role => {
						if (!data.roles.includes(role)) {
							data.roles.push(role);
						}
					});
				}

				data.emailonly = accessLevelData.emailonly;
			}

			if (data.emailonly) {
				data.registration = "email-only";
			}

			if (organization) {
				data.organization = organization._id;
			}

			if (!data.email) { }

			if (data._id) {
				var fields = _.pick(data, ["_id", "disabled", "roles", "emailonly", "organization", "dataTypes"]);

				if (data.manager) {
					fields.manager = data.manager._id;
				} else {
					fields.manager = null;
				}

				if ($scope.oldUser.manager && !fields.manager && $scope.user.remediation.current) {
					$mdDialog.show($mdDialog.confirm().multiple(true).title("Warning").textContent("Removing the manager from this container will clear the current remediation plan.").ok("Confirm").cancel("Cancel")).then(function () {
						checkDataTypes();
					});
				} else {
					checkDataTypes();
				}

				function checkDataTypes() {
					if ($scope.oldUser.dataTypes && data.dataTypes && !_.isEqual($scope.oldUser.dataTypes.sort(), data.dataTypes.sort())) {
						$mdDialog.show($mdDialog.confirm().title("Warning").textContent("Changing the data types associated with this container will 'clear' any not-in-place control where you have accepted risk. Review not-in-place controls for this container and re-record accepted risks based on the updated data types.").ok("Confirm").cancel("Cancel")).then(function () {
							save();
						});
					} else {
						save();
					}
				}

				function save() {
					$http.post("/api/users/" + fields._id, fields).then(result => {
						if (!fields.manager && $scope.user.remediation.current) {
							data.remediation.current = null;
						}

						$mdDialog.hide(data);
						$mdToast.show($mdToast.simple().textContent("User saved"));
					}).catch(result => {
						$mdToast.show($mdToast.simple().textContent("User could not be saved"));
					});
				}
			} else {
				$http.post("/api/users", data).then(result => {
					if ($scope.avatar) {
						//TODO avatar upload
						Upload.upload({
							url: "/api/users" + result.data._id + "/avatar",

							data: {
								file: $scope.avatar
							}
						}).then(function (response) {
							$scope.user.profile.avatar = response.data;
							$mdToast.show($mdToast.simple().textContent("Avatar uploaded"));
							$mdDialog.hide(result.data);
						}).catch(function (response) {
							$mdToast.show($mdToast.simple().textContent("Error uploading avatar"));
							$mdDialog.hide(result.data);
						});
					} else {
						$mdDialog.hide(result.data);
					}

					$mdToast.show($mdToast.simple().textContent("User created"));
				}).catch(result => {
					$mdToast.show($mdToast.simple().textContent("User could not be created"));
				});
			}
		}
	});

})();