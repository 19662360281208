(function () {
    "use strict";

    angular.module("app").component("resetPasswordCard", {
        template: `
            <md-card>
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span class="md-headline">Reset Password</span>
                    </md-card-title-text>
                </md-card-title>

                <md-divider></md-divider>

                <md-card-body ng-if="$ctrl.state == 'password'" layout="column">
                    <div class="padding-2" layout="column">
                        <p class="padding-2-b">Please enter and confirm your new password.</p>

                        <md-input-container class="margin-0-b">
                            <label>Email</label>
                            <input type="email" ng-model="$ctrl.user.email" disabled>
                        </md-input-container>

                        <md-input-container class="margin-0-b">
                            <label>Password</label>
                            <input ng-enter="$ctrl.reset()" type="password" ng-model="$ctrl.data.password" name="password">
                            <p ng-if="$ctrl.errors.password" class="text-danger">{{$ctrl.errors.password}}</p>
                        </md-input-container>

                        <md-input-container class="margin-0-b">
                            <label>Password (Repeat)</label>
                            <input ng-enter="$ctrl.reset()" type="password" ng-model="$ctrl.data.passwordRepeat" name="passwordRepeat">
                            <p ng-if="$ctrl.errors.passwordRepeat" class="text-danger">{{$ctrl.errors.passwordRepeat}}</p>
                        </md-input-container>
                    </div>

                    <md-button class="md-raised md-primary" ng-click="$ctrl.reset()">Reset Password</md-button>
                    <md-button class="md-flat md-primary" ng-href="/login">Back to Login</md-button>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'load'" layout="column" layout-align="center center">
                    <md-progress-circular class="margin-y" md-mode="indeterminate"></md-progress-circular>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'error'" layout="column">
                    <div class="padding-2" layout="column">
                        <p class="padding-2-b">Invalid token, please try again.</p>
                    </div>

                    <md-button class="md-raised md-primary" ng-href="/login">Back to Login</md-button>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'success'" layout="column">
                    <div class="padding-2" layout="column">
                        <p class="padding-2-b"></p>
                    </div>

                    <md-button class="md-raised md-primary" ng-href="/login">Back to Login</md-button>
                </md-card-body>
            </md-card>
        `,

        bindings: {
            userId: "<",
            secret: "<",
        },

        controller: function ($http) {
            var ctrl = this;

            ctrl.state = "load";

            ctrl.token = {};
            ctrl.user = {};
            ctrl.data = {};

            ctrl.$onInit = function () {
                $http.get("/api/auth/reset-password/" + ctrl.userId + "/" + ctrl.secret).then(function (response) {
                    ctrl.token = response.data.token;
                    ctrl.user = response.data.user;

                    ctrl.state = "password";
                }, function (response) {
                    ctrl.state = "error";
                });
            }

            ctrl.reset = function () {
                ctrl.state = "load";

                $http.post("/api/auth/reset-password/" + ctrl.user._id + "/" + ctrl.token.secret, ctrl.data).then(function (response) {
                    window.location = "/login";
                }, function (response) {
                    ctrl.error = response.data.error;
                    ctrl.errors = {};

                    ctrl.error.forEach(error => {
                        if (!ctrl.errors[error.param]) {
                            ctrl.errors[error.param] = error.msg;
                        }
                    })

                    ctrl.state = "password";
                });
            }
        }
    })
})();
