(function () {
    const formats = {
        alt: "M/D/YYYY",
        altTime: "M/D/YYYY, h:ma",
        short: "MMM D",
        shortTime: "MMM D, h:ma",
        regular: "MMMM Do YYYY",
        regularTime: "MMMM Do YYYY, h:ma",
        long: "dddd, MMMM Do YYYY",
        longTime: "dddd, MMMM Do YYYY, h:ma",
    }

    angular.module("app").filter("dateFormat", filter);
    // angular.module("app").filter("date", filter);

    function filter() {
        return (date, format) => {
            if (!moment) {
                console.log("Error: momentJS is not loaded as a global");
                return "!momentJS";
            }

            if (!format) {
                format = "regular";
            }

            return moment(date).format(formats[format]);
        }
    }
})();