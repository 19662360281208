(function () {
    "use strict";

    angular.module("app").component("tab", {
        bindings: {
            tab: "=",
            search: "@?",
        },

        require: {
            list: "^tabList"
        },

        template: `
            <a ng-href="#!?{{$ctrl.search}}={{$ctrl.tab.id}}" ng-disabled="$ctrl.tab.disabled" ng-click="$ctrl.list.group.click($ctrl.tab)">
                <li ng-class="{active: $ctrl.tab.active, 'text-muted': !$ctrl.tab.active}" ng-disabled="$ctrl.tab.disabled">
                    <span>{{$ctrl.tab.title}}</span>
                </li>
            </a>
        `,

        controller: function (Tab) {
            var ctrl = this;

            ctrl.Tab = Tab;
        }
    })
})();
