(function () {
    "use strict";

    angular.module("app").component("materialSlider", {
        template: `
            <div class="">
                <div class="form-group-material" style="display: inline-block; width: 80%; float: left;">
                    <input type="range" class="material" ng-model="$ctrl.bindTo">
                    <label>{{$ctrl.label}} ({{$ctrl.bindTo}})</label>
                </div>
                <div class="form-group-material" style="display: inline-block; max-width: 10%; min-width: 40px; float: right;">
                    <input type="number" class="material" ng-model="$ctrl.bindTo" min="0" max="100">
                </div>
            </div>
        `,
        
        bindings: {
            label: "@",
            bindTo: "="
        },
        
        controller: function ($element) {
            var ctrl = this;
            
            ctrl.$postLink = function () {
                var input = $($element.find("input[type=range]"));

                input.on("mousedown", function () {
                    input.addClass("click")
                });
                
                input.on("mouseup", function () {
                    input.removeClass("click")
                });
            }
        }
    }).directive("input", function () {
        return {
            restrict: "E",
            require: "?ngModel",
            link: function(scope, element, attrs, ngModel) {
                if ("type" in attrs && attrs.type.toLowerCase() === "range") {
                    ngModel.$parsers.push(parseFloat);
                }
            }
        }
    });
})();
