(function () {
    angular.module("app").factory("UserRole", UserRoleProvider);

    function UserRoleProvider ($q, $http, $resource, Error) {

        var UserRoleResource = $resource("/api/user-role/userRoles");
        var PolicyRoleResource = $resource("/api/user-role/policyRoles");
        
        function UserRole() {
            var self = this;

            self.resource = UserRoleResource;
            self.policyRoleResource = PolicyRoleResource;
        }

        return new UserRole();
    }
})();
