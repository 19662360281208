(function () {
    "use strict";

    angular.module("app").factory("Technology", function ($resource) {
        var self = this;

        const icon = "server";

        self.icon = icon;

        var Resource = $resource("/api/technology/:id", {
            id: "@_id"
        });

        self.Resource = Resource;

        const Types = [
            {
                name: "server",
                display: "Server",
                icon: "server",
                category: "hardware"
            }, {
                name: "laptop",
                display: "Laptop",
                icon: "laptop",
                category: "hardware"
            }, {
                name: "mobile",
                display: "Mobile",
                icon: "mobile",
                category: "hardware"
            }, {
                name: "firewall",
                display: "Firewall",
                icon: "shield",
                category: "hardware"
            }, {
                name: "workstation",
                display: "Workstation",
                icon: "desktop",
                category: "hardware"
            }, {
                name: "network-device",
                display: "Network Device",
                icon: "code-fork",
                category: "hardware"
            }, {
                name: "web-app",
                display: "Web Application",
                icon: "window-maximize",
                category: "software"
            }, {
                name: "app",
                display: "Application",
                icon: "window-restore",
                category: "software"
            }, {
                name: "cloud-app",
                display: "Cloud Application",
                icon: "cloud",
                category: "software"
             }, {
                name: "network",
                display: "Network",
                icon: "share-alt",
                category: "network"
             }, {
                name: "wireless",
                display: "Wireless Network",
                icon: "wifi",
                category: "network"
            }, {
                name: "media",
                display: "Media",
                icon: "usb",
                category: "media"
            }
        ];

        self.Types = Types;

        return self;
    });
})();
