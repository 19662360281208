(function () {
    angular.module("app").controller("AwarenessController", AwarenessController);

    function AwarenessController ($scope, $mdSidenav, $mdToast, Session, Awareness, Users, UserRole) {

        $scope.messages = [];
        $scope.currentMessage = null;
        $scope.data = {
            showDisabled:true,
            theme:""
        }
        $scope.canEdit = false;

        $scope.themes = Awareness.themesResource.query().$promise.then(function (themes) {
            $scope.themes = themes;
        });

        // get all policies
        $scope.messages = Awareness.orgResource.query().$promise.then(function (messages) {
            $scope.messages = messages;
        });

        // get a list of users
        Users.resource.query({}, "profile").$promise.then(function (users) {
            $scope.users = users;

            // get a list of roles
            UserRole.resource.query().$promise.then(function (userRoles) {
                $scope.userRoles = userRoles;
                $scope.usersAndRoles = $scope.users.concat(userRoles);
            });

        });

        Session.currentUser.$promise.then( function(user) {
            $scope.canEdit = (user.roles.findIndex(function(role) {
                return role.slug == 'cso' || role.slug == 'riskmanager'
            }) > -1);
        });

        $scope.update = function() {

            var data = {};
            data.awareness = $scope.currentMessage;

            Awareness.orgResource.update(data).$promise.then( function (result) {
                $scope.currentMessage.sentOnce = $scope.currentMessage.sendOnce.sendOnce;

//                $scope.messages.forEach(function(msg) {
//                    if((data.awareness._id+"") == (msg._id+"")) {
//                        msg.enabled = $scope.currentMessage.enabled;
//                        msg.month = $scope.currentMessage.month;
//                        msg.sentOnce = $scope.currentMessage.sendOnce.sendOnce;
//                    }
//                })

                showToast("Awareness message saved.");
           });

            $mdSidenav("editSideNav")
            .toggle()
            .then(function () {
            });
        }

        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        };

        $scope.editAwareness = function(message) {

            if (message.sendOnce == null){
                message.sendOnce = {};
            }
            if (message.sendOnce.to == null){
                message.sendOnce.to = [];
            }

//            $scope.currentMessage = Object.assign({}, message);
            $scope.currentMessage = message;

            $mdSidenav("editSideNav")
            .toggle()
            .then(function () {
            });
        }

        $scope.filterEnabled = function(message) {
            if (!$scope.data.showDisabled && !message.enabled) {
                return false;
            } else {
                return true;
            }
        }
        $scope.filterTheme = function(message) {
            if ($scope.data.theme == "") {
                return true;
            }
            if ($scope.data.theme == message.theme) {
                return true;
            }

            return false;
        }

//        $mdSidenav("editSideNav").onClose(function () {
//            console.log("CLOSE");
//            $scope.currentMessage = null;
//        });

        $scope.filterUsers = function(query) {
            return $scope.usersAndRoles.filter(function(user) {
                if (user.profile) {
                    return user.profile.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                } else {
                    return user.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                }
            });
        }

    }
})();
