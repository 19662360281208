angular.module('app').component('setupBusiness', {
    templateUrl: '/assets/html/setup/setup-business.html',
    controller: setupBusiness,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
    }
});

function setupBusiness($scope, $mdToast, $mdDialog, Compliance) {
    var ctrl = this;
    $scope.industry = "";

//    $scope.industries = ["Aerospace","Agriculture","Construction","Ecommerce","Education","Entertainment","Financial Services","Government","Healthcare","Hospitality","Information Technology","Manufacturing","Mass media","Pharmaceutical","Professional Services","Real Estate","Recreation","Retail","Telecommunications","Transportation","Utilities","Other"];

    ctrl.$onInit = function() {

        $scope.organization = ctrl.organization;

        Compliance.industryResource.query().$promise.then(function (industries) {
            $scope.industries = industries;
        });

        Compliance.requirementsResource.query().$promise.then(function (requirements) {
            $scope.requirements = requirements;
        });
    }

    $scope.update = function() {
//        $scope.organization.industry = $scope.industry;

        if ($scope.organization.industry == null || $scope.organization.industry == "") {
            return;
        }

        $scope.organization.$save().then(function(org) {
            showToast("Compliance requirements saved.");
            ctrl.onNext();
        }, function(err, test) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });
    };

    $scope.industryChanged = function() {
        $scope.organization.compliance.requirements = [];
        $scope.organization.industry.requirements.forEach( function(indReq) {
            var found = false;
            $scope.organization.compliance.requirements.forEach( function(orgReq) {
                if(indReq._id == orgReq || indReq._id == orgReq._id) {
                    found = true;
                }
            });
            if (!found) {
                $scope.organization.compliance.requirements.push(indReq);
//                document.getElementById(indReq._id).checked = true;
            }
        });


    }

    $scope.checkReq = function (requirement) {

        var found = false;
        if ($scope.organization) {
            $scope.organization.compliance.requirements.forEach(function(req) {
                if(requirement._id == req || requirement._id == req._id) {
                    found = true;
                }
            })
        }

        return found;
    }

    $scope.toggleReqSelection = function(requirement) {
        var idx = -1;
        $scope.organization.compliance.requirements.forEach(function(req, i) {
            if(requirement._id == req || requirement._id == req._id) {
                idx = i;
            }
        })

        if (idx > -1) {
            $scope.organization.compliance.requirements.splice(idx,1);
        } else {
            $scope.organization.compliance.requirements.push(requirement._id);
        }
    }

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
