(function () {
    angular.module("app").controller("PartnersController", PartnersController);

    function PartnersController ($scope, Partner) {

        $scope.partners = [];

        Partner.resource.query().$promise.then(function (partners) {
                $scope.partners = partners;
        });

    }
})();
