/**
 * @ngdoc component
 * @name app.control.component:controlStateMetrics
 * @author Nick Vorie
  *
 * @param {object} controlState The ControlState to generate metrics for
 *
 * @description This component will render a series of metrics for a given ControlState
 */
(function () {
    "use strict";

    angular.module("app").component("controlStateMetricsDash", {
        bindings: {
            threats: "=",
            compliance: "=",

            toShow: "=?"
        },

        template: `
            <div layout="column" layout-fill class="padding-3">
                <div layout="row" layout-align="start start">
                    <div ng-if="$ctrl.threats.length" flex="50" class="padding-4-x" layout="column">
                        <h6>Threats</h6>

                        <div ng-repeat="pair in $ctrl._.slice($ctrl.threats, 0, $ctrl.toShow)" class="padding-4-y">
                            <threat-chip threat-pair="pair"></threat-chip>
                        </div>
                    </div>

                    <div ng-if="$ctrl.compliance.length" flex="50" class="padding-4-x" layout="column">
                        <h6>Compliance</h6>

						<span class="padding-4-y" ng-if="$ctrl.shouldRenderCompliance(compliance)" ng-repeat="compliance in $ctrl._.slice($ctrl.compliance, 0, $ctrl.toShow)">
							<value-chip ng-if="compliance.issues.length" data-title="compliance.requirement.title || compliance.requirement" value="compliance.issues.length" type="'xs danger'" value-color="'secondary'"></value-chip>

							<chip ng-if="!compliance.issues.length" type="xs success" value-color="'danger'">
								{{compliance.requirement.title || compliance.requirement}}
							</chip>
						</span>
                    </div>
				</div>

				<div layout="row" layout-align="center center">
					<md-button ng-click="$ctrl.toggleExpand()" class="md-icon-button">
						<span ng-if="$ctrl.expanded"><i class="fas fa-chevron-up"></i></span>
						<span ng-if="!$ctrl.expanded"><i class="fas fa-chevron-down"></i></span>
					</md-button>
				</div>
			</div>
        `,

        controller: function (ControlState, Cvss, Organization) {
            var ctrl = this;

            ctrl.ControlState = ControlState;
            ctrl.Cvss = Cvss;
            ctrl._ = _;

            ctrl.expanded = false;

            if (!ctrl.toShow) ctrl.toShow = 3;

            ctrl.toggleExpand = function () {
                ctrl.expanded = !ctrl.expanded;
                ctrl.toShow = ctrl.expanded ? 20 : 3;
            }

            ctrl.organization = Organization.current;

            ctrl.shouldRenderCompliance = function (compliance) {
                // TODO: debug
                if (!compliance.requirement) {
                    return false;
                }

                return ctrl.organization.compliance.requirements.includes(compliance.requirement._id);
            }
        }
    })
})();
