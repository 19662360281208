(function () {
    angular.module("app").controller("ComplianceController", ComplianceController);

    function ComplianceController ($scope, Session, Compliance) {

        $scope.requirementOptions = [];
        $scope.requirements = [];
        $scope.currentRequirement = "";
        $scope.ready = true;
        $scope.filter = {
            issuesOnly: false
        }

        Session.currentUser.$promise.then(function (user) {

            var requirements = user.organization.compliance.requirements;
            $scope.requirementOptions = requirements;

        });

        $scope.requirementChanged = function(req) {
            $scope.ready = false;

            Compliance
                .resource
                .query({
                    "id[]":[req],
                    "populate":["specifications","controls","policies"],
                    "validate":true,
                })
                .$promise.then(function (requirements) {
                    $scope.requirements = requirements ;
                    $scope.ready = true;
                });
        }

        $scope.filterIssues = function(spec) {

            if ($scope.filter.issuesOnly) {
                return !spec.compliant;
            } else {
                return true;
            }

        };

    }

})();
