(function () {
    angular.module("app").factory("Users", UsersProvider);

    function UsersProvider($q, $http, $resource, $mdToast, $mdDialog, Error) {

        var UsersResource = $resource("/api/users/:id", {
            id: "@_id"
        });

        var UsersByOrgResource = $resource("/api/users/byOrg/:orgId");
        var UsersResourceInvite = $resource("/api/users/invite");

        function User() {
            var self = this;

            self.errorHandler = Error.get("user");
            self.resource = UsersResource;
            self.resourceInvite = UsersResourceInvite;
            self.byOrgResource = UsersByOrgResource;

            self.userModal = function (user, organization) {
                return $mdDialog.show({
                    templateUrl: "/assets/html/user/add-user-modal.html",
                    controller: "UserAddController",
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: true,

                    locals: {
                        user: user,
                        organization: organization
                    }
                });
            }

            self.selectUser = function (options) {
                return $mdDialog.show({
                    templateUrl: "/assets/html/user/select-user-modal.html",
                    controller: "SelectUserController",
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,
                    locals: {
                        options: options
                    }
                });
            }

            self.deleteUser = function (user, users) {
                return $mdDialog.show({
                    templateUrl: "/assets/html/user/archive-user-modal.html",

                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,

                    locals: {
                        user,
                    },

                    controller: function ($scope, $mdDialog, user) {
                        $scope.user = user;
                        $scope.action = "deletion";

                        $scope.hasOwnership = false;
                        $scope.hasTasks = false;

                        $scope.meta = {};
                        $scope.result = {};

                        $http.get(`/api/user/${user._id}/archive`).then(result => {
                            $scope.meta.hasOwnership = result.data.hasOwnership;
                            $scope.meta.hasTasks = result.data.hasTasks;

                            console.log(result)
                        });

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        }

                        $scope.submit = function () {
                            const assignTasks = _.get($scope.result, "assignTasks._id", "");
                            const assignOwnership = _.get($scope.result, "assignOwnership._id", "");

                            console.log({ assignOwnership, assignTasks }, $scope.result)

                            if ($scope.meta.hasTasks && !assignTasks) {
                                console.error("task")
                                return;
                            }

                            if ($scope.meta.hasOwnership && !assignOwnership) {
                                console.error("owner")
                                return;
                            }

                            $http.delete(`/api/users/${user._id}?assignTasks=${assignTasks}&assignOwnership=${assignOwnership}`).then(function (resp) {
                                $mdToast.showSimple("User removed");

                                if (users) {
                                    users.splice(users.indexOf(user), 1)
                                }

                                if (window.location.href.includes("/user/")) {
                                    window.location = "/#user";
                                }

                                $mdDialog.hide($scope.result);
                            }).catch(e => {
                                console.error(e);
                                $mdToast.showSimple("Error deleting, please try again");
                            });
                        }
                    },
                });
            }

            self.archiveUser = function (user) {
                if (user.status.state == "archived") {
                    $http.post(`/api/user/${user._id}/archive`).then(result => {
                        // success toast
                        user.status.state = "active";
                        $mdToast.showSimple("User unarchived");
                    }).catch(e => {
                        console.error(e);
                        $mdToast.showSimple("Error archiving, please try again");
                    });

                    return;
                }

                return $mdDialog.show({
                    templateUrl: "/assets/html/user/archive-user-modal.html",

                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,

                    locals: {
                        user
                    },

                    controller: function ($scope, $mdDialog, user) {
                        $scope.user = user;
                        $scope.action = "archive";

                        $scope.hasOwnership = false;
                        $scope.hasTasks = false;

                        $scope.meta = {};
                        $scope.result = {};

                        $http.get(`/api/user/${user._id}/archive`).then(result => {
                            $scope.meta.hasOwnership = result.data.hasOwnership;
                            $scope.meta.hasTasks = result.data.hasTasks;

                            console.log(result)
                        });

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        }

                        $scope.submit = function () {
                            const assignTasks = _.get($scope.result, "assignTasks._id", "");
                            const assignOwnership = _.get($scope.result, "assignOwnership._id", "");

                            console.log({ assignOwnership, assignTasks }, $scope.result)

                            if ($scope.meta.hasTasks && !assignTasks) {
                                console.error("task")
                                return;
                            }

                            if ($scope.meta.hasOwnership && !assignOwnership) {
                                console.error("owner")
                                return;
                            }

                            $http.post(`/api/user/${user._id}/archive?assignTasks=${assignTasks}&assignOwnership=${assignOwnership}`).then(result => {
                                user.status.state = "archived";
                                $mdDialog.hide($scope.result);
                                $mdToast.showSimple("User archived");
                            }).catch(e => {
                                $mdToast.showSimple("Error archiving, please try again");
                            });
                        }
                    },
                });
            }

        }

        return new User();
    }
})();
