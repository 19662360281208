(function () {
    angular.module("app").controller("ForgotPasswordController", ForgotPasswordController);
    
     function ForgotPasswordController ($scope, $http) {

         $scope.data = {
             requestSent: false,
             email:''
         }
         
        $scope.requestToken = requestToken;
        
        function requestToken () {

            $http.post("/api/auth/reset-password/request/", {
                email: $scope.data.email
            }).then(function (response) {

//                $scope.email = "";
                $scope.data.requestSent = true;

            }, function (response) {

                $scope.data.requestSent = true;

            });
        }
    };
                                    
})();
