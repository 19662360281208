(function () {
    "use strict";
    
    angular.module("app").component("cvssModal", {
        templateUrl: "/assets/html/cvss/modal/index.html",
        
        require: {
            parent: "^^modal",
        },
        
        controller: function (CvssFactory, TabProvider) {
            var ctrl = this;
            
            ctrl.$onInit = function () {
                ctrl.cvssData = ctrl.parent.modal.cvssData;
                
                ctrl.cvssVectorOld = ctrl.cvssData.vector;
                ctrl.metricsChanged = false;
                
                ctrl.onClick = function () {
                    ctrl.cvssData = CvssFactory.updateInstance(ctrl.cvssData);    
                    checkMetrics();
                }
                
                ctrl.submit = function ($event) {
                    ctrl.parent.modal.update(ctrl.cvssData);
                    ctrl.parent.dismiss($event);
                }
                
                ctrl.tabGroup = TabProvider.create(ctrl.cvssData.metrics.map(function (group) {
                    return {
                        id: group.id,
                        content: group.name,
                    }
                }));
            }
            
            function checkMetrics () {
                ctrl.metricsChanged = ctrl.cvssVectorOld != ctrl.cvssData.vector;
                //console.log(ctrl.metricsChanged, ctrl.cvssVectorOld, ctrl.cvssData.vector)
            }
        }
    }).run(function (ModalFactory) {
        ModalFactory.templates["cvss"] = {
            templateUrl: "/assets/html/control/cvss-modal/wrapper.html",
        }
    })
})();
