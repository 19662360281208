/**
 * @ngdoc component
 * @name app.uiKit.component:percentChip
 * @author Nick Vorie
  *
 * @param {object} threatPair The ThreatPair to render a chip for
 *
 * @description This component will render a CVSS color-coded risk score for a given ThreatPair
 */
(function () {
    "use strict";

    angular.module("app").component("percentChip", {
        bindings: {
            title: "=",
            value: "=",
        },

        template: `
            <chip type="xs">
                <span class="value primary">{{$ctrl.value}}%</span>
                <span>{{$ctrl.title}}</span>
            </chip>
        `,

        controller: function (Cvss) {
            var ctrl = this;

            ctrl.Cvss = Cvss;
        }
    })
})();
