angular.module('app').component('setupWorkforce', {
    templateUrl: '/assets/html/setup/setup-workforce.html',
    controller: setupWorkforce,
    bindings: {
        onNext: '&',
        onBack: '&',
        organization: '<',
    }
});

function setupWorkforce($scope, $mdToast, $mdDialog) {
    var ctrl = this;

    $scope.workers = ["full-time employees", "part-time employees", "temporary workers", "contractors", "consultants", "volunteers", "trainees", "student interns", "auditors"];

    ctrl.$onInit = function() {

        $scope.organization = ctrl.organization;

        if ($scope.organization.policy.includeAsWorkers.length < 1) {
            $scope.organization.policy.includeAsWorkers.push("full-time employees");
            $scope.organization.policy.includeAsWorkers.push("part-time employees");
        }


    }

    $scope.update = function() {

        $scope.organization.$save().then(function(org) {
            showToast("Workforce list saved.");
            ctrl.onNext();
        }, function(err, test) {
             // save failed, should tell the user
              //console.log("save existing didn't work ", err);
        });
    };

    $scope.checkWorker = function (worker) {
        var idx = $scope.organization.policy.includeAsWorkers.indexOf(worker);
        if (idx > -1) {
            return true;
        } else {
            return false;
        }
    }

    $scope.toggleWorker = function(worker) {
        var idx = $scope.organization.policy.includeAsWorkers.indexOf(worker);

        if (idx > -1) {
            $scope.organization.policy.includeAsWorkers.splice(idx,1);
        } else {
            $scope.organization.policy.includeAsWorkers.push(worker);
        }
    }

    showToast = function(msg) {
        $mdToast.show (
            $mdToast.simple()
            .textContent(msg)
            .hideDelay(3000)
        );
    };

    $scope.showAlert = function(ev, title, copy) {
        $mdDialog.show(
            $mdDialog.alert()
                .parent(angular.element(document.querySelector('#popupContainer')))
                .clickOutsideToClose(true)
                .title(title)
                .textContent(copy)
                .ok('Got it!')
                .targetEvent(ev)
        );
    };

    $scope.back = function() {
        ctrl.onBack();
    }
}
