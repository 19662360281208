/**
 * @ngdoc component
 * @name app.threat.component:threatChip
 * @author Nick Vorie
  *
 * @param {object} threatPair The ThreatPair to render a chip for
 *
 * @description This component will render a CVSS color-coded risk score for a given ThreatPair
 */
(function () {
    "use strict";

    angular.module("app").component("threatChip", {
        bindings: {
            threatPair: "=",
        },

        template: `
            <risk-chip data-title="$ctrl.threatPair.threat.title" value="$ctrl.threatPair.score"></risk-chip>
        `,

        controller: function (Cvss) {
            var ctrl = this;

            ctrl.Cvss = Cvss;
        }
    })
})();
