(function () {
	angular.module("app").component("rolesConfiguration", {
		bindings: {
			organization: "="
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Role Labels</h3>

					<span class="md-subhead md-block">
						<content content-id="config.roles.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<form name="RolesConfigurationForm">
					<md-input-container class="md-block">
						<label>What should we call people that work under the direct control of {{$ctrl.organization.shortName}}?</label>

						<md-select name="employeePhrase" ng-model="$ctrl.organization.phrase.employee" required>
							<md-option value="associate">associate</md-option>
							<md-option value="employee">employee</md-option>
							<md-option value="staff member">staff member</md-option>
							<md-option value="team member">team member</md-option>
							<md-option value="workforce member">workforce member</md-option>
						</md-select>

						<div ng-messages="RolesConfigurationForm.employeePhrase.$error">
							<div ng-message="required">This is required!</div>
						</div>
					</md-input-container>

					<md-input-container class="md-block">
						<label for="csoPhrase">What do you call the person responsible for information security?</label>
						<input type="text" name="csoPhrase" ng-model="$ctrl.organization.phrase.cso" required>

						<div ng-messages="RolesConfigurationForm.csoPhrase.$error">
							<div ng-message="required">This is required!</div>
						</div>
					</md-input-container>	
				</form>
			</md-card-content>

			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Key Personnel</h3>

					<span class="md-subhead md-block">Select users who fill key roles and perform important functions.</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<user-select label="Who is the primary contact for external assessments sent to {{$ctrl.organization.shortName}}?" bind-to="$ctrl.organization.roles.externalAssessment"></user-select>
				<user-select label="Who is the user designated to be the security officer? They will have the highest level of access in VSC." bind-to="$ctrl.organization.roles.cso"></user-select>
				<user-select label="Who is the deisgnated VSC Account Administrator? This user will be responsible for financial transactions and licensing within VSC." bind-to="$ctrl.organization.roles.accountAdmin"></user-select>
			</md-card-content>
        `,

		controller: function () {
			var ctrl = this;
		},
	});
})();