(function () {
    "use strict";

    angular.module("app").factory("ViewManager", function () {
        function ViewManager () {
            var views = {};

            this.register = function (name, view) {
                views[name] = view;
            }

            this.view = function (name) {
                return views[name];
            }

            this.get = this.view;
        }

        return new ViewManager();
    }).directive("viewScopeCarrier", function (ViewManager) {
        return {
            scope: {
                viewScope: "@?"
            },

            controller: function ($scope) {
                $scope.$parent.view = ViewManager.view($scope.viewScope);
            },
        }
    }).component("splitPaneView", {
        templateUrl: "/assets/html/core/material-strap/views/panes/split.html",
        transclude: {
            leftPane: "leftPane",
            rightPane: "rightPane",
        },
        bindings: {
            viewName: "@?",
            currentSelection: "@?",
        },
        controller: splitPaneController
    });

    function splitPaneController($scope, $element, $attrs, $timeout, ViewManager) {
        var ctrl = this;
        var $window = angular.element(window);
        
        init();
        
        function init () {
            ctrl.classes = {
                leftPane: [],
                rightPane: []
            }

            ctrl.state = ctrl.state || "single";
            ctrl.updateState = updateState;
            ctrl.onKeyPress = onKeyPress;
            ctrl.selectModel = selectModel;
            ctrl.selectNextModel = selectNextModel;

            $window.resize(resizePanes);
            //$window.on("keydown", onKeyPress);

            ViewManager.register(ctrl.viewName, ctrl);

            if (ctrl.currentSelection) {
                selectModel(ctrl.currentSelection);
            }
        }
        
        function onKeyPress ($event) {
            if ($event.key == "ArrowDown") {
            }
        }
        
        function resizePanes() {
            var leftPane = $($element).find("left-pane");
            var rightPane = $($element).find("right-pane");

            rightPane.children().first().css({
//                "min-height": leftPane.children().first().height()
            });
        }

        function updateState(state) {
            if (state == "single") {
                ctrl.state = state;
                ctrl.classes.leftPane = [];
            } else if (state == "split") {
                $timeout(function () {
                    $timeout(function () {
                        ctrl.state = state;
                        $timeout(resizePanes, 0);
                    }, ctrl.state == state ? 0 : 250);

                    ctrl.state = null;

                    ctrl.classes.leftPane = [
                        "col-md-6",
                        "hidden-sm-down"
                    ];
                }, ctrl.state == state ? 0 : 250);
            }
        }
        
        function selectNextModel(models) {
            var newIndex = ctrl.modelIndex + 1;
           
            if (newINdex >= models.length) {
                newIndex = 0;
            }
            
            ctrl.model = models[newIndex];
            ctrl.modelIndex = newIndex;            
        }
        
        function selectModel(model, index) {
            if (ctrl.model == model) return;

            ctrl.model = model;
            ctrl.modelIndex = index;

            if (model) {
                ctrl.updateState("split");
            } else {
                ctrl.updateState("single");
            }

            $timeout(resizePanes, 100);
        }
    }
})();
