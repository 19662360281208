(function () {
    angular.module("app").controller("UserActivityReportController", function ($scope, $http, Session) {
        $scope.data = {
        }

        Session.current.$promise.then(() => {
            $scope.organization = Session.current.organization;
        });

        $http.get("/api/reporting/user-activity").then(res => {
            $scope.data.report = res.data;
            $scope.data.loaded = true;
        }).catch(console.error);

        $scope.$watch("data.filter", () => {
            if (!$scope.data.report) {
                return;
            }

            generateReport();
        }, true);
    });
})();