(function () {
    angular.module("app").component("messages", {
        /* html */
        template: `
            <div class="padding-4-t" flex="grow" layout="column" layout-align="end stretch" layout-fill>
                <md-content style="min-height: 100px;" class="padding-4-x" md-transparent flex="grow" auto-scroll scroll-watch="$ctrl.messages">
                    <div ng-if="$ctrl.messages && $ctrl.messages.length > 0" class="messages">
                        <message ng-repeat="message in $ctrl.messages" data="message" messages="$ctrl.Messages"></message>
                    </div>
                </md-content>

                <md-divider ng-if-start="!$ctrl.readOnly" class="margin-3-t"></md-divider>

                <div ng-if-end flex="none" layout="row" class="margin-4-x">
                    <md-input-container class="margin-0 padding-0 no-border no-error" md-no-float flex="grow">
                        <input class="margin-0 padding-0" ng-model="$ctrl.newMessage" placeholder="Add Message" ng-keypress="$ctrl.onKeypress($event.originalEvent)">
                    </md-input-container>

                    <div flex="none" class="padding-4-l">
                        <md-button class="small" ng-click="$ctrl.submitMessage()" ng-disabled="!$ctrl.newMessage.length" layout-margin-none>
                            <icon type="paper-plane"></icon>
                        </md-button>
                    </div>
                </div>
            </div>
        `,

        bindings: {
            messages: "=",
            readOnly: "=?",

            endpoint: "@",
            endpointQuery: "@?",
        },

        controller: function (Message) {
            var ctrl = this;

            ctrl.onKeypress = onKeypress;
            ctrl.submitMessage = submitMessage;

            ctrl.$onInit = () => {
                ctrl.Messages = Message.instance({
                    endpoint: ctrl.endpoint,
                    endpointQuery: ctrl.endpointQuery
                }, ctrl.messages);
            }

            function onKeypress ($event) {
                if ($event.keyCode == 13) {
                    submitMessage();
                }
            }

            function submitMessage () {
                if (ctrl.newMessage && ctrl.newMessage.length > 0) {
                    ctrl.messages = ctrl.Messages.add(ctrl.newMessage);
                    ctrl.newMessage = "";
                }
            }
        }
    });
})();
