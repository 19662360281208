(function () {
    "use strict";
    
    angular.module("app").factory("Organization", OrganizationProvider);
    
    function OrganizationProvider ($resource) {
        var OrganizationResource = $resource("/api/organization/current");
        
        var AllOrganizationResource = $resource("/api/organization/:id", {
            id: "@_id"
        });

        function OrganizationProvider () {
            var self = this;
            
            self.resource = AllOrganizationResource;
            self.current = OrganizationResource.get();
            self.customers = $resource("/api/organization/customers");

            return self;
        }
        
        return new OrganizationProvider();
    }
})();
