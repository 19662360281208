(function () {
    "use strict";

    angular.module("app").component("materialListElement", {
        templateUrl: "/assets/html/core/material-strap/list/element.html",
        transclude: true,
        require: "^^materialList",
        bindings: {
            active: "=?",
            type: "@?",
            href: "@?",
            onClickMain: "&?",
            iconLeft: "@?",
            onClickLeft: "&?",
            iconRight: "@?",
            onClickRight: "&?",
            condensed: "@?",
        }, 
        
        controller: function () {
            var ctrl = this;
        }
    }).component("materialList", {
        templateUrl: "/assets/html/core/material-strap/list/index.html",
        transclude: true,
        replace: true,
        bindings: {
            condensed: "@",
            elementType: "@?",
            scrolling: "@",
        },
        controller: function ($scope) {
            var self = this;

            this.classes = {
                scrolling: !!self.scrolling,
                condensed: !!self.condensed,
            };
        }
    });
    
})();
