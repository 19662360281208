(function () {
    "use strict";

    angular.module("app").component("controlState", {
        template: `
            <div ng-if="$ctrl.renderScores" class="margin-b">
                <control-state-card ng-repeat="controlState in $ctrl.currentState.controlStates | orderBy:'+control._id'" state="controlState" container-id="$ctrl.container._id" read-only="$ctrl.isAuditor" expanded="$ctrl.expanded" container-type="{{$ctrl.containerType}}" change-state="$ctrl.changeState"></control-state-card>
            </div>

            <div ng-if="$ctrl.stateChanged" position-fixed-bottom-right class="margin-r">
                <a ng-click="$ctrl.cancel()" class="btn btn-raised-secondary">Cancel</a>
                <a ng-click="$ctrl.saveChanges()" class="btn btn-raised-primary">Save</a>
            </div>
        `,

        bindings: {
            container: "=",
            containerType: "@",
            state: "=",
            metric: "@?"
        },

        //TODO: Needs to be cleaned up
        controller: function ($http, $element, $filter, $mdToast, Cvss, User, Control, ControlState, Threat, ConfirmModal) {
            var ctrl = this;

            ctrl.Cvss = Cvss;

            ctrl.saveChanges = saveChanges;
            ctrl.cancel = cancel;
            ctrl.selectState = selectState;
            ctrl.stateChanged = false;
            ctrl.changeState = changeState;

            ctrl.$onInit = function () {
                _.delay(function () {
                    $element.find(".metrics").appendTo("#metrics");
                }, 100);

                User.checkRoles(["auditor"]).then(result => {
                    ctrl.isAuditor = result;
                });

                ctrl.states = ctrl.state.changes || [];
                ctrl.states = $filter("orderBy")(ctrl.states, "date", false);

                //TODO: move remediation to control-state model?
                if (_.get(ctrl.container, "remediation.current.steps.length")) {
                    var controlState = ctrl.container.controlState.current;

                    controlState.controlStates.filter(state => !!state.control).forEach((state) => {
                        var remediationStep = ctrl.container.remediation.current.steps.find((step) => {
                            return (step.control._id || step.control) == state.control._id;
                        });

                        if (remediationStep) {
                            state.remediation = remediationStep
                        }
                    });
                }

                ctrl.currentState = ctrl.container.controlState.current;

                ctrl.currentState.controlStates = ctrl.currentState.controlStates.filter((controlState) => {
                    return controlState.control;
                });

                ctrl.currentState.threats = ControlState.calculateThreatScores(ctrl.currentState.controlStates);

                //selectState(ctrl.states.length - 1);

                ctrl.resetControlState = angular.copy(ctrl.states[ctrl.states.length - 1]);
                ctrl.renderScores = true;
            }

            //TODO: Move into factory
            function saveChanges() {
                ctrl.stateChanged = false;

                $mdToast.show($mdToast.simple().textContent("Saving changes..."));

                $http.post("/api/" + ctrl.containerType + "/" + ctrl.container._id + "/control-state/update?select[]=controlState", {
                    changes: ctrl.currentState.controlStates.map((controlState) => {
                        return {
                            control: controlState.control,
                            state: controlState.state,
                        }
                    })
                }).then(function (response) {
                    $mdToast.show($mdToast.simple().textContent("Changes saves, reloading"));
                    window.location = window.location;
                });
            }

            function cancel() {
                ctrl.stateChanged = false
                ctrl.states[ctrl.states.length - 1] = angular.copy(ctrl.resetControlState);
                selectState(ctrl.states.length - 1);
                $mdToast.show($mdToast.simple().textContent("Control state reset"));
            }

            function selectState(index) {
                if (!ctrl.states) {
                    return console.log("no states");
                }

                ctrl.currentStateIndex = ctrl.states.length - 1;
                ctrl.currentState = ctrl.states[ctrl.currentStateIndex];

                return; //TODO: add back changing of state

                ctrl.currentStateIndex = index;
                ctrl.currentState = ctrl.states[ctrl.currentStateIndex];
                ctrl.currentState = ControlState.scoreMetrics(ctrl.currentState);
            }

            function changeState() {
                ctrl.currentState.threats = ControlState.calculateThreatScores(ctrl.currentState.controlStates);

                ControlState.scoreMetrics(ctrl.currentState.controlStates, (metrics) => {
                    ctrl.currentState.metrics = metrics;
                });

                ctrl.stateChanged = true;
            }
        }
    })
})();