/**
 * @ngdoc service
 * @name app.reporting.service:Reporting
 * @author Terry Courtney, Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Reporting
 */
(function () {
    "use strict";

    angular.module("app").factory("Reporting", function ($resource, $http) {
        var self = this;

        self.complianceReport = $resource("/api/reporting/compliance/:reqId");
        self.reports = $resource("/api/reporting/reports");

        return self;
    });

})();
