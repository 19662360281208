angular.module('app').component('trainingCard', {
    templateUrl: '/assets/html/training/trainingCard.html',
    controller: TrainingCardController,
    bindings: {
        training: '<',
        user: '<',
        trainingId: '@',
        showRefresh: '<',
        showToolbar: '<',
        showTip: '<'
    }
});

function TrainingCardController($mdSidenav, $mdDialog, Training, User) {
    var ctrl = this;

    ctrl.ready = false;
    ctrl.organization = "";

    ctrl.$onInit = function () {
        User.checkRoles(["user-admin", "cso", "riskmanager"]).then(result => {
            ctrl.isAdmin = result;
        });

        if (ctrl.showRefresh == null) {
            ctrl.showRefresh = true;
        };
        if (ctrl.showToolbar == null) {
            ctrl.showToolbar = true;
        };
        if (ctrl.showTip == null) {
            ctrl.showTip = true;
        };

        if (ctrl.training) {
            ctrl.ready = true;
        } else {
            Training.forUser.get({}, function (training) {
                if (training._id) {
                    ctrl.training = training;
                } else {
                    ctrl.training = null;
                }
                ctrl.ready = true;
            });
        }

    }

    ctrl.sendNow = function ($event, document) {
        $mdDialog.show({
            parent: angular.element(document.body),
            targetEvent: $event,
            templateUrl: '/assets/html/training/sendNowModal.html',

            locals: {
                document: document,
            },
            
            controller: function ($scope, $mdDialog, Users, UserRole, document) {
                ctrl = $scope;

                ctrl.document = document;
                ctrl.document.sendTo = [];

                Users.resource.query({}).$promise.then(function (users) {
                    ctrl.users = users.filter(user => {
                        if (user.status.state == "archived" || user.disabled) {
                            return false;
                        }

                        return true;
                    }).map(function (user) {
                        return {
                            _id: user._id,
                            email: user.email,
                            profile: user.profile
                        }
                    });

                    // get a list of roles
                    UserRole.resource.query().$promise.then(function (userRoles) {
                        ctrl.userRoles = userRoles;
                        ctrl.usersAndRoles = ctrl.users.concat(userRoles);

                        console.log(ctrl.usersAndRoles)
                    });

                });
    
                ctrl.cancel = function () {
                    $mdDialog.cancel();
                }
    
                ctrl.send = function () {
                    console.log("send")
                    $mdDialog.hide(ctrl.document);
                }
    
                ctrl.filterUsers = function (query) {
                    // return true;
                    return ctrl.usersAndRoles.filter(function (user) {
                        if (user.profile) {
                            return user.profile.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        } else {
                            return user.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        }
                    });
                }
            }
        }).then(function (docToSend) {
            console.log(docToSend)

            Training.resource.sendNow(docToSend).$promise.then(function (result) {
                showToast("Training sent.");
            });

        }, function (error) {
            console.log("error", error)

        });
    }

    ctrl.showVideoDialog = function (ev, training) {
        Training.openTrainingModal(training).then(function (resp) {
        }, function () {
        });
    };

    ctrl.displayTip = function () {
        $mdSidenav("trainingTip")
        .toggle()
        .then(function () {
        });
    };

    ctrl.refresh = function () {
        Training.forUser.get({}, function (training) {
            if (training._id) {
                ctrl.training = training;

                var video = document.getElementById('video');
                video.src = ctrl.training.video.url;
                video.load();

            } else {
                ctrl.training = null;
            }
            ctrl.ready = true;
        });

    };

    ctrl.closeTip = function () {
        $mdSidenav('trainingTip').close()
            .then(function () {
            });
    };

}

