(function () {
    angular.module("app").component("partnerCard", {
        bindings: {
            partner: "=",
            show: "=?"
        },

        template: `
            <md-card class="vendorCard">
                <md-card-content layout style="min-height:80px" flex>
                    <div flex="1">
                        <img width="80" style="margin-right:10px" ng-src="{{$ctrl.vendor.image.url || 'https://cdn.virtualsecurity.center/assets/img/vendor/default.png'}}">
                    </div>

                    <div flex>
                        <h5 class="truncate">{{$ctrl.partner.organization.name}}</h5>

                        <p>Owner: {{$ctrl.partner.vendor.contact.profile.name}}</p>
                        <p>Contact: {{$ctrl.partner.owner.profile.name}}</p>
                    </div>

                </md-card-content>

                <md-button ng-href="/vendor/{{$ctrl.partner._id}}/assessment" ng-if="$ctrl.partner.assessment.current" class="md-raised md-primary" aria-label="Assessment">Complete Assessment</md-button>

                <md-divider></md-divider>

                <md-card-actions flex layout="row" layout-align="end center">
                    <md-card-icon-actions layout="row" layout-align="end center">

                        <md-button href="" ng-if="$ctrl.partner.assessment.current" ng-href="/vendor/{{$ctrl.partner._id}}/assessment" class="md-icon-button" aria-label="Assessment">
                            <md-tooltip>Complete Assessment</md-tooltip>
                            <md-icon md-font-icon="fa-clipboard" class="fa"></md-icon>
                        </md-button>

                        <md-button class="md-icon-button" ng-click="$ctrl.Partner.editPartner($ctrl.partner, $event)" aria-label="Edit">
                            <md-tooltip>Edit Vendor</md-tooltip>
                            <md-icon md-font-icon="fa-pencil" class="fa"></md-icon>
                        </md-button>

                </md-card-actions>

            </md-card>
        `,

        controller: function (Partner) {
            var ctrl = this;

            ctrl.Partner = Partner;

            ctrl.$onInit = () => {

            }
        }
    });
})();
