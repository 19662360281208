(function () {
    "use strict";
    
    angular.module("app").component("userRoleCardCreate", {
        templateUrl: "/assets/html/user-role/card-create.html",
        
        controller: function (UserRole, Error) {
            var ctrl = this;
            
            ctrl.errorHandler = Error.get("user-role-create");
            ctrl.UserRole = UserRole;
            
            ctrl.form = {};
            
            ctrl.submit = submit;
            
            function submit () {
                ctrl.errorHandler.clear();
                
                var role = new UserRole.resource(ctrl.form);
                
                role.$save().then(function (data) {
                    ctrl.errorHandler.setSuccess("User role '" + data.name + "' has been created");
                    ctrl.form = {};
                }, function (response) {
                    ctrl.errorHandler.process(response.data);
                });
            }
        }
    });
})();
