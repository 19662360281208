(function () {
    "use strict";

    angular.module("app").factory("Threat", ControlFactory);

    function ControlFactory ($resource, $http) {

        var ControlResource = $resource("/api/threat/:id", {
            id: "@_id"
        });

        function ControlProvider () {
            var self = this;

            self.resource = ControlResource;

            return self;
        }


        return new ControlProvider();
    }
})();
