(function () {
    angular.module("app").controller("StandardController", StandardController);

    function StandardController ($scope, $location, Session, Standard) {
        console.log("StandardController");

        $scope.standard = {};
        $scope.organization = {};
        $scope.ready = false;

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        Session.currentUser.$promise.then(function (user) {
            $scope.organization = user.organization;

            Standard.resource.get({id: id}, function(standard) {
                $scope.standard = replaceOrgSlugs(standard, $scope.organization);
                $scope.standard = replaceSlugs($scope.standard, standard.slugs);
                $scope.ready = true;
            });
        });

        function replaceOrgSlugs(standard, org) {
            org.standards.forEach(function(orgStandard) {
               if(orgStandard.standard = standard._id) {
                   standard = replaceSlugs(standard, orgStandard.slugs);
               }
            });

            return standard;
        }

        function replaceSlugs (standard, slugs) {
            slugs.forEach(function (slug) {
                standard.sections.forEach(function(section) {
                    var re = new RegExp("\\[" + slug.slug + "\\]", "gi");
                   section.text = section.text.replace(re, slug.value);
               })
            });
            return standard;
        }

    }
})();
