(function () {
    "use strict";
    
    angular.module("app").component("wizardProgress", {
        templateUrl: "/assets/html/core/wizard/progress.html",
        
        require: {
            parent: "^^wizard"
        }
    });    
})();
