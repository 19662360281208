(function () {
    "use strict";
    
    var app = angular.module("app")
    
    app.factory("MaterialLayout", function () {
        function MaterialLayout () {
            var self = this;

            self.openSideNav = function () {
            }
        }

        return new MaterialLayout();
    });

    app.controller("navs", function($scope) {
        var nav = $("nav.secondary");
        var toggling = false;

        $scope.toggleNav = function (state) {
            if (toggling) return;      

            if (state === true) {
                toggling = true;
                nav.addClass("in");
            } else if (state === false) {
                toggling = true;
                nav.removeClass("in");
            } else {
                $scope.toggleNav(!nav.hasClass("in")); 
            }

            setTimeout(function () {
                toggling = false;
            }, 100);
        } 
    });  

    app.component("horizantalStepper", {
        templateUrl: "/assets/html/core/material-strap/stepper/horizantal.html",
        transclude: true,
    })
})();
