(function () {
    "use strict";

    angular.module("app").component("loginCard", {
        bindings: {},

        require: {},

        template: `
            <md-card style="max-width: 480px;" layout="column">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span class="md-headline">Login</span>
                    </md-card-title-text>
                </md-card-title>

                <md-divider></md-divider>

                <md-card-body ng-if="$ctrl.state == 'gdpr'" layout="column">
                    <div class="padding-2" layout="column">
                        <h3>Our use of cookies</h3>
                        <p>A cookie is a small text file stored on your device to help websites remember information and preferences. Our website only uses essential cookies, which are necessary for its basic functions, like smooth navigation and secure browsing. We avoid non-essential cookies, such as those related to targeted advertising or user behavior tracking, to protect your privacy and user experience. While you have the option to disable cookies in your browser settings, doing so may negatively impact our website's functionality and your overall experience.</p>
                    </div>

                    <md-button class="md-raised md-primary" ng-click="$ctrl.acceptGdpr()">Continue</md-button>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'auth'" layout="column">
                    <div class="padding-2" layout="column">
                        <md-input-container class="margin-0-b">
                            <label>Email/Username</label>
                            <input ng-model="$ctrl.data.email" name="email" autofocus>
                        </md-input-container>

                        <md-input-container class="margin-0-b">
                            <label>Password</label>
                            <input ng-enter="$ctrl.login()" type="password" ng-model="$ctrl.data.password" name="password">
                        </md-input-container>

                        <p ng-if="$ctrl.Login.errorHandler.message" class="text-danger">{{$ctrl.Login.errorHandler.message}}</p>
                    </div>

                    <md-button class="md-raised md-primary" ng-click="$ctrl.login()">Login</md-button>
                    <md-button class="md-flat md-primary" ng-href="/login/forgot-password">Forgot Password</md-button>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'load'" layout="column" layout-align="center center">
                    <md-progress-circular class="margin-y" md-mode="indeterminate"></md-progress-circular>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'two-factor'" layout="column">
                    <div class="padding-2" layout="column">
                        <p ng-if="false" class="padding-2-b">Please provide your six digit, two-factor authentication token to verify your identity.</p>

                        <md-input-container class="margin-0-b">
                            <label>Email/Username</label>
                            <input ng-model="$ctrl.data.email" disabled>
                        </md-input-container>

                        <md-input-container class="margin-0-b">
                            <label>Two Factor Token</label>
                            <input ng-enter="$ctrl.login()" ng-model="$ctrl.data.token" autofocus>
                        </md-input-container>

                        <p ng-if="$ctrl.Login.errorHandler.message" class="text-danger">{{$ctrl.Login.errorHandler.message}}</p>
                    </div>

                    <md-button class="md-raised md-primary" ng-click="$ctrl.login()">Verify</md-button>
                </md-card-body>

                <md-card-body class="text-muted padding-2">
                    <p>By continuing, you agree to Virtual Security Center's <a href="/tos">Terms of Service</a> and <a href="/privacy">Privacy Policy</a>.</p>
                </md-card-body>
            </md-card>
        `,

        controller: function ($window, $cookies, $location, Login) {
            var ctrl = this;

            ctrl.Login = Login;

            ctrl.state = "auth";

            ctrl.acceptGdpr = function () {
                ctrl.data.gdpr = true;
                ctrl.login();
            }

            function finish() {
                var redirect = "" // getQueryVariable("redirectUrl");

                $cookies.remove("logo.url");
                $cookies.remove("logo.display");
                $cookies.remove("logo.deleted");

                window.location = redirect || "/";
            }

            ctrl.login = function () {
                ctrl.state = "load";

                Login.login(ctrl.data, function (error, result) {
                    if (error) {
                        if (error.twoFactor) {
                            ctrl.state = "two-factor";
                        } else if (error.gdpr) {
                            ctrl.state = "gdpr";
                        } else {
                            ctrl.state = "auth";
                        }
                    } else if (result) {
                        finish();
                    }
                });
            }

            //Todo factory
            function getQueryVariable(variable) {
                var query = window.location.search.substring(1);
                var vars = query.split('&');

                for (var i = 0; i < vars.length; i++) {
                    var pair = vars[i].split('=');

                    if (decodeURIComponent(pair[0]) == variable) {
                        return decodeURIComponent(pair[1]);
                    }
                }
            }
        }
    })
})();