(function () {
    "use strict";

    angular.module("app").component("actionableCard", {
        templateUrl: "/assets/html/core/material-strap/card/action.html",
        transclude: {
            title: "cardTitle",
            text: "cardText",
            controls: "cardControls",
            actions: "cardActions",
        },
        bindings: {
            controlPadding: "@?"
        }
    });
    
})();
