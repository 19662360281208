(function () {
    "use strict";

    angular.module("app").component("collapse", {
        bindings: {
            expanded: "=?",
        },

        transclude: {
            trigger: "trigger",
            target: "target",
        },

        template: `
            <div class="clickable" ng-click="" ng-transclude="trigger"></div>
            <div class="target" ng-style="$ctrl.style">
                <div class="inner" ng-transclude="target">
            </div>
        `,

        controller: function ($element, Collapse) {
            var ctrl = this;

            ctrl.style = {
                display: "none"
            }

            ctrl.$onInit = function () {
                if (ctrl.expanded) {
                    ctrl.style.display = "inherit";
                }

                $element.find(".clickable").click(function (e) {
                    ctrl.collapse.toggle()
                })

                ctrl.collapse = Collapse.new({
                    target: $element.find(".target"),
                    state: ctrl.expanded
                });
            }
        }
    })


//    angular.module("app").directive("collapseTrigger", function () {
//        return {
//            link: function ($scope, $element, $attrs) {
//                function position () {
//                    $element.parent().css("margin-bottom", $element.height() * 2);
//                    $element.css("position", "absolute").css("bottom", 0);
//                }
//
//                $(window).resize(position)
//                position();
//            }
//        }
//    })

})();
