(function () {
    angular.module("app").component("message", {
        template: `
            <div flex layout="column" class="message" ng-class="$ctrl.classes">
                <p style="white-space: pre-line;">{{$ctrl.data.content.trim()}}</p>

                <div class="divider"></div>

                <div layout="row">
                    <div flex="grow">
                        <div>{{$ctrl.data.user.profile.name}}</div>

                        <span title="{{$ctrl.parseTime($ctrl.data.date.created)}}" data-placement="top" tooltip>{{$ctrl.parseDate($ctrl.data.date.created)}}</span>

                        <span ng-if="$ctrl.data.date.edited">
                            (edited <span title="{{$ctrl.parseTime($ctrl.data.date.edited)}}" data-placement="top" tooltip>{{$ctrl.parseDate($ctrl.data.date.edited)}})</span>
                    </div>

                    <md-menu ng-if="$ctrl.active">
                        <md-button class="md-icon-button md-button padding-0-r margin-0-r" type="button" ng-click="$mdMenu.open($event)" >
                            <i class="fa fa-ellipsis-v"></i>
                        </md-button>

                        <md-menu-content class="padding-0" width="3">
                            <md-menu-item>
                                <md-button md-autofocus ng-click="$ctrl.messages.edit($event, $ctrl.data)"><i class="fa fa-pencil"></i><span>Edit</span></md-button>
                            </md-menu-item>

                            <md-menu-item>
                                <md-button md-autofocus ng-click="$ctrl.messages.delete($event, $ctrl.data)"><i class="fa fa-trash"></i><span>Delete</span></md-button>
                            </md-menu-item>
                        </md-menu-content>
                    </md-menu>
                </div>
            </div>
        `,

        bindings: {
            data: "=",
            messages: "=?",
        },

        controller: function (Session, Message, $mdDialog, $mdToast, $http) {
            var ctrl = this;

            ctrl.parseDate = parseDate;
            ctrl.parseTime = parseTime;

            Session.current.$promise.then(function () {
                if (Session.current._id == ctrl.data.user._id) {
                    ctrl.active = true;

                    ctrl.classes = {
                        active: true
                    }
                }
            });

            function parseDate (date) {
                var date_ = moment(date);
                return date_.format("MMMM D, YYYY");
            }

            function parseTime (date) {
                var date_ = moment(date);
                return date_.format("h:mma");
            }
        }
    });
})();
