(function () {
    "use strict";

    angular.module("app").component("domainVerificationCard", {
        template: `
            <md-card layout="column">
                <md-card-title class="padding-2">
                    <md-card-title-text>
                        <span class="md-headline">Verify Domain</span>
                    </md-card-title-text>
                </md-card-title>

                <md-divider></md-divider>

                <md-card-body ng-if="$ctrl.state == 'load'" layout="column" layout-align="center center">
                    <md-progress-circular class="margin-y" md-mode="indeterminate"></md-progress-circular>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'success'" layout="column">
                    <div class="padding-2" layout="column">
                        <p>Your domain has been verified.</p>
                    </div>
                </md-card-body>

                <md-card-body ng-if="$ctrl.state == 'error'" layout="column">
                    <div class="padding-2" layout="column">
                        <p>We could not verify your domain. Please try again.</p>
                    </div>
                </md-card-body>
            </md-card>
        `,

		bindings: {
			domain: "@",
			token: "@",
		},

        controller: function ($http) {
            var ctrl = this;

            ctrl.state = "load";

			ctrl.$onInit = function () {
                $http.post(`/api/organization/domain/${ctrl.domain}/verify`, {
                    method: "email",
                    step: 2,
                    token: ctrl.token,
                }).then((response) => {
                    ctrl.state = "success";
                }).catch((response) => {
					ctrl.state = "error";
				});
			}
        }
    })
})();
