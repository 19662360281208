(function () {
    "use strict";
    
    angular.module("app").component("controlError", {
        templateUrl: "/assets/html/core/error/control-label.html",
        
        bindings: {
            key: "@",
            handler: "=",
        }
    });    
})();
