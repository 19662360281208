(function () {
    "use strict";
//left: training/awareness, right: other
    angular.module("app").component("securityTasksCard", {
        template: `
            <md-card layout-align"middl">
                <md-toolbar class="md-hue-3">
                    <div class="md-toolbar-tools">
                        <icon type="check-square-o" class="margin-r"></icon>
                        <h3>My Security Tasks</h3>
                    </div>
                </md-toolbar>

                <task-list ng-if="$ctrl.tasks.$resolved" tasks="$ctrl.tasks"></task-list>

                <md-card-body ng-if="!$ctrl.tasks.$resolved" layout="column" layout-align="center center">
                    <md-progress-circular class="margin-y" md-mode="indeterminate"></md-progress-circular>
                </md-card-body>
            </md-card>
        `,

        bindings: {
            tasks: "=?"
        },

        controller: function (Session, Assessment, Remediation) {
            var ctrl = this;

            ctrl.moment = moment;
        }
    });
})();
