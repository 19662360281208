(function () {
    "use strict";

    angular.module("app").factory("Procedure", ProcedureFactory);

    function ProcedureFactory ($resource, $http) {

        var Resource = $resource("/api/procedure/:id", {
            id: '@id',
            id: '@_id'
        }, {
            schedule: {
                method:'POST',
                url: '/api/procedure/schedule/:id',
                isArray: false
            },
            create: {
                method:'POST',
                url: '/api/procedure',
                isArray: true
            },
            update: {
                method:'POST',
                url: '/api/procedure/:id',
                isArray: false
            },
            submit: {
                method:'POST',
                url: '/api/procedure/submit/:id',
                isArray: false
            }
        });

        var TemplateResource = $resource("/api/procedure/template/:id", {
            id: "@_id"
        }, {
            update: {
                method:'POST',
                url: '/api/procedure/template/:id',
                isArray: false
            }
        });

        function ProcedureProvider() {
            var self = this;

            self.templates = TemplateResource;
            self.procedures = Resource;

            return self;
        }

        return new ProcedureProvider();
    }

})();
