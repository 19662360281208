(function () {
    "use strict";

    angular.module("app").component("policySidebar", {
        bindings: {
            organization: "=",
        },

        /* html */
        template: `
            <div ng-show="$ctrl.containers">
                <md-card ng-if="$ctrl.containers.length" ng-repeat="container in $ctrl.containers | orderBy:order">
                    <md-card-title class="padding-2-y">
                        <md-card-title-text>
                            <h4>{{container.title}}</h4>
                        </md-card-title-text>
                    </md-card-title>

                    <md-card-content>
                        <span ng-bind-html="container.content"></span>
                    </md-card-content>
                </md-card>

                <md-card ng-if="!$ctrl.containers.length">
                    <md-card-content class="text-muted">
                        <h4>Policy Scope</h4>
                        <p>{{$ctrl.organization.name}} ({{$ctrl.organization.shortName}}) expects every {{$ctrl.organization.phrase.employee}} to protect the confidentiality, integrity, and availability of {{$ctrl.organization.shortName}} information and information systems. Each {{$ctrl.organization.phrase.employee}} must read, understand, and put in practice these information security policy requirements. These policies apply to information (in printed, spoken, or electronic form) from or about individuals and third party organizations.</p>
                        <br/>
                        <p>These policies apply to every <strong>{{$ctrl.organization.phrase.employee}}</strong> including <span ng-repeat="phrase in $ctrl.organization.policy.includeAsWorkers">{{phrase}}, </span>and others who perform work for {{$ctrl.organization.shortName}}. These policies also apply to all <span ng-repeat="phrase in $ctrl.organization.policy.includeAsBAs">{{phrase}}, </span>and other third parties providing services to {{$ctrl.organization.shortName}}.</p>
                        <br/>
                        <p>These policies apply to all places where {{$ctrl.organization.shortName}} information is accessed, processed, stored, or transmitted including {{$ctrl.organization.shortName}} facilities as well as <span ng-repeat="phrase in $ctrl.organization.policy.conductBusinessAt">{{phrase}}, </span>and other locations where {{$ctrl.organization.shortName}} information is handled. <span ng-if="$ctrl.organization.policy.technologies.length < 1">These policies also apply to the technologies involved in creating, accessing, managing, processing, storing, and transmitting data.</span><span ng-if="$ctrl.organization.policy.technologies.length > 0">These policies also apply to the technologies involved in creating, accessing, managing, processing, storing, and transmitting such as <span ng-repeat="phrase in $ctrl.organization.policy.technologies"><span ng-show="$last">and </span>{{phrase}}<span ng-show="!$last">, </span></span>.</span></p>
                    </md-card-content>
                </md-card>
            </div>
        `,

        controller: function ($http, Session) {
            var ctrl = this;

			ctrl.$onInit = function () {
            }
            
            $http.get("/api/policy/sidebar").then(result => {
                ctrl.containers = result.data;
            });
        }
    });
})();
