(function () {
    "use strict";

    angular.module("app").factory("Support", SupportProvider);

    function SupportProvider($resource, $mdDialog, $location) {

        function SupportProvider() {
            var self = this;

            self.resource = $resource(
                "/api/support",
                {},
                {
                    writeMessage: {
                        method: 'POST',
                        url: '/api/support/message'
                    }
                });

            self.supportModal = function () {

                return $mdDialog.show({
                    templateUrl: "/assets/html/support/support-modal.html",
                    controller: function ($scope, $mdDialog, Support) {

                        $scope.submit = function () {
                            $scope.message
                            Support.resource.writeMessage({
                                message: $scope.message,
                                reference: $location.absUrl()
                            }).$promise.then(function (result) {
                                $mdDialog.hide();
                            });
                        }

                        $scope.cancel = function () {
                            $mdDialog.cancel();
                        }

                    },
                    parent: angular.element(document.body),
                    bindToController: true,
                    clickOutsideToClose: false,
                    locals: {
                    }
                });
            }

            return self;
        }

        return new SupportProvider();
    }
})();
