(function () {
	angular.module("app").component("notificationConfiguration", {
		bindings: {
			organization: "=",
		},

		/* html */
		template: `
			<md-card-header>
				<md-card-header-text>
					<h3 class="md-title">Notifications</h3>

					<span class="md-subhead md-block">
						<content content-id="config.notifications.description"></content>
					</span>
				</md-card-header-text>
			</md-card-header>

			<md-divider></md-divider>

			<md-card-content>
				<form name="NotificationConfigurationForm">
					<md-input-container class="md-block">
						<label>User Invite</label>
						<textarea ng-if="true||$ctrl.render" rows="5" ng-model="$ctrl.organization.invitation.user"></textarea>
					</md-input-container>

					<md-input-container class="md-block">
						<label>Vendor Invite</label>
						<textarea ng-if="true||$ctrl.render" rows="5" ng-model="$ctrl.organization.invitation.vendor"></textarea>
					</md-input-container>
                </form>
			</md-card-content>
        `,

		controller: function ($timeout) {
			var ctrl = this;

			$timeout(() => {
				ctrl.render = true;
			}, 5000);
		},
	});
})();