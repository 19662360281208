(function () {
    angular.module("app").controller("EditOrgController", EditOrgController);

    function EditOrgController($scope, $mdDialog, $mdToast, $http, org, Organization, Compliance) {

        $scope.ready = false;

        Compliance.industryResource.query().$promise.then(function (industries) {
            $scope.industries = industries;
        });

        // get the current org if we have an id, or create a new user if not
        if (org._id) {
            $scope.isNew = false;
            Organization.resource.get({
                id: org._id
            }, function (org) {
                $scope.org = org;
                $scope.ready = true;
            });
        } else {
            $scope.org = org;
            $scope.org.status = {
                licensed: true
            };
            $scope.isNew = true;
            $scope.ready = true;
        }

        $scope.update = function () {
            $scope.org.$save().then(function (org) {
                $scope.errorMsg = null;

                $mdDialog.hide($scope.org);
                showToast("Saved");

            }, function (err) {
                // ToDo: Save failed.  Need cleaner way if getting message to the user.
                console.log("ERROR", err);
                $scope.errorMsg = err.data.error[0].msg;
            });
        };

        // show toaster message
        showToast = function (msg) {
            $mdToast.show(
                $mdToast.simple()
                .textContent(msg)
                .hideDelay(3000)
            );
        }

        // cancel/close the edit modal
        $scope.cancelDialog = function () {
            $mdDialog.cancel();
        }

    };
})();