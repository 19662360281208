/**
 * @ngdoc component
 * @name app.control.component:controlStateGrapth
 * @author Nick Vorie
  *
 * @param {arraay}   controlStates The ControlStates to generate the graph for
 * @param {function} onClick Callback with index of clicked ControlState
 *
 * @description This component will render a graph for a set of given ControlStates
 */
(function () {
    "use strict";

    angular.module("app").component("controlStateGraph", {
        bindings: {
            controlStates: "=",
            onClick: "=?"
        },

        template: `
            <div flex layout="row" layout-align="center center">
                <canvas class="chart" width="{{$ctrl.width}}" height="200" style="-moz-user-select: none; -webkit-user-select: none; -ms-user-select: none;}"></canvas>
            </div>
        `,

        controller: function ($element, Cvss) {
            var ctrl = this;

            var container = $element.find("div")[0];
            ctrl.width = container.offsetWidth - 20;

            ctrl.$onInit = function () {
                var controlStates = _.chain(ctrl.controlStates).cloneDeep().reverse().slice(0, 10).reverse().value();

                var minResidualRisks = _.map(controlStates, (controlState) => {
                    return controlState.metrics[2].value.min;
                });

                var maxResidualRisks = _.map(controlStates, (controlState) => {
                    return controlState.metrics[2].value.max;
                });

                var controlsInPlace = _.map(controlStates, (controlState) => {
                    return controlState.metrics[0].value / 10 ;
                });

                setTimeout(function () {
                    var ctx = $element.find("canvas")[0].getContext("2d");

                    var config = {
                        type: "line",

                        data: {
                            labels: _.map(controlStates, (controlState) => {
                                return moment(controlState.date).format("MMM D");
                            }),

                            datasets: [
                                {
                                    label: "Controls in Place",
                                    data: controlsInPlace,
                                    backgroundColor: "rgba(0, 0, 0, 0)",
                                    borderColor: "rgba(40, 40, 160, 0.9)"
                                }, {
                                    label: "Min Residual Risk",
                                    data: minResidualRisks,
                                    backgroundColor: "rgba(255, 255, 255, 1)",
                                    borderColor: "rgba(151, 151, 151, 1)",
                                }, {
                                    label: "Max Residual Risk",
                                    data: maxResidualRisks,
                                    backgroundColor: "rgba(255, 191, 0, .5)",
                                    borderColor: "rgba(255, 191, 0, 1)"
                                },
                            ]
                        },

                        options: {
                            tooltips: {
                                mode: "index",
                                intersect: false,
                            },

                            responsive: false,
                            maintainAspectRatio: false,

//                            onClick: function (a, clickedPoints) {
//                                if (clickedPoints) {
//                                    ctrl.onClick(clickedPoints);
//                                }
//                            },

                            scales: {
                                yAxes: [{
                                    ticks: {
                                        min: 0,
                                        max: 10
                                    }
                                }]
                            }
                        }
                    };

                    ctrl.chart = new Chart(ctx, config);
                }, 300);
            }
        }
    });
})();
