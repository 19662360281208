(function () {
    "use strict";

    angular.module("app").component("commentChip", {
        template: `<span ng-if="$ctrl.count>0" class="fa-stack" style="margin-left:12px;position:absolute;top:-1px">\
                        <i class="fa fa-comment fa-stack-2x" style="color:gray"></i>\
                        <strong class="fa-stack" style="color:white;padding-left:2px;padding-top:4px;font-size:80%">\
                            {{$ctrl.count}}\
                        </strong>\
                    </span>`,
        bindings: {
            count: "<"
        },

        controller: function () {
            var ctrl = this;
        }
    })
})();
