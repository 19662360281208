(function () {
    angular.module("app").directive("userTwoFactor", userTwoFactor);
    
    function userTwoFactor() {
        return {
            templateUrl: "/assets/html/user/two-factor.html",

            controller: function ($scope, $http, Session, Error) {
                $scope.Session = Session;
                $scope.errorHandler = Error.get("user-two-factor");

                $scope.enableTwoFactor = enableTwoFactor;
                $scope.disableTwoFactor = disableTwoFactor;
                $scope.verifyTwoFactor = verifyTwoFactor;
                $scope.resetTwoFactor = resetTwoFactor;

                cleanScope();

                function cleanScope() {
                    $scope.data = {
                        password: ""
                    };
                }
                
                function enableTwoFactor() {
                    $http.post("/api/user/two-factor", {
                        state: true,
                        password: $scope.data.password
                    }).then(function(response) {
                        Session.loadUser();
                        cleanScope()
                    }, function(response) {
                        $scope.errorHandler.process(response.data);
                        cleanScope()
                    });            
                }

                function disableTwoFactor() {
                    $http.post("/api/user/two-factor", {
                        state: false,
                        password: $scope.data.password
                    }).then(function(response) {
                        Session.loadUser();
                        cleanScope();
                    }, function(response) {
                        $scope.errorHandler.process(response.data);
                        cleanScope()
                    });
                }

                function verifyTwoFactor() {
                    $http.get("/api/user/two-factor/verify/" + $scope.data.token).then(function(response) {
                        Session.loadUser();
                        cleanScope()
                    }, function(response) {
                        $scope.errorHandler.process(response.data);
                        cleanScope()
                    });
                }

				function resetTwoFactor () {
					$http.post("/api/user/two-factor/reset").then(response => {
						Session.currentUser.twoFactor.enabled = false;
					}).catch(response => {

					});
				}
            }
        }
    }
})();
