(function () {
    angular.module("app").controller("AdminTrainingController", AdminTrainingController);

    function AdminTrainingController($scope, $mdSidenav, $mdDialog, $mdToast, $http, Session, Training, Users, UserRole, Compliance, Upload) {

        $scope.messages = [];
        $scope.currentMessage = null;
        $scope.isAdmin = false;
        $scope.canEdit = false;
        $scope.canDelete = false;
        $scope.uploadPercent = 0;
        $scope.showVideoProgress = false;
        $scope.showImageProgress = false;
        $scope.submitting = false;
        $scope.themes = [];
        $scope.requirements = [];
        $scope.filter = {
            searchText: "",
            type: "",
            showDefaults: true,
            showDisabled: true
        };

        this.$onInit = function () {

            Session.currentUser.$promise.then(function (user) {
                $scope.isAdmin = user.admin;
                $scope.canEdit = (user.roles.findIndex(function (role) {
                    return role.slug == 'cso' || role.slug == 'riskmanager'
                }) > -1);
            });

            Training.resource.query().$promise.then(function (messages) {
                $scope.messages = messages;
            });

            Compliance.requirementsResource.query().$promise.then(function (reqs) {
                $scope.requirements = reqs;
            });

        }

        $scope.update = function () {
            $scope.submitting = true;

            Training.resource.update($scope.currentMessage).$promise.then(function (result) {
                //                $scope.currentMessage.sentOnce = $scope.currentMessage.sendOnce.sendOnce;

                var idx = $scope.messages.findIndex(function (document) {
                    if (result.replacesDocument) {
                        return result._id == document._id || result.replacesDocument._id == document._id;
                    } else {
                        return result._id == document._id;
                    }
                });

                if (idx > -1) {
                    $scope.messages[idx] = result;
                } else {
                    $scope.messages.push(result);
                }

                $scope.submitting = false;
                showToast("Training message saved.");
            });

            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        }

        $scope.sendNow = function (document, $event) {

            sendNowController = function ($scope, $mdDialog, Users, document) {
                $scope.document = document;
                $scope.document.sendTo = [];

                this.$onInit = function () {
                    Users.resource.query({}).$promise.then(function (users) {
                        $scope.users = users.filter(user => {
                            if (user.status.state == "archived" || user.disabled) {
                                return false;
                            }

                            return true;
                        }).map(function (user) {
                            return {
                                _id: user._id,
                                email: user.email,
                                profile: user.profile
                            }
                        });

                        // get a list of roles
                        UserRole.resource.query().$promise.then(function (userRoles) {
                            $scope.userRoles = userRoles;
                            $scope.usersAndRoles = $scope.users.concat(userRoles);
                        });

                    });
                }

                $scope.cancel = function () {
                    $mdDialog.cancel();
                }

                $scope.send = function () {
                    $mdDialog.hide($scope.document);
                }

                $scope.filterUsers = function (query) {
                    return $scope.usersAndRoles.filter(function (user) {
                        if (user.profile) {
                            return user.profile.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        } else {
                            return user.name.toLowerCase().indexOf(query.toLowerCase()) > -1;
                        }
                    });
                }
            }

            $mdDialog.show({
                parent: angular.element(document.body),
                targetEvent: $event,
                templateUrl: '/assets/html/training/sendNowModal.html',
                locals: {
                    document: document,
                    usersAndRoles: $scope.usersAndRoles
                },
                controller: sendNowController
            }).then(function (docToSend) {

                Training.resource.sendNow(docToSend).$promise.then(function (result) {
                    showToast("Training sent.");
                });

            }, function () {
            });


        }

        $scope.deleteDocument = function (document) {

            var confirm = $mdDialog.confirm()
                .title('Confirm removal of this customized training?')
                .textContent(document.title)
                .ariaLabel('Remove document')
                .ok('Yes, remove training')
                .cancel('No, cancel');

            $mdDialog.show(confirm).then(function () {
                removeItem(document);
            }, function () {
            });

        }

        removeItem = function (document) {
            $scope.submitting = true;

            Training.resource.delete({ id: document._id }).$promise.then(function (result) {

                Training.resource.query().$promise.then(function (messages) {
                    $scope.messages = messages;

                    $mdSidenav("editSideNav")
                        .close()
                        .then(function () {
                        });

                    $scope.submitting = false;
                    showToast("Training removed: " + document.title);
                });

            }, function (err, result) {

            });

        }

        showToast = function (msg) {
            $mdToast.show(
                $mdToast.simple()
                    .textContent(msg)
                    .hideDelay(3000)
            );
        }

        $scope.editTraining = function (message) {

            if (message.sendOnce == null) {
                message.sendOnce = {};
            }
            if (message.sendOnce.to == null) {
                message.sendOnce.to = [];
            }

            $scope.currentMessage = message;

            if ($scope.currentMessage.organization) {
                $scope.canDelete = true;
            } else {
                $scope.currentMessage.default = true;

                if ($scope.isAdmin) {
                    $scope.canDelete = true;
                } else {
                    $scope.canDelete = false;
                }
            }

            if ($scope.currentMessage.video && $scope.currentMessage.video.url) {
                var player = document.getElementById('videoPlayer');
                var source = document.getElementById('mp4Source');
                player.pause();
                source.src = $scope.currentMessage.video.url;
                player.load();
            }

            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        }

        $scope.newDocument = function () {

            $scope.currentMessage = {
                isNew: true,
                enabled: false,
                requireAcknowledgement: false
            };
            $scope.canEdit = false;

            $scope.editForm.$setUntouched();
            $scope.editForm.$setPristine();

            $mdSidenav("editSideNav").toggle().then(function () {
            });
        }

        $scope.cancelEdit = function () {
            $mdSidenav("editSideNav")
                .toggle()
                .then(function () {
                });
        };

        $scope.filterEnabled = function (message) {
            if (!$scope.filter.showDisabled && !message.enabled) {
                return false;
            } else {
                return true;
            }
        }

        $scope.filterType = function (message) {
            if ($scope.filter.type == "") {
                return true;
            }
            if ($scope.filter.type == message.type) {
                return true;
            }

            return false;
        }

        $scope.uploadFile = function (file, type) {
            Upload.upload({
                url: '/api/training/' + $scope.currentMessage._id + '/' + type,
                data: { file: file }
            }).then(function (resp) {

                resp.data.url += `?${_.random(100)}=${_.random(100)}`;
                $scope.currentMessage[type] = resp.data;
                showToast("Upload complete.");
                $scope.showVideoProgress = false;
                $scope.showImageProgress = false;
                $scope.submitting = false;
                $scope.uploadPercent = 0;

                if (type == 'video') {
                    var player = document.getElementById('videoPlayer');
                    var source = document.getElementById('mp4Source');
                    player.pause();
                    source.src = $scope.currentMessage.video.url;
                    player.load();
                }
                //                console.log('Success ' + resp.config.data.file.name + 'uploaded. Response: ' + resp.data);

            }, function (resp) {
                console.log('Error status: ' + resp.status);
                $scope.showVideoProgress = false;
                $scope.showImageProgress = false;
                $scope.submitting = false;
                $scope.uploadPercent = 0;
            }, function (evt) {
                $scope.submitting = true;
                if (type == 'video') {
                    $scope.uploadPercent = parseInt(100.0 * evt.loaded / evt.total);
                    $scope.showVideoProgress = true;
                } else {
                    $scope.showImageProgress = true;
                }
                //console.log('progress: ' + progressPercentage + '% ' + evt.config.data.file.name);
            });
        };

        $scope.resetFile = function (type) {
            $http.delete("/api/training/" + $scope.currentMessage._id + "/" + type).then(function (resp) {
                $scope.currentMessage[type] = resp.data;
            }, function (resp) {
                //                ctrl.errorHandler.process(response.data);
            });
        }

        $scope.clearFilter = function () {
            $scope.filter.searchText = "";
            $scope.filter.type = "";
            $scope.filter.showDefaults = true;
            $scope.filter.showDisabled = true;
        };

        $scope.filterDefaults = function (document) {
            return $scope.filter.showDefaults || document.organization;
        };


    }
})();
