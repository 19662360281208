(function () {
    "use strict";

    angular.module("app").factory("Policy", PolicyFactory);

    function PolicyFactory ($resource, $http) {

        var PolicyResource = $resource("/api/policy/:id", {
            id: "@_id"
        }, {
            update: {
                method:'POST',
                url: '/api/policy/:id',
                isArray: false
            }
        });

        function PolicyProvider() {
            var self = this;

            self.resource = PolicyResource;

            self.getCategories = function() {
                return $resource("/api/policy/categories");
            }

            self.getSections = function(category) {
                return $resource("/api/policy/sections/:category", {
                    category: category
                });
            }

            self.getByRole = function(role) {
                return $resource("/api/policy/byRole/:role", {
                    role: role
                });
            }

            self.acknowledgeResource = $resource(
                "/api/policy/acknowledge",
                {},
                {
                    acknowledge: {
                        method:'POST',
                        url: '/api/policy/acknowledge',
                        isArray: false
                    }
                }
            );

            self.getByUser = function() {
                return $resource("/api/policy/byUser");
            }

            self.orgPolicies = function() {
                return $resource("/api/policy/orgPolicies");
            }

            return self;
        }

        return new PolicyProvider();
    }

})();
