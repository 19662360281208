/**
 * @ngdoc service
 * @name app.container.service:Container
 * @author Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Containers
 */
(function () {
    "use strict";

    angular.module("app").factory("Container", function ($resource, $http, $mdDialog, $mdToast, Technology) {
        var self = this;

        var Resource = $resource("/api/:containerType/:id", {
            containerType: "@containerType",
            id: "@_id",
        }, {
            metrics: {
                method: "GET",
                params: {
                    id: "metrics"
                }
            }
        });

        var TypeResource = $resource("/api/container/:id", {
            id: "@_id"
        });

        self.Resource = Resource;

        self.typeResource = TypeResource;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name endpoint
         *
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         *
         * @description Generate an api endpoint path for container
         * */
        function endpoint(container, type) {
            var containerId = container ? `${container._id}/` : ""
            return `/api/${type}/${containerId}`;
        }

        self.endpoint = endpoint;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name query
         *
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         * @param {array}  populate  An array of properties to select from the document
         *
         * @description Generate an api endpoint path for container
         * */
        function query(type, select) {
            return Resource.query({
                containerType: type,
                "select[]": select || ["controlState.current", "assessment.current", "remediation.current"]
            }, () => { });
        }

        self.query = query;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name metrics
         *
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         *
         * @description Generate an api endpoint path for container
         * */
        function metrics(type) {
            var metrics = {
                values: [],
                count: 0,
            };

            new Resource({
                containerType: type
            }).$metrics((data) => {
                metrics.values = data.values;
                metrics.count = data.count;
            });

            return metrics;
        }

        self.metrics = metrics;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name endpoint
         *
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         * @param {array}  populate  An array of properties to select from the document
         *
         * @description Generate an api endpoint path for container
         * */
        function load(type, id, select) {
            return Resource.get({
                containerType: type,
                id: id,
                "select[]": select || ["controlState", "assessment.current", "remediation.current"]
            }, () => { });
        }

        self.load = load;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name remove
         *
         * @param {event} $event     Event passed from ng-click
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         *
         * @description Display an confirm dialog an delete a container
         * */
        function remove(container, type) {
            container.containerType = type;
            var containerResource = new Resource(container);

            containerResource.$remove((res) => { });

        }

        self.remove = remove;

        /*
         * @ngdoc method
         * @methodOf app.container.service:Container
         * @name edit
         *
         * @param {event} $event     Event passed from ng-click
         * @param {object} container The container to edit
         * @param {string} type      The containerType
         *
         * @description Display an edit modal for a container
         * */
        function edit($event, containers, container, type) {
            const oldContainer = _.clone(container);

            //Call dialog
            $mdDialog.show({
                templateUrl: `/assets/html/${type}/edit-modal.html`,

                locals: {
                    container: container,
                    containerType: type,
                },

                controller: ($scope, Container, container, containerType, Upload, Assessment, Organization) => {
                    //Create Container resource instance
                    container.containerType = containerType;

                    if (containerType == "technology") {
                        $scope.Technology = Technology;
                    }

                    $scope.container = container;
                    $scope.oldContainer = _.cloneDeep($scope.container);
                    $scope.containerType = containerType;

                    $scope.organization = Organization.current;

                    $scope.organization.$promise.then(() => {
                        $scope.notificationEmailUrl = emailLink();
                    });

                    $scope.$watch("container.primaryContact.email", () => {
                        $scope.notificationEmailUrl = emailLink();
                    });

                    //Save callback
                    $scope.save = function () {
                        if (!$scope.container.type) {
                            $mdToast.showSimple("Type is required");
                            return;
                        }

                        if (containerType == "vendor") {
                            if (_.get($scope, "container.baa.inPlace")) {
                                if (!$scope.container.baa.executionDate || $scope.container.baa.executionDate == "" || !$scope.container.baa.expirationDate || $scope.container.baa.expirationDate == "") {
                                    return;
                                };
                            };
                        }

                        var form = $scope.form;

                        if ($scope.oldContainer.manager && !$scope.container.manager && $scope.container.remediation.current) {
                            $mdDialog.show($mdDialog.confirm().multiple(true).title("Warning").textContent("Removing the manager from this container will clear the current remediation plan.").ok("Confirm").cancel("Cancel")).then(function () {
                                checkDataTypes();
                            });
                        } else {
                            checkDataTypes();
                        }

                        function checkDataTypes() {
                            if (oldContainer.dataTypes && $scope.container.dataTypes && !_.isEqual(oldContainer.dataTypes.sort(), $scope.container.dataTypes.sort())) {
                                $mdDialog.show($mdDialog.confirm().multiple(true).title("Warning").textContent("Changing the data types associated with this container will 'clear' any not-in-place control where you have accepted risk. Review not-in-place controls for this container and re-record accepted risks based on the updated data types.").ok("Confirm").cancel("Cancel")).then(function () {
                                    save();
                                });
                            } else {
                                save();
                            }
                        }

                        function save() {
                            $mdToast.showSimple("Saving...");

                            var isNew = !$scope.container._id;
                            var containerResource = new Container.Resource(_.cloneDeep($scope.container));

                            containerResource.$save((container, a) => {

                                console.log("RESULT", container, a)

                                if ($scope.avatar) {
                                    if (_.get($scope, "avatar.reset")) {
                                        $scope.resetImage();
                                        finish();
                                    } else if (form.avatar.$valid && $scope.avatar) {
                                        $scope.uploadImage($scope.avatar, finish);
                                    } else {
                                        finish();
                                    }
                                } else {
                                    finish();
                                }

                                function finish() {
                                    $mdDialog.hide(container);

                                    if (isNew) {
                                        Assessment.start(null, container, containerType);
                                    }

                                    $mdToast.showSimple("Saved");
                                }
                            }, (error) => {
                                let message = "Unknown error."

                                console.error(error);

                                if (error.data) {
                                    message = _.values(error.data.error.message).concat();
                                }

                                $mdToast.showSimple(`Error saving. ${message}`);
                            });
                        }

                    }

                    function emailLink() {
                        const organization = $scope.organization;

                        var name = organization.shortName || organization.name;
                        var subject = `Vendor Assessment from ${name}`;
                        var body = organization.invitation.vendor;
                        var body1 = body.replace(/{orgName}/g, name);

                        body = encodeURI(body1);

                        return `mailto:${$scope.container.primaryContact.email}?subject=${subject}&body=${body}`;
                    }

                    //Cancel callback
                    $scope.cancel = function () {
                        $mdDialog.cancel();
                    }

                    $scope.setAvatar = function ($file) {
                        $scope.avatar = $file;
                    }

                    $scope.resetAvatar = function (value) {
                        if (!$scope.avatar) {
                            $scope.avatar = {}
                        }

                        $scope.avatar.reset = value;
                    }

                    $scope.uploadImage = function (file, cb) {
                        Upload.upload({
                            url: '/api/' + type + '/' + $scope.container._id + '/image',

                            data: {
                                file: file
                            }
                        }).then(function (resp) {
                            $scope.container.image = resp.data;
                            $scope.container.image.url += "?" + _.random(0, 100) + "=1"

                            $mdToast.showSimple("Image uploaded");

                            setTimeout(cb, 500);
                        }, function (resp) {
                            $mdToast.showSimple("Image upload failed");

                            cb();
                        });
                    };

                    $scope.resetImage = function () {
                        $http.delete('/api/' + type + '/' + $scope.container._id + '/image').then(function (resp) {
                            $scope.container.image = resp.data;
                            container = $scope.container;
                            $mdDialog.hide(container);

                            $mdToast.showSimple("Image reset");
                        }, function (resp) {
                            $mdToast.showSimple("Image failed");
                        });
                    }
                },

                targetEvent: $event,
                parent: angular.element(document.body),
                bindToController: true,
                clickOutsideToClose: true,
                multiple: true,
            }).then((updatedContainer) => {
                if (!containers || containers.find(c => c._id == updatedContainer._id)) {
                    _.assign(container, updatedContainer);
                } else {
                    containers.push(updatedContainer);
                }
            }, () => { });
        }

        self.edit = edit;

        self.archive = function ($event, containers, container, type) {
            const archived = !!container.archived;

            $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent(`Are you sure you want to ${archived ? 'un' : ''}archive this?`).ok("Confirm").cancel("Cancel")).then(function () {

                $http.post(`/api/${type}/${container._id}/archive`).then(() => {
                    container.archived = !container.archived;
                    if (container.archived) {
                        $mdToast.showSimple("Archived");
                    } else {
                        $mdToast.showSimple("Unarchived");
                    }

                }).catch((error) => {
                    if (error.data.error == "assessment") {
                        $mdToast.showSimple("Cannot archive a container that has an active assessment");
                    } else if (error.data.error == "remediation") {
                        $mdToast.showSimple("Cannot archive a container that has an active remediation plan");
                    } else if (error.data.error == "procedure") {
                        $mdToast.showSimple("Cannot archive a container that has an active procedure");
                    } else {
                        $mdToast.showSimple("Error");
                    }
                });
            });
        }

        //TODO: clean up/document
        function deleteItem($event, containers, container, type) {
            $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Are you sure you want to remove this?").ok("Confirm").cancel("Cancel")).then(function () {

                self.remove(container, type);

                $mdToast.showSimple("Removed");

                if (containers == null) {
                    window.location = "/#" + type;
                } else {
                    containers.splice(containers.indexOf(container), 1);
                }
            });
        }

        self.deleteItem = deleteItem;

        return self;
    });
})();