(function () {
    "use strict";

    angular.module("app").component("taskList", {
        /* html */
        template: `
            <md-list class="padding-0">
                <md-list-item class="md-3-line" ng-repeat="task in $ctrl.Task.currentTasks | orderBy:'+date.due'" ng-init="task.overdue = $ctrl.moment().isAfter(task.date.due)" ng-href="{{task.type == 'prompt' ? '/api/tasks/open/' + task._id : task.url}}">
                    <img ng-if="task.container.image.url" ng-src="{{task.container.image.url}}" class="md-avatar" alt="" />

                    <img ng-if="!task.container.image.url && task.image.url" ng-src="{{task.container.image.url}}" class="md-avatar" alt="" />

                    <div ng-if="!task.container.image.url && !task.image.url" class="md-avtar-icon" md-colors="{color:'primary'}">
                        <md-icon ng-if="task.container.type.icon" md-font-icon="{{task.container.type.icon}}" class="fa"></md-icon>
                        <md-icon ng-if="!task.container.type.icon" md-font-icon="{{'fa-' + (task.icon || 'check') }}" class="fa"></md-icon>
                    </div>

                    <div class="md-list-item-text">
                        <h3>{{task.title}}</h3>
                        <h4>{{task.description}}</h4>

                        <p ng-if="task.details">{{task.details}}</p>
                    </div>

                    <chip class="margin-l" type="{{task.overdue ? 'danger' : ''}}">
                        <span class="tooltip-wrapper">
                            <md-tooltip md-direction="top">Due {{$ctrl.moment().to(task.date.due)}}</md-tooltip>
                            <span>{{task.date.due | dateFormat:"short"}}</span>
                        </span>
                    </chip>

                    <md-divider ng-if="!$last"></md-divider>
                </md-list-item>

                <md-list-item ng-if="!$ctrl.Task.currentTasks.length">
                    <span>No Tasks</span>
                </md-list-item>
            </md-list>
        `,

        bindings: {
            tasks: "=?"
        },

        controller: function (Task) {
            var ctrl = this;

            ctrl.moment = moment;
            ctrl.Task = Task;
        }
    });
})();