(function () {
    "use strict";

    angular.module("app").factory("Document", DocumentFactory);

    function DocumentFactory($resource, $http) {

        var DocumentResource = $resource("/api/documents/:id", {
            id: "@_id"
        }, {
            update: {
                method: 'POST',
                url: '/api/documents/:id',
                isArray: false
            }
        });

        var DocumentTypeResource = $resource("/api/documents/type");

        function DocumentProvider() {
            var self = this;

            self.resource = DocumentResource;
            self.types = DocumentTypeResource.query();

            self.orgDocuments = function () {
                return $resource("/api/documents/orgDocuments");
            }

            return self;
        }

        return new DocumentProvider();
    }

})();