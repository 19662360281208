/**
 * @ngdoc component
 * @name app.uiKit.component:riskChip
 * @author Nick Vorie
  *
 * @param {object} threatPair The ThreatPair to render a chip for
 *
 * @description This component will render a CVSS color-coded risk score for a given ThreatPair
 */
(function () {
    "use strict";

    angular.module("app").component("riskChip", {
        bindings: {
            title: "=",
            value: "=",
        },

        template: `
            <chip type="xs">
                <md-tooltip>Risk: {{$ctrl.cvssScore.name}}</md-tooltip>

                <span ng-if="$ctrl.value.max > -1" class="value" ng-style="$ctrl.cvssScore.css">
                    <span>{{$ctrl.value.max}}</span>
                </span>

                <span ng-if="$ctrl.value.min > -1 && $ctrl.value.max == undefined" class="value" ng-style="$ctrl.cvssScore.css">
                    <span>{{$ctrl.value.min}}</span>
                </span>

                <span ng-if="$ctrl.value.min == undefined && $ctrl.value.max == undefined" class="value" ng-style="$ctrl.cvssScore.css">
                    <span>{{$ctrl.value}}</span>
                </span>

                <span>{{$ctrl.title}}</span>
            </chip>
        `,

        controller: function (Cvss) {
            var ctrl = this;

            ctrl.$onChanges = (changes) => {
                ctrl.cvssScore = Cvss.getSeverity(ctrl.value.max || ctrl.value);
            }
        }
    })
})();
