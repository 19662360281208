(function () {
    "use strict";

    angular.module("app").directive("surfaceTouch", function () {
        return {
            link: function ($scope, element, attrs) {
                element.addClass("touch-watched");

                element.on("click", function () {
                    element.addClass("touch-active");
                    setTimeout(function () {
                        element.removeClass("touch-active");
                    }, 350);
                });
            }
        }
    });
    
})();
