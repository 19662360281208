/**
 * @ngdoc component
 * @name app.data-type.component:datatypeConfiguration
 * @author Nick Vorie

 * @description This component will render a series of check boxes used to specify what data is stored on a given Container
 */
(function () {
    angular.module("app").component("datatypeConfiguration", {
		/* html */
		template: `
			<div layout="column">
				<div ng-repeat="v in $ctrl.dataTypes" layout="row" layout-align="start center">
					<md-checkbox flex="40" ng-model="v.enabled" ng-click="$ctrl.update()">{{v.dataType.name}}</md-checkbox>
					<md-slider flex="30" ng-model="v.weight" ng-disabled="!v.enabled" min="0" max="10" ng-change="$ctrl.update()"></md-slider>
				</div>
			</div>
        `,

		bindings: {
			organization: "="
        },

        controller: function ($http, $timeout) {
            var ctrl = this;

			ctrl.$onInit = function () {
				ctrl.dataTypes = [];

				$http.get("/api/data-type").then(result => {
					result.data.forEach(dataType => {
						var enabled = dataType.defaults.enabled;
						var weight = dataType.defaults.value;

						if (ctrl.organization.dataTypes) {
							var existingType = ctrl.organization.dataTypes.find(v => {
								return dataType._id == (v.dataType._id || v.dataType);
							});

							if (existingType) {
								enabled = existingType.enabled;
								weight = existingType.weight;
							}
						}

						ctrl.dataTypes.push({
							dataType,
							enabled,
							weight,
						});
					});
				});
			}

			ctrl.update = function () {
				$timeout(() => {
					ctrl.organization.dataTypes = ctrl.dataTypes.map(dataType => {
						return {
							dataType: dataType.dataType._id,
							enabled: dataType.enabled,
							weight: dataType.weight
						}
					});
				});
			}
        }
    })
})();
