(function () {
    "use strict";

    angular.module("app").component("tabList", {
        bindings: {
            group: "=",
            search: "@?",
        },

        template: `
            <ul class="tabs divider">
                <tab ng-repeat="tab in $ctrl.group.tabs" tab="tab" search="{{$ctrl.search}}"></tab>
            </ul>
        `,

        controller: function (Tab) {
            var ctrl = this;
            ctrl.Tab = Tab;
        }
    })
})();
