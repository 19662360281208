(function () {
    "use strict";
    
    angular.module("app").component("cvssInput", {
        template: `
            <div class="row">
                <div class="col-xs-12">
                    <p>{{$ctrl.label}}</p>
                </div>

                <div class="col-xs-11">
                    <cvss-vector ng-click="$ctrl.showModal();" cvss-data="$ctrl.cvssData"></cvss-vector>
                </div>

                <div class="col-xs-1" style="position: relative; top: 10px;">
                    <a ng-click="$ctrl.toggleScores();" class="btn btn-flat-secondary" style="position: relative; right: 15px;"><icon type="{{$ctrl.toggleScoreIcon}}"></icon></a>
                </div>

                <div class="col-xs-12 ease-transition" ng-show="$ctrl.scoresActive" ng-animate="'appear-transition'">
                    <cvss-score-group scores="$ctrl.cvssData.scores"></cvss-score-group>
                </div>
            </div>
        `,
        
        bindings: {
            label: "@",  
            bindTo: "="
        },
        
        controller: function (CvssFactory, ModalFactory) {
            var ctrl = this;
            
            ctrl.bindTo = ctrl.cvssData = CvssFactory.newCvssInstance();
            ctrl.scoresActive = ctrl.scoresActive || false;
            
            ctrl.showModal = showModal;
            ctrl.toggleScores = toggleScores;
            
            function showModal () {
                ModalFactory.template("cvss", {
                    cvssData: ctrl.cvssData, 
                    update: updateData
                });
            }
            
            function toggleScores (state) {
                ctrl.scoresActive = (state !== undefined) ? state : ctrl.scoresActive = !ctrl.scoresActive;
                ctrl.toggleScoreIcon = ctrl.scoresActive ? "chevron-up" : "chevron-down";
            }
            
            function updateData (data) {
                ctrl.cvssData = CvssFactory.updateInstance(data);
            }
            
            ctrl.$onInit = function () {
                ctrl.ModalFactory = ModalFactory;
                ctrl.toggleScores(ctrl.scoresActive);
            }
        }
    });
})();