(function () {
    angular.module("app").controller("StandardDetailController", StandardDetailController);

    function StandardDetailController ($scope, $location, $mdDialog, $mdToast, Standard) {

        $scope.standard = {};
        $scope.ready = false;
        $scope.isNew = true;

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        if (id == "new") {
            $scope.standard = new Standard.resource();
            $scope.ready = true;
        } else {
            Standard.resource.get({id: id}, function(standard) {
                $scope.standard = standard;
                $scope.isNew = false;
                $scope.ready = true;
            });
        }

        // update the database
        $scope.update = function() {

            $scope.standard.$save().then(function(data) {
                  // save worked
                 showToast("Standard '" + $scope.standard.title + "'saved.");
            }, function(err, test) {
                  // save failed, should tell the user
                  // console.log("save existing didn't work ", err, test);
            });
        };

        // show toast; everyone loves toast
        showToast = function(msg) {
            $mdToast.show (
                $mdToast.simple()
                .textContent(msg)
            );
        };

        $scope.addSection = function(standard) {
            $scope.inserted = {
                title: '',
                text: ''
            };
            standard.sections.push($scope.inserted);
        };

        $scope.addSlug = function(standard) {
            $scope.insertedSlug = {
                slug: '',
                default: ''
            };
            standard.slugs.push($scope.insertedSlug);
        };

        $scope.showDeleteDialog = function(list, index, msg) {
            $scope.modalMessage = msg;

            $mdDialog.show({
                contentElement: '#confirmDelete',
                clickOutsideToClose: true,
                controller: 'StatementDetailController',
                parent: angular.element(document.body)
            }).then( function() {
                removeItem(list, index);
            }, function() {
                // cancel button was pressed
                //console.log("cancel");
            });
        };

        $scope.closeDialog = function() {
              $mdDialog.hide();
        };

        $scope.cancelDialog = function() {
              $mdDialog.cancel();
        };

        function removeItem(list, index) {
            list.splice(index, 1);
            $scope.update();
        };

        $scope.cancel = function() {
              location = "/standards/standards";
        };

    }
})();
