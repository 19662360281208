(function () {
    "use strict";
    
    angular.module("app").component("cvssScore", {
        template: `
            <div class="form-group-material">
                <input type="text" ng-model="$ctrl.score.severityString" ng-style="{color: $ctrl.score.rating.color}" disabled>
                <label>{{$ctrl.label}}</label>
            </div>
        `,

        bindings: {
            score: "=",
            label: "@",
        }
    }).component("cvssScoreGroup", {
        template: `
            <cvss-score label="Base Score" score="$ctrl.scores.base"></cvss-score>
            <cvss-score label="Temporal Score" score="$ctrl.scores.temporal"></cvss-score>
            <cvss-score label="Enviromental Score" score="$ctrl.scores.enviromental"></cvss-score>
        `,

        bindings: {
            scores: "="
        }
    })
})();