(function () {
    angular.module("app").controller("VendorDetailController", VendorDetailController);

    function VendorDetailController ($scope, $location, Vendor) {

        $scope.vendor = {};
        $scope.ready = false;

        var urlPieces = $location.absUrl().split("/");
        var id = urlPieces[urlPieces.length - 1];

        Vendor.resource.get({vendorId: id}, function(vendor) {
                $scope.vendor = vendor;
                $scope.ready = true;
        });

    }

})();
