(function () {
	angular.module("app").component("userProfile", {
		/* html */
		template: `
            <div ng-show="ready">
                <form name="profileForm" ng-submit="update()">
					<div layout="row" layout-padding class="padding-0-y">
						<div flex="20">
							<div layout="column" layout-align="start center">
								<div ngf-select="" ng-model="avatar" name="avatar" ngf-pattern="'image/*'" ngf-accept="'image/*'" ngf-max-size="10MB" layout="column" layout-align="start center">

									<img ng-if="!avatar && user.profile.avatar.url" class="avatar circle clickable" ng-src="{{user.profile.avatar.url}}">

									<img ng-if="!avatar && !user.profile.avatar.url" flex="100" class="avatar circle clickable" src="https://cdn.virtualsecurity.center/assets/user/img/avatar/default.png">

									<img ng-if="avatar" class="avatar circle clickable" flex="100" ngf-thumbnail="avatar">
								</div>

								<a class="margin-4-t" href="" ng-click="resetAvatar()">Reset Avatar</a>
							</div>
						</div>

						<div flex="40" class="padding-0-y">
							<md-input-container ng-if="user.email" flex="100" class="md-block">
								<label class="md-label">Email</label>
								<input type="text" ng-model="user.email" disabled>
							</md-input-container>

							<md-input-container ng-if="user.username" flex="100" class="md-block">
								<label class="md-label">Username</label>
								<input type="text" ng-model="user.username" disabled>
							</md-input-container>

							<md-input-container class="md-block">
								<label class="md-label">First Name</label>
								<input type="text" name="firstName" ng-model="user.profile.firstName" required>

								<div ng-messages="profileForm.firstName.$error">
									<div ng-message="required">This is required</div>
								</div>
							</md-input-container>

							<md-input-container class="md-block">
								<label class="md-label">Last Name</label>
								<input type="text" name="lastName" ng-model="user.profile.lastName" required>

								<div ng-messages="profileForm.lastName.$error">
									<div ng-message="required">This is required</div>
								</div>
							</md-input-container>
						</div>

						<div flex="40" class="padding-0-y">
							<md-input-container class="md-block">
								<label class="md-label">Title</label>
								<input type="text" ng-model="user.profile.title">
							</md-input-container>

							<md-input-container class="md-block">
								<label class="md-label">Phone</label>
								<input type="text" ng-model="user.profile.phone" phone-input>
							</md-input-container>

							<md-input-container class="md-block">
								<label class="md-label">Phone Extension</label>
								<input type="text" ng-model="user.profile.phoneExtention">
							</md-input-container>
						</div>
					</div>

                    <div layout layout-align="end center">
                        <md-button type="submit" class="md-button md-primary md-raised">Update</md-button>
                    </div>
                </form>
            </div>
        `,

		controller: function ($scope, $http, $mdDialog, $mdToast, Session, User, Upload, Instruction) {
			$scope.ready = true;
			$scope.avatarURL = "";

			$scope.user = Session.currentUser;

			// save user
			$scope.update = function () {
				$http.post("/api/user", {
					profile: $scope.user.profile
				}).then(function (response) {
					$mdToast.show($mdToast.simple().textContent("Profile updated"));

					if ($scope.avatar) {
						Upload.upload({
							url: "/api/user/avatar",

							data: {
								file: $scope.avatar
							}
						}).then(function (response) {
							$scope.user.profile.avatar = response.data;
							showToast("Avatar uploaded");
						}).catch(function (res) {
							showToast("Error uploading avatar");
						});
					}
				}, function (response) {
					$mdToast.show($mdToast.simple().textContent("Error saving profile"));
				});
			};

			$scope.resetGuidance = () => {
				Instruction.reset(() => {});
			}

			// show toaster message
			showToast = function (msg) {
				$mdToast.show($mdToast.simple().textContent(msg).hideDelay(3000));
			};

			/*
			 * TODO: make factory
			 * */
			$scope.resetAvatar = function () {
				$scope.avatar = null;

				$http.delete("/api/user/avatar").then(function (response) {
					$scope.user.profile.avatar = response.data;
					showToast("Avatar image reset");
				}, function (response) {
					showToast("Error resetting avatar");
				});
			}
		},
	});
})();