/**
 * @ngdoc service
 * @name app.control.service:Remediation
 * @author Nick Vorie
 *
 * @description
 * This Factory provides helper methods for dealing with Remediation plans
 */
(function () {
    "use strict";
    
    angular.module("app").factory("Remediation", RemediationFactory);

    function RemediationFactory ($resource, $http, $mdDialog, $mdToast, User) {
        
        var RemediationResource = $resource("/api/task/remediation/:id", {
            id: "@_id"
        }, {
            updateStep: {
                method: "POST",
                url: "/api/task/Remediation/:id/step/:stepId"
            }
        });
        
        function RemediationProvider () {
            var self = this;
            
            var defaults = {
                endpoint: "/api/container",
                containerId: "",
            }

            self.instance = function (options) {
                var _self = this;

                _self.options = options = _.assign(defaults, options);

                _self.add = function ($event, data, cb) {
                    console.log("REM3", data);

                    $http.post(`${_self.options.endpoint}/${_self.options.containerId}/remediation/add`, data).then((res) => {
                        $mdToast.showSimple("Plan saved");

                        if (cb) {
                            cb(res);
                        }
                    });
                }

                _self.submit = function ($event, remediation) {
                    $mdDialog.show($mdDialog.confirm().title("All done?").textContent("After submitting you will be unable to edit").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function() {
                        $mdToast.showSimple("Submitting remediation");

                        $http.post(`${_self.options.endpoint}/${_self.options.containerId}/remediation/submit`, {
                            steps: remediation.steps
                        }).then((res) => {
                            $mdToast.showSimple("Saved");

                            if (res.data.success) {
                                window.location = "/#" + self.options.containerType
                            }
                        });
                    });
                }

                _self.reset = function ($event, remediation) {
                    $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Are you sure you wish to reset all remediation answers?").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function() {
                        $mdToast.showSimple("Resetting remediation");

                        remediation.steps.forEach((step) => {
                            step.remediation.acknowledged = false;

                            step.remediation.attributes.forEach((a) => {
                                a.remediation.acknowledged = false;
                            });
                        });

                        $http.post(`${_self.options.endpoint}/${_self.options.containerId}/remediation/submit`, {
                            steps: remediation.steps
                        }).then((res) => {
                            $mdToast.showSimple("Saved");
                        });
                    });
                }

                _self.close = function ($event, container) {
                    $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Closing an remediation will discard any selected control states").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function() {
                        $mdToast.showSimple("Closing remediation");

                        $http.post(`${_self.options.endpoint}/${_self.options.containerId}/remediation/close`).then((res) => {
                            $mdToast.showSimple("Remediation closed");
                            container.remediation.current = null;
                        });
                    });
                }

                return _self;
            }

            self.close = close;

            function close ($event, container, containerType) {
                $mdDialog.show($mdDialog.confirm().title("Please confirm").textContent("Closing a Remediation plan will discard any selected control states").targetEvent($event).ok("Confirm").cancel("Cancel")).then(function() {
                    $mdToast.showSimple("Closing Remediation");

                    $http.post(`/api/${container.type.type}/${container._id}/remediation/close`).then((res) => {
                        $mdToast.showSimple("Remediation closed");
                        container.remediation.current = null;
                    });
                });
            }


            /*
             * Returns an array of the current User's assigned Remediation Plans
             * */
            self.getAssignedPlans = (cb) => {
                $http.get("/api/remediation/assigned").then((res) => {
                    cb(res.data);
                });
            }

            self.stepsComplete = function (steps) {
                var total = steps.length;

                var completed = steps.filter((step) => {
                    return !!step.action.acknowledged;
                }).length;

                var percent = Math.round((completed / total) * 100);

                return {
                    total: total,
                    completed: completed,
                    percent: percent,
                    text: `${completed}/${total} (${percent}%) completed`
                }
            }

            /**
            * @ngdoc method
            * @methodOf app.control.service:Remediation
            * @name getDueDate
            *
            * @param {array} An Array of Remediation steps
            *
            * @description Returns the closest due date for an Array of steps
            */
            self.getDueDate = function (steps) {
                return _.head(_.sortBy(_.filter(steps, (step) => (step.dueDate)), (step) => (step.dueDate))).dueDate;
            }

            self.stepsOverdue = function (steps) {
                return steps.filter((step) => moment().isAfter(step.dueDate));
            }

            return self;
        }

        return new RemediationProvider();
    }
})();
